import { ErrorPayload } from './error.types';

export const SET_ERROR = 'ERROR/SET_ERROR';

export function setError(payload: ErrorPayload) {
  return {
    type: SET_ERROR,
    payload
  } as const;
}

export type ErrorActionTypes = ReturnType<typeof setError>;

import React from 'react';
import { useSelector } from 'react-redux';

import ClaimSummary from '../../../components/Claim/ClaimSummary';
import { AppState } from '../../../redux/root.reducer';
import { getClaimById } from '../../../redux/selectors/claim.selectors';
import { getClaimItemsByClaimId } from '../../../redux/selectors/claim-item.selectors';
import { getAddressByClaimId } from '../../../redux/selectors';

export interface ClaimSummaryContainerProps {
  claimId: string;
}

const ClaimSummaryContainer = ({ claimId }: ClaimSummaryContainerProps) => {
  const claim = useSelector((state: AppState) => getClaimById(state, claimId));
  const items = useSelector((state: AppState) => getClaimItemsByClaimId(state, { claimId }));
  const address = useSelector((state: AppState) => getAddressByClaimId(state, claimId));

  if (!claim) return null;
  return <ClaimSummary address={address} key={claimId} claim={claim} items={items} />;
};

export default ClaimSummaryContainer;

import { TenancyInventoryDTO } from '@reposit/api-client';
import moment from 'moment';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getFirstInventoryRespondentByClaimId } from '../../redux/tenancy-inventory/tenancy-inventory.selectors';
import InlineTable from '../InlineTable';
import RepositCard from '../Reposit/RepositCard';

interface InventoryDetailsProps {
  inventory: TenancyInventoryDTO;
  claimId: string;
}

const InventoryDetailsCard = styled(RepositCard)`
  width: 100%;
`;

const InventoryDetails: React.FC<InventoryDetailsProps> = ({ inventory, claimId }) => {
  const respondentThatConfirmed = useSelector(getFirstInventoryRespondentByClaimId(claimId));

  const items: Array<{ key: string; value: string; linkTo?: string }> = [
    {
      key: 'Confirmed',
      value: inventory.confirmed ? 'Yes' : 'No',
    },
  ];

  if (respondentThatConfirmed) {
    const { firstName, lastName, id: confirmedByUserId } = respondentThatConfirmed.user;
    items.push(
      {
        key: 'Confirmed By',
        value: respondentThatConfirmed ? `${firstName} ${lastName}` : 'N/A',
        linkTo: `/users/${confirmedByUserId}`,
      },
      {
        key: 'Timestamp',
        value: respondentThatConfirmed ? moment(respondentThatConfirmed.respondedAt).format('DD/MM/YYYY HH:mm:ss') : 'N/A',
      }
    );
  }

  return (
    <InventoryDetailsCard flush title="Inventory Details" tooltip="lorem">
      <Container>
        <Row>
          <Col lg={12} style={{ padding: 0 }}>
            <InlineTable items={items} />
          </Col>
        </Row>
      </Container>
    </InventoryDetailsCard>
  );
};

export default InventoryDetails;

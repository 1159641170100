import { UsersFilter, UsersSort } from '../../constants/users';
import { USER_LIST_PAGE_SIZE } from './user-list.saga';
import { FETCH_USERS_API_SUCCESS, UserListActionTypes, USERS_UPDATE_FILTERS, USERS_GOTO_PAGE } from './user-list.actions';
import { UserListState } from './user-list.types';

const initialState: UserListState = {
  results: [],
  count: 0,
  page: 1,
  filters: {
    query: '',
    filter: UsersFilter.ALL,
    sort: UsersSort.CREATED_AT,
    tenancyStatus: ''
  },
  lastPage: undefined
};

export default (state = initialState, action: UserListActionTypes): UserListState => {
  switch (action.type) {
    case FETCH_USERS_API_SUCCESS:
      const last = Math.ceil(action.payload.count / USER_LIST_PAGE_SIZE);

      return {
        ...state,
        count: action.payload.count,
        lastPage: last,
        results: [...action.payload.ids]
      };

    case USERS_GOTO_PAGE:
      const { count, page } = state;
      const lastPage = Math.ceil(count / USER_LIST_PAGE_SIZE);
      let p = action.payload;

      if (p === 'prev') p = page - 1;
      if (p === 'next') p = page + 1;
      if (p >= lastPage) p = lastPage;
      if (p <= 0) p = 1;

      return {
        ...state,
        page: p
      };

    case USERS_UPDATE_FILTERS:
      return {
        ...state,
        page: 1,
        filters: {
          ...state.filters,
          ...action.payload
        }
      };

    default:
      return state;
  }
};

import styled from 'styled-components';

export const TableContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const TableItem = styled.div`
  display: flex;
  flex: 1;
  flex-basis: 40%;
  align-items: center;
  min-height: 48px;
  background: #faf9fc;
  margin-bottom: 5px;
  padding: 0 40px;
`;

export const TableColumn = styled.div<{ header?: boolean }>`
  font-family: ${(props) => props.theme.typography.face.secondary};
  color: ${(props) => props.theme.colors.body2};

  ${(props) =>
    props.header &&
    `
    flex-basis: 180px;
    min-width: 180px;
    font-weight: bold;
    color: ${props.theme.colors.body}
  `}
`;

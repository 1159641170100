import React, { useEffect } from 'react';
import styled from 'styled-components';
import { appConfig } from '../../appConfig';
import { useSelector } from 'react-redux';
import { getCurrentRoute } from '../../redux/auth/auth.selectors';

const LogoutWrapper = styled.div`
  /* background: rgba(0, 0, 0, 0.5); */
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
`;

const LoginView = () => {
  const currentRoute = useSelector(getCurrentRoute);
  useEffect(() => {
    window.location.href = `${appConfig.links.auth0.login}&state=${currentRoute}`;
  }, [currentRoute]);
  return <LogoutWrapper />;
};

export default LoginView;

import {
  DELETE_TENANT_API_SUCCESS,
  RepositActionTypes,
  EXTEND_OUTCOME_DEADLINE_API_SUCCESS,
  REMOVE_TENANT_ON_REPOSIT_API_SUCCESS,
  REOPEN_REPOSIT_API_SUCCESS,
} from '../../reposit/reposit.actions';

export const tenancyEntityReducer = (state: any = {}, action: RepositActionTypes) => {
  if (action.type === DELETE_TENANT_API_SUCCESS) {
    const newTenants = state[action.payload.tenancyId].tenants.filter(
      (tenantId: string) => tenantId !== action.payload.tenancyInviteId
    );
    return {
      ...state,
      [action.payload.tenancyId]: {
        ...state[action.payload.tenancyId],
        tenants: newTenants,
      },
    };
  }
  if (action.type === EXTEND_OUTCOME_DEADLINE_API_SUCCESS) {
    const { hasExtendedOutcomeDeadline, outcomeDeadline } = action.payload;
    return {
      ...state,
      [action.payload.id]: {
        ...state[action.payload.id],
        hasExtendedOutcomeDeadline,
        outcomeDeadline,
      },
    };
  }
  if (action.type === REMOVE_TENANT_ON_REPOSIT_API_SUCCESS) {
    const originalTenants = state[action.payload.tenancyId].tenants;
    const newTenants = originalTenants.filter((tenantId: string) => tenantId !== action.payload.tenantId);
    return {
      ...state,
      [action.payload.tenancyId]: {
        ...state[action.payload.tenancyId],
        tenants: newTenants,
      },
    };
  }
  if (action.type === REOPEN_REPOSIT_API_SUCCESS) {
    return {
      ...state,
      [action.payload.tenancyId]: {
        ...state[action.payload.tenancyId],
        checkout: undefined,
      },
    };
  }
  return state;
};

import { setToken, setCurrentRoute, logout } from './auth.actions';
import { AuthState } from './auth.types';
import { createReducer } from '@reduxjs/toolkit';

const initialState: AuthState = {
  token: '',
  currentRoute: '',
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(setToken, (state, action) => {
      state.token = action.payload;
    })
    .addCase(setCurrentRoute, (state, action) => {
      state.currentRoute = action.payload;
    })
    .addCase(logout, (state) => {
      state.currentRoute = '';
    });
});

import { ReferencesFilter, ReferencesSort } from '../../constants/references';
import { REFERENCING_LIST_PAGE_SIZE } from './reference-list.saga';
import {
  FETCH_REFERENCES_API_SUCCESS,
  ReferencingListActionTypes,
  REFERENCES_UPDATE_FILTERS,
  REFERENCES_GOTO_PAGE
} from './reference-list.actions';
import { ReferenceListState } from './reference-list.types';

const initialState: ReferenceListState = {
  results: [],
  count: 0,
  page: 1,
  filters: {
    filter: ReferencesFilter.IN_REVIEW,
    sort: ReferencesSort.CREATED_AT
  },
  lastPage: undefined
};

export default (state = initialState, action: ReferencingListActionTypes): ReferenceListState => {
  switch (action.type) {
    case FETCH_REFERENCES_API_SUCCESS:
      const last = Math.ceil(action.payload.count / REFERENCING_LIST_PAGE_SIZE);

      return {
        ...state,
        count: action.payload.count,
        lastPage: last,
        results: [...action.payload.ids]
      };

    case REFERENCES_GOTO_PAGE:
      const { count, page } = state;
      const lastPage = Math.ceil(count / REFERENCING_LIST_PAGE_SIZE);
      let p = action.payload;

      if (p === 'prev') p = page - 1;
      if (p === 'next') p = page + 1;
      if (p >= lastPage) p = lastPage;
      if (p <= 0) p = 1;

      return {
        ...state,
        page: p
      };

    case REFERENCES_UPDATE_FILTERS:
      return {
        ...state,
        page: 1,
        filters: {
          ...state.filters,
          ...action.payload
        }
      };

    default:
      return state;
  }
};

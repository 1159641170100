import { AppState } from '../root.reducer';
import { denormalize } from 'normalizr';
import SCHEMA from '../schema';
import { KpiDocumentDTO } from '@reposit/api-client';

export const getKpiDocumentsPagination = (state: AppState) => ({
  count: state.kpiDocument.count,
  page: state.kpiDocument.page,
});

export const getKpiDocuments = (state: AppState): KpiDocumentDTO[] =>
  state.kpiDocument.results.map((i) => denormalize(state.entities.kpiDocument[i], SCHEMA.kpiDocument, state.entities));

import React from 'react';
import { Link } from 'react-router-dom';

import { TableContainer, TableItem, TableColumn } from './styles';

export interface InlineTableItem {
  key: string;
  value: string | JSX.Element;
  linkTo?: string;
}

export interface InlineTableProps {
  items: Array<InlineTableItem>;
}

const InlineTable: React.FC<InlineTableProps> = ({ items }) => {
  const renderColumn = (item: InlineTableItem) => {
    if (typeof item.value === 'string') {
      if (item.linkTo) {
        return (
          <Link style={{ textDecoration: 'underline' }} to={item.linkTo}>
            {item.value}
          </Link>
        );
      } else {
        return item.value;
      }
    }
    return item.value;
  };

  return (
    <TableContainer>
      {items.map((item) => (
        <TableItem key={item.key}>
          <TableColumn header>{item.key}</TableColumn>
          <TableColumn>{renderColumn(item)}</TableColumn>
        </TableItem>
      ))}
    </TableContainer>
  );
};

export default InlineTable;

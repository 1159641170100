import { call, put, select } from 'redux-saga/effects';
import axios, { AxiosInstance } from 'axios';
import {
  TenanciesApi,
  UsersApi,
  TenancyOrdersApi,
  OrganizationsApi,
  AddressesApi,
  TenancyCheckoutsApi,
  ClaimsApi,
  ClaimItemsApi,
  TenancyExpiriesApi,
  ClaimDocumentsApi,
  DocumentsApi,
  ClaimItemDocumentsApi,
  OrganizationInvitesApi,
  TenancyInvitesApi,
  LandlordsApi,
  ReferencesApi,
  OrderCustomerActionsApi,
  ReferenceDocumentsApi,
  ChecksApi,
  IntegratorApi,
  IntegratorCommissionApi,
  ArbitrationsApi,
  ArbitrationDocumentsApi,
  KpiDocumentApi,
  TenancyAnniversariesApi,
  OrganizationUsersApi,
  TenantsApi,
  CommissionApi,
  TenancyOrderNotesApi,
  OrganizationBankAccountsApi,
  BankAccountsApi,
  AuthApi,
  TenancyUsersApi,
  PaymentPlansApi,
  OrdersApi,
  ReconciliationDocumentsApi,
  InternalReportingApi,
  InternalTeamMembersApi,
  OrganizationInternalOwnersApi,
} from '@reposit/api-client';

import { logout } from '../auth/auth.actions';
import { getAccessToken } from '../auth/auth.selectors';
import { appConfig } from '../../appConfig';
import { AppState } from '../root.reducer';
import { AppDispatch } from '../..';

const HTTP_UNAUTHORIZED = 401;

export const runSagaWithAuth = (saga: () => any) =>
  function* () {
    try {
      const result = yield call(() => saga());
      return result;
    } catch (e) {
      if (e.response.status === HTTP_UNAUTHORIZED) {
        yield put(logout());
      }
      throw e;
    }
  };

export const runThunkWithAuth = (thunk: () => Promise<any>, dispatch: AppDispatch) => {
  return thunk().catch((e) => {
    if (e.response.status === HTTP_UNAUTHORIZED) {
      dispatch(logout());
    }
    throw e;
  });
};

export function* createAxiosInstance() {
  const accessToken = yield select(getAccessToken);
  return axios.create({
    baseURL: appConfig.api.basePath,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export function createStandardAxiosInstance(state: AppState) {
  const accessToken = getAccessToken(state);
  return axios.create({
    baseURL: appConfig.api.basePath,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export function* createTenanciesApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new TenanciesApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createTenantsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new TenantsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createClaimsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new ClaimsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createClaimItemsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new ClaimItemsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createClaimItemDocumentsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new ClaimItemDocumentsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createClaimDocumentsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new ClaimDocumentsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createUsersApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new UsersApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createTenancyOrdersApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new TenancyOrdersApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createTenancyAnniversaryApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new TenancyAnniversariesApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createOrganizationsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new OrganizationsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createBankAccountsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new BankAccountsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createOrganizationBankAccountsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new OrganizationBankAccountsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createAddressesApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new AddressesApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createTenancyCheckoutsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new TenancyCheckoutsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createOrganizationInviteApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new OrganizationInvitesApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createOrganizationUserApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new OrganizationUsersApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createTenancyInviteApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new TenancyInvitesApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createTenancyExpiriesApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new TenancyExpiriesApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createDocumentsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new DocumentsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createLandlordsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new LandlordsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createReferencesApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new ReferencesApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createOrderCustomerActionApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new OrderCustomerActionsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createReferenceDocumentsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new ReferenceDocumentsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createChecksApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new ChecksApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createIntegratorsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new IntegratorApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createOrganizationInternalOwnersApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new OrganizationInternalOwnersApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createInternalTeamMembersApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new InternalTeamMembersApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createIntegratorCommissionApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new IntegratorCommissionApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createCommissionApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new CommissionApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createArbitrationsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new ArbitrationsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createArbitrationDocumentsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new ArbitrationDocumentsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createKpiDocumentsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new KpiDocumentApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createReconciliationDocumentsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new ReconciliationDocumentsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createInternalReportingApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new InternalReportingApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createTenancyOrderNotesApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new TenancyOrderNotesApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createAuthApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new AuthApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createTenancyUsersApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new TenancyUsersApi({}, appConfig.api.basePath, axiosInstance);
}

export function createStandardClaimsApi(state: AppState) {
  const axiosInstance: AxiosInstance = createStandardAxiosInstance(state);
  return new ClaimsApi({}, appConfig.api.basePath, axiosInstance);
}

export function createStandardPaymentPlansApi(state: AppState) {
  const axiosInstance: AxiosInstance = createStandardAxiosInstance(state);
  return new PaymentPlansApi({}, appConfig.api.basePath, axiosInstance);
}

export function createStandardOrdersApi(state: AppState) {
  const axiosInstance: AxiosInstance = createStandardAxiosInstance(state);
  return new OrdersApi({}, appConfig.api.basePath, axiosInstance);
}

import React from 'react';
import { FormikProps, Formik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';

import Modal from '../Modal/index';
import FieldWithLabel from '../FormFields/FieldWithLabel';
import { RadioGroup, Input } from '../FormFields';
import { Header3, P2 } from '../Typography';
import Sort from '../Sort';
import { Option } from '../../interfaces/option.interface';
import Button from '../Button';
import Loading from '../Loading';
import { useDispatch } from 'react-redux';
import { createOrganizationInviteRequested } from '../../redux/organization/organization.actions';
import { CreateOrganizationInvitePayload } from '../../redux/organization/organization.types';

const ActionBar = styled.div`
  display: flex;
  padding: 24px 0;
  justify-content: flex-end;
  flex-wrap: wrap;
  row-gap: 10px;

  button {
    margin-right: 10px;
  }
`;

interface OrganizationInviteModalProps {
  organizationId: string;
  onDismiss: () => void;
  integratorOptions: Option[];
  isInviteLoading: boolean;
}

enum IS_INTEGRATOR {
  YES = 'YES',
  NO = 'NO',
}

interface AddOrganizationInviteForm {
  email: string;
  isIntegrator: IS_INTEGRATOR;
  integratorId?: string;
}

const VALIDATION_SCHEMA = yup.object().shape({
  email: yup.string().email().required('Required'),
  isIntegrator: yup.string().required('Required'),
  integratorId: yup.string().when('isIntegrator', {
    is: (isIntegrator: IS_INTEGRATOR) => isIntegrator === IS_INTEGRATOR.YES,
    then: yup.string().required('Required'),
    otherwise: yup.string(),
  }),
});

interface FieldProps {
  fullWidth?: boolean;
}

const Field = styled.div<FieldProps>`
  min-height: 86px;
  width: ${(props) => (props.fullWidth ? '96%' : '47%')};
  margin: 0 5px 0 5px;
`;

export const OrganizationInviteModal: React.FC<OrganizationInviteModalProps> = ({
  onDismiss,
  integratorOptions,
  organizationId,
  isInviteLoading,
}) => {
  const dispatch = useDispatch();
  return (
    <Modal onDismiss={onDismiss}>
      <Header3>Create Organization Invite</Header3>
      <P2>
        If an integrator is selected the invite will be automatically accepted and the user onboarded. The integrator will be
        provided with API credentials for this user, and the user will be notified that the integration has been enabled. The user
        will be asked to set their password and agree to our T&Cs on initial login.
      </P2>

      <Formik
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={(values) => {
          const { integratorId, email } = values;

          const integratorData =
            values.isIntegrator === IS_INTEGRATOR.YES
              ? {
                  integratorId,
                }
              : {};

          const data: CreateOrganizationInvitePayload = {
            email,
            organizationId,
            ...integratorData,
          };

          dispatch(createOrganizationInviteRequested(data));
        }}
        initialValues={{
          email: '',
          isIntegrator: IS_INTEGRATOR.NO,
          integratorId: integratorOptions[0].value,
        }}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          errors,
          touched,
          isValid,
          setValues,
          setFieldValue,
        }: FormikProps<AddOrganizationInviteForm>) => {
          if (isInviteLoading) {
            return <Loading />;
          }
          return (
            <form onSubmit={handleSubmit}>
              <FieldWithLabel
                label="Are you onboarding for an integrator?"
                error={errors.isIntegrator}
                touched={touched.isIntegrator}
              >
                <RadioGroup
                  name="isIntegrator"
                  // has to be string here annoyingly
                  options={[
                    { value: IS_INTEGRATOR.YES, label: 'Yes' },
                    { value: IS_INTEGRATOR.NO, label: 'No' },
                  ]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.isIntegrator}
                  touched={touched.isIntegrator}
                  selected={values.isIntegrator}
                />
              </FieldWithLabel>
              {values.isIntegrator === IS_INTEGRATOR.YES ? (
                <FieldWithLabel label="Integrator">
                  <Sort
                    label="Integrator"
                    onSelect={(val) => setFieldValue('integratorId', val)}
                    options={integratorOptions}
                    selected={values.integratorId || ''}
                    hideLabel
                  />
                </FieldWithLabel>
              ) : null}
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Field fullWidth={true}>
                  <FieldWithLabel label="Email address" touched={touched.email} error={errors.email}>
                    <Input
                      name="email"
                      value={values.email}
                      error={errors.email}
                      touched={touched.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FieldWithLabel>
                </Field>
              </div>
              <ActionBar>
                <Button buttonType="warning" onClick={onDismiss}>
                  Cancel
                </Button>
                <Button disabled={!isValid}>Confirm</Button>
              </ActionBar>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

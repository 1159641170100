import React, { useState } from 'react';
import styled from 'styled-components';
import { Evidence } from './Evidence';
import { History, TENANT_ROUND } from './History';
import { DocumentEntity, ClaimProposalEntity } from '../../../redux/entities/entities.types';
import {
  ItemProposal,
  AgentDocumentsAndProposals,
  TenantDocumentsAndProposal,
  getAllAgentDocumentsAndProposalsForClaimItem,
  getAllTenantDocumentsAndProposalsForClaimItem,
  ClaimProposal,
} from '../../../redux/selectors/mediation.selectors';
import Card from '../../Card';
import { P3, P2 } from '../../Typography';
import { ClaimProposalDTOStatusEnum } from '@reposit/api-client';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/root.reducer';

interface AuditTrailProps {
  itemProposals: ItemProposal[];
  claimItemId: string;
}

const AuditTrailCard = styled(Card)`
  flex-grow: 1;
  margin-top: 1rem;
  min-width: 270px;
  padding-bottom: 14px;
`;

type TabOptions = 'evidence' | 'history';

interface TabProps {
  currentTab: TabOptions;
  tab: TabOptions;
}

export const AuditTrailContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-around;
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: center;
`;

export const DocumentLinks = styled.ul`
  padding: 0 0 0 14px;
  list-style: square;
  margin-top: 10px;
  line-height: 1.4em;
`;

export const DocumentLink = styled.li`
  margin: 0 0 6px;

  a {
    color: ${(props) => props.theme.colors.primaryBrand};
    display: inline;
    font-family: ${(props) => props.theme.typography.face.secondary};
  }
`;

export const EvidenceList: React.FC<{ documents?: DocumentEntity[] }> = ({ documents }) => {
  return documents && documents.length ? (
    <DocumentLinks>
      {documents.map((doc) => (
        <DocumentLink key={doc.id}>
          <a href={doc.url} target="_blank" rel="noopener noreferrer">
            {doc.name}
          </a>
        </DocumentLink>
      ))}
    </DocumentLinks>
  ) : (
    <P3>No Evidence Provided</P3>
  );
};

const TabContainer = styled.div`
  display: flex;
  margin: auto;
`;

const Tab = styled.div<TabProps>`
  background-color: ${(props) => (props.currentTab === props.tab ? props.theme.colors.secondaryBrand : 'white')};
  width: 130px;
  padding: 6px 0;
  text-align: center;
  border: solid 1px #e9ecef;

  cursor: ${(props) => (props.currentTab === props.tab ? 'default' : 'pointer')};
`;

const HistoryTab = styled(Tab)`
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  border-left: none;
`;

const EvidenceTab = styled(Tab)`
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
`;

const TabHeader = styled(P2)<TabProps>`
  margin-bottom: 0;
  color: ${(props) => (props.currentTab === props.tab ? 'white' : props.theme.colors.body)};
`;

export const isProposalPublished = (proposal: ClaimProposalEntity | ClaimProposal | undefined) => {
  if (!proposal) return false;
  else if (proposal.round === TENANT_ROUND) {
    return proposal.status !== ClaimProposalDTOStatusEnum.DRAFT && proposal.status !== ClaimProposalDTOStatusEnum.AWAITINGPAYMENT;
  } else {
    return proposal.status !== ClaimProposalDTOStatusEnum.DRAFT;
  }
};

export const getAgentDocumentsToShow = (docsAndProposals: AgentDocumentsAndProposals | undefined): DocumentEntity[] => {
  if (!docsAndProposals) return [];
  const agentFirstDocs =
    docsAndProposals && isProposalPublished(docsAndProposals.firstProposal) ? docsAndProposals.firstDocuments : [];
  const agentSecondDocs =
    docsAndProposals && isProposalPublished(docsAndProposals.secondProposal) ? docsAndProposals.secondDocuments : [];
  return [...agentFirstDocs, ...agentSecondDocs];
};
export const getTenantDocumentsToShow = (docsAndProposals: TenantDocumentsAndProposal | undefined): DocumentEntity[] => {
  if (!docsAndProposals) return [];
  return docsAndProposals && isProposalPublished(docsAndProposals.proposal) ? docsAndProposals.documents : [];
};

const AuditTrail: React.FC<AuditTrailProps> = ({ itemProposals, claimItemId }) => {
  const [currentTab, setCurrentTab] = useState<TabOptions>('history');

  const horizontalPadding = 40;

  const agentDocumentsAndProposals = useSelector((state: AppState) =>
    getAllAgentDocumentsAndProposalsForClaimItem(state, claimItemId)
  );
  const tenantDocumentsAndProposals = useSelector((state: AppState) =>
    getAllTenantDocumentsAndProposalsForClaimItem(state, claimItemId)
  );

  const agentDocumentsToShow = getAgentDocumentsToShow(agentDocumentsAndProposals);
  const tenantDocumentsToShow = getTenantDocumentsToShow(tenantDocumentsAndProposals);

  return (
    <AuditTrailContainer>
      <AuditTrailCard style={{ padding: `24px ${horizontalPadding}px 14px` }}>
        <FlexBox>
          <TabContainer>
            <HistoryTab currentTab={currentTab} tab="history" onClick={() => setCurrentTab('history')}>
              <TabHeader tab="history" currentTab={currentTab}>
                History
              </TabHeader>
            </HistoryTab>
            <EvidenceTab currentTab={currentTab} tab="evidence" onClick={() => setCurrentTab('evidence')}>
              <TabHeader tab="evidence" currentTab={currentTab}>
                Evidence
              </TabHeader>
            </EvidenceTab>
          </TabContainer>
        </FlexBox>

        {currentTab === 'evidence' ? (
          <Evidence agentDocuments={agentDocumentsToShow} tenantDocuments={tenantDocumentsToShow} itemProposals={itemProposals} />
        ) : (
          <History itemProposals={itemProposals} />
        )}
      </AuditTrailCard>
    </AuditTrailContainer>
  );
};

export default AuditTrail;

import { find } from 'lodash';

import { ClaimDocumentEntity } from '../entities/entities.types';
import { AppState } from '../root.reducer';

export const getClaimDocumentById = (state: AppState, claimDocumentId: string): ClaimDocumentEntity | undefined => {
  if (!state.entities.claimDocument) return undefined;

  return state.entities.claimDocument[claimDocumentId];
};

export const getClaimDocumentByDocumentId = (state: AppState, documentId: string): ClaimDocumentEntity | undefined => {
  if (!state.entities.claimDocument) return undefined;
  return find(state.entities.claimDocument, claimDocument => claimDocument.document === documentId);
};

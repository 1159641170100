import React from 'react';
import styled from 'styled-components';

export enum AnniversaryStatusEnum {
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED',
  NOT_CHARGABLE = 'NOT_CHARGABLE',
  NOT_DUE = 'NOT_DUE',
}

interface AnniversaryStatusProps {
  status: AnniversaryStatusEnum;
  label?: string;
}

const Container = styled.div<AnniversaryStatusProps>`
  background: ${(props) => props.theme.colors.positive};
  border-radius: 24px;
  display: inline-block;
  color: #fff;
  font-family: ${(props) => props.theme.typography.face.secondary};
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 0 auto;
  padding: 4px 20px;

  ${(props) =>
    props.status === AnniversaryStatusEnum.PENDING &&
    `
    background: ${props.theme.colors.warning}
  `}

  ${(props) =>
    props.status === AnniversaryStatusEnum.COMPLETE &&
    `
    background: ${props.theme.colors.positive}
  `}

  ${(props) =>
    props.status === AnniversaryStatusEnum.CANCELLED &&
    `
    background: ${props.theme.colors.negative}
  `}

  ${(props) => {
    if (props.status === AnniversaryStatusEnum.NOT_CHARGABLE || props.status === AnniversaryStatusEnum.NOT_DUE) {
      return `
      background: ${props.theme.colors.body2}
    `;
    }
  }}
`;

const resolveLabel = {
  [AnniversaryStatusEnum.PENDING]: 'Pending',
  [AnniversaryStatusEnum.COMPLETE]: 'Complete',
  [AnniversaryStatusEnum.NOT_CHARGABLE]: 'Not Chargable',
  [AnniversaryStatusEnum.CANCELLED]: 'Cancelled',
  [AnniversaryStatusEnum.NOT_DUE]: 'Not Due',
} as any;

export const AnniversaryStatus: React.FC<AnniversaryStatusProps> = ({ status, label }) => {
  return <Container status={status}>{label || resolveLabel[status]}</Container>;
};

import React from 'react';
import { useSelector } from 'react-redux';
import RepositSummary from '../../../components/Reposit/RepositSummary';
import { makeGetRepositSummary } from '../../../redux/selectors';
import { AppState } from '../../../redux/root.reducer';

export interface RepostSummaryContainerProps {
  tenancyOrderId: string;
}

export default (props: RepostSummaryContainerProps) => {
  const getRepositSummary = makeGetRepositSummary();
  const summary = useSelector((state: AppState) => getRepositSummary(state, props));

  if (!summary) return null;
  return <RepositSummary key={props.tenancyOrderId} data={summary} />;
};

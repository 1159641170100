import { createReducer } from '@reduxjs/toolkit';
import { OrderState } from './order.types';
import { fetchAmountRemainingForPaymentPlanOrderThunk } from './order.thunk';
import { clearAmountRemaining } from './order.action';

const initialState: OrderState = {
  amountRemaining: undefined,
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(fetchAmountRemainingForPaymentPlanOrderThunk.fulfilled, (state, action) => {
      state.amountRemaining = action.payload;
    })
    .addCase(clearAmountRemaining.type, (state) => {
      state.amountRemaining = undefined;
    });
});

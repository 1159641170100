import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';

import RepositCard from '../Reposit/RepositCard';
import { Container, Row, Col } from 'react-grid-system';
import FieldWithLabel from '../FormFields/FieldWithLabel';
import TextArea from '../FormFields/TextArea';
import Button from '../Button';
import styled from 'styled-components';
import { RadioGroup } from '../FormFields';
import {
  approveClaimRequested,
  APPROVE_CLAIM_STORE_KEY,
  declineClaimRequested,
  DECLINE_CLAIM_STORE_KEY,
  requestClaimEvidencesRequested,
  REQUEST_CLAIM_EVIDENCES_STORE_KEY,
} from '../../redux/claim/claim.actions';
import { useFlashMessage, FLASH_MESSAGE_TIMEOUT } from '../../containers/FlashMessage';
import FlashMessage from '../FlashMessage';
import { createLoadingSelector } from '../../redux/loading/loading.selector';

interface ClaimReviewProps {
  claimId: string;
  canSendMessage: boolean;
}

interface ClaimForm {
  decision: '' | 'APPROVED' | 'REJECTED' | 'FURTHER_EVIDENCE_REQUIRED';
  message: string;
}

const Actions = styled.div`
  padding: 12px 0 0;

  button {
    margin: 0 10px 0 0;
  }
`;

const VALIDATION_SCHEMA = yup.object().shape({
  decision: yup.string().required('Required'),
  message: yup.string().when('decision', {
    is: (decision: string) => decision === 'FURTHER_EVIDENCE_REQUIRED',
    then: yup.string().required('Required'),
    otherwise: yup.string(),
  }),
});

const ClaimReview: React.FC<ClaimReviewProps> = ({ claimId, canSendMessage }) => {
  const dispatch = useDispatch();
  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage([
    APPROVE_CLAIM_STORE_KEY,
    DECLINE_CLAIM_STORE_KEY,
    REQUEST_CLAIM_EVIDENCES_STORE_KEY,
  ]);
  const loading = useSelector(
    createLoadingSelector([APPROVE_CLAIM_STORE_KEY, DECLINE_CLAIM_STORE_KEY, REQUEST_CLAIM_EVIDENCES_STORE_KEY])
  );

  const onSubmit = ({ decision, message }: ClaimForm) => {
    if (decision) {
      if (decision === 'APPROVED') {
        dispatch(approveClaimRequested(claimId));
      } else if (decision === 'REJECTED') {
        dispatch(declineClaimRequested(claimId));
      } else if (decision === 'FURTHER_EVIDENCE_REQUIRED') {
        dispatch(requestClaimEvidencesRequested({ claimId, message }));
      }
    }
  };

  const radioOptions = [
    {
      value: 'APPROVED',
      label: 'Approved',
    },
    {
      value: 'REJECTED',
      label: 'Rejected',
    },
  ];

  if (canSendMessage) {
    radioOptions.push({
      value: 'FURTHER_EVIDENCE_REQUIRED',
      label: 'Request more evidence',
    });
  }

  return (
    <RepositCard
      title="Review"
      subtitle="This claim requires reviewing by a Reposit admin"
      flashMessage={
        flashMessagePayload ? (
          <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMEOUT} payload={flashMessagePayload} />
        ) : undefined
      }
      tooltip="lorem"
    >
      <Formik
        validationSchema={VALIDATION_SCHEMA}
        initialValues={{
          decision: '',
          message: '',
        }}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, handleBlur, handleSubmit, touched, errors, isValid }: FormikProps<ClaimForm>) => (
          <form onSubmit={handleSubmit}>
            <Container fluid>
              <Row>
                <Col lg={12} style={{ padding: 0 }}>
                  <FieldWithLabel
                    label="Decision"
                    touched={touched.decision}
                    error={errors.decision}
                    style={{ marginBottom: 24 }}
                  >
                    <RadioGroup
                      name="decision"
                      options={radioOptions}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      selected={values.decision}
                    />
                  </FieldWithLabel>
                </Col>
              </Row>
              {values.decision === 'FURTHER_EVIDENCE_REQUIRED' ? (
                <Row>
                  <Col lg={12} style={{ padding: 0 }}>
                    <FieldWithLabel label="Message" touched={touched.message} error={errors.message}>
                      <TextArea name="message" value={values.message} onChange={handleChange} onBlur={handleBlur} />
                    </FieldWithLabel>
                  </Col>
                </Row>
              ) : null}
              <Row>
                <Col lg={12} style={{ padding: 0 }}>
                  <Actions>
                    <Button buttonType="secondary" noArrow disabled={loading || !isValid}>
                      Submit Review
                    </Button>
                    {/* <Button buttonType="secondary" noArrow>
                    Request More Evidence
                  </Button>
                  <Button buttonType="warning" noArrow>
                    Reject Claim
                  </Button> */}
                  </Actions>
                </Col>
              </Row>
            </Container>
          </form>
        )}
      </Formik>
    </RepositCard>
  );
};

export default ClaimReview;

import { ModalState } from './modal.types';
import { ModalActionTypes, SET_CURRENT_MODAL } from './modal.actions';

const initialState: ModalState = {
  currentModal: '',
};

export default (state: ModalState = initialState, action: ModalActionTypes): ModalState => {
  switch (action.type) {
    case SET_CURRENT_MODAL: {
      return {
        ...state,
        currentModal: action.payload,
      };
    }
    default:
      return state;
  }
};

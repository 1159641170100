import styled from 'styled-components';

import { RepositTheme } from '../../../base/theme';
import { getBreakpoint } from '../../../base/style';
import { P2, P3, Header5, Header3, Caption } from '../../Typography';
import CalendarIcon from '../../../assets/svg/calendar-blk.svg';

const RepositStatusColors: { [key: string]: string } = {
  PENDING: RepositTheme.colors.warning,
  COMPLETE: RepositTheme.colors.positive,
  DRAFT: RepositTheme.colors.body2,
  DELETED: RepositTheme.colors.negative,
  CLOSED: RepositTheme.colors.negative,
  CHECKED_OUT: RepositTheme.colors.positive,
};

export const SummaryContainer = styled.div`
  @media all and (min-width: ${getBreakpoint('lg')}) {
    display: flex;
    justify-content: space-between;
  }
`;

export const ColLeft = styled.div``;

export const ColRight = styled.div`
  flex: 0 0 280px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    flex-direction: column;
    max-width: 280px;
  }
`;

export const RepositStatus = styled(P2)<{ status: string }>`
  align-items: center;
  color: ${(props) => RepositStatusColors[props.status]};
  display: flex;

  &:before {
    background: ${(props) => RepositStatusColors[props.status]};
    border-radius: 12px;
    content: '';
    display: block;
    height: 10px;
    margin: -2px 10px 0 0;
    width: 10px;
  }
`;

export const Action = styled.div`
  display: flex;
  justify-content: space-between;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    justify-content: flex-end;
  }
`;

export const SmallLabel = styled(P3)`
  margin: 0;
`;

export const TenancyDates = styled.div`
  display: none;
  margin: 24px 0 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
  }
`;

export const Label = styled(Header5)`
  align-items: center;
  display: flex;
  margin: 0 14px 0 0;
  padding: 0;

  &:before {
    background: transparent url(${CalendarIcon}) no-repeat center center;
    background-size: 16px;
    content: '';
    display: block;
    height: 20px;
    margin: -3px 10px 0 0;
    width: 20px;
  }
`;

export const Dates = styled(P2)`
  color: ${(props) => props.theme.colors.body2};
  margin: 12px 0 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    margin: 1px 0 0;
    padding: 0;
  }
`;

export const Organization = styled.div`
  align-items: center;
  display: none;
  margin: 24px 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: none;
    display: block;
    text-align: right;
    margin: 24px 0 0;
  }
`;

export const OrganizationName = styled.span`
  color: ${(props) => props.theme.colors.body2};
  display: block;
  font-family: ${(props) => props.theme.typography.face.secondary};
  line-height: 1.5;
  letter-spacing: 0.09px;
  margin: 0 8px;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    margin: 4px 0 0;
  }
`;

export const NoAddress = styled(P2)`
  font-style: italic;
`;

export const Address = styled.div`
  width: 100%;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
  }
`;

export const FirstLineAddress = styled(Header3)`
  line-height: 1.2;
  margin: 0;
  padding: 90;
`;

export const TownPostcode = styled(Caption)`
  padding: 2px 0 0;
  @media screen and (min-width: ${getBreakpoint('lg')}) {
    margin: 0 0 0 12px;
  }
`;

export const ShowMoreTitle = styled(Header3)<{ mb15?: boolean }>`
  margin: 0;
  margin-top: 15px;
  ${(props) =>
    props.mb15 &&
    `
    margin-bottom: 15px;
  `}
`;

export const FETCH_RECONCILIATION_DOCUMENTS_STORE_KEY = `REPOSIT_LIST/FETCH_RECONCILIATION_DOCUMENTS`;
export const FETCH_RECONCILIATION_DOCUMENTS_API_REQUESTED = `REPOSIT_LIST/FETCH_RECONCILIATION_DOCUMENTS_API_REQUESTED`;
export const FETCH_RECONCILIATION_DOCUMENTS_API_SUCCESS = `REPOSIT_LIST/FETCH_RECONCILIATION_DOCUMENTS_API_SUCCESS`;
export const FETCH_RECONCILIATION_DOCUMENTS_API_FAILED = `REPOSIT_LIST/FETCH_RECONCILIATION_DOCUMENTS_API_FAILED`;

export function fetchReconciliationDocumentsRequested(date: string) {
  return {
    type: FETCH_RECONCILIATION_DOCUMENTS_API_REQUESTED,
    payload: {
      date,
    },
  } as const;
}
export function fetchReconciliationDocumentsSuccess(count: number, ids: string[]) {
  return {
    type: FETCH_RECONCILIATION_DOCUMENTS_API_SUCCESS,
    payload: {
      count,
      ids,
    },
  } as const;
}
export function fetchReconciliationDocumentsFailed(error: string) {
  return {
    type: FETCH_RECONCILIATION_DOCUMENTS_API_FAILED,
    payload: error,
  } as const;
}

export type ReconciliationDocumentsActionTypes = ReturnType<
  | typeof fetchReconciliationDocumentsRequested
  | typeof fetchReconciliationDocumentsSuccess
  | typeof fetchReconciliationDocumentsFailed
>;

import React from 'react';
import styled from 'styled-components';

interface RepositSwitchProps {}

const Container = styled.div`
  background: ${(props) => props.theme.colors.warning};
  border-radius: 24px;
  color: #fff;
  font-family: ${(props) => props.theme.typography.face.secondary};
  font-size: 1em;
  font-weight: bold;
  margin: 0;
  padding: 6px 20px;
  display: flex;
  justify-content: center; /* Align horizontal */
  align-items: center; /* Align vertical */
`;

const RepositSwitch: React.FC<RepositSwitchProps> = () => {
  return <Container>Switch</Container>;
};

export default RepositSwitch;

import { CreateDocumentPayload, CreateDocumentSuccessPayload } from './document.types';

export const CREATE_DOCUMENT_STORE_KEY = `DOCUMENT/CREATE_DOCUMENT`;
export const CREATE_DOCUMENT_API_REQUESTED = `DOCUMENT/CREATE_DOCUMENT_API_REQUESTED`;
export const CREATE_DOCUMENT_API_SUCCESS = `DOCUMENT/CREATE_DOCUMENT_API_SUCCESS`;
export const CREATE_DOCUMENT_API_FAILED = `DOCUMENT/CREATE_DOCUMENT_API_FAILED`;

export const CLEAR_CLAIM_ITEM_DOCUMENT_IDS = `DOCUMENT/CLEAR_CLAIM_ITEM_DOCUMENT_IDS`;
export const REMOVE_CLAIM_ITEM_DOCUMENT_ID = `DOCUMENT/REMOVE_CLAIM_ITEM_DOCUMENT_ID`;

/* -------------------------------------------- CREATE DOCUMENT -------------------------------------------- */
export function createDocumentRequested(payload: CreateDocumentPayload) {
  return {
    type: CREATE_DOCUMENT_API_REQUESTED,
    payload
  } as const;
}
export function createDocumentSuccess(payload: CreateDocumentSuccessPayload) {
  return {
    type: CREATE_DOCUMENT_API_SUCCESS,
    payload
  } as const;
}
export function createDocumentFailed(error: string) {
  return {
    type: CREATE_DOCUMENT_API_FAILED,
    payload: error
  } as const;
}

export function clearClaimItemDocumentIds() {
  return {
    type: CLEAR_CLAIM_ITEM_DOCUMENT_IDS
  } as const;
}

export function removeClaimItemDocumentId(payload: string) {
  return {
    type: REMOVE_CLAIM_ITEM_DOCUMENT_ID,
    payload
  } as const;
}

export type DocumentActionTypes = ReturnType<
  | typeof createDocumentRequested
  | typeof createDocumentSuccess
  | typeof createDocumentFailed
  | typeof clearClaimItemDocumentIds
  | typeof removeClaimItemDocumentId
>;

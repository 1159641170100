import { UsersApi, SearchUserResultsDTO } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import SCHEMA from '../schema';
import { createUsersApi, runSagaWithAuth } from '../utils/api.utils';
import { FETCH_USERS_API_REQUESTED, UserListActionTypes, fetchUsersSuccess, fetchUsersFailed } from './user-list.actions';
import { UserListFilters } from './user-list.types';
import { getUserListFilters, getUserListPagination } from './user-list.selectors';

// ****************
// WORKERS
// ****************

export const USER_LIST_PAGE_SIZE = 10;

export function* fetchUsers() {
  try {
    const filters: UserListFilters = yield select(getUserListFilters);
    const pagination = yield select(getUserListPagination);
    const usersApi: UsersApi = yield createUsersApi();

    const apiResponse: AxiosResponse<SearchUserResultsDTO> = yield call(
      runSagaWithAuth(() =>
        usersApi.searchUser(filters.filter, pagination.page, USER_LIST_PAGE_SIZE, filters.query, filters.sort)
      )
    );

    const ids: string[] = yield syncEntitiesAndGetResults(apiResponse.data.results, SCHEMA.users);
    yield put<UserListActionTypes>(fetchUsersSuccess(apiResponse.data.count, ids));
  } catch (e) {
    yield put<UserListActionTypes>(fetchUsersFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchUserListSagas() {
  yield takeLatest(FETCH_USERS_API_REQUESTED, fetchUsers);
}

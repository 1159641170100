import { DELETE_REPOSIT_NOTE_API_SUCCESS, RepositActionTypes } from '../../reposit/reposit.actions';

export const tenancyOrderEntityReducer = (state: any = {}, action: RepositActionTypes) => {
  if (action.type === DELETE_REPOSIT_NOTE_API_SUCCESS) {
    const newNotes = state[action.payload.tenancyOrderId].tenancyOrderNotes.filter(
      (tenancyOrderNoteId: string) => tenancyOrderNoteId !== action.payload.tenancyOrderNoteId
    );
    return {
      ...state,
      [action.payload.tenancyOrderId]: {
        ...state[action.payload.tenancyOrderId],
        tenancyOrderNotes: newNotes,
      },
    };
  }
  return state;
};

export const SET_CURRENT_MODAL = `MODAL/SET_CURRENT_MODAL`;

export type CurrentModal = 'CREATE_ORGANIZATION_INVITE' | 'RESET_PASSWORD_EMAIL' | '';

export function setCurrentModal(payload: CurrentModal) {
  return {
    type: SET_CURRENT_MODAL,
    payload,
  } as const;
}

export type ModalActionTypes = ReturnType<typeof setCurrentModal>;

import { InternalTeamMembersActions, GET_INTERNAL_TEAM_MEMBERS_API_SUCCESS } from './internal-team-member.actions';

export interface InternalTeamMemberState {
  results: string[];
}

const initialState: InternalTeamMemberState = {
  results: [],
};

export default (state = initialState, action: InternalTeamMembersActions): InternalTeamMemberState => {
  switch (action.type) {
    case GET_INTERNAL_TEAM_MEMBERS_API_SUCCESS:
      return {
        ...state,
        results: action.payload.ids,
      };

    default:
      return state;
  }
};

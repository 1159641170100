export const FETCH_ORGANIZATION_INTERNAL_OWNERS_BY_ID_STORE_KEY =
  'ORGANIZATION_INTERNAL_OWNERS/FETCH_ORGANIZATION_INTERNAL_OWNERS_BY_ID';
export const FETCH_ORGANIZATION_INTERNAL_OWNERS_BY_ID_API_REQUESTED =
  'ORGANIZATION_INTERNAL_OWNERS/FETCH_ORGANIZATION_INTERNAL_OWNERS_BY_ID_API_REQUESTED';
export const FETCH_ORGANIZATION_INTERNAL_OWNERS_BY_ID_API_SUCCESS =
  'ORGANIZATION_INTERNAL_OWNERS/FETCH_ORGANIZATION_INTERNAL_OWNERS_BY_ID_API_SUCCESS';
export const FETCH_ORGANIZATION_INTERNAL_OWNERS_BY_ID_API_FAILED =
  'ORGANIZATION_INTERNAL_OWNERS/FETCH_ORGANIZATION_INTERNAL_OWNERS_BY_ID_API_FAILED';

export function fetchOrganizationInternalOwnersByIdRequested(organizationId: string) {
  return {
    type: FETCH_ORGANIZATION_INTERNAL_OWNERS_BY_ID_API_REQUESTED,
    payload: {
      organizationId,
    },
  } as const;
}

export function fetchOrganizationInternalOwnersByIdSuccess(ids: string[]) {
  return {
    type: FETCH_ORGANIZATION_INTERNAL_OWNERS_BY_ID_API_SUCCESS,
    payload: {
      ids,
    },
  } as const;
}

export function fetchOrganizationInternalOwnersByIdFailed(error: string) {
  return {
    type: FETCH_ORGANIZATION_INTERNAL_OWNERS_BY_ID_API_FAILED,
    payload: error,
  } as const;
}

export type OrganizationInternalOwnerActions = ReturnType<
  | typeof fetchOrganizationInternalOwnersByIdRequested
  | typeof fetchOrganizationInternalOwnersByIdSuccess
  | typeof fetchOrganizationInternalOwnersByIdFailed
>;

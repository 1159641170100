import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import DownArrow from '../../../assets/svg/down-purple.svg';
import { getBreakpoint } from '../../../base/style';

interface SideMenuContainerProps {
  isOpen: boolean;
}

export const sideMenuWidth = 240;

export const SideMenuContainer = styled.div<SideMenuContainerProps>`
  background: #fff;
  box-sizing: border-box;
  flex: 1 0 ${sideMenuWidth}px;
  padding: 20px 0;
  height: 100vh;
  position: fixed;
  transform: ${(props) => (props.isOpen ? 'translate3d(0px, 0px,0px);' : `translate3d(-${sideMenuWidth}px, 0px,0px);`)};
  transition: transform 0.3s ease-out;
  width: ${sideMenuWidth}px;
  z-index: 999;

  @media all and (min-width: ${getBreakpoint('md')}) {
    transform: translate3d(0px, 0px, 0px);
    position: fixed;
  }
`;

interface NavigationProps {
  isOpen?: boolean;
  sub?: boolean;
}

interface NavButtonProps {
  isOpen?: boolean;
  subItem?: boolean;
}

export const Navigation = styled.ul<NavigationProps>`
  background: ${(props) => (props.sub ? props.theme.colors.bgAccent : 'transparent')};
  border-top: ${(props) => (props.sub && props.isOpen ? `1px solid ${props.theme.colors.dividerLight}` : 'none')};
  border-bottom: ${(props) => (props.sub && props.isOpen ? `1px solid ${props.theme.colors.dividerLight}` : 'none')};
  overflow: hidden;
  height: ${(props) => (props.sub && !props.isOpen ? 0 : 'auto')};
  position: relative;
`;

export const NavItem = styled.li<NavigationProps>`
  font-family: ${(props) => props.theme.typography.face.primary};
  border-top: 1px solid ${(props) => (props.sub ? props.theme.colors.dividerDark : 'none')};
  font-size: ${(props) => (props.sub ? '12px' : '14px')};
  letter-spacing: 0.39px;
  z-index: 1;
  padding: ${(props) => (props.sub ? '0 0 0 24px' : '0')};
  position: relative;
`;

export const NavItemButton = styled(NavLink)<NavigationProps>`
  align-items: center;
  cursor: pointer;
  display: flex;
  color: ${(props) => props.theme.colors.body2};
  padding: 14px 0;
  text-decoration: none;
  transition: all 0.2s;

  &:hover {
    color: ${(props) => props.theme.colors.primaryBrand};
  }

  &.active {
    &:before {
      opacity: 1;
    }

    color: ${(props) => props.theme.colors.primaryBrand};
  }
`;

export const NavButton = styled(NavItemButton)<NavButtonProps>`
  position: relative;
  color: ${(props) => props.theme.colors.body2};

  &:before {
    background: ${(props) => props.theme.colors.primaryBrand};
    border-radius: 1px;
    content: '';
    display: block;
    opacity: 0;
    height: 2px;
    margin: 0 9px 0 0;
    width: 12px;
  }

  &:after {
    background: transparent url(${DownArrow}) 0 -1px;
    background-size: 10px;
    content: '';
    display: ${(props) => (props.subItem ? 'block' : 'none')};
    height: 10px;
    position: absolute;
    right: 20px;
    transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'none')};
    transition: transform 0.2s;
    width: 10px;
  }
`;

export const LogoutButton = styled(NavItemButton)`
  color: ${(props) => props.theme.colors.negative};
  &:before {
    content: '';
    display: block;
    opacity: 0;
    height: 2px;
    margin: 0 9px 0 0;
    width: 12px;
  }

  &.active {
    color: ${(props) => props.theme.colors.negative};
  }
`;

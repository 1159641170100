import React, { useState } from 'react';
import styled from 'styled-components';
import { P3 } from '../Typography';

interface ExpandableContentProps {
  header?: JSX.Element;
  hiddenContent?: JSX.Element;
}

const Container = styled('div')`
  cursor: pointer;
`;

const Hider = styled(P3)<{ isOpen: boolean }>`
  margin-bottom: ${(props) => (props.isOpen ? '5px' : 0)};
  color: ${(props) => props.theme.colors.primaryBrand};
  cursor: pointer;
  display: block;
  display: flex;

  span {
    text-decoration: underline;
  }

  &:after {
    content: '+';
    display: block;
    margin: 0 6px;
  }
`;

export const ExpandableContent: React.FC<ExpandableContentProps> = ({ header, hiddenContent }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Container>
      {header ? header : null}
      {hiddenContent ? (
        <Hider onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
          {isOpen ? <span>Hide</span> : <span>Show More</span>}
        </Hider>
      ) : null}
      {isOpen && hiddenContent ? hiddenContent : null}
    </Container>
  );
};

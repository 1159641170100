import { ClaimFilter, ClaimSort } from '../../constants/claims';
import {
  ClaimListActionTypes,
  FETCH_CLAIMS_API_SUCCESS,
  CLAIMS_GOTO_PAGE,
  CLAIMS_UPDATE_FILTERS,
  CLEAR_FILTERS
} from './claim-list.actions';
import { CLAIM_LIST_PAGE_SIZE } from './claim-list.saga';
import { ClaimListState } from './claim-list.types';

const initialState: ClaimListState = {
  results: [],
  count: 0,
  page: 1,
  filters: {
    query: '',
    filter: ClaimFilter.ALL,
    sort: ClaimSort.CREATED_AT
  },
  lastPage: undefined
};

export default (state = initialState, action: ClaimListActionTypes): ClaimListState => {
  switch (action.type) {
    case FETCH_CLAIMS_API_SUCCESS:
      const last = Math.ceil(action.payload.count / CLAIM_LIST_PAGE_SIZE);
      return {
        ...state,
        count: action.payload.count,
        lastPage: last,
        results: [...action.payload.ids]
      };

    case CLAIMS_GOTO_PAGE:
      const { count, page } = state;
      const lastPage = Math.ceil(count / CLAIM_LIST_PAGE_SIZE);
      let p = action.payload;

      if (p === 'prev') p = page - 1;
      if (p === 'next') p = page + 1;
      if (p >= lastPage) p = lastPage;
      if (p <= 0) p = 1;

      return {
        ...state,
        page: p
      };

    case CLAIMS_UPDATE_FILTERS:
      return {
        ...state,
        page: 1,
        filters: {
          ...state.filters,
          ...action.payload
        }
      };

    case CLEAR_FILTERS:
      return {
        ...state,
        page: 1,
        filters: {
          query: '',
          filter: ClaimFilter.ALL,
          sort: ClaimSort.CREATED_AT
        }
      };

    default:
      return state;
  }
};

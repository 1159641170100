import { Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import * as Yup from 'yup';
import RepositLogo from '../../../assets/png/primary-full-colour.png';
import DisabledIcon from '../../../assets/svg/disabled.svg';
import { getBreakpoint } from '../../../base/style';
import { CardComponent } from '../../../components/Card/index';
import FieldWithLabel from '../../../components/FormFields/FieldWithLabel/index';
import { Input } from '../../../components/FormFields/index';
import { Header4, P2, StyledLink } from '../../../components/Typography/index';
import { setHasRequestedPasswordReset } from '../../../redux/account/account.actions';
import { getHasRequestedResetPassword, getPersistedEmail } from '../../../redux/account/account.selectors';
import { loginRequested, LOGIN_STORE_KEY } from '../../../redux/auth/auth.actions';
import { getAccessToken } from '../../../redux/auth/auth.selectors';
import { createErrorMessageSelector } from '../../../redux/error/error.selector';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';

interface LoginFormProps {
  email: string;
  password: string;
}

const Schema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
  password: Yup.string().required('Required'),
});

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 40px 40px 24px;
  text-align: center;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 40px 40px;
`;

export const LogoImage = styled.img`
  max-height: 52px;
  display: block;
  object-fit: contain;
  max-width: 100%;
`;

export const AuthButton = styled.button`
  margin-top: 18px;
  align-items: center;
  text-align: center;
  border: none;
  cursor: pointer;
  font-family: ${(props) => props.theme.typography.face.primary};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  outline: none;
  text-decoration: none;
  width: 100%;
  height: 52px;
  background-color: ${(props) => props.theme.colors.primaryBrand};
  color: white;
  font-size: 16px;
  border-radius: 3px;

  &:disabled {
    background: ${(props) => props.theme.colors.disabled};
    color: ${(props) => props.theme.colors.placeholder};
    cursor: not-allowed;
    transition: all 0.3s;

    &:after {
      background: transparent url(${DisabledIcon}) center center;
      background-size: 12px;
      object-fit: contain;
      display: block;
    }

    &:hover {
      background: ${(props) => props.theme.colors.disabled};
      color: ${(props) => props.theme.colors.placeholder};
    }
  }
`;

export const AuthCard = styled(CardComponent)`
  box-shadow: none;
  background: inherit;
  padding: 0;
  margin: 0;
  width: 100%;

  &:hover {
    box-shadow: none;
    transform: none;
  }

  @media all and (min-width: ${getBreakpoint('sm')}) {
    background: white;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 20px 0;
    width: 400px;
    height: 550px;

    &:hover {
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    }
  }
`;

export const AuthContainer = styled.div`
  @media all and (min-width: ${getBreakpoint('sm')}) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
`;

const FormView: React.FC<{ setEmail: (email: string) => void; email: string }> = ({ setEmail, email }) => {
  const dispatch = useDispatch();
  const isLoggingInSelector = createLoadingSelector([LOGIN_STORE_KEY]);
  const errorSelector = createErrorMessageSelector([LOGIN_STORE_KEY]);
  const isLoggingIn = useSelector(isLoggingInSelector);
  const loginError = useSelector(errorSelector);
  const persistedEmail = useSelector(getPersistedEmail);

  const loginErrorText = loginError ? 'Wrong email or password' : '';

  const formattedEmail = email || persistedEmail || '';
  return (
    <>
      <LogoContainer>
        <LogoImage src={RepositLogo} />
        <Header4 style={{ marginTop: 24, fontSize: 24, color: '#2d333a', fontWeight: 400 }}>Welcome</Header4>
        <P2 style={{ color: '#2d333a', fontSize: 14 }}>Log in to continue to Reposit </P2>
      </LogoContainer>
      <FormContainer>
        <Formik
          initialValues={{ email: formattedEmail, password: '' }}
          validationSchema={Schema}
          onSubmit={(data) => {
            setEmail(data.email);
            dispatch(loginRequested(data));
          }}
        >
          {(props: FormikProps<LoginFormProps>) => {
            return (
              <form onSubmit={props.handleSubmit}>
                <FieldWithLabel label="Email" error={props.errors.email} touched={props.touched.email}>
                  <Input
                    name="email"
                    value={props.values.email}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.errors.email}
                    touched={props.touched.email}
                  />
                </FieldWithLabel>
                <FieldWithLabel label="Password" error={loginErrorText || props.errors.password} touched={props.touched.password}>
                  <Input
                    name="password"
                    type="password"
                    value={props.values.password}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.errors.password}
                    touched={props.touched.password}
                  />
                </FieldWithLabel>
                <StyledLink to="/auth/reset-password-request" style={{ marginTop: '18px', display: 'block' }}>
                  Forgot password?
                </StyledLink>
                <AuthButton type="submit" disabled={isLoggingIn}>
                  Continue
                </AuthButton>
              </form>
            );
          }}
        </Formik>
      </FormContainer>
    </>
  );
};

const EmailSent: React.FC<{ email: string }> = ({ email }) => {
  const dispatch = useDispatch();
  const onClick = () => dispatch(setHasRequestedPasswordReset(false));

  return (
    <>
      <LogoContainer>
        <LogoImage src={RepositLogo} />
        <Header4 style={{ marginTop: 24, fontSize: 24, color: '#2d333a', fontWeight: 400 }}>Check Your Email</Header4>
        <P2 style={{ color: '#2d333a', fontSize: 14 }}>
          {`It looks like you haven't logged in for a while, please check the email address ${email} for instructions to reset your password.`}
        </P2>
        <AuthButton onClick={onClick}>Go to login</AuthButton>
      </LogoContainer>
    </>
  );
};

export const Login: React.FC<unknown> = () => {
  const token = useSelector(getAccessToken);
  const hasRequestedResetPassword = useSelector(getHasRequestedResetPassword);
  const [email, setEmail] = useState('');

  if (token) {
    return <Redirect to="/reposits" />;
  }
  return (
    <AuthContainer>
      <AuthCard>
        {hasRequestedResetPassword ? <EmailSent email={email} /> : <FormView email={email} setEmail={setEmail} />}
      </AuthCard>
    </AuthContainer>
  );
};

import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import FlashMessage from '../../components/FlashMessage';
import { FullPageLoader } from '../../components/Loading';
import RepositCard from '../../components/Reposit/RepositCard';
import {
  fetchOrganizationInvitesRequested,
  FETCH_ORGANIZATION_INVITES_STORE_KEY,
  resendInviteRequested,
  RESEND_INVITE_STORE_KEY,
} from '../../redux/invite/invite.actions';
import { getCurrentOrganizationInvites } from '../../redux/invite/invite.selectors';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import { FLASH_MESSAGE_TIMEOUT, useFlashMessage } from '../FlashMessage';
import { Email, InviteItem, InviteItemWrapper, InviteListItem, InvitesListWrapper, ResendButton } from './styles';

interface OrganizationInvitesProps {
  organizationId: string;
}

const OrganizationInvites: React.FC<OrganizationInvitesProps> = ({ organizationId }) => {
  const dispatch = useDispatch();
  const invites = useSelector(getCurrentOrganizationInvites);
  const isFetchingLoadingSelector = createLoadingSelector([FETCH_ORGANIZATION_INVITES_STORE_KEY]);
  const resendSelector = createLoadingSelector([RESEND_INVITE_STORE_KEY]);
  const isLoading = useSelector(isFetchingLoadingSelector);
  const isSending = useSelector(resendSelector);
  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage([RESEND_INVITE_STORE_KEY]);

  useEffect(() => {
    dispatch(fetchOrganizationInvitesRequested(organizationId));
  }, [dispatch, organizationId]);

  const resendInvite = (inviteId: string) => {
    dispatch(resendInviteRequested(inviteId, organizationId));
  };

  if (isLoading) {
    return <FullPageLoader />;
  }

  return (
    <Container>
      {flashMessagePayload && (
        <Row>
          <Col sm={12}>
            <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMEOUT} payload={flashMessagePayload} />
          </Col>
        </Row>
      )}
      <Row>
        <Col sm={12}>
          <RepositCard title={invites.length ? 'Organisation Invites' : 'No pending invitations'} tooltip="" flush>
            <InvitesListWrapper>
              {invites.map((invite) => (
                <InviteListItem key={invite.id}>
                  <InviteItemWrapper>
                    <InviteItem>
                      <Email>{invite.email}</Email>
                    </InviteItem>
                    <ResendButton disabled={isSending} buttonType="secondary" mini="true" onClick={() => resendInvite(invite.id)}>
                      Resend
                    </ResendButton>
                  </InviteItemWrapper>
                </InviteListItem>
              ))}
            </InvitesListWrapper>
          </RepositCard>
        </Col>
      </Row>
    </Container>
  );
};

export default OrganizationInvites;

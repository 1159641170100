import React from 'react';
import styled from 'styled-components';
import bankIcon from '../../assets/svg/bank.svg';
import { Header5 } from '../../components/Typography';
import { OrganizationBankAccountEntity } from '../../redux/entities/entities.types';
import BankAccountType from './bank-account-type';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BankAccountLabel = styled(Header5)`
  align-items: center;
  display: flex;
  margin: 0 14px 0 0;
  padding: 0;

  &:before {
    background: transparent url(${bankIcon}) no-repeat center center;

    content: '';
    display: block;
    height: 24px;
    margin: 0px 10px 0 0;
    width: 24px;
  }
`;

interface BankAccountHeaderProps {
  bankAccount: OrganizationBankAccountEntity;
}

const BankAccountHeader: React.FC<BankAccountHeaderProps> = ({ bankAccount: { accountName, accountType } }) => {
  return (
    <Container>
      <BankAccountLabel>{accountName}</BankAccountLabel>
      <BankAccountType accountType={accountType || ''} />
    </Container>
  );
};

export default BankAccountHeader;

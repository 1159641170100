import React from 'react';
import Card from '../Card';
import { Header3, P2 } from '../Typography';
import styled from 'styled-components';
import { getBreakpoint } from '../../base/style';
import { NavLink } from 'react-router-dom';
import { OrganizationDTO } from '@reposit/api-client';
import { getDisplayAddress } from '../../utils/common.utils';

interface OrganisationCardProps {
  organization: OrganizationDTO;
}

const Inner = styled.div`
  min-height: 80px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    display: flex;
    justify-content: flex-end;
  }
`;

const Meta = styled.div`
  margin: 0 0 24px;
  @media screen and (min-width: ${getBreakpoint('lg')}) {
    margin: 0 auto 0 0;
  }
`;

const Name = styled(Header3)`
  margin: 6px 0 0;
  padding: 0;
`;

const Location = styled(P2)`
  margin: 0;
  padding: 0;
`;

const OrganisationCard: React.FC<OrganisationCardProps> = ({ organization }) => {
  const { name, attributes } = organization;

  return (
    <NavLink to={`/organisations/${organization.id}`}>
      <Card hover style={{ padding: '5px 5px 10px 40px' }}>
        <Inner>
          <Meta>
            <Name>{name}</Name>
            {attributes && attributes.address && <Location>{getDisplayAddress(attributes.address)}</Location>}
          </Meta>
        </Inner>
      </Card>
    </NavLink>
  );
};

export default OrganisationCard;

export const GET_INTERNAL_TEAM_MEMBERS_STORE_KEY = `INTERNAL_TEAM_MEMBERS/GET_INTERNAL_TEAM_MEMBERS`;
export const GET_INTERNAL_TEAM_MEMBERS_API_REQUESTED = `INTERNAL_TEAM_MEMBERS/GET_INTERNAL_TEAM_MEMBERS_API_REQUESTED`;
export const GET_INTERNAL_TEAM_MEMBERS_API_SUCCESS = `INTERNAL_TEAM_MEMBERS/GET_INTERNAL_TEAM_MEMBERS_API_SUCCESS`;
export const GET_INTERNAL_TEAM_MEMBERS_API_FAILED = `INTERNAL_TEAM_MEMBERS/GET_INTERNAL_TEAM_MEMBERS_API_FAILED`;

export function getInternalTeamMembersRequested() {
  return {
    type: GET_INTERNAL_TEAM_MEMBERS_API_REQUESTED,
  } as const;
}
export function getInternalTeamMembersSuccess(ids: string[]) {
  return {
    type: GET_INTERNAL_TEAM_MEMBERS_API_SUCCESS,
    payload: {
      ids,
    },
  } as const;
}
export function getInternalTeamMembersFailed(error: string) {
  return {
    type: GET_INTERNAL_TEAM_MEMBERS_API_FAILED,
    payload: error,
  } as const;
}

export type InternalTeamMembersActions = ReturnType<
  typeof getInternalTeamMembersRequested | typeof getInternalTeamMembersSuccess | typeof getInternalTeamMembersFailed
>;

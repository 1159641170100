import { put, call, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { ClaimItemsApi, ClaimItemDTO } from '@reposit/api-client';

import {
  CREATE_CLAIM_ITEM_API_REQUESTED,
  createClaimItemSuccess,
  ClaimItemActionTypes,
  createClaimItemFailed,
  DISCARD_CLAIM_ITEM_API_REQUESTED,
  discardClaimItemSuccess,
  discardClaimItemFailed,
  updateClaimItemSuccess,
  updateClaimItemFailed,
  UPDATE_CLAIM_ITEM_API_REQUESTED,
  CREATE_CLAIM_ITEM_STORE_KEY,
  DISCARD_CLAIM_ITEM_STORE_KEY,
  UPDATE_CLAIM_ITEM_STORE_KEY,
} from './claim-item.actions';
import { runSagaWithAuth, createClaimItemsApi } from '../utils/api.utils';
import SCHEMA from '../schema';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import { getErrorMessage } from '../../utils/common.utils';
import { UpdateClaimItemPayload, CreateClaimItemPayload, DiscardClaimItemPayload } from './claim-item.types';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import { FlashState } from '../../components/FlashMessage/index';

export function* createClaimItem({ payload }: { type: string; payload: CreateClaimItemPayload }) {
  try {
    const claimItemsApi: ClaimItemsApi = yield createClaimItemsApi();
    const apiResponse: AxiosResponse<ClaimItemDTO> = yield call(
      runSagaWithAuth(() => claimItemsApi.createClaimItem(payload.claimId, payload.data))
    );
    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.claimItem);
    yield put<ClaimItemActionTypes>(createClaimItemSuccess(apiResponse.data));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: CREATE_CLAIM_ITEM_STORE_KEY,
        message: 'Success! You have add a claim item.',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: CREATE_CLAIM_ITEM_STORE_KEY, message: error, state: FlashState.ERROR })
    );
    yield put<ClaimItemActionTypes>(createClaimItemFailed(error));
  }
}

export function* updateClaimItem({ payload }: { type: string; payload: UpdateClaimItemPayload }) {
  try {
    const claimItemsApi: ClaimItemsApi = yield createClaimItemsApi();
    const apiResponse: AxiosResponse<ClaimItemDTO> = yield call(
      runSagaWithAuth(() => claimItemsApi.updateClaimItem(payload.claimId, payload.claimItemId, payload.data))
    );
    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.claimItem);
    yield put<ClaimItemActionTypes>(updateClaimItemSuccess(apiResponse.data));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_CLAIM_ITEM_STORE_KEY,
        message: 'Success! You have updated a claim item.',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: UPDATE_CLAIM_ITEM_STORE_KEY, message: error, state: FlashState.ERROR })
    );
    yield put<ClaimItemActionTypes>(updateClaimItemFailed(error));
  }
}

export function* discardClaimItem({ payload }: { type: string; payload: DiscardClaimItemPayload }) {
  try {
    const claimItemsApi: ClaimItemsApi = yield createClaimItemsApi();
    yield call(runSagaWithAuth(() => claimItemsApi.deleteClaimItem(payload.claimId, payload.claimItemId)));
    yield put<ClaimItemActionTypes>(discardClaimItemSuccess(payload));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: DISCARD_CLAIM_ITEM_STORE_KEY,
        message: 'Success! You have removed a claim item.',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: DISCARD_CLAIM_ITEM_STORE_KEY, message: error, state: FlashState.ERROR })
    );
    yield put<ClaimItemActionTypes>(discardClaimItemFailed(error));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchClaimItemsSagas() {
  yield takeLatest(CREATE_CLAIM_ITEM_API_REQUESTED, createClaimItem);
  yield takeLatest(UPDATE_CLAIM_ITEM_API_REQUESTED, updateClaimItem);
  yield takeLatest(DISCARD_CLAIM_ITEM_API_REQUESTED, discardClaimItem);
}

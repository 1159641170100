import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

import { getAddressFirstLine } from '../../../utils/common.utils';
import Card from '../../Card';
import CardMessage from '../../CardMessage';
import { RepositSummaryData } from '../../../redux/selectors';
import { Table, Thead, Tr, Th, Tbody, Td } from '../../Table';
import {
  RepositStatus,
  SummaryContainer,
  ColLeft,
  Address,
  FirstLineAddress,
  TownPostcode,
  NoAddress,
  TenancyDates,
  Label,
  Dates,
  ColRight,
  Action,
  Organization,
  SmallLabel,
  OrganizationName,
  ShowMoreTitle,
} from './styles';
import CollapsableContent from '../../CollapsableContent';

interface RepositSummaryProps {
  data: RepositSummaryData;
}

const renderRepositStatus = (reposit: RepositSummaryData) => {
  let status = reposit.status;

  if (reposit.deletedAt) {
    status = 'DELETED';
  }

  if (reposit.completedAt && !reposit.checkoutId) {
    status = 'CLOSED';
  }

  if (reposit.checkoutId) {
    status = 'CHECKED_OUT';
  }

  const statusMap: { [key: string]: string } = {
    DRAFT: 'Draft',
    COMPLETE: 'Complete',
    PENDING: 'Awaiting Tenant Actions',
    DELETED: 'Deleted',
    CLOSED: 'Closed',
    CHECKED_OUT: 'Checked Out',
  };

  return <RepositStatus status={status}>{statusMap[status]}</RepositStatus>;
};

const RepositSummary: React.FC<RepositSummaryProps> = ({ data }) => {
  const { id, startDate, status, organization, deletedAt, address, endDate, createdAt, tenants } = data;
  const showIncompletePrompt =
    moment().isAfter(moment(startDate)) && status !== 'COMPLETE' && status !== 'CANCELLED' && deletedAt === null;

  return (
    <Card
      hover
      flush={false}
      style={{ padding: '12px 20px 32px 40px' }}
      flashMessage={
        showIncompletePrompt ? (
          <CardMessage
            title="The start date for this Reposit is in the past"
            subtitle="Contact agent immediately"
            type="warning"
          />
        ) : undefined
      }
    >
      <NavLink to={`/reposits/${id}`} style={{ textDecoration: 'none' }}>
        <SummaryContainer>
          <ColLeft>
            {renderRepositStatus(data)}
            {address ? (
              <Address>
                <FirstLineAddress>{getAddressFirstLine(address)}</FirstLineAddress>
                <TownPostcode>{address.postcode}</TownPostcode>
              </Address>
            ) : (
              <NoAddress>You haven't added a property to this Reposit yet.</NoAddress>
            )}
            <TenancyDates>
              <Label>Tenancy dates</Label>
              <Dates>
                {moment(startDate).format('Do MMM YYYY')} - {moment(endDate).format('Do MMM YYYY')}
              </Dates>
            </TenancyDates>
          </ColLeft>
          <ColRight>
            <Action>
              <SmallLabel>Created {moment(createdAt).fromNow()}</SmallLabel>
            </Action>
            {organization && (
              <Organization>
                <SmallLabel>Current Organization</SmallLabel>
                <OrganizationName>{organization.name}</OrganizationName>
              </Organization>
            )}
          </ColRight>
        </SummaryContainer>
      </NavLink>
      {tenants && (
        <CollapsableContent>
          <Container>
            <Row>
              <Col lg={12}>
                <ShowMoreTitle>Tenants</ShowMoreTitle>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>Name</Th>
                      <Th>Email</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {tenants.map((tenant) => (
                      <Tr>
                        <Td>{`${tenant.firstName} ${tenant.lastName}`}</Td>
                        <Td>{tenant.email}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        </CollapsableContent>
      )}
    </Card>
  );
};

export default RepositSummary;

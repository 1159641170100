import React, { useState } from 'react';
import { Container, Row } from 'react-grid-system';
import { FLASH_MESSAGE_TIMEOUT, useFlashMessage } from '../../containers/FlashMessage';
import Button from '../Button';
import FlashMessage from '../FlashMessage';
import Modal from '../Modal';
import { Header3, P1 } from '../Typography';
import { DOWNLOAD_DOCUMENTS_STORE_KEY } from '../../redux/claim/claim.actions';
import { RadioGroup } from '../FormFields';

interface DownloadClaimDocumentsModalProps {
  onDismiss: () => void;
  onSubmit: (type: 'ADR' | 'INSURER') => void;
  isSubmitting: boolean;
}

const DownloadClaimDocumentsModal: React.FC<DownloadClaimDocumentsModalProps> = ({ onDismiss, onSubmit, isSubmitting }) => {
  const [flashMessagePayload, dismissFlashMessage] = useFlashMessage([DOWNLOAD_DOCUMENTS_STORE_KEY]);
  const [type, setType] = useState<'ADR' | 'INSURER'>('INSURER');
  return (
    <Modal onDismiss={onDismiss}>
      {flashMessagePayload ? (
        <FlashMessage payload={flashMessagePayload} onDismiss={dismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMEOUT} />
      ) : null}
      <Header3 style={{ marginBottom: 15 }}>Download Claim Documents</Header3>
      <P1 style={{ marginBottom: 10 }}>Please select which format you would like below: </P1>
      <RadioGroup
        name="type"
        options={[
          { value: 'INSURER', label: 'Insurer' },
          { value: 'ADR', label: 'ADR' },
        ]}
        onChange={(e) => {
          setType(e.target.value as 'ADR' | 'INSURER');
        }}
        onBlur={() => {}}
        selected={type}
      />
      <>
        <Container fluid>
          <Row style={{ display: 'flex', justifyContent: 'flex-end', columnGap: '8px', paddingTop: '24px' }}>
            <Button buttonType="secondary" noArrow={true} onClick={onDismiss}>
              Cancel
            </Button>
            <Button disabled={isSubmitting} onClick={() => onSubmit(type)}>
              Confirm
            </Button>
          </Row>
        </Container>
      </>
    </Modal>
  );
};

export default DownloadClaimDocumentsModal;

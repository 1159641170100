import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import { UPDATE_ORG_NOTES_STORE_KEY } from '../../../redux/organization/organization.actions';
import { Header3 } from '../../../components/Typography';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import Loading from '../../../components/Loading';

const Action = styled.div`
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

interface AddRepositNoteModalProps {
  onSubmit: () => void;
  onDismiss: (showModal: boolean) => void;
}

const AddRepositNoteModal: React.SFC<AddRepositNoteModalProps> = ({ onSubmit, onDismiss }) => {
  const addRepositNoteLoadingSelector = createLoadingSelector([UPDATE_ORG_NOTES_STORE_KEY]);
  const isLoading = useSelector(addRepositNoteLoadingSelector);

  let content = (
    <Fragment>
      <Header3 style={{ marginBottom: 6 }}>Are you happy to add this note to this Reposit?</Header3>
      <Action>
        <Button buttonType="secondary" noArrow={true} onClick={() => onDismiss(false)}>
          No, return to form
        </Button>
        <Button
          onClick={() => {
            onSubmit();
            onDismiss(false);
          }}
          disabled={isLoading}
        >
          Yes
        </Button>
      </Action>
    </Fragment>
  );

  return <Modal onDismiss={() => onDismiss(false)}>{isLoading ? <Loading /> : content}</Modal>;
};

export default AddRepositNoteModal;

import { Formik, FormikProps } from 'formik';
import { get } from 'lodash';
import React, { Fragment, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import NotesIcon from '../../assets/svg/notes.svg';
import Button from '../../components/Button';
import FlashMessage from '../../components/FlashMessage';
import TextArea from '../../components/FormFields/TextArea';
import RepositCard from '../../components/Reposit/RepositCard';
import { P1 } from '../../components/Typography';
import { updateOrganizationNotesRequested, UPDATE_ORG_NOTES_STORE_KEY } from '../../redux/organization/organization.actions';
import { getOrganization } from '../../redux/selectors/organization.selectors';
import { FLASH_MESSAGE_TIMEOUT, useFlashMessage } from '../FlashMessage';
import EditOrgNotesModal from './EditOrgNotesModal';

const ActionsContainer = styled.div`
  margin: 16px 0 96px;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

const Schema = Yup.object().shape({
  notes: Yup.string(),
});

interface OrganizationNotesProps {
  organizationId: string;
}

export interface OrgNotesFormValues {
  notes: string;
}

const OrganizationNotes: React.FC<OrganizationNotesProps> = ({ organizationId }) => {
  const dispatch = useDispatch();
  const [isModalShowing, showModal] = useState<boolean>(false);
  const updateOrgNotes = (values: OrgNotesFormValues) => {
    dispatch(updateOrganizationNotesRequested({ notes: values.notes, organizationId }));
  };
  const currentOrg = useSelector(getOrganization(organizationId));
  const [flashMessagePayload, dismissFlashMessage] = useFlashMessage([UPDATE_ORG_NOTES_STORE_KEY]);
  return (
    <Container>
      <Formik
        initialValues={{ notes: get(currentOrg, 'attributes.notes', '') }}
        onSubmit={() => showModal(true)}
        validationSchema={Schema}
      >
        {({ values, handleChange, handleSubmit, handleBlur }: FormikProps<OrgNotesFormValues>) => {
          return (
            <form onSubmit={handleSubmit}>
              <Fragment>
                <Container>
                  <Row>
                    <Col lg={10} push={{ lg: 1 }}>
                      <RepositCard
                        title="Organisation Notes"
                        icon={NotesIcon}
                        flashMessage={
                          flashMessagePayload ? (
                            <FlashMessage
                              payload={flashMessagePayload}
                              onDismiss={dismissFlashMessage}
                              timeRemaining={FLASH_MESSAGE_TIMEOUT}
                            />
                          ) : undefined
                        }
                      >
                        <P1>Use this area to jot down any additional notes about this organisation.</P1>
                        <TextArea name="notes" value={values.notes} onChange={handleChange} onBlur={handleBlur} />
                      </RepositCard>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={10} push={{ lg: 1 }}>
                      <ActionsContainer>
                        <Button type="submit">Update Organization Notes</Button>
                      </ActionsContainer>
                    </Col>
                  </Row>
                </Container>
              </Fragment>
              {isModalShowing ? (
                <EditOrgNotesModal onDismiss={() => showModal(false)} onSubmit={() => updateOrgNotes(values)} />
              ) : null}
            </form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default OrganizationNotes;

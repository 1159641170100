import {
  UserDTOInternalRolesEnum,
  UpdateUserAboutMeDTO,
  UpdateYourCompanyDTO,
  CurrentUserInfoDTOCurrentOrganizationRolesEnum,
} from '@reposit/api-client';
import { AddressForm } from '../reposit/reposit.actions';
import { createAction } from '@reduxjs/toolkit';

export const FETCH_ME_STORE_KEY = 'ACCOUNT/FETCH_ME';
export const FETCH_ME_API_REQUESTED = `ACCOUNT/FETCH_ME_API_REQUESTED`;
export const FETCH_ME_API_SUCCESS = 'ACCOUNT/FETCH_ME_API_SUCCESS';
export const FETCH_ME_API_FAILED = 'ACCOUNT/FETCH_ME_API_FAILED';
export const SET_ORGANIZATION_ID = 'ACCOUNT/SET_ORGANIZATION_ID';

export const UPDATE_ABOUT_ME_STORE_KEY = 'ACCOUNT/UPDATE_ABOUT_ME';
export const UPDATE_ABOUT_ME_API_REQUESTED = `ACCOUNT/UPDATE_ABOUT_ME_API_REQUESTED`;
export const UPDATE_ABOUT_ME_API_SUCCESS = 'ACCOUNT/UPDATE_ABOUT_ME_API_SUCCESS';
export const UPDATE_ABOUT_ME_API_FAILED = 'ACCOUNT/UPDATE_ABOUT_ME_API_FAILED';

export const UPDATE_YOUR_COMPANY_STORE_KEY = 'ACCOUNT/UPDATE_YOUR_COMPANY';
export const UPDATE_YOUR_COMPANY_API_REQUESTED = `ACCOUNT/UPDATE_YOUR_COMPANY_API_REQUESTED`;
export const UPDATE_YOUR_COMPANY_API_SUCCESS = 'ACCOUNT/UPDATE_YOUR_COMPANY_API_SUCCESS';
export const UPDATE_YOUR_COMPANY_API_FAILED = 'ACCOUNT/UPDATE_YOUR_COMPANY_API_FAILED';

export const UPDATE_AST_ACCEPTANCE_STORE_KEY = 'ACCOUNT/UPDATE_AST_ACCEPTANCE';
export const UPDATE_AST_ACCEPTANCE_API_REQUESTED = `ACCOUNT/UPDATE_AST_ACCEPTANCE_API_REQUESTED`;
export const UPDATE_AST_ACCEPTANCE_API_SUCCESS = 'ACCOUNT/UPDATE_AST_ACCEPTANCE_API_SUCCESS';
export const UPDATE_AST_ACCEPTANCE_API_FAILED = 'ACCOUNT/UPDATE_AST_ACCEPTANCE_API_FAILED';

export const UPDATE_INFORMED_LANDLORDS_STORE_KEY = 'ACCOUNT/UPDATE_INFORMED_LANDLORDS';
export const UPDATE_INFORMED_LANDLORDS_API_REQUESTED = `ACCOUNT/UPDATE_INFORMED_LANDLORDS_API_REQUESTED`;
export const UPDATE_INFORMED_LANDLORDS_API_SUCCESS = 'ACCOUNT/UPDATE_INFORMED_LANDLORDS_API_SUCCESS';
export const UPDATE_INFORMED_LANDLORDS_API_FAILED = 'ACCOUNT/UPDATE_INFORMED_LANDLORDS_API_FAILED';

export const SET_ACCOUNT_CURRENT_ADDRESS_FORM = `ACCOUNT/SET_CURRENT_ADDRESS_FORM`;

export const HAS_REQUESTED_PASSWORD_RESET = 'AUTH/HAS_REQUESTED_PASSWORD_RESET';
export const HAS_RESET_PASSWORD = 'AUTH/HAS_RESET_PASSWORD';

export const fetchMeRequested = createAction(FETCH_ME_API_REQUESTED);
export const fetchMeSuccess = createAction<{
  currentUserId: string;
  currentOrganizationId: string;
  currentOrganizationRoles?: CurrentUserInfoDTOCurrentOrganizationRolesEnum[];
  internalRoles?: UserDTOInternalRolesEnum[];
}>(FETCH_ME_API_SUCCESS);
export const fetchMeFailed = createAction<string>(FETCH_ME_API_FAILED);

export const setOrganizationId = createAction<string>(SET_ORGANIZATION_ID);

export const updateAboutMeRequested = createAction<UpdateUserAboutMeDTO>(UPDATE_ABOUT_ME_API_REQUESTED);
export const updateAboutMeSuccess = createAction(UPDATE_ABOUT_ME_API_SUCCESS);
export const updateAboutMeFailed = createAction<string>(UPDATE_ABOUT_ME_API_FAILED);

export const updateYourCompanyRequested = createAction<UpdateYourCompanyDTO>(UPDATE_YOUR_COMPANY_API_REQUESTED);
export const updateYourCompanySuccess = createAction(UPDATE_YOUR_COMPANY_API_SUCCESS);
export const updateYourCompanyFailed = createAction<string>(UPDATE_YOUR_COMPANY_API_FAILED);

export const updateASTAcceptanceRequested = createAction(UPDATE_AST_ACCEPTANCE_API_REQUESTED);
export const updateASTAcceptanceSuccess = createAction(UPDATE_AST_ACCEPTANCE_API_SUCCESS);
export const updateASTAcceptanceFailed = createAction<string>(UPDATE_AST_ACCEPTANCE_API_FAILED);

export const updateInformedLandlordsRequested = createAction(UPDATE_INFORMED_LANDLORDS_API_REQUESTED);
export const updateInformedLandlordsSuccess = createAction(UPDATE_INFORMED_LANDLORDS_API_SUCCESS);
export const updateInformedLandlordsFailed = createAction<string>(UPDATE_INFORMED_LANDLORDS_API_FAILED);

export const setAccountCurrentAddressForm = createAction<AddressForm>(SET_ACCOUNT_CURRENT_ADDRESS_FORM);

export const setHasRequestedPasswordReset = createAction<boolean>(HAS_REQUESTED_PASSWORD_RESET);

import React from 'react';
import { ClaimWithCheckoutRelationsDTO } from '@reposit/api-client';
import moment from 'moment';

import RepositCard from '../Reposit/RepositCard';
import { Tbody, Tr, Td, Table, Thead } from '../Table';
import { useSelector } from 'react-redux';
import { getFirstAgentProposal, getFirstTenantProposal, getSecondAgentProposal } from '../../redux/selectors/mediation.selectors';
import { get } from 'lodash';
import { AppState } from '../../redux/root.reducer';

interface DeadlinesCardProps {
  title: string;
  tenancyOutcomeDeadline?: string;
  claim?: ClaimWithCheckoutRelationsDTO;
}

const DeadlinesCard: React.FC<DeadlinesCardProps> = ({ claim, title, tenancyOutcomeDeadline }) => {
  const claimId = get(claim, 'id', '');
  const agentFirstProposal = useSelector((state: AppState) => getFirstAgentProposal(state, claimId));
  const tenantProposal = useSelector((state: AppState) => getFirstTenantProposal(state, claimId));
  const agentFinalProposal = useSelector((state: AppState) => getSecondAgentProposal(state, claimId));
  const mediationEnabled = !!(claim && claim.mediationEnabled);
  const substantiationDeadline = claim && claim.substantiationDeadline;
  return (
    <RepositCard flush title={title} tooltip="lorem">
      <Table>
        <Thead>
          <Tr>
            <Td>Type</Td>
            <Td>Deadline</Td>
          </Tr>
        </Thead>
        <Tbody>
          {tenancyOutcomeDeadline && (
            <Tr>
              <Td>Claim publish deadline</Td>
              <Td>{moment(tenancyOutcomeDeadline).format('DD/MM/YYYY')}</Td>
            </Tr>
          )}
          {agentFirstProposal && (
            <Tr>
              <Td>{mediationEnabled ? 'First response deadline for tenant(s)' : 'Claim response deadline'}</Td>
              <Td>{moment(agentFirstProposal.responseDeadline).format('DD/MM/YYYY')}</Td>
            </Tr>
          )}
          {tenantProposal && (
            <Tr>
              <Td>Response deadline for supplier</Td>
              <Td>{moment(tenantProposal.responseDeadline).format('DD/MM/YYYY')}</Td>
            </Tr>
          )}
          {agentFinalProposal && (
            <Tr>
              <Td>Final response deadline for tenant(s)</Td>
              <Td>{moment(agentFinalProposal.responseDeadline).format('DD/MM/YYYY')}</Td>
            </Tr>
          )}
          {substantiationDeadline && (
            <Tr>
              <Td>Substantiation deadline</Td>
              <Td>{moment(substantiationDeadline).format('DD/MM/YYYY')}</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </RepositCard>
  );
};

export default DeadlinesCard;

import { IntegratorState } from './integrator.types';
import {
  IntegratorActionTypes,
  GET_INTEGRATORS_API_SUCCESS,
  GENERATE_COMMISSION_API_SUCCESS,
  CLEAR_CSV_URL,
} from './integrator.actions';

const initialState: IntegratorState = {
  results: [],
};

export default (state = initialState, action: IntegratorActionTypes): IntegratorState => {
  switch (action.type) {
    case GET_INTEGRATORS_API_SUCCESS:
      return {
        ...state,
        results: action.payload.ids,
      };
    case GENERATE_COMMISSION_API_SUCCESS: {
      return {
        ...state,
        csvURL: action.payload.csvURL,
      };
    }
    case CLEAR_CSV_URL: {
      return {
        ...state,
        csvURL: undefined,
      };
    }
    default:
      return state;
  }
};

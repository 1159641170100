import { takeLatest, put, call } from 'redux-saga/effects';
import { get } from 'lodash';
import { LandlordsApi, LandlordDTO, UpdateLandlordDTOTypeEnum } from '@reposit/api-client';
import { AxiosResponse } from 'axios';

import {
  FETCH_LANDLORD_DETAILS_API_REQUESTED,
  fetchLandlordDetailsFailed,
  LandlordActionTypes,
  fetchLandlordDetailsSuccess,
  updateLandlordDetailsApiError,
  updateLandlordDetailsApiSuccess,
  UPDATE_LANDLORD_DETAILS_API_REQUESTED,
  UPDATE_LANDLORD_DETAILS_STORE_KEY,
  FETCH_LANDLORD_DETAILS_STORE_KEY
} from './landlord.actions';
import { createLandlordsApi, runSagaWithAuth } from '../utils/api.utils';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import SCHEMA from '../schema';
import { LandlordDetailsForm } from './landlord.types';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import { FlashState } from '../../components/FlashMessage';
import { setLoading, LoadingActionTypes } from '../loading/loading.actions';

export function* fetchLandlordDetails({ payload: landlordId }: { type: string; payload: string }) {
  try {
    const landlordsApi: LandlordsApi = yield createLandlordsApi();
    const response: AxiosResponse<LandlordDTO> = yield call(runSagaWithAuth(() => landlordsApi.getLandlordById(landlordId)));
    yield syncEntitiesAndGetResults(response.data, SCHEMA.landlord);
    yield put<LandlordActionTypes>(fetchLandlordDetailsSuccess());
  } catch (err) {
    yield put<LandlordActionTypes>(fetchLandlordDetailsFailed(get(err, 'response.data.message', err)));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: FETCH_LANDLORD_DETAILS_STORE_KEY,
        message: err,
        state: FlashState.ERROR
      })
    );
  }
}

export function* updateLandlordDetails({ payload }: { type: string; payload: { id: string; landlord: LandlordDetailsForm } }) {
  yield put<LoadingActionTypes>(
    setLoading({
      action: UPDATE_LANDLORD_DETAILS_STORE_KEY,
      isLoading: true
    })
  );

  try {
    const { id, landlord } = payload;
    const landlordApi: LandlordsApi = yield createLandlordsApi();
    const response: AxiosResponse<LandlordDTO> = yield call(
      runSagaWithAuth(() =>
        landlordApi.updateLandlord(id, {
          type: landlord.type === 'COMPANY' ? UpdateLandlordDTOTypeEnum.COMPANY : UpdateLandlordDTOTypeEnum.SOLEPERSON,
          firstName: landlord.firstName,
          lastName: landlord.lastName,
          companyName: landlord.companyName,
          email: landlord.email
        })
      )
    );
    yield syncEntitiesAndGetResults(response.data, SCHEMA.landlord);
    yield put<LandlordActionTypes>(updateLandlordDetailsApiSuccess());
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_LANDLORD_DETAILS_STORE_KEY,
        message: 'Landlord has been successfully updated',
        state: FlashState.SUCCESS
      })
    );
  } catch (err) {
    yield put<LandlordActionTypes>(updateLandlordDetailsApiError());
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_LANDLORD_DETAILS_STORE_KEY,
        message: err,
        state: FlashState.ERROR
      })
    );
  }

  yield put<LoadingActionTypes>(
    setLoading({
      action: UPDATE_LANDLORD_DETAILS_STORE_KEY,
      isLoading: false
    })
  );
}

export function* watchLandlordActions() {
  yield takeLatest(FETCH_LANDLORD_DETAILS_API_REQUESTED, fetchLandlordDetails);
  yield takeLatest(UPDATE_LANDLORD_DETAILS_API_REQUESTED, updateLandlordDetails);
}

import { OrganizationBankAccountsApi, OrganizationBankAccountDTO } from '@reposit/api-client';
import { call, put, takeLatest } from 'redux-saga/effects';
import SCHEMA from '../schema';
import { createOrganizationBankAccountsApi, runSagaWithAuth } from '../utils/api.utils';
import {
  fetchOrganizationBankAccountsFailed,
  fetchOrganizationBankAccountsSuccess,
  fetchOrganizationBankAccountsRequested,
  FETCH_ORG_BANK_ACCOUNTS_STORE_KEY,
} from './organization-bank-account.actions';
import { AxiosResponse } from 'axios';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import { FlashState } from '../../components/FlashMessage';
import { getErrorMessage } from '../../utils/common.utils';

// ****************
// WORKERS
// ****************

export function* getOrganizationBankAccounts({ payload }: { payload: { organizationId: string }; type: string }) {
  try {
    const organizationBankAccountsApi: OrganizationBankAccountsApi = yield createOrganizationBankAccountsApi();
    const apiResponse: AxiosResponse<OrganizationBankAccountDTO[]> = yield call(
      runSagaWithAuth(() => organizationBankAccountsApi.getBankAccounts(payload.organizationId))
    );
    const ids: string[] = yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.organizationBankAccounts);
    yield put(fetchOrganizationBankAccountsSuccess(ids));
  } catch (e) {
    const error = getErrorMessage(e);
    yield put(fetchOrganizationBankAccountsFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: FETCH_ORG_BANK_ACCOUNTS_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

// ****************
// WATCHERS
// ****************
export function* watchOrganizationUserSagas() {
  yield takeLatest(fetchOrganizationBankAccountsRequested.type, getOrganizationBankAccounts);
}

import React from 'react';
import styled from 'styled-components';

import CalendarIcon from '../../assets/svg/calendar-blk.svg';
import { Header5, P2 } from '../Typography';
import { AnniversaryStatus, AnniversaryStatusEnum } from '../AnniversaryStatus';

const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Label = styled(Header5)`
  align-items: center;
  display: flex;
  margin: 0 14px 0 0;
  padding: 0;

  &:before {
    background: transparent url(${CalendarIcon}) no-repeat center center;
    background-size: 16px;
    content: '';
    display: block;
    height: 20px;
    margin: -3px 10px 0 0;
    width: 20px;
  }
`;

const DateText = styled(P2)`
  color: ${(props) => props.theme.colors.body2};
  margin-bottom: 0;
`;

interface TenancyAnniversaryHeaderProps {
  periodStart: string;
  status: AnniversaryStatusEnum;
}

export const TenancyAnniversaryHeader: React.FC<TenancyAnniversaryHeaderProps> = ({ periodStart, status }) => {
  return (
    <Container>
      <div style={{ display: 'flex' }}>
        <Label>Period starting</Label>
        <DateText>{periodStart}</DateText>
      </div>
      <div style={{ display: 'flex' }}>
        <AnniversaryStatus status={status} />
      </div>
    </Container>
  );
};

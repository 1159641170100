import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import moment from 'moment';

import { TenancyOrderWithActions } from '../../../redux/selectors';
import RepositCard from '../RepositCard';
import { Table, Tbody, Tr, Td, Th } from '../../Table';

interface RepositAdditionalInformationProps {
  tenancyOrder: TenancyOrderWithActions;
}

const RepositAdditionalInformation: React.FC<RepositAdditionalInformationProps> = ({ tenancyOrder }) => {
  const checkoutDate = tenancyOrder.tenancy.checkout && tenancyOrder.tenancy.checkout.createdAt;
  const completedAt = tenancyOrder.order && tenancyOrder.order.completedAt;
  const createdAt = tenancyOrder.createdAt;
  const deletedAt = tenancyOrder.deletedAt;
  const deactivationReason = tenancyOrder.deactivationReason;
  const { legacyId, topUpEnabled } = tenancyOrder.tenancy;
  const { hasExtendedOutcomeDeadline } = tenancyOrder.tenancy;

  const info: { key: string; value: string }[] = [
    { key: 'Outcome Deadline Extended', value: `${hasExtendedOutcomeDeadline}` },
    { key: 'Annual Fee Collection Enabled', value: `${topUpEnabled}` },
  ];

  if (createdAt) {
    info.push({
      key: 'Created At',
      value: moment(createdAt).format('DD/MM/YYYY HH:mm'),
    });
  }

  if (checkoutDate) {
    info.push({
      key: 'Checkout Date',
      value: moment(checkoutDate).format('DD/MM/YYYY HH:mm'),
    });
  }

  if (completedAt) {
    info.push({
      key: 'Completed At',
      value: moment(completedAt).format('DD/MM/YYYY HH:mm'),
    });
  }

  if (deletedAt) {
    info.push({
      key: 'Deleted At',
      value: moment(deletedAt).format('DD/MM/YYYY HH:mm'),
    });
    info.push({
      key: 'Deactivation Reason',
      value: deactivationReason ? deactivationReason : '(No reason was provided for this Reposit deactivation.)',
    });
  }

  if (legacyId) {
    info.push({
      key: 'Legacy Id',
      value: legacyId,
    });
  }

  return (
    <RepositCard title="Additional Information">
      <Container>
        <Row>
          <Col lg={12} style={{ padding: 0 }}>
            <Table>
              <Tbody>
                {info.map((item) => (
                  <Tr key={item.key}>
                    <Th width={250}>{item.key}</Th>
                    <Td style={{ paddingLeft: 10 }}>{item.value}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </RepositCard>
  );
};

export default RepositAdditionalInformation;

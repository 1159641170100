import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Button from '../../../components/Button';

interface TenancyDateSelectorActions {
  initialDate?: Date;
  onDateSelect: (date: Date) => void;
}

const DateSelector = styled.div`
  clear: both;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0;

  button {
    justify-content: center;
    margin: 0 0 10px;
    width: 100%;
  }
`;

interface TermOption {
  id: string;
  label: string;
  calculateDate: (initialDate: moment.Moment) => Date;
}

const TermOptions: TermOption[] = [
  {
    id: 'SIX_MONTHS',
    label: '6 Month Tenancy',
    calculateDate: initialDate =>
      initialDate
        .add(6, 'months')
        .subtract(1, 'days')
        .toDate()
  },
  {
    id: 'ONE_YEAR',
    label: '1 Year Tenancy',
    calculateDate: initialDate =>
      initialDate
        .add(1, 'years')
        .subtract(1, 'days')
        .toDate()
  },
  {
    id: 'TWO_YEAR',
    label: '2 Year Tenancy',
    calculateDate: initialDate =>
      initialDate
        .add(2, 'years')
        .subtract(1, 'days')
        .toDate()
  }
];

const TenancyDateSelector: React.FC<TenancyDateSelectorActions> = ({ initialDate, onDateSelect }) => (
  <DateSelector>
    {TermOptions.map(term => (
      <Button
        key={term.id}
        buttonType="secondary"
        onClick={() => onDateSelect(term.calculateDate(moment(initialDate)))}
        noArrow
        type={'button'}
      >
        {term.label}
      </Button>
    ))}
  </DateSelector>
);

export default TenancyDateSelector;

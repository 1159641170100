import { AppState } from '../root.reducer';

export const getReferenceList = (state: AppState) => {
  return state.referencingList.results.map((referenceId: string) => state.entities.reference[referenceId]);
};
export const getReferenceListFilters = (state: AppState) => state.referencingList.filters;

export const getReferenceListPagination = (state: AppState) => ({
  count: state.referencingList.count,
  page: state.referencingList.page
});

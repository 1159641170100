import qs from 'qs';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { get } from 'lodash';

import Loading from '../../components/Loading/index';
import { setToken } from '../../redux/auth/auth.actions';

interface ILocationHash {
  access_token: string;
  state: string;
}

const AuthenticateView = ({ location, history }: RouteComponentProps) => {
  const dispatch = useDispatch();
  const params: ILocationHash = qs.parse(location.hash.substr(1));
  const accessToken = get(params, 'access_token');
  const redirectStateParam = get(params, 'state');

  useEffect(() => {
    if (accessToken) {
      dispatch(setToken(accessToken));
      const url = redirectStateParam || '/reposits';
      history.push(url);
    } else {
      history.push('/unauthorised');
    }
  }, [dispatch, history, accessToken, redirectStateParam]);

  return <Loading />;
};

export default AuthenticateView;

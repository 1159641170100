import { OrganizationsApi, SearchOrganizationResultsDTO } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import SCHEMA from '../schema';
import { createOrganizationsApi, runSagaWithAuth } from '../utils/api.utils';
import {
  FETCH_ORGANIZATIONS_API_REQUESTED,
  OrganizationListActionTypes,
  fetchOrganizationsSuccess,
  fetchOrganizationsFailed,
} from './organization-list.actions';
import { OrganizationListFilters, FetchOrganizationsRequestedPayload } from './organization-list.types';
import { getOrganizationListFilters, getOrganizationListPagination } from './organization-list.selectors';

// ****************
// WORKERS
// ****************

export const ORGANIZATION_LIST_PAGE_SIZE = 10;

export function* fetchOrganizations({ payload }: { type: string; payload: FetchOrganizationsRequestedPayload }) {
  try {
    const filters: OrganizationListFilters = yield select(getOrganizationListFilters);
    const pagination = yield select(getOrganizationListPagination);
    const organizationsApi: OrganizationsApi = yield createOrganizationsApi();

    const pageSize = payload.pageSize || ORGANIZATION_LIST_PAGE_SIZE;
    const sort = payload.sort ? payload.sort : filters.sort;
    const apiResponse: AxiosResponse<SearchOrganizationResultsDTO> = yield call(
      runSagaWithAuth(() => organizationsApi.searchOrganization(filters.filter, pagination.page, pageSize, filters.query, sort))
    );

    const ids: string[] = yield syncEntitiesAndGetResults(apiResponse.data.results, SCHEMA.organizations);
    yield put<OrganizationListActionTypes>(fetchOrganizationsSuccess(apiResponse.data.count, ids));
  } catch (e) {
    yield put<OrganizationListActionTypes>(fetchOrganizationsFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchOrganizationListSagas() {
  yield takeLatest(FETCH_ORGANIZATIONS_API_REQUESTED, fetchOrganizations);
}

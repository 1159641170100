import { InviteState } from './invite.types';
import {
  InviteActionTypes,
  FETCH_ORGANIZATION_INVITES_API_SUCCESS,
  FETCH_ORGANIZATION_INVITES_API_FAILED,
  TOGGLE_IS_INVITE_USER_FORM_OPEN,
  DELETE_INVITE_API_SUCCESS
} from './invite.actions';

const initialState: InviteState = {
  currentOrganizationInvites: [],
  haveCurrentOrganizationInvitesBeenFetched: false,
  isInviteUserFormOpen: false
};

export default (state = initialState, action: InviteActionTypes): InviteState => {
  switch (action.type) {
    case FETCH_ORGANIZATION_INVITES_API_SUCCESS:
      return {
        ...state,
        currentOrganizationInvites: action.payload,
        haveCurrentOrganizationInvitesBeenFetched: true
      };
    case FETCH_ORGANIZATION_INVITES_API_FAILED:
      return {
        ...state,
        haveCurrentOrganizationInvitesBeenFetched: true
      };

    case TOGGLE_IS_INVITE_USER_FORM_OPEN:
      return {
        ...state,
        isInviteUserFormOpen: !state.isInviteUserFormOpen
      };

    case DELETE_INVITE_API_SUCCESS:
      const inviteId = action.payload;
      const newInvites = state.currentOrganizationInvites.filter(id => id !== inviteId);
      return {
        ...state,
        currentOrganizationInvites: newInvites
      };
    default:
      return state;
  }
};

import { OrganizationWithRepositsAndRoles } from '@reposit/api-client';
import { denormalize } from 'normalizr';
import { get } from 'lodash';

import { AppState } from '../root.reducer';
import SCHEMA from '../schema';
import { getCurrentOrganizationId } from '../account/account.selectors';

export const getOrganization =
  (id: string) =>
  (state: AppState): OrganizationWithRepositsAndRoles | undefined => {
    if (!state.entities.organization) return undefined;
    return denormalize(state.entities.organization[id], SCHEMA.organization, state.entities);
  };

export const getIsYourCompanyComplete = (state: AppState): boolean => {
  const currentOrgId = getCurrentOrganizationId(state);
  const currentOrg = getOrganization(currentOrgId)(state);
  return get(currentOrg, 'attributes.yourCompanyComplete', false);
};
export const getIsUpdatedASTComplete = (state: AppState): boolean => {
  const currentOrgId = getCurrentOrganizationId(state);
  const currentOrg = getOrganization(currentOrgId)(state);
  return get(currentOrg, 'attributes.updatedAST', false);
};
export const getIsInformedLandlordsComplete = (state: AppState): boolean => {
  const currentOrgId = getCurrentOrganizationId(state);
  const currentOrg = getOrganization(currentOrgId)(state);
  return get(currentOrg, 'attributes.informedLandlords', false);
};

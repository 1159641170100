import styled from 'styled-components';

export const Container = styled.div``;

export const SelectContainer = styled.div`
  display: flex;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  color: ${(props) => props.theme.colors.body};
  height: 40px;
  width: 100%;
  align-items: center;
  padding: 0 5px;

  input {
    flex: 1;
    border: 0;
    height: 32px;
    padding: 0 16px;
    font-family: ${(props) => props.theme.typography.face.secondary};
    font-size: 1em;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    outline: none;
  }

  &:focus {
    box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px ${(props) => props.theme.colors.body};
  }

  &:disabled {
    background: rgba(244, 244, 244, 0.6);
    cursor: not-allowed;
    opacity: 0.45;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  width: 40px;
  justify-content: center;
  align-items: center;
`;

export const OptionsContainer = styled.div`
  width: 100%;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid #eee;
  padding: 5px 0;
  position: absolute;
  background: #fff;
`;

export const OptionsList = styled.ul``;

export const OptionItem = styled.li<{ selected?: boolean }>`
  display: flex;
  height: 35px;
  align-items: center;
  padding: 0 16px;
  font-family: ${(props) => props.theme.typography.face.secondary};
  color: ${(props) => props.theme.colors.body};

  ${(props) =>
    props.selected &&
    `
    background: ${props.theme.colors.primaryBrand};
    color: #fff;
    cursor: pointer;
  `}
`;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Header4, P3 } from '../Typography/index';
import moment from 'moment';
import { FlashMessagePayload } from '../../redux/flash-messages/flash-messages.types';
import WhiteLogo from '../../assets/png/icon-white.png';

export enum FlashState {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

interface Jumbo {
  jumbo?: boolean;
}

interface FlashMessageProps {
  jumbo?: boolean;
  payload: FlashMessagePayload;
  timeRemaining: number;
  onDismiss: () => void;
}

const DefaultMessageWrapper = styled.div<Jumbo>`
  /* background-image: linear-gradient(86deg, #017802, #5fce06); */
  background: ${(props) => props.theme.colors.positive};
  border-radius: 8px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  margin: 0 5px;
  position: relative;
  overflow: hidden;

  &:before {
    background: transparent url(${WhiteLogo}) no-repeat 0 -60px;
    background-size: cover;
    content: '';
    display: block;
    position: absolute;
    opacity: 0.05;
    top: -100%;
    left: 0;
    height: 200%;
    width: 60%;
  }
`;

const WarningMessageWrapper = styled(DefaultMessageWrapper)<Jumbo>`
  background: ${(props) => props.theme.colors.warning};
`;

const ErrorMessageWrapper = styled(DefaultMessageWrapper)<Jumbo>`
  background: ${(props) => props.theme.colors.negative};
`;

const Message = styled(Header4)<Jumbo>`
  color: #fff;
  font-weight: ${(props) => (props.jumbo ? 700 : 500)};
  font-size: ${(props) => (props.jumbo ? `20px` : 'inherit')};
  margin: 0;
  padding: 0;
  max-width: 500px;
`;

const Countdown = styled(P3)`
  color: #fff;
  margin: 0 0 0 auto;
  opacity: 0.5;
  padding: 4px 0 0 0;
`;

const FlashMessageContainer = styled.div<Jumbo>`
  box-sizing: border-box;
  padding: 11px 24px;
  text-align: ${(props) => (props.jumbo ? 'center' : 'left')};
  height: ${(props) => (props.jumbo ? '174px' : 'auto')};
  align-items: ${(props) => (props.jumbo ? 'center' : 'flex-start')};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FlashMessageInner = styled.div`
  align-items: 'flex-start';
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const JumboFlashMessageInner = styled(FlashMessageInner)`
  position: absolute;
  top: 11px;
  width: calc(100% - 36px);
`;

const countdown = (timeRemaining: number, onDismiss: () => void, updateTimeLeft: (time: number) => void) => {
  const seconds = Math.ceil(timeRemaining / 1000);
  let prevSecondsRemaining = 0;
  const end = moment().add(seconds, 'seconds');

  const timer = setInterval(() => {
    const now = moment(end).diff(moment());
    const secondsRemaining = Math.ceil(now / 1000);

    if (prevSecondsRemaining !== secondsRemaining) {
      prevSecondsRemaining = secondsRemaining;
      updateTimeLeft(prevSecondsRemaining);
    }

    const isBefore = moment().isBefore(end);
    if (!isBefore) {
      clearTimeout(timer);
      onDismiss();
    }
  }, 100);

  return timer;
};

const FlashMessage: React.SFC<FlashMessageProps> = ({ payload, timeRemaining, onDismiss, jumbo }) => {
  const [timeLeft, updateTimeLeft] = useState(timeRemaining / 1000);
  useEffect(() => {
    const timer = countdown(timeRemaining, onDismiss, updateTimeLeft);

    return function cleanup() {
      clearTimeout(timer);
    };
  }, [timeRemaining, onDismiss]);

  let content = (
    <FlashMessageContainer>
      <FlashMessageInner>
        <Message>{payload.message}</Message>
        <Countdown>This will disappear in {timeLeft}s</Countdown>
      </FlashMessageInner>
    </FlashMessageContainer>
  );

  if (jumbo) {
    content = (
      <FlashMessageContainer jumbo>
        <JumboFlashMessageInner>
          <Countdown>This will disappear in {timeLeft}s</Countdown>
        </JumboFlashMessageInner>
        <Message jumbo>{payload.message}</Message>
      </FlashMessageContainer>
    );
  }

  if (payload.state === FlashState.WARNING)
    return (
      <WarningMessageWrapper jumbo={!!jumbo} onClick={onDismiss}>
        {content}
      </WarningMessageWrapper>
    );
  if (payload.state === FlashState.ERROR)
    return (
      <ErrorMessageWrapper jumbo={!!jumbo} onClick={onDismiss}>
        {content}
      </ErrorMessageWrapper>
    );

  return (
    <DefaultMessageWrapper onClick={onDismiss} jumbo={!!jumbo}>
      {content}
    </DefaultMessageWrapper>
  );
};

export default FlashMessage;

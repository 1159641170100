import React from 'react';
import numeral from 'numeral';
import styled from 'styled-components';
import { OrderWithOrderCustomersDTOStatusIdEnum } from '@reposit/api-client';
import { NumberFormat } from '../../../constants/number-formats';

interface RepositPriceProps {
  orderStatus: OrderWithOrderCustomersDTOStatusIdEnum;
  orderAmount: number;
}

const Container = styled.div<{ orderStatus: OrderWithOrderCustomersDTOStatusIdEnum }>`
  background: ${(props) =>
    props.orderStatus === OrderWithOrderCustomersDTOStatusIdEnum.COMPLETE
      ? props.theme.colors.positive
      : props.theme.colors.warning};
  border-radius: 24px;
  color: #fff;
  font-family: ${(props) => props.theme.typography.face.secondary};
  font-size: 1em;
  font-weight: bold;
  padding: 6px 20px;
  text-align: center;
  display: flex;
  justify-content: center; /* Align horizontal */
  align-items: center; /* Align vertical */
`;

const RepositPrice: React.FC<RepositPriceProps> = ({ orderStatus, orderAmount }) => {
  return <Container orderStatus={orderStatus}>£{numeral(orderAmount / 100).format(NumberFormat.THOUSANDS_PENCE)}</Container>;
};

export default RepositPrice;

import { NotifyIntegratorPayload } from './integrator.types';

export const GET_INTEGRATORS_STORE_KEY = `INTEGRATOR/GET_INTEGRATORS`;
export const GET_INTEGRATORS_API_REQUESTED = `INTEGRATOR/GET_INTEGRATORS_API_REQUESTED`;
export const GET_INTEGRATORS_API_SUCCESS = `INTEGRATOR/GET_INTEGRATORS_API_SUCCESS`;
export const GET_INTEGRATORS_API_FAILED = `INTEGRATOR/GET_INTEGRATORS_API_FAILED`;

export const GENERATE_COMMISSION_STORE_KEY = `INTEGRATOR/GENERATE_COMMISSION`;
export const GENERATE_COMMISSION_API_REQUESTED = `INTEGRATOR/GENERATE_COMMISSION_API_REQUESTED`;
export const GENERATE_COMMISSION_API_SUCCESS = `INTEGRATOR/GENERATE_COMMISSION_API_SUCCESS`;
export const GENERATE_COMMISSION_API_FAILED = `INTEGRATOR/GENERATE_COMMISSION_API_FAILED`;
export const CLEAR_CSV_URL = `INTEGRATOR/CLEAR_CSV_URL`;

export const NOTIFY_INTEGRATOR_STORE_KEY = `INTEGRATOR/NOTIFY_INTEGRATOR`;
export const NOTIFY_INTEGRATOR_API_REQUESTED = 'INTEGRATOR/NOTIFY_INTEGRATOR_API_REQUESTED';
export const NOTIFY_INTEGRATOR_API_SUCCESS = 'INTEGRATOR/NOTIFY_INTEGRATOR_API_SUCCESS';
export const NOTIFY_INTEGRATOR_API_FAILED = 'INTEGRATOR/NOTIFY_INTEGRATOR_API_FAILED';

export function getIntegratorsRequested(organizationId?: string) {
  return {
    type: GET_INTEGRATORS_API_REQUESTED,
    payload: {
      organizationId,
    },
  } as const;
}

export function getIntegratorsSuccess(ids: string[]) {
  return {
    type: GET_INTEGRATORS_API_SUCCESS,
    payload: {
      ids,
    },
  } as const;
}

export function getIntegratorsFailed(error: string) {
  return {
    type: GET_INTEGRATORS_API_FAILED,
    payload: error,
  } as const;
}

export function generateCommissionRequested(integratorId: string) {
  return {
    type: GENERATE_COMMISSION_API_REQUESTED,
    payload: {
      integratorId,
    },
  } as const;
}
export function generateCommissionSuccess(csvURL: string) {
  return {
    type: GENERATE_COMMISSION_API_SUCCESS,
    payload: {
      csvURL,
    },
  } as const;
}
export function generateCommissionFailed(error: string) {
  return {
    type: GENERATE_COMMISSION_API_FAILED,
    payload: error,
  } as const;
}

export function clearCsvURL() {
  return {
    type: CLEAR_CSV_URL,
  } as const;
}

export function notifyIntegratorRequested(payload: NotifyIntegratorPayload) {
  return {
    type: NOTIFY_INTEGRATOR_API_REQUESTED,
    payload,
  } as const;
}

export function notifyIntegratorSuccess() {
  return {
    type: NOTIFY_INTEGRATOR_API_SUCCESS,
    payload: {},
  } as const;
}

export function notifyIntegratorFailed(error: string) {
  return {
    type: NOTIFY_INTEGRATOR_API_FAILED,
    payload: error,
  } as const;
}

export type IntegratorActionTypes = ReturnType<
  | typeof getIntegratorsRequested
  | typeof getIntegratorsSuccess
  | typeof getIntegratorsFailed
  | typeof generateCommissionRequested
  | typeof generateCommissionSuccess
  | typeof generateCommissionFailed
  | typeof clearCsvURL
  | typeof notifyIntegratorRequested
  | typeof notifyIntegratorSuccess
  | typeof notifyIntegratorFailed
>;

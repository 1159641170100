import { CommissionActionTypes } from './commission.actions';
import { CommissionState } from './commission.types';

const initialState: CommissionState = {};

export default (state = initialState, action: CommissionActionTypes): CommissionState => {
  switch (action.type) {
    default:
      return state;
  }
};

import React, { Fragment } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { TenantWithActions } from '../../redux/selectors';
import TenantListItem from './tenant-list-item';

interface TenantListProps {
  tenants: TenantWithActions[];
  onDeleteClick?: (id: string) => void;
  hideButtons?: boolean;
  tenancyId?: string;
  tenancyOrderStatus: string;
}

const TenantListWrapper = styled.ul`
  background: #fff;
  margin: 22px 0;
  min-width: 200px;
  overflow: hidden;
  padding: 0;
`;

const TenantList: React.SFC<TenantListProps> = ({ onDeleteClick, tenants, hideButtons, tenancyId, tenancyOrderStatus }) => {
  const { id: tenancyOrderId } = useParams<{ id: string }>();
  return (
    <Fragment>
      <TenantListWrapper>
        {tenants.map(
          (tenant) =>
            tenancyId && (
              <TenantListItem
                key={tenant.id}
                tenant={tenant}
                onDeleteClick={onDeleteClick}
                hideButtons={hideButtons}
                tenancyId={tenancyId}
                tenancyOrderId={tenancyOrderId}
                tenancyOrderStatus={tenancyOrderStatus}
              />
            )
        )}
      </TenantListWrapper>
    </Fragment>
  );
};

export default TenantList;

import { UserDTOInternalRolesEnum } from '@reposit/api-client';
import { get } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInternalRoles } from '../../redux/account/account.selectors';
import { logout } from '../../redux/auth/auth.actions';
import { getCurrentUser } from '../../redux/selectors/user.selectors';
import AccountSelector from '../AccountSelector';
import CurrentUser from '../CurrentUser';
import { LogoutButton, Navigation, NavItem, SideMenuContainer } from './components';
import MenuItem from './components/MenuItem';

export interface MenuItemDefinition {
  id: string;
  label: string;
  location: string;
  items?: MenuItemDefinition[];
}

const getToolItems = (roles: UserDTOInternalRolesEnum[]): MenuItemDefinition[] => {
  const items = [
    {
      id: 'KPIS',
      label: 'KPIs',
      location: '/tools/kpis',
    },
    {
      id: 'RECONCILIATION',
      label: 'Reconciliation',
      location: '/tools/reconciliation',
    },
    // {
    //   id: 'DOCUMENT',
    //   label: 'Document Downloads',
    //   location: '/tools/downloads',
    // },
  ];
  const hasFinanceAllRole = roles.some((r) => r === UserDTOInternalRolesEnum.FINANCEALL);
  if (hasFinanceAllRole) {
    items.push({
      id: 'INTEGRATOR_COMMISSION',
      label: 'Integrator Commission',
      location: '/tools/integrator-commission',
    });
    items.push({
      id: 'DIRECT_COMMISSION',
      label: 'Direct Commission',
      location: '/tools/direct-commission',
    });
    items.push({
      id: 'BANK_ACCOUNTS',
      label: 'Bank Accounts',
      location: '/tools/bank-accounts',
    });
  }

  const hasSalesInternalReportingRole = roles.some((r) => r === UserDTOInternalRolesEnum.INTERNALREPORTSALES);
  if (hasSalesInternalReportingRole) {
    items.push({
      id: 'CREATED_REPOSITS',
      label: 'Created Reposits Report',
      location: '/tools/internal-reports/created-reposits',
    });
    items.push({
      id: 'COMPLETED_REPOSITS',
      label: 'Completed Reposits Report',
      location: '/tools/internal-reports/completed-reposits',
    });
  }

  return items;
};

const getMenuItems = (roles: UserDTOInternalRolesEnum[]): MenuItemDefinition[] => {
  return [
    {
      id: 'ORGANISATIONS',
      label: 'Organisations',
      location: '/organisations',
    },
    {
      id: 'REPOSITS',
      label: 'Reposits',
      location: '/reposits',
    },
    {
      id: 'CLAIMS',
      label: 'Claims',
      location: '/claims',
    },
    {
      id: 'USERS',
      label: 'Users',
      location: '/users',
    },
    {
      id: 'REFERENCES',
      label: 'References',
      location: '/references',
    },
    {
      id: 'TOOLS',
      label: 'Tools',
      location: '/tools',
      items: getToolItems(roles),
    },
  ];
};

interface SideMenuProps {}

const SideMenu: React.FC<SideMenuProps> = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const [expandedItem, setExpandedItem] = useState<string>('');
  const internalRoles = useSelector(getInternalRoles);
  const menuItems = getMenuItems(internalRoles || []);
  return (
    <SideMenuContainer isOpen={true}>
      <CurrentUser name={get(currentUser, 'firstName', '')} />
      <AccountSelector activeAccount={'0'} onChange={console.log} organizations={[]} />
      <Navigation>
        {menuItems.map((item) => (
          <MenuItem key={item.id} item={item} isExpanded={expandedItem === item.id} setExpandedItem={setExpandedItem} />
        ))}
      </Navigation>
      <Navigation style={{ marginTop: 60 }}>
        <NavItem>
          <LogoutButton to="/auth/login" onClick={() => dispatch(logout())}>
            Logout
          </LogoutButton>
        </NavItem>
      </Navigation>
    </SideMenuContainer>
  );
};

export default SideMenu;

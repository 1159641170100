import React, { Fragment } from 'react';
import { Provider, useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import { createBrowserHistory, History } from 'history';
import createSagaMiddleware from '@redux-saga/core';
import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { routerMiddleware } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';

import App from './App';
import { RepositTheme } from './base/theme';
import { GlobalStyle } from './base/style';
import { getPersistedReducer } from './redux/root.reducer';
import rootSaga from './redux/root.saga';
import { configureStore } from '@reduxjs/toolkit';

// create the saga middleware
export const sagaMiddleware = createSagaMiddleware();
export const history: History<any> = createBrowserHistory();

const persistedReducer = getPersistedReducer(history);

// mount it on the Store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(sagaMiddleware, routerMiddleware(history)),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={RepositTheme}>
        <Fragment>
          <App />
          <GlobalStyle />
        </Fragment>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

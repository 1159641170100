import { put, takeLatest, select, call } from 'redux-saga/effects';
import { ClaimsApi, SearchClaimResultsDTO } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';

import SCHEMA from '../schema';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import { getCurrentOrganizationId } from '../account/account.selectors';
import { ClaimListFilters, FetchClaimListPayload } from './claim-list.types';
import { createClaimsApi, runSagaWithAuth } from '../utils/api.utils';
import { fetchClaimsSuccess, fetchClaimsFailed, ClaimListActionTypes, FETCH_CLAIMS_API_REQUESTED } from './claim-list.actions';
import { getClaimListFilters, getClaimListPagination } from './claim-list.selectors';

export const CLAIM_LIST_PAGE_SIZE = 10;

// ****************
// WORKERS
// ****************

export function* fetchClaimList({ payload }: { type: string; payload: FetchClaimListPayload }) {
  try {
    const filters: ClaimListFilters = yield select(getClaimListFilters);
    const currentOrganizationId: string = yield select(getCurrentOrganizationId);
    const pagination = yield select(getClaimListPagination);
    const claimsApi: ClaimsApi = yield createClaimsApi();
    const orgId = payload.organizationId || currentOrganizationId;

    const apiResponse: AxiosResponse<SearchClaimResultsDTO> = yield call(
      runSagaWithAuth(() =>
        claimsApi.optimisedSearchClaim(
          orgId,
          pagination.page,
          CLAIM_LIST_PAGE_SIZE,
          filters.query,
          filters.filter,
          filters.sort,
          true
        )
      )
    );
    const ids: string[] = yield syncEntitiesAndGetResults(apiResponse.data.results, SCHEMA.claims);
    yield put<ClaimListActionTypes>(fetchClaimsSuccess(apiResponse.data.count, ids));
  } catch (e) {
    yield put<ClaimListActionTypes>(fetchClaimsFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchClaimListSagas() {
  yield takeLatest(FETCH_CLAIMS_API_REQUESTED, fetchClaimList);
}

import { CommissionApi } from '@reposit/api-client';
import { get } from 'lodash';
import moment from 'moment';
import { call, put, takeLatest } from 'redux-saga/effects';
import { createCommissionApi, runSagaWithAuth } from '../utils/api.utils';
import {
  CommissionActionTypes,
  generateLastMonthsDirectCommissionFailed,
  generateLastMonthsDirectCommissionSuccess,
  GENERATE_LAST_MONTHS_DIRECT_COMMISSION_API_REQUESTED,
} from './commission.actions';

// ****************
// WORKERS
// ****************

export function* generateLastMonthsDirectCommission() {
  try {
    const commissionAPI: CommissionApi = yield createCommissionApi();
    const period = moment().subtract(1, 'month').format('YYYY/MM');
    const result = yield call(runSagaWithAuth(() => commissionAPI.generateCommissionCsvForPeriod(period)));

    const fileBlob = new Blob([result.data], { type: 'text/csv' });
    const url = URL.createObjectURL(fileBlob);
    window.open(url);
    URL.revokeObjectURL(url);
    yield put<CommissionActionTypes>(generateLastMonthsDirectCommissionSuccess());
  } catch (e) {
    yield put<CommissionActionTypes>(generateLastMonthsDirectCommissionFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchCommissionSagas() {
  yield takeLatest(GENERATE_LAST_MONTHS_DIRECT_COMMISSION_API_REQUESTED, generateLastMonthsDirectCommission);
}

import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import { Header3, P2 } from '../../../components/Typography';
import { setCurrentRepositActionModal, disableTopUpsRequested } from '../../../redux/reposit/reposit.actions';

interface DisableTopUpsModalProps {
  tenancyId: string;
}

const ActionBar = styled.div`
  display: flex;
  padding: 24px 0;
  justify-content: flex-end;
  flex-wrap: wrap;
  row-gap: 10px;

  button {
    margin-right: 10px;
  }
`;

export const DisableTopUpsModal: React.FC<DisableTopUpsModalProps> = ({ tenancyId }) => {
  const dispatch = useDispatch();
  return (
    <Modal onDismiss={() => dispatch(setCurrentRepositActionModal({ name: '' }))}>
      <Header3 style={{ marginBottom: 6 }}>Are you sure? </Header3>
      <P2>
        Disabling annual fees will cancel any pending annual fee orders and stop any future annual fees from being charged for
        this Reposit.
      </P2>
      <ActionBar>
        <Button buttonType="warning" onClick={() => dispatch(setCurrentRepositActionModal({ name: '' }))}>
          Cancel
        </Button>
        <Button onClick={() => dispatch(disableTopUpsRequested(tenancyId))}>Confirm</Button>
      </ActionBar>
    </Modal>
  );
};

import React, { Fragment, Suspense } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import styled from 'styled-components';

import AppHeader from './components/AppHeader';
import { MainContent } from './components/Common';
import MobileHeader from './components/MobileHeader';
import { PrivateRoute } from './components/PrivateRoute';
import SideMenu from './components/SideMenu';
import AddNewOrganisation from './containers/AddOrganisation';
import AuthenticateView from './containers/Authenticate';
import ClaimListContainer from './containers/Claim/List';
import ClaimView from './containers/Claim/View';
import Landlord from './containers/Landlord';
import LoginView from './containers/Login';
import NotFoundView from './containers/NotFound';
import Organisation from './containers/Organisation';
import Organisations from './containers/Organisations';
import Reference from './containers/Reference';
import References from './containers/References';
import RepositListContainer from './containers/Reposit/List';
import RepositNoteContainer from './containers/Reposit/Notes';
import RepositTransferView from './containers/Reposit/Transfer';
import RepositView from './containers/Reposit/View';
import { DirectCommission } from './containers/Tools/DirectCommission';
import { IntegratorCommission } from './containers/Tools/IntegratorCommission';
import { Kpis } from './containers/Tools/Kpis';
import User from './containers/User';
import Users from './containers/Users';
import { DownloadAllBankAccounts } from './containers/Tools/BankAccounts';
import { appConfig } from './appConfig';
import { Maintenance } from './containers/Maintenance/maintenance';
import { useSelector } from 'react-redux';
import { getAccessToken } from './redux/auth/auth.selectors';
import { Login } from './containers/Auth/Login/login';
import { ResetPasswordRequest } from './containers/Auth/ResetPasswordRequest/reset-password-request';
import { ResetPassword } from './containers/Auth/ResetPassword/reset-password';
import { SetPassword } from './containers/Auth/SetPassword/set-password';
import { getClaimsDownloadStatus } from './redux/claim/claim.selector';
import { Link } from 'react-router-dom';
import { Downloads } from './containers/Tools/Downloads/downloads';
import { ReconciliationDocs } from './containers/Tools/ReconciliationDocuments';
import { CreatedRepositsReport } from './containers/Tools/InternalReports/created-reposits-repor';
import { CompletedRepositsReport } from './containers/Tools/InternalReports/completed-reposits-report';

const AdminBar = styled.div`
  align-items: center;
  background: ${(props) => props.theme.colors.negative};
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  color: #fff;
  display: flex;
  font-family: ${(props) => props.theme.typography.face.secondary};
  font-size: 14px;
  position: fixed;
  height: 36px;
  padding: 0 24px;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  justify-content: space-between;

  #admin-bold {
    font-weight: bold;
    margin: 0 6px;
  }
`;

const StyledLink = styled(Link)`
  color: white;
  font-weight: bold;
  margin-right: 40px;
  text-decoration: underline;
`;

interface RouteDefinition {
  path: string;
  exact: boolean;
  mobileMenu: boolean;
  isFullWidth: boolean;
  nav?: () => JSX.Element | null;
  header?: () => JSX.Element | null;
  main: (props?: any) => JSX.Element;
}

const navigation = <SideMenu />;

const routes: RouteDefinition[] = [
  {
    path: '/',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader />,
    main: (props: any) => <Redirect to="/organisations" />,
  },
  {
    path: '/organisations',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader showAddAgentButton />,
    main: (props: any) => <Organisations {...props} />,
  },
  {
    path: '/organisations/new',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader />,
    main: (props: any) => <AddNewOrganisation {...props} />,
  },
  {
    path: '/organisations/:id',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader showAddAgentButton />,
    main: (props: any) => <Organisation {...props} />,
  },
  {
    path: '/reposits',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader />,
    main: (props: any) => <RepositListContainer {...props} />,
  },
  {
    path: '/reposits/:id/transfer',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader />,
    main: (props: any) => <RepositTransferView {...props} />,
  },
  {
    path: '/reposits/:id/add-note',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader />,
    main: (props: any) => <RepositNoteContainer {...props} />,
  },
  {
    path: '/reposits/:id',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader />,
    main: (props: any) => <RepositView {...props} />,
  },
  {
    path: '/claims',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader />,
    main: (props: any) => <ClaimListContainer {...props} />,
  },
  {
    path: '/claims/:id',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader />,
    main: (props: any) => <ClaimView {...props} />,
  },
  {
    path: '/users',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader />,
    main: (props: any) => <Users {...props} />,
  },
  {
    path: '/users/:userId',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader />,
    main: (props: any) => <User {...props} />,
  },
  {
    path: '/landlords/:id',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader />,
    main: (props: any) => <Landlord {...props} />,
  },
  {
    path: '/references/:referenceId',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader />,
    main: (props: any) => <Reference {...props} />,
  },
  {
    path: '/login',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => null,
    header: () => null,
    main: () => <LoginView />,
  },
  {
    path: '/logout',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => null,
    header: () => null,
    main: () => <Redirect to="/organisations" />,
  },
  {
    path: '/authenticate',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => null,
    header: () => null,
    main: (props: any) => <AuthenticateView {...props} />,
  },
  {
    path: '/references',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader />,
    main: (props: any) => <References {...props} />,
  },
  {
    path: '/tools/integrator-commission',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader />,
    main: (props: any) => <IntegratorCommission />,
  },
  {
    path: '/tools/direct-commission',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader />,
    main: (props: any) => <DirectCommission />,
  },
  {
    path: '/tools/bank-accounts',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader />,
    main: (props: any) => <DownloadAllBankAccounts />,
  },
  {
    path: '/tools/kpis',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader />,
    main: (props: any) => <Kpis />,
  },
  {
    path: '/tools/reconciliation',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader />,
    main: (props: any) => <ReconciliationDocs />,
  },
  {
    path: '/tools/downloads',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader />,
    main: (props: any) => <Downloads />,
  },
  {
    path: '/tools/internal-reports/created-reposits',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader />,
    main: (props: any) => <CreatedRepositsReport />,
  },
  {
    path: '/tools/internal-reports/completed-reposits',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader />,
    main: (props: any) => <CompletedRepositsReport />,
  },
  {
    path: '/auth/login',
    exact: true,
    mobileMenu: false,
    isFullWidth: true,
    nav: () => null,
    header: () => null,
    main: (props: any) => <Login />,
  },
  {
    path: '/auth/reset-password-request',
    exact: true,
    mobileMenu: false,
    isFullWidth: true,
    nav: () => null,
    header: () => null,
    main: (props: any) => <ResetPasswordRequest />,
  },
  {
    path: '/auth/reset-password',
    exact: true,
    mobileMenu: false,
    isFullWidth: true,
    nav: () => null,
    header: () => null,
    main: (props: any) => <ResetPassword />,
  },
  {
    path: '/auth/set-password',
    exact: true,
    mobileMenu: false,
    isFullWidth: true,
    nav: () => null,
    header: () => null,
    main: (props: any) => <SetPassword />,
  },
  {
    path: '*',
    exact: false,
    mobileMenu: false,
    isFullWidth: false,
    nav: () => null,
    header: () => null,
    main: (props: any) => <NotFoundView {...props} />,
  },
];

const isPublicRoute = (path: string) => {
  return (
    path === '/login' ||
    path === '/authenticate' ||
    path === '/error' ||
    path === '/auth/login' ||
    path === '/auth/reset-password-request' ||
    path === '/auth/reset-password' ||
    path === '/auth/set-password'
  );
};

interface RoutesProps {}

const Routes: React.FC<RoutesProps> = (props) => {
  const history = useHistory();
  const routesCopy = [...routes];
  routesCopy.shift();
  const catchAllRoute = routesCopy.pop();
  const currentRoute = routesCopy.find((route) => history.location.pathname.startsWith(route.path)) || catchAllRoute;
  const token = useSelector(getAccessToken);
  const claimsDownloadStatus = useSelector(getClaimsDownloadStatus);

  if (appConfig.inMaintenanceMode) {
    return <Maintenance />;
  }

  return (
    <Fragment>
      {token && (
        <AdminBar>
          <span>
            You are logged in as an <span id="admin-bold">Admin</span>
          </span>
          {claimsDownloadStatus ? (
            <StyledLink className="" to="/tools/downloads">
              View Downloads
            </StyledLink>
          ) : null}
        </AdminBar>
      )}
      <div style={{ margin: '36px 0 0' }}>
        <Switch>
          {routes.map((route, index) => {
            const props = {
              key: index,
              path: route.path,
              exact: route.exact,
              component: route.nav,
            };
            if (isPublicRoute(route.path)) {
              return <Route {...props} />;
            }
            return <PrivateRoute {...props} />;
          })}
        </Switch>
      </div>
      <MainContent isFullWidth={currentRoute && currentRoute.isFullWidth} isMobileMenuOpen={false} style={{ margin: '36px 0 0' }}>
        <Switch>
          {routes.map((route, index) => {
            const props = {
              key: index,
              path: route.path,
              exact: route.exact,
              component: () => (route.mobileMenu ? <MobileHeader onMenuClick={console.log} isMobileMenuOpen={false} /> : null),
            };
            if (isPublicRoute(route.path)) {
              return <Route {...props} />;
            }
            return <PrivateRoute {...props} />;
          })}
        </Switch>
        <Suspense fallback={null}>
          <Switch>
            {routes.map((route, index) => {
              const props = {
                key: index,
                path: route.path,
                exact: route.exact,
                component: route.header,
              };
              if (isPublicRoute(route.path)) {
                return <Route {...props} />;
              }
              return <PrivateRoute {...props} />;
            })}
          </Switch>
          <Switch>
            {routes.map((route, index) => {
              const props = {
                key: index,
                path: route.path,
                exact: route.exact,
                component: route.main,
              };
              if (isPublicRoute(route.path)) {
                return <Route {...props} />;
              }
              return <PrivateRoute {...props} />;
            })}
          </Switch>
        </Suspense>
      </MainContent>
    </Fragment>
  );
};

export default Routes;

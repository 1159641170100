import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Modal from '../../components/Modal';
import Loading from '../../components/Loading';
import Button from '../../components/Button';
import { Header3 } from '../../components/Typography';
import { UPDATE_ORG_NOTES_STORE_KEY } from '../../redux/organization/organization.actions';
import { createLoadingSelector } from '../../redux/loading/loading.selector';

const Action = styled.div`
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

interface EditOrgNotesModalProps {
  onSubmit: () => void;
  onDismiss: (showModal: boolean) => void;
}

const EditOrgNotesModal: React.SFC<EditOrgNotesModalProps> = ({ onSubmit, onDismiss }) => {
  const editOrgNotesLoadingSelector = createLoadingSelector([UPDATE_ORG_NOTES_STORE_KEY]);
  const isLoading = useSelector(editOrgNotesLoadingSelector);

  let content = (
    <Fragment>
      <Header3 style={{ marginBottom: 6 }}>Are you happy to edit the notes for this organisation?</Header3>
      <Action>
        <Button buttonType="secondary" noArrow={true} onClick={() => onDismiss(false)}>
          No, return to form
        </Button>
        <Button
          onClick={() => {
            onSubmit();
            onDismiss(false);
          }}
        >
          Yes
        </Button>
      </Action>
    </Fragment>
  );

  return <Modal onDismiss={() => onDismiss(false)}>{isLoading ? <Loading /> : content}</Modal>;
};

export default EditOrgNotesModal;

import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import { Header3 } from '../../../components/Typography';
import { extendOutcomeDeadlineRequested, setCurrentRepositActionModal } from '../../../redux/reposit/reposit.actions';

interface ExtendOutcomeDeadlineModalProps {
  tenancyId: string;
}

const ActionBar = styled.div`
  display: flex;
  padding: 24px 0;
  justify-content: flex-end;
  flex-wrap: wrap;
  row-gap: 10px;

  button {
    margin-right: 10px;
  }
`;

export const ExtendOutcomeDeadlineModal: React.FC<ExtendOutcomeDeadlineModalProps> = ({ tenancyId }) => {
  const dispatch = useDispatch();
  return (
    <Modal onDismiss={() => dispatch(setCurrentRepositActionModal({ name: '' }))}>
      <Header3 style={{ marginBottom: 6 }}>Are you sure you would like to extend the outcome deadline of this reposit? </Header3>
      <ActionBar>
        <Button buttonType="warning" onClick={() => dispatch(setCurrentRepositActionModal({ name: '' }))}>
          Cancel
        </Button>
        <Button onClick={() => dispatch(extendOutcomeDeadlineRequested(tenancyId))}>Confirm</Button>
      </ActionBar>
    </Modal>
  );
};

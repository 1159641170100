import React, { Fragment } from 'react';
import Card from '../../Card';
import { Header3, Caption } from '../../Typography';
import styled from 'styled-components';
import { RoundButton } from '../../Button';
import HelpIcon from '../../../assets/svg/help.svg';

interface RepositCardProps {
  children?: JSX.Element | JSX.Element[];
  title: string;
  subtitle?: string;
  icon?: string;
  tooltip?: string;
  flush?: boolean;
  flashMessage?: JSX.Element;
  headerContent?: JSX.Element;
}

interface HeaderProps {
  icon?: string;
  flush?: boolean;
}

const Header = styled.div<HeaderProps>`
  align-items: center;
  display: flex;
  margin-bottom: 1rem;
  padding: ${(props) => (props.flush ? '0 35px' : '0')};

  &:before {
    background: ${(props) => (props.icon ? `transparent url(${props.icon}) center center` : 'transparent')};
    background-size: 20px;
    content: '';
    display: ${(props) => (props.icon ? `block` : 'none')};
    height: 20px;
    margin: 0 20px 0 0;
    width: 20px;
  }
`;

const Subtitle = styled(Caption)`
  margin-left: 8px;
  margin-bottom: 0;
`;

const HelpButton = styled(RoundButton)`
  margin-left: auto;
`;

const RepositCard: React.FC<RepositCardProps> = ({
  children,
  title,
  subtitle,
  icon,
  tooltip,
  flush,
  flashMessage,
  headerContent,
}) => {
  return (
    <Card hover flashMessage={flashMessage} flush={flush}>
      <Header icon={icon} flush={flush}>
        <Header3 style={{ marginBottom: 0 }}>{title}</Header3>
        {subtitle && <Subtitle>- {subtitle}</Subtitle>}
        {tooltip && <HelpButton icon={HelpIcon} data-tip={tooltip} />}
        {headerContent && headerContent}
      </Header>
      <Fragment>{children}</Fragment>
    </Card>
  );
};

export default RepositCard;

import { OrganizationsApi, PropertyDTO, TenanciesApi } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getCurrentOrganizationId } from '../account/account.selectors';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import SCHEMA from '../schema';
import { createOrganizationsApi, createTenanciesApi, runSagaWithAuth } from '../utils/api.utils';
import {
  fetchOrganizationPropertiesFailed,
  fetchOrganizationPropertiesSuccess,
  FETCH_ORGANIZATION_PROPERTIES_API_REQUESTED,
  PropertiesActionTypes,
  UPDATE_PROPERTY_STORE_KEY,
} from './property.actions';
import { updatePropertyFailed, updatePropertySuccess, UPDATE_PROPERTY_API_REQUESTED } from './property.actions';
import { UpdatePropertyPayload } from './property.types';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import { FlashState } from '../../components/FlashMessage';
import { fetchTenancyOrderRequested, RepositActionTypes } from '../reposit/reposit.actions';

// ****************
// WORKERS
// ****************

export function* fetchPropertiesForOrganization() {
  try {
    const organizationId: string = yield select(getCurrentOrganizationId);
    const organizationsApi: OrganizationsApi = yield createOrganizationsApi();
    const apiResponse: AxiosResponse<PropertyDTO[]> = yield call(
      runSagaWithAuth(() => organizationsApi.fetchPropertiesByOrganizationId(organizationId))
    );
    const ids: string[] = yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.properties);
    yield put<PropertiesActionTypes>(fetchOrganizationPropertiesSuccess(ids));
  } catch (e) {
    yield put<PropertiesActionTypes>(fetchOrganizationPropertiesFailed(get(e, 'response.data.message', e)));
  }
}

export function* updateProperty({ payload }: { type: string; payload: UpdatePropertyPayload }) {
  try {
    const tenanciesApi: TenanciesApi = yield createTenanciesApi();
    const apiResponse: AxiosResponse<PropertyDTO[]> = yield call(
      runSagaWithAuth(() => tenanciesApi.updateExistingProperty(payload.tenancyId, payload as any))
    );

    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.tenancy);
    yield put<RepositActionTypes>(fetchTenancyOrderRequested(payload.tenancyOrderId));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_PROPERTY_STORE_KEY,
        message: 'Success! Property details have been updated.',
        state: FlashState.SUCCESS,
      })
    );
    yield put<PropertiesActionTypes>(updatePropertySuccess());
  } catch (e) {
    const error = get(e, 'response.data.message', e);
    yield put<PropertiesActionTypes>(updatePropertyFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_PROPERTY_STORE_KEY,
        message: 'Something went wrong',
        state: FlashState.ERROR,
      })
    );
  }
}

// ****************
// WATCHERS
// ****************
export function* watchPropertiesSagas() {
  yield takeLatest(FETCH_ORGANIZATION_PROPERTIES_API_REQUESTED, fetchPropertiesForOrganization);
  yield takeLatest(UPDATE_PROPERTY_API_REQUESTED, updateProperty);
}

import { ClaimItemActionTypes, DISCARD_CLAIM_ITEM_API_SUCCESS } from '../../claim-item/claim-item.actions';
import {
  ClaimDocumentActionTypes,
  DELETE_CLAIM_DOCUMENT_API_SUCCESS,
  CREATE_CLAIM_DOCUMENT_API_SUCCESS
} from '../../claim-document/claim-document.actions';

export const claimEntityReducer = (state: any = {}, action: ClaimItemActionTypes | ClaimDocumentActionTypes) => {
  switch (action.type) {
    case DISCARD_CLAIM_ITEM_API_SUCCESS:
      const newClaimItems = state[action.payload.claimId].items.filter((itemId: string) => itemId !== action.payload.claimItemId);
      return {
        ...state,
        [action.payload.claimId]: {
          ...state[action.payload.claimId],
          items: newClaimItems
        }
      };
    case DELETE_CLAIM_DOCUMENT_API_SUCCESS:
      const newClaimDocuments = state[action.payload.claimId].documents.filter(
        (itemId: string) => itemId !== action.payload.claimDocumentId
      );
      return {
        ...state,
        [action.payload.claimId]: {
          ...state[action.payload.claimId],
          documents: newClaimDocuments
        }
      };

    case CREATE_CLAIM_DOCUMENT_API_SUCCESS:
      const currentDocs = state[action.payload.claimId].documents || [];
      return {
        ...state,
        [action.payload.claimId]: {
          ...state[action.payload.claimId],
          documents: [...currentDocs, action.payload.claimDocumentId]
        }
      };

    default:
      return state;
  }
};

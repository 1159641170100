import { Formik, FormikProps } from 'formik';
import moment from 'moment';
import React from 'react';
import * as Yup from 'yup';
import styled from 'styled-components';

import { DATE_FORMAT } from '../../constants/dates';
import Button from '../Button';
import DatePicker from '../FormFields/DatePicker';
import FieldWithLabel from '../FormFields/FieldWithLabel';
import Modal from '../Modal';
import { Header3 } from '../Typography';

export enum ReopenType {
  REPOSIT = 'REPOSIT',
  CLAIM = 'CLAIM',
}

interface ReopenModalProps {
  onSubmit: (date?: Date) => void;
  onDismiss: () => void;
  type: ReopenType;
  isSubmitting: boolean;
}

interface ReopenForm {
  outcomeDeadline?: Date;
}

const Schema = Yup.object().shape({
  outcomeDeadline: Yup.date().typeError('Outcome deadline must be a valid date').required('Required'),
});

const ActionBar = styled.div`
  display: flex;
  padding: 24px 0;
  justify-content: flex-end;
  flex-wrap: wrap;
  row-gap: 10px;

  button {
    margin-right: 10px;
  }
`;

export const ReopenModal: React.FC<ReopenModalProps> = ({ onSubmit, type, onDismiss, isSubmitting }) => {
  return (
    <Modal onDismiss={onDismiss}>
      <Header3 style={{ marginBottom: 15 }}>
        {`Are you sure you would like to reopen this ${
          type === ReopenType.REPOSIT ? 'reposit' : 'claim'
        }? If so, please choose a new outcome deadline below`}
      </Header3>

      <Formik initialValues={{}} validationSchema={Schema} onSubmit={(val) => onSubmit(val.outcomeDeadline)}>
        {({ values, handleSubmit, handleChange, handleBlur, touched, errors, setFieldValue }: FormikProps<ReopenForm>) => (
          <form onSubmit={handleSubmit}>
            <FieldWithLabel label="Outcome Deadline">
              <DatePicker
                name="outcomeDeadline"
                value={values.outcomeDeadline}
                dateFormat={DATE_FORMAT}
                onBlur={handleBlur}
                touched={touched.outcomeDeadline}
                placeholder={'DD/MM/YYYY'}
                error={errors.outcomeDeadline}
                minDate={moment().add(1, 'day').toDate()}
                onChange={(date) => {
                  setFieldValue('outcomeDeadline', date);
                }}
              />
            </FieldWithLabel>
            <ActionBar style={{ marginTop: 20 }}>
              <Button disabled={isSubmitting} type="submit">
                Confirm
              </Button>
            </ActionBar>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaymentPlansApi } from '@reposit/api-client';
import moment from 'moment';
import { FlashState } from '../../components/FlashMessage';
import { getErrorMessage } from '../../utils/common.utils';
import { fetchClaimRequested } from '../claim/claim.actions';
import { setFlashMessage } from '../flash-messages/flash-messages.actions';
import { AppState } from '../root.reducer';
import { createStandardPaymentPlansApi, runThunkWithAuth } from '../utils/api.utils';
import { CREATE_PAYMENT_PLAN_STORE_KEY, DELETE_PAYMENT_PLAN_STORE_KEY } from './payment-plan.actions';
import { CreatePaymentPlanPayload, DeletePaymentPlanPayload } from './payment-plan.types';

export const createPaymentPlanThunk = createAsyncThunk<
  void,
  CreatePaymentPlanPayload,
  {
    state: AppState;
  }
>('payment-plan/create', async (payload, thunkAPI) => {
  const state = thunkAPI.getState();
  const dispatch = thunkAPI.dispatch;
  try {
    const api: PaymentPlansApi = createStandardPaymentPlansApi(state);
    const { startDate, amountPerInstalment, orderCustomerId, claimId, manualPaymentsTotal, isHistoric, interestAmount } = payload;

    await runThunkWithAuth(
      () =>
        api.createPaymentPlan({
          startDate: moment(startDate).format('YYYY-MM-DD'),
          amountPerInstalment,
          orderCustomerId,
          manualPaymentsTotal,
          isHistoric,
          interestAmount,
        }),
      dispatch
    );

    dispatch(fetchClaimRequested({ claimId }));
    dispatch(
      setFlashMessage({
        key: CREATE_PAYMENT_PLAN_STORE_KEY,
        message: `Success! Payment plan created for claim ${claimId}`,
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch(
      setFlashMessage({
        key: CREATE_PAYMENT_PLAN_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
});

export const deletePaymentPlanThunk = createAsyncThunk<
  void,
  DeletePaymentPlanPayload,
  {
    state: AppState;
  }
>('payment-plan/delete', async (payload, thunkAPI) => {
  const { claimId, paymentPlanId } = payload;
  const state = thunkAPI.getState();
  const dispatch = thunkAPI.dispatch;
  try {
    const api: PaymentPlansApi = createStandardPaymentPlansApi(state);

    await runThunkWithAuth(() => api.deletePaymentPlanById(paymentPlanId), dispatch);

    dispatch(fetchClaimRequested({ claimId }));
    dispatch(
      setFlashMessage({
        key: DELETE_PAYMENT_PLAN_STORE_KEY,
        message: `Success! Payment plan deleted for claim ${claimId}`,
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch(
      setFlashMessage({
        key: DELETE_PAYMENT_PLAN_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
});

import React from 'react';
import RepositCard from '../Reposit/RepositCard';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import { Container, Row, Col } from 'react-grid-system';
import { Header4 } from '../Typography';
import FieldWithLabel from '../FormFields/FieldWithLabel';
import { Input } from '../FormFields';
import Button from '../Button';
import styled from 'styled-components';
import { get } from 'lodash';
import LandlordFormFields from '../../containers/Landlord/LandlordFormFields';
import { useDispatch, useSelector } from 'react-redux';
import { updatePropertyRequested, UPDATE_PROPERTY_STORE_KEY } from '../../redux/property/property.actions';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import { useFlashMessage, FLASH_MESSAGE_TIMEOUT } from '../../containers/FlashMessage';
import FlashMessage from '../FlashMessage';
import countries from 'i18n-iso-countries';
import Select from '../FormFields/Select';
import { countryList, UK_POSTCODE_REG_EX } from '../../utils/common.utils';

interface PropertyUpdateProps {
  property: any;
  tenancyId: string;
  tenancyOrderId: string;
}

const Schema = yup.object().shape({
  address: yup.object().shape(
    {
      buildingNumber: yup.string().nullable(),
      buildingName: yup.string().nullable(),
      street: yup.string().required('Required'),
      postcode: yup.string().matches(UK_POSTCODE_REG_EX, 'Must be a UK postcode').required('Required'),
      country: yup.string().required('Required'),
    },
    [['buildingNumber', 'buildingName']]
  ),
  landlord: yup.object({
    type: yup.string().required('Required'),
    email: yup.string().email().required('Required'),
    firstName: yup.string().when('type', {
      is: (type: string) => type === 'SOLE_PERSON',
      then: yup.string().required('Required'),
      otherwise: yup.string().nullable(),
    }),
    lastName: yup.string().when('type', {
      is: (type: string) => type === 'SOLE_PERSON',
      then: yup.string().required('Required'),
      otherwise: yup.string().nullable(),
    }),
    companyName: yup.string().when('type', {
      is: (type: string) => type === 'COMPANY',
      then: yup.string().required('Required'),
      otherwise: yup.string().nullable(),
    }),
  }),
});

export interface AddressFormValues {
  address: {
    buildingNumber: string;
    street: string;
    postcode: string;
    country: string;
    roomNumber?: string;
    flatNumber?: string;
    buildingName?: string;
    town?: string;
    county?: string;
  };
  landlord: {
    firstName: string;
    lastName: string;
    companyName: string;
    type: string;
    email: string;
  };
}

const FormActions = styled.div`
  align-items: center;
  border-top: 1px solid ${(props) => props.theme.colors.dividerDark};
  display: flex;
  justify-content: flex-end;
  margin: 36px 0 0;
  padding: 22px 0 0;
`;

const PropertyUpdate: React.FC<PropertyUpdateProps> = ({ property, tenancyId, tenancyOrderId }) => {
  const dispatch = useDispatch();
  const loadingSelector = createLoadingSelector([UPDATE_PROPERTY_STORE_KEY]);
  const isLoading = useSelector(loadingSelector);
  const [flashMessagePayload, dismissFlashMessage] = useFlashMessage([UPDATE_PROPERTY_STORE_KEY]);

  return (
    <RepositCard
      title="Property"
      flashMessage={
        flashMessagePayload ? (
          <FlashMessage payload={flashMessagePayload} onDismiss={dismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMEOUT} />
        ) : undefined
      }
    >
      <Formik
        initialValues={{
          address: {
            buildingNumber: get(property.address, 'buildingNumber', ''),
            buildingName: get(property.address, 'buildingName', ''),
            street: get(property.address, 'street', ''),
            postcode: get(property.address, 'postcode', ''),
            country: get(property.address, 'country', ''),
            roomNumber: get(property.address, 'roomNumber', ''),
            flatNumber: get(property.address, 'flatNumber', ''),
            town: get(property.address, 'town', ''),
            county: get(property.address, 'county', ''),
          },
          landlord: {
            firstName: get(property.landlord, 'firstName') || '',
            lastName: get(property.landlord, 'lastName') || '',
            email: get(property.landlord, 'email') || '',
            companyName: get(property.landlord, 'companyName') || '',
            type: get(property.landlord, 'type', 'SOLE_PERSON'),
          },
        }}
        validationSchema={Schema}
        onSubmit={(values) => {
          dispatch(
            updatePropertyRequested({
              ...values,
              tenancyId,
              tenancyOrderId,
              landlord: {
                ...values.landlord,
                landlordTypeId: values.landlord.type,
              },
              address: {
                ...values.address,
                country: countries.toAlpha3(values.address.country),
              },
            })
          );
        }}
      >
        {({ values, handleSubmit, handleChange, handleBlur, touched, errors, setFieldValue }: FormikProps<AddressFormValues>) => {
          return (
            <form onSubmit={handleSubmit}>
              <Container fluid style={{ padding: 0 }}>
                <Row>
                  <Col lg={6}>
                    <Header4>Address</Header4>
                    <FieldWithLabel
                      label="Room Number"
                      touched={touched.address && touched.address.roomNumber}
                      error={errors.address && errors.address.roomNumber}
                    >
                      <Input
                        name="address.roomNumber"
                        value={values.address.roomNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched.address && touched.address.roomNumber}
                        error={errors.address && errors.address.roomNumber}
                      />
                    </FieldWithLabel>
                    <FieldWithLabel
                      label="Flat Number"
                      touched={touched.address && touched.address.flatNumber}
                      error={errors.address && errors.address.flatNumber}
                    >
                      <Input
                        name="address.flatNumber"
                        value={values.address.flatNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched.address && touched.address.flatNumber}
                        error={errors.address && errors.address.flatNumber}
                      />
                    </FieldWithLabel>
                    <FieldWithLabel
                      label="Building Number"
                      touched={touched.address && touched.address.buildingNumber}
                      error={errors.address && errors.address.buildingNumber}
                    >
                      <Input
                        name="address.buildingNumber"
                        value={values.address.buildingNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched.address && touched.address.buildingNumber}
                        error={errors.address && errors.address.buildingNumber}
                      />
                    </FieldWithLabel>
                    <FieldWithLabel
                      label="Building Name"
                      touched={touched.address && touched.address.buildingName}
                      error={errors.address && errors.address.buildingName}
                    >
                      <Input
                        name="address.buildingName"
                        value={values.address.buildingName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched.address && touched.address.buildingName}
                        error={errors.address && errors.address.buildingName}
                      />
                    </FieldWithLabel>
                    <FieldWithLabel
                      label="Street*"
                      touched={touched.address && touched.address.street}
                      error={errors.address && errors.address.street}
                    >
                      <Input
                        name="address.street"
                        value={values.address.street}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched.address && touched.address.street}
                        error={errors.address && errors.address.street}
                      />
                    </FieldWithLabel>
                    <FieldWithLabel
                      label="Town"
                      touched={touched.address && touched.address.town}
                      error={errors.address && errors.address.town}
                    >
                      <Input
                        name="address.town"
                        value={values.address.town}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched.address && touched.address.town}
                        error={errors.address && errors.address.town}
                      />
                    </FieldWithLabel>
                    <FieldWithLabel
                      label="County"
                      touched={touched.address && touched.address.county}
                      error={errors.address && errors.address.county}
                    >
                      <Input
                        name="address.county"
                        value={values.address.county}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched.address && touched.address.county}
                        error={errors.address && errors.address.county}
                      />
                    </FieldWithLabel>
                    <FieldWithLabel
                      label="Postcode*"
                      touched={touched.address && touched.address.postcode}
                      error={errors.address && errors.address.postcode}
                    >
                      <Input
                        name="address.postcode"
                        value={values.address.postcode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched.address && touched.address.postcode}
                        error={errors.address && errors.address.postcode}
                      />
                    </FieldWithLabel>
                    <FieldWithLabel
                      label="Country*"
                      touched={touched.address && touched.address.country}
                      error={errors.address && errors.address.country}
                    >
                      <Select
                        name="address.country"
                        onChange={(value: any) => setFieldValue('address.country', value)}
                        onBlur={handleBlur}
                        value={countries.toAlpha2(values.address.country) || 0}
                        // touched={touched.address && touched.address.country}
                        // error={errors.address && errors.address.country}
                      >
                        {Object.keys(countryList).map((country) => (
                          <option key={country} value={country}>
                            {countryList[country]}
                          </option>
                        ))}
                      </Select>
                    </FieldWithLabel>
                  </Col>
                  <Col lg={6}>
                    <Header4>Landlord</Header4>
                    <LandlordFormFields
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      values={{ ...values.landlord }}
                      errors={{ ...errors.landlord }}
                      touched={{ ...touched.landlord }}
                    />
                  </Col>
                </Row>
              </Container>
              <Container style={{ padding: 0 }}>
                <Row>
                  <Col lg={12}>
                    <FormActions>
                      <Button buttonType="secondary" type="submit" disabled={isLoading}>
                        Update address
                      </Button>
                    </FormActions>
                  </Col>
                </Row>
              </Container>
            </form>
          );
        }}
      </Formik>
    </RepositCard>
  );
};

export default PropertyUpdate;

import React from 'react';
import styled from 'styled-components';
import { CardComponent } from '../../components/Card/index';
import { getBreakpoint } from '../../base/style';
import { Header4, P2 } from '../../components/Typography/index';
import RepositLogo from '../../assets/png/primary-full-colour.png';

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 40px 40px 24px;
  text-align: center;
`;

const LogoImage = styled.img`
  max-height: 52px;
  display: block;
  object-fit: contain;
  max-width: 100%;
`;

const AuthCard = styled(CardComponent)`
  box-shadow: none;
  background: inherit;
  padding: 0;
  margin: 0;
  width: 100%;

  &:hover {
    box-shadow: none;
    transform: none;
  }

  @media all and (min-width: ${getBreakpoint('sm')}) {
    background: white;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 20px 0;
    width: 400px;
    height: 450px;

    &:hover {
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    }
  }
`;

const AuthContainer = styled.div`
  @media all and (min-width: ${getBreakpoint('sm')}) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
  }
`;

export const Maintenance: React.FC<unknown> = () => {
  return (
    <AuthContainer>
      <AuthCard>
        <LogoContainer>
          <LogoImage src={RepositLogo} />
          <Header4 style={{ marginTop: 24, fontSize: 24, color: '#2d333a', fontWeight: 400 }}>
            Reposit is currently down for maintenance
          </Header4>
          <P2 style={{ color: '#2d333a', fontSize: 14 }}>We expect to be back at 18:00 GMT. Thank you for your patience.</P2>
        </LogoContainer>
      </AuthCard>
    </AuthContainer>
  );
};

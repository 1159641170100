import React from 'react';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { UpdateUserDTO, UserDTOUserTypeIdEnum } from '@reposit/api-client';

import Modal from '../../components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { setIsEditModalOpen, UPDATE_USER_BY_ID_STORE_KEY, updateUserByIdRequested } from '../../redux/user/user.actions';
import { Header3 } from '../../components/Typography';
import { phoneNumberStringMatcher } from '../../utils/common.utils';
import { UserEntity } from '../../redux/entities/entities.types';
import { Container, Row } from 'react-grid-system';
import FieldWithLabel from '../../components/FormFields/FieldWithLabel';
import { Input, RadioGroup } from '../../components/FormFields';
import DatePicker from '../../components/FormFields/DatePicker';
import { DATE_FORMAT } from '../../constants/dates';
import Button from '../../components/Button';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import Select from '../../components/FormFields/Select';

interface EditUserFormProps
  extends Omit<UpdateUserDTO, 'addresses' | 'dob' | 'endOfTenancyContact' | 'endOfTenancyEmailsDisabled'> {
  dob?: Date;
  subscribedToPersonalNotificationsOnly?: 'YES' | 'NO';
  endOfTenancyEmailsDisabled?: 'YES' | 'NO';
}

interface EditUserModalProps {
  user: UserEntity;
}

const requiredString = Yup.string().required('Required');
const nonRequiredString = Yup.string().nullable();
const requiredEmail = Yup.string().email('Invalid email').required('Required');
const nonRequiredEmail = Yup.string().email('Invalid email').nullable();
const requiredDate = Yup.date().typeError('Must be a valid date').required('Required');
const nonRequiredDate = Yup.date().nullable().typeError('Must be a valid date');
const requiredPhoneNumber = Yup.string()
  .min(11, 'Incorrectly formatted phone number')
  .max(15, 'Incorrectly formatted phone number')
  .matches(phoneNumberStringMatcher, 'Incorrectly formatted phone number')
  .required('Required');
const nonRequiredPhoneNumber = Yup.string()
  .min(11, 'Incorrectly formatted phone number')
  .max(15, 'Incorrectly formatted phone number')
  .matches(phoneNumberStringMatcher, 'Incorrectly formatted phone number')
  .nullable();
const requiredBooleanEnum = Yup.string().oneOf(['YES', 'NO']).required('Required');
const nonRequiredBooleanEnum = Yup.string().oneOf(['YES', 'NO']).nullable();

const TenantSchema = (initialValues: EditUserFormProps) =>
  Yup.object().shape({
    firstName: initialValues.firstName ? requiredString : nonRequiredString,
    lastName: initialValues.lastName ? requiredString : nonRequiredString,
    email: initialValues.email ? requiredEmail : nonRequiredEmail,
    dob: initialValues.dob ? requiredDate : nonRequiredDate,
    mobileNumber: initialValues.mobileNumber ? requiredPhoneNumber : nonRequiredPhoneNumber,
    gender: initialValues.gender ? requiredString : nonRequiredString,
  });
const SupplierSchema = (initialValues: EditUserFormProps) =>
  Yup.object().shape({
    firstName: initialValues.firstName ? requiredString : nonRequiredString,
    lastName: initialValues.lastName ? requiredString : nonRequiredString,
    email: initialValues.email ? requiredEmail : nonRequiredEmail,
    jobTitle: initialValues.jobTitle ? requiredString : nonRequiredString,
    mobileNumber: initialValues.mobileNumber ? requiredPhoneNumber : nonRequiredPhoneNumber,
    officePhoneNumber: initialValues.officePhoneNumber ? requiredPhoneNumber : nonRequiredPhoneNumber,
    subscribedToPersonalNotificationsOnly: initialValues.subscribedToPersonalNotificationsOnly
      ? requiredBooleanEnum
      : nonRequiredBooleanEnum,
    endOfTenancyEmailsDisabled: initialValues.endOfTenancyEmailsDisabled ? requiredBooleanEnum : nonRequiredBooleanEnum,
  });

const TenantForm: React.FC<FormikProps<EditUserFormProps>> = ({
  errors,
  values,
  handleChange,
  touched,
  handleBlur,
  setFieldValue,
}) => {
  return (
    <>
      <Row>
        <FieldWithLabel
          label="First Name"
          error={errors.firstName}
          touched={touched.firstName}
          style={{ width: '48%', marginRight: 10, marginTop: 10 }}
        >
          <Input
            name="firstName"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.firstName}
            touched={touched.firstName}
          />
        </FieldWithLabel>
        <FieldWithLabel
          label="Last Name"
          error={errors.lastName}
          touched={touched.lastName}
          style={{ width: '48%', marginLeft: 10, marginTop: 10 }}
        >
          <Input
            name="lastName"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.lastName}
            touched={touched.lastName}
          />
        </FieldWithLabel>
      </Row>
      <Row>
        <FieldWithLabel
          label="Email"
          error={errors.email}
          touched={touched.email}
          style={{ width: '48%', marginRight: 10, marginTop: 10 }}
        >
          <Input
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.email}
            touched={touched.email}
          />
        </FieldWithLabel>
        <FieldWithLabel label="Date of birth" style={{ width: '48%', marginLeft: 10, marginTop: 10 }}>
          <DatePicker
            name="dob"
            value={values.dob}
            dateFormat={DATE_FORMAT}
            onBlur={handleBlur}
            touched={touched.dob}
            placeholder={'DD/MM/YYYY'}
            error={errors.dob}
            onChange={(date) => {
              setFieldValue('dob', date);
            }}
            maxDate={moment().startOf('day').subtract(1, 'day').toDate()}
          />
        </FieldWithLabel>
      </Row>
      <Row>
        <FieldWithLabel
          label="Mobile Number"
          error={errors.mobileNumber}
          touched={touched.mobileNumber}
          style={{ width: '48%', marginRight: 10, marginTop: 10 }}
        >
          <Input
            name="mobileNumber"
            value={values.mobileNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.mobileNumber}
            touched={touched.mobileNumber}
          />
        </FieldWithLabel>
        <FieldWithLabel
          label="Gender"
          error={errors.gender}
          touched={touched.gender}
          style={{ width: '48%', marginLeft: 10, marginTop: 10 }}
        >
          <Select name="gender" onChange={(val) => setFieldValue('gender', val)} onBlur={handleBlur} value={values.gender}>
            <option key={'MALE'} value={'MALE'}>
              Male
            </option>
            <option key={'FEMALE'} value={'FEMALE'}>
              Female
            </option>
            <option key={'UNKNOWN'} value={'UNKNOWN'}>
              Rather not say
            </option>
          </Select>
        </FieldWithLabel>
      </Row>
    </>
  );
};
const SupplierForm: React.FC<FormikProps<EditUserFormProps>> = ({ errors, values, handleChange, touched, handleBlur }) => {
  return (
    <>
      <Row>
        <FieldWithLabel
          label="First Name"
          error={errors.firstName}
          touched={touched.firstName}
          style={{ width: '48%', marginRight: 10, marginTop: 10 }}
        >
          <Input
            name="firstName"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.firstName}
            touched={touched.firstName}
          />
        </FieldWithLabel>
        <FieldWithLabel
          label="Last Name"
          error={errors.lastName}
          touched={touched.lastName}
          style={{ width: '48%', marginLeft: 10, marginTop: 10 }}
        >
          <Input
            name="lastName"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.lastName}
            touched={touched.lastName}
          />
        </FieldWithLabel>
      </Row>
      <Row>
        <FieldWithLabel
          label="Email"
          error={errors.email}
          touched={touched.email}
          style={{ width: '48%', marginRight: 10, marginTop: 10 }}
        >
          <Input
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.email}
            touched={touched.email}
          />
        </FieldWithLabel>
        <FieldWithLabel
          label="Job Title"
          error={errors.jobTitle}
          touched={touched.jobTitle}
          style={{ width: '48%', marginLeft: 10, marginTop: 10 }}
        >
          <Input
            name="jobTitle"
            value={values.jobTitle}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.jobTitle}
            touched={touched.jobTitle}
          />
        </FieldWithLabel>
      </Row>
      <Row>
        <FieldWithLabel
          label="Mobile Number"
          error={errors.mobileNumber}
          touched={touched.mobileNumber}
          style={{ width: '48%', marginRight: 10, marginTop: 10 }}
        >
          <Input
            name="mobileNumber"
            value={values.mobileNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.mobileNumber}
            touched={touched.mobileNumber}
          />
        </FieldWithLabel>
        <FieldWithLabel
          label="Office Phone Number"
          error={errors.officePhoneNumber}
          touched={touched.officePhoneNumber}
          style={{ width: '48%', marginLeft: 10, marginTop: 10 }}
        >
          <Input
            name="officePhoneNumber"
            value={values.officePhoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.officePhoneNumber}
            touched={touched.officePhoneNumber}
          />
        </FieldWithLabel>
      </Row>
      <Row>
        <FieldWithLabel
          label="Only subscribed to personal notifications"
          touched={touched.subscribedToPersonalNotificationsOnly}
          error={errors.subscribedToPersonalNotificationsOnly}
          style={{ marginTop: 10 }}
        >
          <RadioGroup
            name="subscribedToPersonalNotificationsOnly"
            options={[
              { value: 'YES', label: 'Yes' },
              { value: 'NO', label: 'No' },
            ]}
            onChange={handleChange}
            onBlur={handleBlur}
            selected={values.subscribedToPersonalNotificationsOnly}
          />
        </FieldWithLabel>
      </Row>
      <Row>
        <FieldWithLabel
          label="All end of tenancy emails disabled"
          touched={touched.endOfTenancyEmailsDisabled}
          error={errors.endOfTenancyEmailsDisabled}
          style={{ marginTop: 10 }}
        >
          <RadioGroup
            name="endOfTenancyEmailsDisabled"
            options={[
              { value: 'YES', label: 'Yes' },
              { value: 'NO', label: 'No' },
            ]}
            onChange={handleChange}
            onBlur={handleBlur}
            selected={values.endOfTenancyEmailsDisabled}
          />
        </FieldWithLabel>
      </Row>
    </>
  );
};

const generateBooleanValues = (inverseReturn: boolean, value?: boolean) => {
  if (value === undefined) {
    return undefined;
  }
  if (inverseReturn) {
    return value ? 'NO' : 'YES';
  }
  return value ? 'YES' : 'NO';
};

export const EditUserModal: React.FC<EditUserModalProps> = ({ user }) => {
  const dispatch = useDispatch();
  const isEditingUserSelector = createLoadingSelector([UPDATE_USER_BY_ID_STORE_KEY]);
  const isEditingUser = useSelector(isEditingUserSelector);
  const initValues: EditUserFormProps = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    jobTitle: user.attributes && user.attributes.jobTitle,
    dob: user.attributes && user.attributes.dob ? moment(user.attributes.dob, 'YYYY/MM/DD').toDate() : undefined,
    mobileNumber: user.attributes && user.attributes.mobileNumber,
    officePhoneNumber: user.attributes && user.attributes.officePhoneNumber,
    // inverse here to make the question read better in the UI
    subscribedToPersonalNotificationsOnly: generateBooleanValues(true, user.attributes && user.attributes.endOfTenancyContact),
    endOfTenancyEmailsDisabled: generateBooleanValues(false, user.attributes && user.attributes.endOfTenancyEmailsDisabled),
    gender: user.attributes && user.attributes.gender,
  };

  const onSubmit = (values: EditUserFormProps) => {
    const dob = values.dob ? moment(values.dob).format('YYYY-MM-DD') : undefined;
    // inverse here to make the question read better in the UI
    const endOfTenancyContact =
      values.subscribedToPersonalNotificationsOnly === undefined
        ? undefined
        : values.subscribedToPersonalNotificationsOnly === 'NO';
    const endOfTenancyEmailsDisabled =
      values.endOfTenancyEmailsDisabled === undefined ? undefined : values.endOfTenancyEmailsDisabled === 'YES';

    const payload = {
      ...values,
      dob,
      endOfTenancyContact,
      endOfTenancyEmailsDisabled,
    };
    dispatch(updateUserByIdRequested({ userId: user.id, user: payload }));
  };
  const Schema = user.userTypeId === UserDTOUserTypeIdEnum.TENANT ? TenantSchema(initValues) : SupplierSchema(initValues);

  return (
    <Modal onDismiss={() => dispatch(setIsEditModalOpen(false))}>
      <Header3 style={{ marginBottom: 6 }}>Edit User</Header3>
      <Formik validationSchema={Schema} initialValues={initValues} onSubmit={onSubmit}>
        {(props: FormikProps<EditUserFormProps>) => {
          return (
            <form onSubmit={props.handleSubmit}>
              <Container fluid>
                {user.userTypeId === UserDTOUserTypeIdEnum.TENANT ? <TenantForm {...props} /> : <SupplierForm {...props} />}
                <Row style={{ display: 'flex', justifyContent: 'flex-end', columnGap: '8px', paddingTop: '24px' }}>
                  <Button buttonType="secondary" noArrow={true} onClick={() => dispatch(setIsEditModalOpen(false))}>
                    Cancel
                  </Button>
                  <Button disabled={isEditingUser} type="submit">
                    Confirm
                  </Button>
                </Row>
              </Container>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

import Modal from '../Modal';
import { Header3, P1 } from '../Typography';
import React from 'react';
import { FLASH_MESSAGE_TIMEOUT, useFlashMessage } from '../../containers/FlashMessage';
import { WAIVE_CLAIM_DISPUTE_FEE_STORE_KEY } from '../../redux/claim/claim.actions';
import FlashMessage from '../FlashMessage';
import { Container, Row } from 'react-grid-system';
import Button from '../Button';

interface WaiveClaimDisputeFeeModalProps {
  onDismiss: () => void;
  onSubmit: () => void;
  isSubmitting: boolean;
}

const WaiveClaimDisputeFeeModal: React.FC<WaiveClaimDisputeFeeModalProps> = ({ onDismiss, onSubmit, isSubmitting }) => {
  const [flashMessagePayload, dismissFlashMessage] = useFlashMessage([WAIVE_CLAIM_DISPUTE_FEE_STORE_KEY]);
  return (
    <>
      <Modal onDismiss={onDismiss}>
        {flashMessagePayload ? (
          <FlashMessage payload={flashMessagePayload} onDismiss={dismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMEOUT} />
        ) : null}
        <Header3 style={{ marginBottom: 15 }}>Waive Dispute Fee</Header3>
        <P1 style={{ marginBottom: 20 }}>Are you sure you would like to waive the dispute fee for this case?</P1>
        <>
          <Container fluid>
            <Row style={{ display: 'flex', justifyContent: 'flex-end', columnGap: '8px', paddingTop: '24px' }}>
              <Button buttonType="secondary" noArrow={true} onClick={onDismiss}>
                Cancel
              </Button>
              <Button disabled={isSubmitting} onClick={onSubmit}>
                Confirm
              </Button>
            </Row>
          </Container>
        </>
      </Modal>
    </>
  );
};

export default WaiveClaimDisputeFeeModal;

import React from 'react';
import styled from 'styled-components';
import { PolicyDTOStatusEnum } from '@reposit/api-client';

interface PolicyStatusProps {
  status: string;
  label?: string;
}

const Container = styled.div<PolicyStatusProps>`
  background: ${(props) => props.theme.colors.positive};
  border-radius: 24px;
  display: inline-block;
  color: #fff;
  font-family: ${(props) => props.theme.typography.face.secondary};
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 0 auto;
  padding: 4px 20px;

  ${(props) =>
    props.status === PolicyDTOStatusEnum.DRAFT &&
    `
    background: ${props.theme.colors.body2}
  `}

  ${(props) =>
    props.status === PolicyDTOStatusEnum.SUBMITTED &&
    `
    background: ${props.theme.colors.warning}
  `}

  ${(props) =>
    props.status === PolicyDTOStatusEnum.ISSUED &&
    `
    background: ${props.theme.colors.positive}
  `}

  ${(props) =>
    props.status === PolicyDTOStatusEnum.CLOSED &&
    `
    background: ${props.theme.colors.negative}
  `}

  ${(props) =>
    props.status === PolicyDTOStatusEnum.DEACTIVATED &&
    `
    background: ${props.theme.colors.negative}
  `}

${(props) =>
    props.status === PolicyDTOStatusEnum.UNKNOWN &&
    `
    background: ${props.theme.colors.body2}
  `}
`;

const resolveLabel = {
  [PolicyDTOStatusEnum.DRAFT]: 'Draft',
  [PolicyDTOStatusEnum.SUBMITTED]: 'Submitted To Insurer',
  [PolicyDTOStatusEnum.ISSUED]: 'Active',
  [PolicyDTOStatusEnum.CLOSED]: 'Closed',
  [PolicyDTOStatusEnum.DEACTIVATED]: 'Deactivated',
  [PolicyDTOStatusEnum.UNKNOWN]: 'Unknown',
} as any;

const PolicyStatus: React.FC<PolicyStatusProps> = ({ status, label }) => {
  return <Container status={status}>{label || resolveLabel[status]}</Container>;
};

export default PolicyStatus;

import { LandlordDTO } from '@reposit/api-client';
import React, { Fragment } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import styled from 'styled-components';
import { getBreakpoint } from '../../base/style';
import { Input, RadioGroup } from '../../components/FormFields';
import FieldWithLabel from '../../components/FormFields/FieldWithLabel';
import Loading from '../../components/Loading';

const Field = styled.div`
  @media screen and (min-width: ${getBreakpoint('lg')}) {
    max-width: 94%;
  }
`;

const FieldAlt = styled.div`
  @media screen and (min-width: ${getBreakpoint('lg')}) {
    max-width: 97%;
  }
`;

interface LandlordFormFieldsProps {
  handleChange: (e: any) => any;
  handleBlur: (e: any) => any;
  values: any;
  errors: any;
  touched: any;
}

const LandlordFormFields: React.FC<LandlordFormFieldsProps> = ({ handleBlur, handleChange, values, touched, errors }) => {
  const renderLandlordDetails = (landlord: LandlordDTO) => {
    return (
      <Container fluid>
        <Row>
          <Col lg={12} style={{ padding: 0 }}>
            <FieldWithLabel label="Type" touched={false} style={{ marginBottom: 24 }}>
              <RadioGroup
                name="landlord.type"
                options={[
                  {
                    value: 'COMPANY',
                    label: 'Company'
                  },
                  {
                    value: 'SOLE_PERSON',
                    label: 'Individual'
                  }
                ]}
                onChange={handleChange}
                onBlur={handleBlur}
                selected={values.type}
              />
            </FieldWithLabel>
          </Col>
          {values.type === 'SOLE_PERSON' && (
            <Fragment>
              <Col lg={6} style={{ padding: 0 }}>
                <Field>
                  <FieldWithLabel label="First Name" error={errors.firstName} touched={touched.firstName}>
                    <Input
                      name="landlord.firstName"
                      value={values.firstName}
                      error={errors.firstName}
                      touched={touched.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FieldWithLabel>
                </Field>
              </Col>
              <Col lg={6} style={{ padding: 0 }}>
                <Field>
                  <FieldWithLabel label="Last Name" error={errors.firstName} touched={touched.lastName}>
                    <Input
                      name="landlord.lastName"
                      value={values.lastName}
                      error={errors.lastName}
                      touched={touched.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FieldWithLabel>
                </Field>
              </Col>
            </Fragment>
          )}
          {values.type === 'COMPANY' && (
            <Fragment>
              <Col lg={6} style={{ padding: 0 }}>
                <Field>
                  <FieldWithLabel label="Company Name" error={errors.companyName} touched={touched.companyName}>
                    <Input
                      name="landlord.companyName"
                      value={values.companyName}
                      error={errors.companyName}
                      touched={touched.companyName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FieldWithLabel>
                </Field>
              </Col>
            </Fragment>
          )}
        </Row>
        <Row>
          <Col lg={12} style={{ padding: 0 }}>
            <FieldAlt>
              <FieldWithLabel label="Email" error={errors.email} touched={touched.email}>
                <Input
                  name="landlord.email"
                  value={values.email}
                  error={errors.email}
                  touched={touched.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FieldWithLabel>
            </FieldAlt>
          </Col>
        </Row>
      </Container>
    );
  };

  return <Container>{values ? renderLandlordDetails(values) : <Loading />}</Container>;
};

export default LandlordFormFields;

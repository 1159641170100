import { omit } from 'lodash';

import { ClaimItemActionTypes, DISCARD_CLAIM_ITEM_API_SUCCESS } from '../../claim-item/claim-item.actions';

export const claimItemEntityReducer = (state: any = {}, action: ClaimItemActionTypes) => {
  switch (action.type) {
    case DISCARD_CLAIM_ITEM_API_SUCCESS:
      return omit(state, action.payload.claimItemId);
    default:
      return state;
  }
};

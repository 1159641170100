import React from 'react';
import { Container } from 'react-grid-system';
import { ClaimItem } from '../../redux/selectors/mediation.selectors';
import RepositCard from '../Reposit/RepositCard';
import { Header4, P2 } from '../Typography';
import MediationClaimItem from './MediationClaimItem';

interface BaseMediationClaimItemsProps {
  claimItems: ClaimItem[];
}

export const MediationClaimItems: React.FC<BaseMediationClaimItemsProps> = ({ claimItems }) => {
  const settledItems = claimItems.filter((ci) => ci.isSettled);
  const nonSettledItems = claimItems.filter((ci) => !ci.isSettled);
  return (
    <RepositCard flush title={'Claim Items'}>
      <Container>
        <Header4 style={{ padding: '0 20px' }}>Non Settled Items</Header4>
        {nonSettledItems.length ? (
          nonSettledItems.map((claimItem) => {
            return <MediationClaimItem key={claimItem.id} claimItem={claimItem} />;
          })
        ) : (
          <P2 style={{ padding: '0 20px' }}>All items have been settled</P2>
        )}
        <Header4 style={{ padding: '0 20px' }}>Settled Items</Header4>
        {settledItems.length ? (
          settledItems.map((claimItem) => {
            return <MediationClaimItem key={claimItem.id} claimItem={claimItem} />;
          })
        ) : (
          <P2 style={{ padding: '0 20px' }}>No settled claim items</P2>
        )}
      </Container>
    </RepositCard>
  );
};

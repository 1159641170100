import { all } from 'redux-saga/effects';

import * as RepositListSagas from './reposit-list/reposit-list.saga';
import * as AccountSagas from './account/account.saga';
import * as RepositSagas from './reposit/reposit.saga';
import * as PropertySagas from './property/property.saga';
import * as AddressSagas from './address/address.saga';
import * as ClaimSagas from './claim/claim.saga';
import * as ClaimListSagas from './claim-list/claim-list.saga';
import * as ClaimItemSagas from './claim-item/claim-item.saga';
import * as UserSagas from './user/user.saga';
import * as InviteSagas from './invite/invite.saga';
import * as OrganizationUserSagas from './organization-user/organization-user.saga';
import * as ClaimDocumentSagas from './claim-document/claim-document.saga';
import * as DocumentSagas from './document/document.saga';
import * as LandlordSagas from './landlord/landlord.saga';
import * as ReferenceSagas from './referencing/referencing.saga';
import * as UserListSagas from './user-list/user-list.saga';
import * as OrganizationListSagas from './organization-list/organization-list.saga';
import * as OrganizationSagas from './organization/organization.saga';
import * as ReferenceListSagas from './reference-list/reference-list.saga';
import * as IntegratorSagas from './integrator/integrator.saga';
import * as KpiDocumentSagas from './kpi-document/kpi-document.saga';
import * as TenancyAnniversarySagas from './tenancy-anniversary/tenancy-anniversary.saga';
import * as CommissionSagas from './commission/commission.saga';
import * as OrganizationRecipientsSagas from './organization-recipient/organization-recipient.saga';
import * as OrganizationBankAccountSagas from './organization-bank-account/organization-bank-account.saga';
import * as BankAccountSagas from './bank-account/bank-account.saga';
import * as AuthSagas from './auth/auth.saga';
import * as GuarantorSagas from './guarantor/guarantor.saga';
import * as ReconciliationDocumentSagas from './reconciliation-document/reconciliation-document.saga';
import * as InternalReportSagas from './internal-reports/internal-reports.saga';
import * as InternalTeamMemberSagas from './internal-team-member/internal-team-member.saga';
import * as OrganizationInternalOwnerSagas from './organization-internal-owner/organization-internal-owner.saga';

export default function* rootSaga() {
  yield all([
    RepositListSagas.watchRepositListSagas(),
    AccountSagas.watchAccountSagas(),
    RepositSagas.watchRepositSagas(),
    PropertySagas.watchPropertiesSagas(),
    AddressSagas.watchAddressesSagas(),
    ClaimSagas.watchClaimsSagas(),
    ClaimSagas.watchDownloads(),
    ClaimListSagas.watchClaimListSagas(),
    ClaimItemSagas.watchClaimItemsSagas(),
    UserSagas.watchUserSagas(),
    InviteSagas.watchInviteSagas(),
    OrganizationUserSagas.watchOrganizationUserSagas(),
    ClaimDocumentSagas.watchClaimDocumentsSagas(),
    DocumentSagas.watchDocumentsSagas(),
    LandlordSagas.watchLandlordActions(),
    ReferenceSagas.watchReferencingSagas(),
    UserListSagas.watchUserListSagas(),
    OrganizationListSagas.watchOrganizationListSagas(),
    OrganizationSagas.watchOrganizationSagas(),
    ReferenceListSagas.watchReferenceListSagas(),
    IntegratorSagas.watchIntegratorSagas(),
    KpiDocumentSagas.watchKpiDocumentSagas(),
    TenancyAnniversarySagas.watchTenancyAnniversarySagas(),
    CommissionSagas.watchCommissionSagas(),
    OrganizationRecipientsSagas.watchRecipientSagas(),
    OrganizationBankAccountSagas.watchOrganizationUserSagas(),
    BankAccountSagas.watchOrganizationUserSagas(),
    AuthSagas.watchAuthSagas(),
    GuarantorSagas.watchGuarantorSagas(),
    ReconciliationDocumentSagas.watchReconciliationDocumentSagas(),
    InternalReportSagas.watchInternalReportsSagas(),
    InternalTeamMemberSagas.watchIntegratorSagas(),
    OrganizationInternalOwnerSagas.watchSagas(),
  ]);
}

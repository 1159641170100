import { ClaimState, CurrentClaimZipDownloadStatus } from './claim.types';
import {
  ClaimActionTypes,
  FETCH_CLAIM_API_SUCCESS,
  FETCH_CLAIM_API_FAILED,
  CLEAR_CLAIM_HAS_FETCHED,
  SET_CURRENT_CLAIM_ACTION_MODAL,
  SET_CURRENT_CLAIM_ZIP_DOWNLOAD_TOTAL,
  FINISH_CURRENT_CLAIM_ZIP_DOWNLOAD,
  START_CURRENT_CLAIM_ZIP_DOWNLOAD,
  INCREMENT_CURRENT_CLAIM_ZIP_DOWNLOAD_CURRENT,
  SET_CURRENT_CLAIM_ZIP_DOWNLOAD_ZIP_PROGRESS,
} from './claim.actions';

const initialState: ClaimState = {
  hasClaimBeenFetched: false,
  isAddClaimItemFormOpen: false,
  isEditClaimItemFormOpen: false,
  currentClaimActionModal: '',
};

const emptyClaimDownload: CurrentClaimZipDownloadStatus = {
  total: 0,
  current: 0,
  zipProgress: 0,
};

export default (state = initialState, action: ClaimActionTypes): ClaimState => {
  switch (action.type) {
    // requested and failed are handled in loading and error reducers
    case FETCH_CLAIM_API_SUCCESS: {
      return {
        ...state,
        hasClaimBeenFetched: true,
      };
    }
    case FETCH_CLAIM_API_FAILED: {
      return {
        ...state,
        hasClaimBeenFetched: true,
      };
    }
    case CLEAR_CLAIM_HAS_FETCHED: {
      return {
        ...state,
        hasClaimBeenFetched: false,
      };
    }
    case SET_CURRENT_CLAIM_ACTION_MODAL: {
      return {
        ...state,
        currentClaimActionModal: action.payload,
      };
    }
    case SET_CURRENT_CLAIM_ZIP_DOWNLOAD_TOTAL: {
      const claimsDownloadStatus = state.claimsDownloadStatus || {};
      // may be empty
      const currentClaimDownload = claimsDownloadStatus[action.payload.claimId] || emptyClaimDownload;
      return {
        ...state,
        claimsDownloadStatus: {
          ...claimsDownloadStatus,
          [action.payload.claimId]: {
            ...currentClaimDownload,
            total: action.payload.total,
          },
        },
      };
    }
    case SET_CURRENT_CLAIM_ZIP_DOWNLOAD_ZIP_PROGRESS: {
      const claimsDownloadStatus = state.claimsDownloadStatus || {};
      const currentClaimDownload = claimsDownloadStatus[action.payload.claimId];
      return {
        ...state,
        claimsDownloadStatus: {
          ...claimsDownloadStatus,
          [action.payload.claimId]: {
            ...currentClaimDownload,
            zipProgress: action.payload.progress,
          },
        },
      };
    }
    case INCREMENT_CURRENT_CLAIM_ZIP_DOWNLOAD_CURRENT: {
      const claimsDownloadStatus = state.claimsDownloadStatus || {};
      const currentClaimDownload = claimsDownloadStatus[action.payload];

      return {
        ...state,
        claimsDownloadStatus: {
          ...claimsDownloadStatus,
          [action.payload]: {
            ...currentClaimDownload,
            current: currentClaimDownload.current + 1,
          },
        },
      };
    }

    case FINISH_CURRENT_CLAIM_ZIP_DOWNLOAD: {
      const claimsDownloadStatus = state.claimsDownloadStatus || {};
      const currentClaimDownload = claimsDownloadStatus[action.payload];

      return {
        ...state,
        claimsDownloadStatus: {
          ...claimsDownloadStatus,
          [action.payload]: { ...currentClaimDownload, zipProgress: 100 },
        },
      };
    }
    case START_CURRENT_CLAIM_ZIP_DOWNLOAD: {
      const claimsDownloadStatus = state.claimsDownloadStatus || {};

      return {
        ...state,
        claimsDownloadStatus: {
          ...claimsDownloadStatus,
          [action.payload]: emptyClaimDownload,
        },
      };
    }

    default:
      return state;
  }
};

import React from 'react';
import Card from '../Card';
import { Header3, P2 } from '../Typography';
import styled from 'styled-components';
import Button from '../Button';
import { getBreakpoint } from '../../base/style';
import { NavLink } from 'react-router-dom';
import { ReferenceDTO } from '@reposit/api-client';
import CardMessage from '../CardMessage';

interface ReferenceCardProps {
  reference: ReferenceDTO;
}

const Inner = styled.div`
  min-height: 80px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    display: flex;
    justify-content: flex-end;
  }
`;

const Meta = styled.div`
  margin: 0 0 24px;
  @media screen and (min-width: ${getBreakpoint('lg')}) {
    margin: 0 auto 0 0;
  }
`;

const Name = styled(Header3)`
  margin: 6px 0 0;
  padding: 0;
`;

const Email = styled(P2)`
  margin: 0;
  padding: 0;
`;

const Action = styled.div`
  margin: 24px 0 16px;

  a {
    text-decoration: none;
  }

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    margin: 0;
    padding: 0 0 0 24px;
  }
`;

const ReferenceCard: React.FC<ReferenceCardProps> = ({ reference }) => {
  const [name, email] = getDisplayInfo(reference);

  return (
    <Card
      hover
      style={{ padding: '5px 5px 10px 40px' }}
      flashMessage={
        reference.status === 'IN_REVIEW' ? (
          <CardMessage
            title="This reference needs to be reviewed"
            subtitle="Please review as soon as possible"
            type="secondary"
          />
        ) : (
          undefined
        )
      }
    >
      <Inner>
        <Meta>
          <Name>{name}</Name>
          <Email>{email}</Email>
        </Meta>
        <Action>
          <NavLink to={`/references/${reference.id}`}>
            <Button buttonType="secondary" mini="true">
              View
            </Button>
          </NavLink>
        </Action>
      </Inner>
    </Card>
  );
};

const getDisplayInfo = (reference: ReferenceDTO) => {
  const attributes = reference.applicant.attributes as any;

  if (attributes.firstName) {
    return [`${attributes.firstName} ${attributes.lastName}`, attributes.email];
  } else if (attributes.user && attributes.user.firstName) {
    return [`${attributes.user.firstName} ${attributes.user.lastName}`, attributes.user.email];
  }

  return [reference.id, ''];
};

export default ReferenceCard;

import {
  CustomerDTO,
  OrderCustomerDTO,
  OrderWithOrderCustomersDTO,
  OrderWithOrderCustomersDTOStatusIdEnum,
  PaymentPlanDTO,
  OrderManualPaymentDTO,
} from '@reposit/api-client';
import { get } from 'lodash';
import { useMemo } from 'react';
import { AppState } from '../root.reducer';
import { getFullClaimById } from '../selectors/claim.selectors';
import { getFirstAgentProposal, getSecondAgentProposal } from '../selectors/mediation.selectors';

export const getPendingPaymentPlanOrder =
  (claimId: string) =>
  (state: AppState): OrderWithOrderCustomersDTO | undefined => {
    // if arb exists choose that
    // otherwise its the latest pending claim proposal claim proposal
    let order;

    const claim = useMemo(() => getFullClaimById(state, claimId), [state]);
    if (!claim) {
      return undefined;
    }
    if (claim.arbitration && claim.arbitration.repaymentOrder) {
      return claim.arbitration.repaymentOrder;
    }

    const firstAgentProposal = getFirstAgentProposal(state, claimId);
    const firstOrderIsPending =
      firstAgentProposal && firstAgentProposal.order
        ? firstAgentProposal.order.statusId === OrderWithOrderCustomersDTOStatusIdEnum.PENDING
        : false;
    const secondAgentProposal = getSecondAgentProposal(state, claimId);
    const secondOrderIsPending =
      secondAgentProposal && secondAgentProposal.order
        ? secondAgentProposal.order.statusId === OrderWithOrderCustomersDTOStatusIdEnum.PENDING
        : false;

    if (!firstOrderIsPending && !secondOrderIsPending) return undefined;

    if (secondAgentProposal && secondOrderIsPending) {
      order = secondAgentProposal.order;
    }
    if (firstAgentProposal && firstOrderIsPending) {
      order = firstAgentProposal.order;
    }

    return order;
  };

const getOrderCustomerWithPaymentPlanByClaimId =
  (claimId: string) =>
  (state: AppState): OrderCustomerDTO | undefined => {
    const claim = getFullClaimById(state, claimId);
    if (!claim) {
      return undefined;
    }
    const arbOrderCustomers: OrderCustomerDTO[] = get(claim, 'arbitration.repaymentOrder.orderCustomers', []);
    if (arbOrderCustomers.length) {
      const ocWithPaymentPlan = arbOrderCustomers.find((oc) => oc.paymentPlan);
      return ocWithPaymentPlan;
    } else {
      // latest proposal
      const firstAgentProposal = claim.proposals.find((p) => p.round === 1);
      const firstOrderCustomerWithPaymentPlan: OrderCustomerDTO = get(firstAgentProposal, 'order.orderCustomers', []).find(
        (oc: OrderCustomerDTO) => oc.paymentPlan
      );

      const secondAgentProposal = claim.proposals.find((p) => p.round === 3);
      const secondOrderCustomerWithPaymentPlan: OrderCustomerDTO = get(secondAgentProposal, 'order.orderCustomers', []).find(
        (oc: OrderCustomerDTO) => oc.paymentPlan
      );

      return firstOrderCustomerWithPaymentPlan || secondOrderCustomerWithPaymentPlan;
    }
  };

export const getPaymentPlanByClaimId =
  (claimId: string) =>
  (state: AppState): PaymentPlanDTO | undefined => {
    const orderCustomerWithPaymentPlan = getOrderCustomerWithPaymentPlanByClaimId(claimId)(state);
    if (!orderCustomerWithPaymentPlan) return undefined;
    return get(orderCustomerWithPaymentPlan, 'paymentPlan');
  };

export const getPaymentPlanCustomerByClaimId =
  (claimId: string) =>
  (state: AppState): CustomerDTO | undefined => {
    const orderCustomerWithPaymentPlan = getOrderCustomerWithPaymentPlanByClaimId(claimId)(state);
    if (!orderCustomerWithPaymentPlan) return undefined;
    return get(orderCustomerWithPaymentPlan, 'customer');
  };

export const getManualPaymentsByClaimId =
  (claimId: string) =>
  (state: AppState): OrderManualPaymentDTO[] => {
    const claim = useMemo(() => getFullClaimById(state, claimId), [state]);
    const arbOrderManualPayments = get(claim, 'arbitration.repaymentOrder.manualPayments');

    const firstAgentProposal = getFirstAgentProposal(state, claimId);
    const firstOrderManualPayments = get(firstAgentProposal, 'order.manualPayments');
    const secondAgentProposal = getSecondAgentProposal(state, claimId);
    const secondOrderManualPayments = get(secondAgentProposal, 'order.manualPayments');

    return arbOrderManualPayments || firstOrderManualPayments || secondOrderManualPayments || [];
  };

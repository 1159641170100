import { OrganizationRecipientDTOGroupEnum } from '@reposit/api-client';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import RepositCard from '../../components/Reposit/RepositCard';
import { getOrganizationRecipientsByGroup } from '../../redux/selectors/organization-recipient.selectors';
import {
  Email,
  OrganizationRecipientItem,
  OrganizationRecipientItemWrapper,
  OrganizationRecipientListItem,
  OrganizationRecipientsListWrapper,
} from './styles';

interface OrganizationOrganizationRecipientsProps {
  group: OrganizationRecipientDTOGroupEnum;
}

const OrganizationRecipientGroup: React.FC<OrganizationOrganizationRecipientsProps> = ({ group }) => {
  const orgRecipients = useSelector(getOrganizationRecipientsByGroup(group));
  const groupName = group === OrganizationRecipientDTOGroupEnum.INITIAL ? `Pre-tenancy` : `Tenancy Management`;

  return (
    <Container>
      <Row>
        <Col sm={12}>
          <RepositCard
            title={orgRecipients.length ? `${groupName} recipients` : `No ${groupName} recipients available`}
            tooltip=""
            flush
          >
            <OrganizationRecipientsListWrapper>
              {orgRecipients.map((or) => (
                <OrganizationRecipientListItem key={or.id}>
                  <OrganizationRecipientItemWrapper>
                    <OrganizationRecipientItem>
                      <Email>{or.email}</Email>
                    </OrganizationRecipientItem>
                  </OrganizationRecipientItemWrapper>
                </OrganizationRecipientListItem>
              ))}
            </OrganizationRecipientsListWrapper>
          </RepositCard>
        </Col>
      </Row>
    </Container>
  );
};

export default OrganizationRecipientGroup;

import { ClaimWithCheckoutRelationsDTO } from '@reposit/api-client';
import moment from 'moment';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { truncateFilename } from '../../utils/common.utils';
import SecondaryPanel from '../SecondaryPanel';
import { Table, Tbody, Td, Thead, Tr } from '../Table';
import { A, Header4, P1, P2 } from '../Typography';

export enum ClaimResponseStatus {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  AWAITING_RESPONSE = 'AWAITING_RESPONSE',
}

interface ClaimResponseProps {
  claim: ClaimWithCheckoutRelationsDTO;
}

/* User	Accepted	Description	Responded At	Amount Paid	Paid At  */

const ResponseTitle = styled(Header4)`
  margin: 0 0 6px;
`;

const EvidenceTitle = styled(Header4)`
  margin: 24px 0 12px;
`;

const DisputeInformation: React.FC<ClaimResponseProps> = ({ claim }) => {
  const tenantDisputeMessage = claim.tenantDisputeMessage;

  return (
    <SecondaryPanel>
      {tenantDisputeMessage ? (
        <Fragment>
          <ResponseTitle>Dispute Message:</ResponseTitle>
          <P1>{tenantDisputeMessage}</P1>
        </Fragment>
      ) : (
        <P2>No dispute message provided</P2>
      )}
      <EvidenceTitle>Evidence:</EvidenceTitle>
      {claim.responseDocuments && claim.responseDocuments.length > 0 ? (
        claim.responseDocuments.map(({ document }) => (
          <Table key={document.id}>
            <Thead>
              <Tr>
                <Td>Id</Td>
                <Td>Name</Td>
                <Td>Type</Td>
                <Td>Uploaded At</Td>
                <Td>Action</Td>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>{document.id}</Td>
                <Td>{truncateFilename(document.name)}</Td>
                <Td>{document.typeId}</Td>
                <Td>{moment(document.createdAt).format('DD/MM/YYYY HH:mm:ss')}</Td>
                <Td>
                  <A target="_blank" href={document.url}>
                    View
                  </A>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        ))
      ) : (
        <Fragment>
          <P2>No evidence provided</P2>
        </Fragment>
      )}
    </SecondaryPanel>
  );
};

export default DisputeInformation;

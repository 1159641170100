import { startCase } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import Card from '../../Card/index';
import { Header3 } from '../../Typography/index';

const ClaimCard = styled(Card)`
  padding: 18px 5px 2px;
  a {
    text-decoration: none;
  }
`;

interface NoClaimsListProps {
  currentFilter: string;
}

const NoClaimsList: React.FC<NoClaimsListProps> = ({ currentFilter }) => {
  let content = <Header3>This organization doesn't currently have any claims.</Header3>;

  if (currentFilter) {
    content = (
      <Header3>
        There are currently no claims found with your chosen filter:{' '}
        <span style={{ fontStyle: 'italic' }}>{startCase(currentFilter.toLowerCase())}</span>
      </Header3>
    );
  }

  return <ClaimCard>{content}</ClaimCard>;
};

export default NoClaimsList;

import { take, fork, race, put, call, takeLatest } from 'redux-saga/effects';
import {
  POLL_FOR_REFERENCE_REQUESTED,
  POLL_FOR_REFERENCE_SUCCESS,
  pollForReferenceRequested,
  pollForReferenceSuccess,
  ReferenceActionTypes,
  pollForReferenceFailed,
  CREATE_REFERENCE_API_REQUESTED,
  createReferenceSuccess,
  createReferenceFailed,
  getReferenceChecksSuccess,
  getReferenceChecksFailed,
  GET_REFERENCE_CHECKS_API_REQUESTED,
  uploadReferenceDocsSuccess,
  uploadReferenceDocsFailed,
  UPLOAD_REFERENCE_DOCS_API_REQUESTED,
  removeReferenceDocSuccess,
  removeReferenceDocFailed,
  REMOVE_REFERENCE_DOC_API_REQUESTED,
  SET_REFERENCE_REVIEW_API_REQUESTED,
  setReferenceReviewSuccess,
  setReferenceReviewFailed,
  SET_REFERENCE_REVIEW_STORE_KEY,
  UPLOAD_REFERENCE_DOCS_STORE_KEY,
  REMOVE_REFERENCE_DOC_STORE_KEY,
  overrideReferenceCheckSuccess,
  overrideReferenceCheckFailed,
  OVERRIDE_REFERENCE_CHECK_API_REQUESTED,
  OVERRIDE_REFERENCE_CHECK_STORE_KEY,
  getReferenceChecksRequested,
} from './referencing.actions';
import { get } from 'lodash';
import {
  createReferencesApi,
  runSagaWithAuth,
  createOrderCustomerActionApi,
  createReferenceDocumentsApi,
  createChecksApi,
} from '../utils/api.utils';
import {
  PollReferenceRequestedPayload,
  CreateReferenceRequestedPayload,
  GetReferenceChecksRequestedPayload,
  UploadReferenceDocsRequestedPayload,
  RemoveReferenceDocsRequestedPayload,
  OverrideCheckRequestedPayload,
} from './referencing.types';
import { ReferencesApi, OrderCustomerActionsApi, ReferenceDocumentsApi, ChecksApi } from '@reposit/api-client';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import SCHEMA from '../schema';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import { FlashState } from '../../components/FlashMessage/index';

// ****************
// WORKERS
// ****************

export function* pollReference({ payload }: { type: string; payload: PollReferenceRequestedPayload }) {
  try {
    const referencesApi: ReferencesApi = yield createReferencesApi();
    const { data } = yield call(runSagaWithAuth(() => referencesApi.findReferenceById(payload.referenceId)));

    const id: string = yield syncEntitiesAndGetResults({ ...data }, SCHEMA.reference);
    yield put<ReferenceActionTypes>(pollForReferenceSuccess(id, data.documents));
  } catch (e) {
    yield put<ReferenceActionTypes>(pollForReferenceFailed(get(e, 'response.data.message', e)));
  }
}

export function* createReference({ payload }: { type: string; payload: CreateReferenceRequestedPayload }) {
  try {
    const orderCustomerActionsApi: OrderCustomerActionsApi = yield createOrderCustomerActionApi();
    const { data } = yield call(runSagaWithAuth(() => orderCustomerActionsApi.reference(payload.orderId, payload.customerId)));
    yield put<ReferenceActionTypes>(createReferenceSuccess(data.id));
    yield put<ReferenceActionTypes>(pollForReferenceRequested(data.id));
  } catch (e) {
    const error = get(e, 'response.data.message', e);
    yield put<ReferenceActionTypes>(createReferenceFailed(error));
  }
}

export function* overrideCheck({ payload }: { type: string; payload: OverrideCheckRequestedPayload }) {
  try {
    const checksApi: ChecksApi = yield createChecksApi();
    const { data } = yield call(runSagaWithAuth(() => checksApi.override(payload.checkId)));
    yield put<ReferenceActionTypes>(overrideReferenceCheckSuccess(data.id));
    yield put<ReferenceActionTypes>(pollForReferenceRequested(data.referenceId));
    yield put<ReferenceActionTypes>(getReferenceChecksRequested(data.referenceId));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: OVERRIDE_REFERENCE_CHECK_STORE_KEY,
        message: 'Success!',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = get(e, 'response.data.message', e);
    yield put<ReferenceActionTypes>(overrideReferenceCheckFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: OVERRIDE_REFERENCE_CHECK_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* getReferenceChecks({ payload }: { type: string; payload: GetReferenceChecksRequestedPayload }) {
  try {
    const referencesApi: ReferencesApi = yield createReferencesApi();
    const { data } = yield call(runSagaWithAuth(() => referencesApi.getChecks(payload.referenceId)));
    const ids: string[] = yield syncEntitiesAndGetResults({ ...data }, SCHEMA.checks);
    yield put<ReferenceActionTypes>(getReferenceChecksSuccess(ids));
  } catch (e) {
    const error = get(e, 'response.data.message', e);
    yield put<ReferenceActionTypes>(getReferenceChecksFailed(error));
  }
}

export function* uploadReferenceDocs({ payload }: { type: string; payload: UploadReferenceDocsRequestedPayload }) {
  try {
    const referenceDocumentsApi: ReferenceDocumentsApi = yield createReferenceDocumentsApi();
    const { data } = yield call(
      runSagaWithAuth(() =>
        referenceDocumentsApi.addReferenceDocument(payload.referenceId, payload.file, payload.file.name, payload.type)
      )
    );
    yield put<ReferenceActionTypes>(uploadReferenceDocsSuccess(data));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPLOAD_REFERENCE_DOCS_STORE_KEY,
        message: `Success! Item successfully uploaded`,
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = get(e, 'response.data.message', e);
    yield put<ReferenceActionTypes>(uploadReferenceDocsFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPLOAD_REFERENCE_DOCS_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* removeReferenceDocs({ payload }: { type: string; payload: RemoveReferenceDocsRequestedPayload }) {
  try {
    const referenceDocumentsApi: ReferenceDocumentsApi = yield createReferenceDocumentsApi();
    yield call(
      runSagaWithAuth(() => referenceDocumentsApi.deleteReferenceDocument(payload.referenceId, payload.referenceDocumentId))
    );

    yield put<ReferenceActionTypes>(removeReferenceDocSuccess());
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: REMOVE_REFERENCE_DOC_STORE_KEY,
        message: `Success! Item removed`,
        state: FlashState.SUCCESS,
      })
    );
    yield put<ReferenceActionTypes>(pollForReferenceRequested(payload.referenceId));
  } catch (e) {
    const error = get(e, 'response.data.message', e);
    yield put<ReferenceActionTypes>(removeReferenceDocFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: REMOVE_REFERENCE_DOC_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* setReferenceReview({ payload }: { type: string; payload: GetReferenceChecksRequestedPayload }) {
  try {
    const referencesApi: ReferencesApi = yield createReferencesApi();
    yield call(runSagaWithAuth(() => referencesApi.readyForReview(payload.referenceId)));
    yield put<ReferenceActionTypes>(setReferenceReviewSuccess());
    yield put<ReferenceActionTypes>(pollForReferenceRequested(payload.referenceId));
  } catch (e) {
    const error = get(e, 'response.data.message', e);
    yield put<ReferenceActionTypes>(setReferenceReviewFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: SET_REFERENCE_REVIEW_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

// ****************
// WATCHERS
// ****************
export function* watchReferencingSagas() {
  yield takeLatest(CREATE_REFERENCE_API_REQUESTED, createReference);
  yield takeLatest(GET_REFERENCE_CHECKS_API_REQUESTED, getReferenceChecks);
  yield takeLatest(UPLOAD_REFERENCE_DOCS_API_REQUESTED, uploadReferenceDocs);
  yield takeLatest(REMOVE_REFERENCE_DOC_API_REQUESTED, removeReferenceDocs);
  yield takeLatest(SET_REFERENCE_REVIEW_API_REQUESTED, setReferenceReview);
  yield takeLatest(OVERRIDE_REFERENCE_CHECK_API_REQUESTED, overrideCheck);

  while (true) {
    const action = yield take(POLL_FOR_REFERENCE_REQUESTED);
    yield race([fork(pollReference, action), take(POLL_FOR_REFERENCE_SUCCESS)]);
  }
}

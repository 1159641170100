import { OrganizationListState } from './organization-list.types';
import { OrganizationFilter, OrganizationSort } from '../../constants/organization';
import {
  OrganizationListActionTypes,
  FETCH_ORGANIZATIONS_API_SUCCESS,
  ORGANIZATIONS_GOTO_PAGE,
  ORGANIZATIONS_UPDATE_FILTERS
} from './organization-list.actions';
import { ORGANIZATION_LIST_PAGE_SIZE } from './organization-list.saga';

const initialState: OrganizationListState = {
  results: [],
  count: 0,
  page: 1,
  filters: {
    query: '',
    filter: OrganizationFilter.ALL,
    sort: OrganizationSort.CREATED_AT,
    tenancyStatus: ''
  },
  lastPage: undefined
};

export default (state = initialState, action: OrganizationListActionTypes): OrganizationListState => {
  switch (action.type) {
    case FETCH_ORGANIZATIONS_API_SUCCESS:
      const last = Math.ceil(action.payload.count / ORGANIZATION_LIST_PAGE_SIZE);

      return {
        ...state,
        count: action.payload.count,
        lastPage: last,
        results: [...action.payload.ids]
      };

    case ORGANIZATIONS_GOTO_PAGE:
      const { count, page } = state;
      const lastPage = Math.ceil(count / ORGANIZATION_LIST_PAGE_SIZE);
      let p = action.payload;

      if (p === 'prev') p = page - 1;
      if (p === 'next') p = page + 1;
      if (p >= lastPage) p = lastPage;
      if (p <= 0) p = 1;

      return {
        ...state,
        page: p
      };

    case ORGANIZATIONS_UPDATE_FILTERS:
      return {
        ...state,
        page: 1,
        filters: {
          ...state.filters,
          ...action.payload
        }
      };

    default:
      return state;
  }
};

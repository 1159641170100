import React from 'react';
import moment from 'moment';

import RepositCard from '../Reposit/RepositCard';
import { Table, Thead, Tbody, Td, Tr } from '../Table';
import { A } from '../Typography';
import { ClaimDocumentDTO } from '@reposit/api-client';
import { truncateFilename } from '../../utils/common.utils';

interface ClaimDocumentsProps {
  documents: ClaimDocumentDTO[];
}

const ClaimDocuments: React.FC<ClaimDocumentsProps> = ({ documents }) => {
  return (
    <RepositCard flush title="Supporting Documents" tooltip="lorem">
      <Table>
        <Thead>
          <Tr>
            <Td>Id</Td>
            <Td>Name</Td>
            <Td>Type</Td>
            <Td>Uploaded At</Td>
            <Td>Action</Td>
          </Tr>
        </Thead>
        <Tbody>
          {documents &&
            documents.map(({ document }) => (
              <Tr key={document.id}>
                <Td>{document.id}</Td>
                <Td>{truncateFilename(document.name)}</Td>
                <Td>{document.typeId}</Td>
                <Td>{moment(document.createdAt).format('DD/MM/YYYY HH:mm:ss')}</Td>
                <Td>
                  <A target="_blank" href={document.url}>
                    View
                  </A>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </RepositCard>
  );
};

export default ClaimDocuments;

import React, { useCallback, useEffect, Fragment } from 'react';
import { Container, Col, Row } from 'react-grid-system';
import { Header2, P1, Header3 } from '../../components/Typography';
import Filter from '../../components/Filter';
import Sort from '../../components/Sort';
import { ContentContainer } from '../../components/Common';
import ReferenceCard from '../../components/ReferenceCard';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchReferencesRequested,
  updateFilters as updateFiltersActionCreator,
  FETCH_REFERENCES_STORE_KEY,
  Page,
  goToPage
} from '../../redux/reference-list/reference-list.actions';
import {
  getReferenceListFilters,
  getReferenceListPagination,
  getReferenceList
} from '../../redux/reference-list/reference-list.selectors';
import { ReferenceListFilters } from '../../redux/reference-list/reference-list.types';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import { createErrorMessageSelector } from '../../redux/error/error.selector';
import Error from '../../components/Error';
import Loading from '../../components/Loading';
import Pagination from '../../components/Pagination';
import { ReferencesFilter } from '../../constants/references';
import Card from '../../components/Card';

interface ReferencesProps {}

export interface ReferenceFilterOption {
  value: ReferencesFilter;
  label: string;
}

const filters: ReferenceFilterOption[] = [
  {
    value: ReferencesFilter.IN_REVIEW,
    label: 'Needs review'
  },
  {
    value: ReferencesFilter.INFO_REQUIRED,
    label: 'Info Required'
  },
  {
    value: ReferencesFilter.GUARANTOR_REQUIRED,
    label: 'Guarantor Required'
  }
];

const References: React.FC<ReferencesProps> = () => {
  const dispatch = useDispatch();
  const fetchReferences = useCallback(() => dispatch(fetchReferencesRequested()), [dispatch]);
  const referenceListLoadingSelector = createLoadingSelector([FETCH_REFERENCES_STORE_KEY]);
  const getReferenceListError = createErrorMessageSelector([FETCH_REFERENCES_STORE_KEY]);
  const referencesGotoPage = useCallback((page: Page) => dispatch(goToPage(page)), [dispatch]);
  const isReferenceListFetching = useSelector(referenceListLoadingSelector);
  const error = useSelector(getReferenceListError);

  const { sort, filter } = useSelector(getReferenceListFilters);
  const { page, count } = useSelector(getReferenceListPagination);
  const updateFilters = useCallback((filters: Partial<ReferenceListFilters>) => dispatch(updateFiltersActionCreator(filters)), [
    dispatch
  ]);

  const references = useSelector(getReferenceList);

  useEffect(() => {
    fetchReferences();
  }, [fetchReferences, sort, filter, page]);

  const renderReferences = () => {
    if (error) return <Error error={error} />;
    if (isReferenceListFetching) return <Loading />;
    if (!isReferenceListFetching && !references.length)
      return (
        <Card>
          <Header3>No reference found.</Header3>
          <P1>Please try another query or reset the filters and try again.</P1>
        </Card>
      );

    return (
      <Fragment>
        {references.map((reference: any) => (
          <ReferenceCard key={reference.id} reference={reference} />
        ))}
        <Pagination count={count} currentPage={page} onNavigate={referencesGotoPage} />
      </Fragment>
    );
  };

  return (
    <Container>
      <Row>
        <Col sm={12}>
          <Header2>References</Header2>
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <Filter
            label="Filter by"
            onSelect={filter => {
              updateFilters({ filter });
            }}
            options={filters}
            selected={filter}
          />
        </Col>
        <Col sm={4}>
          <Sort label="Sort by" onSelect={sort => updateFilters({ sort })} options={['Created at']} selected={'0'} />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <ContentContainer style={{ marginTop: 48 }}>{renderReferences()}</ContentContainer>
        </Col>
      </Row>
    </Container>
  );
};

export default References;

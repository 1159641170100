import React, { Fragment, useEffect, useCallback } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Header2, Caption, P1, StyledLink, P2, Header3 } from '../../../components/Typography';
import RepositCard from '../../../components/Reposit/RepositCard';
import Button from '../../../components/Button';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../components/Modal';
import { useParams } from 'react-router-dom';
import {
  fetchTenancyOrderRequested,
  FETCH_TENANCY_ORDER_STORE_KEY,
  setIsTransferModalOpen,
  transferRepositRequested,
  TRANSFER_REPOSIT_STORE_KEY,
} from '../../../redux/reposit/reposit.actions';
import { AppState } from '../../../redux/root.reducer';
import { getTenancyOrder } from '../../../redux/selectors';
import { FullPageLoader } from '../../../components/Loading';
import { getDisplayAddress } from '../../../utils/common.utils';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import {
  fetchOrganizationsRequested,
  FETCH_ORGANIZATIONS_STORE_KEY,
  updateFilters as updateFiltersActionCreator,
} from '../../../redux/organization-list/organization-list.actions';
import { getOrganizationList } from '../../../redux/organization-list/organization-list.selectors';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import FieldWithLabel from '../../../components/FormFields/FieldWithLabel';
import { getIsTransferModalOpen } from '../../../redux/reposit/reposit.selectors';
import { useFlashMessage, FLASH_MESSAGE_TIMEOUT } from '../../FlashMessage';
import FlashMessage from '../../../components/FlashMessage';
import { OrganizationSort } from '../../../constants/organization';
import SearchableTextInput from '../../../components/SearchableTextInput';

interface RepositTransferView {
  id: string;
}

const Action = styled.div`
  text-align: right;
  margin: 24px 0 0;

  button {
    margin: 0 12px 0 0;
  }
`;

interface TransferRepositForm {
  organization: string;
}

const RepositTransferView: React.FC<RepositTransferView> = (props) => {
  const dispatch = useDispatch();

  // TODO: This is temporary to set the page size to 9999 until we handle transferring a reposit properly.
  const fetchOrganizations = useCallback(
    () => dispatch(fetchOrganizationsRequested(9999, OrganizationSort.ALPHABETICAL)),
    [dispatch]
  );
  const { id } = useParams<RepositTransferView>();
  const tenancyOrder = useSelector((state: AppState) => getTenancyOrder(state, { tenancyOrderId: id }));
  const loadingSelector = createLoadingSelector([FETCH_TENANCY_ORDER_STORE_KEY, FETCH_ORGANIZATIONS_STORE_KEY]);
  const isLoading = useSelector(loadingSelector);
  const organizations = useSelector(getOrganizationList);
  const isModalShowing = useSelector(getIsTransferModalOpen);
  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage([TRANSFER_REPOSIT_STORE_KEY]);

  const handleSearch = (query: string) => {
    if (query) {
      dispatch(updateFiltersActionCreator({ query }));
      fetchOrganizations();
    }
  };

  const onSubmit = (values: TransferRepositForm) => {
    dispatch(
      transferRepositRequested({
        organizationId: values.organization,
        tenancyOrderId: id,
      })
    );
  };

  useEffect(() => {
    dispatch(fetchTenancyOrderRequested(id));
  }, [dispatch, id]);

  if (!tenancyOrder) return <FullPageLoader />;

  const VALIDATION_SCHEMA = yup.object().shape({
    organization: yup.string().required('Required'),
  });

  return (
    <Fragment>
      <Formik
        initialValues={{
          organization: '',
        }}
        onSubmit={onSubmit}
        validationSchema={VALIDATION_SCHEMA}
      >
        {({ values, handleSubmit, isValid, setValues }: FormikProps<TransferRepositForm>) => (
          <form onSubmit={() => console.log('show modal')}>
            <Container>
              <Row>
                <Col lg={12}>
                  <Header2>Transfer Reposit</Header2>
                  <Caption>{getDisplayAddress(tenancyOrder.tenancy.property.address)}</Caption>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  {flashMessagePayload ? (
                    <FlashMessage
                      onDismiss={onDismissFlashMessage}
                      timeRemaining={FLASH_MESSAGE_TIMEOUT}
                      payload={flashMessagePayload}
                    />
                  ) : undefined}
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <RepositCard title="Select an Organization">
                    <Fragment>
                      <FieldWithLabel label="Please select an organization to transfer the Reposit to.">
                        <SearchableTextInput
                          name="organisation"
                          items={organizations}
                          keyProperty="id"
                          valueProperty="name"
                          loading={isLoading}
                          onChange={(term) => handleSearch(term)}
                          onItemSelected={(organization) => setValues({ ...values, organization: organization.id })}
                        />
                      </FieldWithLabel>
                      <P2 style={{ marginTop: 12 }}>
                        Don't see the organization? <StyledLink to={`/organisations/new`}>Create a new one.</StyledLink>
                      </P2>
                      <Action>
                        <Button
                          type="button"
                          disabled={!isValid || !values.organization}
                          onClick={() => dispatch(setIsTransferModalOpen(true))}
                        >
                          Transfer
                        </Button>
                      </Action>
                    </Fragment>
                  </RepositCard>
                </Col>
              </Row>
            </Container>
            {isModalShowing ? (
              <Modal onDismiss={() => dispatch(setIsTransferModalOpen(false))}>
                <Fragment>
                  <Header3 style={{ marginBottom: 6 }}>Are you sure you wish to transfer this Reposit?</Header3>
                  <P1>This cannot be undone.</P1>
                  <Action>
                    <Button buttonType="secondary" noArrow={true} onClick={() => dispatch(setIsTransferModalOpen(false))}>
                      Cancel
                    </Button>
                    <Button type="submit" onClick={() => handleSubmit()}>
                      Transfer
                    </Button>
                  </Action>
                </Fragment>
              </Modal>
            ) : undefined}
          </form>
        )}
      </Formik>
    </Fragment>
  );
};

export default RepositTransferView;

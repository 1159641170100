import { BankAccountsApi } from '@reposit/api-client';
import { get } from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { createBankAccountsApi, runSagaWithAuth } from '../utils/api.utils';
import { fetchBankAccountsFailed, fetchBankAccountsRequested, fetchBankAccountsSuccess } from './bank-account.actions';
import { AxiosResponse } from 'axios';

// ****************
// WORKERS
// ****************

export function* getBankAccounts() {
  try {
    const bankAccountsApi: BankAccountsApi = yield createBankAccountsApi();

    const result: AxiosResponse<any> = yield call(runSagaWithAuth(() => bankAccountsApi.getActive()));

    const fileBlob = new Blob([result.data], { type: 'text/csv' });
    const url = URL.createObjectURL(fileBlob);
    window.open(url);
    URL.revokeObjectURL(url);
    yield put(fetchBankAccountsSuccess());
  } catch (e) {
    yield put(fetchBankAccountsFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchOrganizationUserSagas() {
  yield takeLatest(fetchBankAccountsRequested.type, getBankAccounts);
}

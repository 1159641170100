import { SET_LOADING, LoadingActionTypes } from './loading.actions';

const initialState = {};

export default (state = initialState, action: LoadingActionTypes) => {
  const { type } = action;
  if (type === SET_LOADING) {
    return {
      ...state,
      [action.payload.action]: action.payload.isLoading
    };
  }
  const matchesAPIAction = /(.*)_(API_REQUESTED|API_SUCCESS|API_FAILED)/.exec(type);
  if (matchesAPIAction) {
    const [, name, requestState] = matchesAPIAction;
    return {
      ...state,
      [name]: requestState === 'API_REQUESTED'
    };
  }
  return state;
};

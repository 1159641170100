import React from 'react';
import styled from 'styled-components';

import TenantsIcon from '../../../assets/svg/tenants.svg';
import LandlordIcon from '../../../assets/svg/person-active.svg';
import { ClaimProposalDTOStatusEnum } from '@reposit/api-client';
import { DocumentEntity } from '../../../redux/entities/entities.types';
import { ItemProposal } from '../../../redux/selectors/mediation.selectors';
import { getBreakpoint } from '../../../base/style';
import { P3, Header4 } from '../../Typography';

interface EvidenceProps {
  agentDocuments: DocumentEntity[];
  tenantDocuments: DocumentEntity[];
  itemProposals: ItemProposal[];
}

interface HeaderProps {
  flush?: boolean;
}

const EvidenceContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${getBreakpoint('md')}) {
    flex-direction: row;
  }
`;

export const EvidenceInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  overflow-wrap: anywhere;

  @media screen and (min-width: ${getBreakpoint('md')}) {
    width: 50%;
  }
`;

export const DocumentLinks = styled.ul`
  padding: 0 0 0 14px;
  list-style: square;
  margin-top: 10px;
  line-height: 1.4em;
`;

export const DocumentLink = styled.li`
  margin: 0 0 6px;

  a {
    color: ${(props) => props.theme.colors.primaryBrand};
    display: inline;
    font-family: ${(props) => props.theme.typography.face.secondary};
  }
`;

const Icon = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

export const Header = styled.div<HeaderProps>`
  align-items: center;
  display: flex;
  padding: ${(props) => (props.flush ? '0 35px' : '0')};
`;

export const EvidenceList: React.FC<{ documents?: DocumentEntity[] }> = ({ documents }) => {
  return documents && documents.length ? (
    <DocumentLinks>
      {documents.map((doc) => (
        <DocumentLink key={doc.id}>
          <a href={doc.url} target="_blank" rel="noopener noreferrer">
            {doc.name}
          </a>
        </DocumentLink>
      ))}
    </DocumentLinks>
  ) : (
    <P3 style={{ marginTop: 10 }}>No Evidence Provided</P3>
  );
};

export const Evidence: React.FC<EvidenceProps> = ({ agentDocuments, tenantDocuments, itemProposals }) => {
  const hasTenantRespondedYet = itemProposals.find(
    (ip) =>
      ip.claimProposal.round === 2 &&
      ip.claimProposal.status !== ClaimProposalDTOStatusEnum.DRAFT &&
      ip.claimProposal.status !== ClaimProposalDTOStatusEnum.AWAITINGPAYMENT
  );
  return (
    <EvidenceContainer>
      <EvidenceInnerContainer style={{ marginRight: 8 }}>
        <Header>
          <Icon src={LandlordIcon} />
          <Header4 style={{ marginBottom: 0 }}>Landlord evidence</Header4>
        </Header>
        <EvidenceList documents={agentDocuments} />
      </EvidenceInnerContainer>
      {hasTenantRespondedYet ? (
        <EvidenceInnerContainer>
          <Header>
            <Icon src={TenantsIcon} />
            <Header4 style={{ marginBottom: 0 }}>Tenant evidence</Header4>
          </Header>
          <EvidenceList documents={tenantDocuments} />
        </EvidenceInnerContainer>
      ) : null}
    </EvidenceContainer>
  );
};

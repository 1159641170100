import { get } from 'lodash';

import { PropertiesState } from './property.types';
import {
  PropertiesActionTypes,
  FETCH_ORGANIZATION_PROPERTIES_API_SUCCESS,
  FETCH_ORGANIZATION_PROPERTIES_API_FAILED
} from './property.actions';
import { RepositActionTypes, UPDATE_PROPERTY_API_SUCCESS } from '../reposit/reposit.actions';

const initialState: PropertiesState = {
  currentOrganizationProperties: [],
  haveCurrentOrganizationPropertiesBeenFetched: false
};

export default (state = initialState, action: PropertiesActionTypes | RepositActionTypes): PropertiesState => {
  switch (action.type) {
    case FETCH_ORGANIZATION_PROPERTIES_API_SUCCESS:
      return {
        ...state,
        currentOrganizationProperties: action.payload,
        haveCurrentOrganizationPropertiesBeenFetched: true
      };
    case FETCH_ORGANIZATION_PROPERTIES_API_FAILED:
      return {
        ...state,
        haveCurrentOrganizationPropertiesBeenFetched: true
      };
    case UPDATE_PROPERTY_API_SUCCESS:
      const newPropertyIsNotInCurrentList = !state.currentOrganizationProperties.find(
        id => id === get(action.payload, 'property.id')
      );
      return action.payload.property && newPropertyIsNotInCurrentList
        ? {
            ...state,
            currentOrganizationProperties: [...state.currentOrganizationProperties, action.payload.property.id]
          }
        : state;

    default:
      return state;
  }
};

import { CurrentUserInfoDTO, OrganizationsApi, UpdateUserAboutMeDTO, UpdateYourCompanyDTO, UsersApi } from '@reposit/api-client';
import { get } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { logout } from '../auth/auth.actions';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import SCHEMA from '../schema';
import { createOrganizationsApi, createUsersApi, runSagaWithAuth } from '../utils/api.utils';
import {
  fetchMeFailed,
  fetchMeSuccess,
  FETCH_ME_API_REQUESTED,
  updateAboutMeFailed,
  updateAboutMeSuccess,
  updateASTAcceptanceFailed,
  updateASTAcceptanceSuccess,
  updateInformedLandlordsFailed,
  updateInformedLandlordsSuccess,
  updateYourCompanyFailed,
  updateYourCompanySuccess,
  UPDATE_ABOUT_ME_API_REQUESTED,
  UPDATE_AST_ACCEPTANCE_API_REQUESTED,
  UPDATE_INFORMED_LANDLORDS_API_REQUESTED,
  UPDATE_YOUR_COMPANY_API_REQUESTED,
} from './account.actions';
import { getCurrentOrganizationId } from './account.selectors';

// ****************
// WORKERS
// ****************
export function* fetchMe() {
  try {
    const usersApi: UsersApi = yield createUsersApi();
    const { data } = yield call(runSagaWithAuth(() => usersApi.me()));

    const { user, currentOrganization, currentOrganizationRoles }: CurrentUserInfoDTO = data;
    const { internalRoles } = user;

    const id: string = yield syncEntitiesAndGetResults({ ...user, organization: currentOrganization }, SCHEMA.user);
    let currentOrganizationId = yield select(getCurrentOrganizationId) || null;

    yield put(
      fetchMeSuccess({ currentUserId: id, currentOrganizationId, currentOrganizationRoles, internalRoles: internalRoles as any })
    );
  } catch (e) {
    yield put(fetchMeFailed(get(e, 'response.data.message', e)));
    yield put(logout());
  }
}

export function* updateAboutMe({ payload }: { payload: UpdateUserAboutMeDTO; type: string }) {
  try {
    const usersApi: UsersApi = yield createUsersApi();
    const { data } = yield call(runSagaWithAuth(() => usersApi.updateAboutMe(payload)));
    yield syncEntitiesAndGetResults(data, SCHEMA.user);
    yield put(updateAboutMeSuccess());
  } catch (e) {
    yield put(updateAboutMeFailed(get(e, 'response.data.message', e)));
  }
}

export function* updateYourCompany({ payload }: { payload: UpdateYourCompanyDTO; type: string }) {
  try {
    let currentOrganizationId = yield select(getCurrentOrganizationId);
    const orgApi: OrganizationsApi = yield createOrganizationsApi();
    const { data } = yield call(runSagaWithAuth(() => orgApi.updateYourCompany(currentOrganizationId, payload)));
    yield syncEntitiesAndGetResults(data, SCHEMA.organization);
    yield put(updateYourCompanySuccess());
  } catch (e) {
    yield put(updateYourCompanyFailed(get(e, 'response.data.message', e)));
  }
}

export function* updateASTAcceptance() {
  try {
    let currentOrganizationId = yield select(getCurrentOrganizationId);
    const orgApi: OrganizationsApi = yield createOrganizationsApi();
    const { data } = yield call(runSagaWithAuth(() => orgApi.updateASTAcceptance(currentOrganizationId)));
    yield syncEntitiesAndGetResults(data, SCHEMA.organization);
    yield put(updateASTAcceptanceSuccess());
  } catch (e) {
    yield put(updateASTAcceptanceFailed(get(e, 'response.data.message', e)));
  }
}

export function* updateInformedLandlords() {
  try {
    let currentOrganizationId = yield select(getCurrentOrganizationId);
    const orgApi: OrganizationsApi = yield createOrganizationsApi();
    const { data } = yield call(runSagaWithAuth(() => orgApi.updateInformedLandlords(currentOrganizationId)));
    yield syncEntitiesAndGetResults(data, SCHEMA.organization);
    yield put(updateInformedLandlordsSuccess());
  } catch (e) {
    yield put(updateInformedLandlordsFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchAccountSagas() {
  yield takeLatest(FETCH_ME_API_REQUESTED, fetchMe);
  yield takeLatest(UPDATE_ABOUT_ME_API_REQUESTED, updateAboutMe);
  yield takeLatest(UPDATE_YOUR_COMPANY_API_REQUESTED, updateYourCompany);
  yield takeLatest(UPDATE_AST_ACCEPTANCE_API_REQUESTED, updateASTAcceptance);
  yield takeLatest(UPDATE_INFORMED_LANDLORDS_API_REQUESTED, updateInformedLandlords);
}

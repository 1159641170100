export enum OrderStatusFilter {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  CHECKED_OUT = 'CHECKED_OUT',
}

export enum RepositStatusFilter {
  ALL = 'ALL',
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  CHECKED_OUT = 'CHECKED_OUT',
  LET_ONLY = 'LET_ONLY',
  DELETED = 'DELETED',
}

export enum TenancyStatusFilter {
  ACTIVE = 'ACTIVE',
  CHECKED_OUT = 'CHECKED_OUT',
}

export enum RepositSort {
  END_DATE_DESCENDING = 'END_DATE_DESCENDING',
  END_DATE_ASCENDING = 'END_DATE_ASCENDING',
  START_DATE_DESCENDING = 'START_DATE_DESCENDING',
  START_DATE_ASCENDING = 'START_DATE_ASCENDING',
  CREATED_AT_DESCENDING = 'CREATED_AT_DESCENDING',
  CREATED_AT_ASCENDING = 'CREATED_AT_ASCENDING',
}

export enum LetOnlyFilter {
  ALL = 'ALL',
  YES = 'YES',
  NO = 'NO',
}

import { OrganizationInternalOwnerDTO, OrganizationInternalOwnersApi } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import SCHEMA from '../schema';
import { createOrganizationInternalOwnersApi, runSagaWithAuth } from '../utils/api.utils';
import {
  fetchOrganizationInternalOwnersByIdFailed,
  fetchOrganizationInternalOwnersByIdSuccess,
  FETCH_ORGANIZATION_INTERNAL_OWNERS_BY_ID_API_REQUESTED,
  OrganizationInternalOwnerActions,
} from './organization-internal-owner.actions';

// ****************
// WORKERS
// ****************

export function* fetchOrganizationInternalOwnersById({ payload }: { payload: { organizationId: string }; type: string }) {
  try {
    const api: OrganizationInternalOwnersApi = yield createOrganizationInternalOwnersApi();
    const apiResponse: AxiosResponse<OrganizationInternalOwnerDTO> = yield call(
      runSagaWithAuth(() => api.getOwners(payload.organizationId))
    );
    const ids: string[] = yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.organizationInternalOwners);
    yield put<OrganizationInternalOwnerActions>(fetchOrganizationInternalOwnersByIdSuccess(ids));
  } catch (e) {
    yield put<OrganizationInternalOwnerActions>(fetchOrganizationInternalOwnersByIdFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchSagas() {
  yield takeLatest(FETCH_ORGANIZATION_INTERNAL_OWNERS_BY_ID_API_REQUESTED, fetchOrganizationInternalOwnersById);
}

import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { Header2, P2 } from '../../../components/Typography';
import { Table, Thead, Tr, Td, Tbody } from '../../../components/Table';
import { useSelector } from 'react-redux';
import { getClaimsDownloadStatus } from '../../../redux/claim/claim.selector';
import Card from '../../../components/Card';
import { CurrentClaimZipDownloadStatus } from '../../../redux/claim/claim.types';

export const Downloads = () => {
  const downloadStatuses = useSelector(getClaimsDownloadStatus) || {};
  const keys = Object.keys(downloadStatuses);
  const arrayOfDownloads: { id: string; data: CurrentClaimZipDownloadStatus }[] = keys.map((id) => ({
    id,
    data: downloadStatuses[id],
  }));
  return (
    <Container>
      <Row>
        <Col sm={12}>
          <Header2>Claim Document Downloads</Header2>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Card>
            {arrayOfDownloads.length ? (
              <Table style={{ borderTop: 'none' }}>
                <Thead>
                  <Tr>
                    <Td>Claim ID</Td>
                    <Td>Download Progress</Td>
                    <Td>Zip Progress</Td>
                  </Tr>
                </Thead>
                <Tbody>
                  {arrayOfDownloads.map((download) => {
                    const {
                      id,
                      data: { current, total, zipProgress },
                    } = download;
                    return (
                      <Tr>
                        <Td>{id}</Td>
                        <Td>{current && total ? `${current}/${total}` : 'pending'}</Td>
                        <Td>{zipProgress}%</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            ) : (
              <P2>No downloads queued</P2>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export const FETCH_KPI_DOCUMENTS_STORE_KEY = `REPOSIT_LIST/FETCH_KPI_DOCUMENTS`;
export const FETCH_KPI_DOCUMENTS_API_REQUESTED = `REPOSIT_LIST/FETCH_KPI_DOCUMENTS_API_REQUESTED`;
export const FETCH_KPI_DOCUMENTS_API_SUCCESS = `REPOSIT_LIST/FETCH_KPI_DOCUMENTS_API_SUCCESS`;
export const FETCH_KPI_DOCUMENTS_API_FAILED = `REPOSIT_LIST/FETCH_KPI_DOCUMENTS_API_FAILED`;

export const REPOSITS_GOTO_PAGE = 'REPOSIT_LIST/REPOSITS_GOTO_PAGE';
export const REPOSITS_UPDATE_FILTERS = 'REPOSIT_LIST/UPDATE_FILTERS';

export function fetchKpiDocumentsRequested(date: string) {
  return {
    type: FETCH_KPI_DOCUMENTS_API_REQUESTED,
    payload: {
      date,
    },
  } as const;
}
export function fetchKpiDocumentsSuccess(count: number, ids: string[]) {
  return {
    type: FETCH_KPI_DOCUMENTS_API_SUCCESS,
    payload: {
      count,
      ids,
    },
  } as const;
}
export function fetchKpiDocumentsFailed(error: string) {
  return {
    type: FETCH_KPI_DOCUMENTS_API_FAILED,
    payload: error,
  } as const;
}

export type KpiDocumentsActionTypes = ReturnType<
  typeof fetchKpiDocumentsRequested | typeof fetchKpiDocumentsSuccess | typeof fetchKpiDocumentsFailed
>;

import { createAction } from '@reduxjs/toolkit';
import { AddGuarantor, DeleteGuarantor, GuarantorModalPayload, UpdateGuarantor } from './guarantor.types';

export const ADD_GUARANTOR_STORE_KEY = 'GUARANTOR/ADD_GUARANTOR';
export const UPDATE_GUARANTOR_STORE_KEY = 'GUARANTOR/UPDATE_GUARANTOR';
export const DELETE_GUARANTOR_STORE_KEY = 'GUARANTOR_DELETE_GUARANTOR';

export const addGuarantorRequested = createAction<AddGuarantor>('GUARANTOR/ADD_GUARANTOR_API_REQUESTED');
export const addGuarantorSuccess = createAction('GUARANTOR/ADD_GUARANTOR_API_SUCCESS');
export const addGuarantorFailed = createAction<string>('GUARANTOR/ADD_GUARANTOR_API_FAILED');

export const updateGuarantorRequested = createAction<UpdateGuarantor>('GUARANTOR/UPDATE_GUARANTOR_API_REQUESTED');
export const updateGuarantorSuccess = createAction('GUARANTOR/UPDATE_GUARANTOR_API_SUCCESS');
export const updateGuarantorFailed = createAction<string>('GUARANTOR/UPDATE_GUARANTOR_API_FAILED');

export const deleteGuarantorRequested = createAction<DeleteGuarantor>('GUARANTOR/DELETE_GUARANTOR_API_REQUESTED');
export const deleteGuarantorSuccess = createAction<string>('GUARANTOR/DELETE_GUARANTOR_API_SUCCESS');
export const deleteGuarantorFailed = createAction<string>('GUARANTOR/DELETE_GUARANTOR_API_FAILED');

export const setGuarantorModal = createAction<GuarantorModalPayload>('GUARANTOR/SET_GUARANTOR_MODAL_STATE');

export type GuarantorActionTypes = ReturnType<
  typeof deleteGuarantorRequested | typeof deleteGuarantorSuccess | typeof deleteGuarantorFailed
>;

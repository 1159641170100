import {
  IntegratorApi,
  IntegratorDTO,
  IntegratorCommissionApi,
  FileLocationDTO,
  OrganizationUsersApi,
} from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import SCHEMA from '../schema';
import {
  createIntegratorsApi,
  createIntegratorCommissionApi,
  runSagaWithAuth,
  createOrganizationUserApi,
} from '../utils/api.utils';
import {
  getIntegratorsFailed,
  getIntegratorsSuccess,
  GET_INTEGRATORS_API_REQUESTED,
  IntegratorActionTypes,
  generateCommissionSuccess,
  generateCommissionFailed,
  GENERATE_COMMISSION_API_REQUESTED,
  notifyIntegratorSuccess,
  notifyIntegratorFailed,
  NOTIFY_INTEGRATOR_STORE_KEY,
  NOTIFY_INTEGRATOR_API_REQUESTED,
} from './integrator.actions';
import { GenerateCommissionPayload, NotifyIntegratorPayload } from './integrator.types';
import { getErrorMessage } from '../../utils/common.utils';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import { FlashState } from '../../components/FlashMessage';

// ****************
// WORKERS
// ****************

export function* fetchIntegrators(args: { type: string }) {
  try {
    const integratorsAPI: IntegratorApi = yield createIntegratorsApi();

    const apiResponse: AxiosResponse<IntegratorDTO> = yield call(runSagaWithAuth(() => integratorsAPI.getIntegrators()));
    const ids: string[] = yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.integrators);
    yield put<IntegratorActionTypes>(getIntegratorsSuccess(ids));
  } catch (e) {
    yield put<IntegratorActionTypes>(getIntegratorsFailed(get(e, 'response.data.message', e)));
  }
}

export function* generateCommission({ payload }: { type: string; payload: GenerateCommissionPayload }) {
  try {
    const integratorsAPI: IntegratorCommissionApi = yield createIntegratorCommissionApi();

    const apiResponse: AxiosResponse<FileLocationDTO> = yield call(
      runSagaWithAuth(() => integratorsAPI.generateLastMonthCommission(payload.integratorId))
    );
    yield put<IntegratorActionTypes>(generateCommissionSuccess(apiResponse.data.fileLocation));
  } catch (e) {
    yield put<IntegratorActionTypes>(generateCommissionFailed(get(e, 'response.data.message', e)));
  }
}

export function* notifyIntegrator({ payload }: { type: string; payload: NotifyIntegratorPayload }) {
  try {
    const { organizationId, userId, integratorId } = payload;
    const organizationUsersApi: OrganizationUsersApi = yield createOrganizationUserApi();
    yield call(runSagaWithAuth(() => organizationUsersApi.notifyIntegrator(userId, organizationId, { integratorId })));
    yield put<IntegratorActionTypes>(notifyIntegratorSuccess());
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: NOTIFY_INTEGRATOR_STORE_KEY,
        message: 'Success! Integrator and User Notified!',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<IntegratorActionTypes>(notifyIntegratorFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: NOTIFY_INTEGRATOR_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

// ****************
// WATCHERS
// ****************
export function* watchIntegratorSagas() {
  yield takeLatest(GET_INTEGRATORS_API_REQUESTED, fetchIntegrators);
  yield takeLatest(GENERATE_COMMISSION_API_REQUESTED, generateCommission);
  yield takeLatest(NOTIFY_INTEGRATOR_API_REQUESTED, notifyIntegrator);
}

import { AppState } from '../root.reducer';
import { denormalize } from 'normalizr';
import SCHEMA from '../schema';
import { ReconciliationDocumentDTO } from '@reposit/api-client';

export const getReconciliationDocumentsPagination = (state: AppState) => ({
  count: state.reconciliationDocument.count,
  page: state.reconciliationDocument.page,
});

export const getReconciliationDocuments = (state: AppState): ReconciliationDocumentDTO[] =>
  state.reconciliationDocument.results.map((i) =>
    denormalize(state.entities.reconciliationDocument[i], SCHEMA.reconciliationDocument, state.entities)
  );

import { TenancyAnniversariesApi, TenancyAnniversaryWithTenancyDTO } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getErrorMessage } from '../../utils/common.utils';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import SCHEMA from '../schema';
import { createTenancyAnniversaryApi, runSagaWithAuth } from '../utils/api.utils';
import {
  fetchTenancyAnniversariesSuccess,
  fetchTenancyAnniversariesFailed,
  TenancyAnniversaryActionTypes,
  FETCH_TENANCY_ANNIVERSARIES_API_REQUESTED,
} from './tenancy-anniversary.actions';

// ****************
// WORKERS
// ****************

export function* fetchTenancyAnniversariesByTenancyId({ payload }: { type: string; payload: string }) {
  try {
    const tenancyAnniversaryApi: TenancyAnniversariesApi = yield createTenancyAnniversaryApi();
    const apiResponse: AxiosResponse<TenancyAnniversaryWithTenancyDTO> = yield call(
      runSagaWithAuth(() => tenancyAnniversaryApi.getTenancyAnniversaries(payload))
    );

    const ids = yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.tenancyAnniversaries);
    yield put<TenancyAnniversaryActionTypes>(fetchTenancyAnniversariesSuccess(ids));
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<TenancyAnniversaryActionTypes>(fetchTenancyAnniversariesFailed(error));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchTenancyAnniversarySagas() {
  yield takeLatest(FETCH_TENANCY_ANNIVERSARIES_API_REQUESTED, fetchTenancyAnniversariesByTenancyId);
}

export const GENERATE_LAST_MONTHS_DIRECT_COMMISSION_STORE_KEY = `INTEGRATOR/GENERATE_LAST_MONTHS_DIRECT_COMMISSION`;
export const GENERATE_LAST_MONTHS_DIRECT_COMMISSION_API_REQUESTED = `INTEGRATOR/GENERATE_LAST_MONTHS_DIRECT_COMMISSION_API_REQUESTED`;
export const GENERATE_LAST_MONTHS_DIRECT_COMMISSION_API_SUCCESS = `INTEGRATOR/GENERATE_LAST_MONTHS_DIRECT_COMMISSION_API_SUCCESS`;
export const GENERATE_LAST_MONTHS_DIRECT_COMMISSION_API_FAILED = `INTEGRATOR/GENERATE_LAST_MONTHS_DIRECT_COMMISSION_API_FAILED`;
export const CLEAR_CSV_URL = `INTEGRATOR/CLEAR_CSV_URL`;

export function generateLastMonthsDirectCommissionRequested() {
  return {
    type: GENERATE_LAST_MONTHS_DIRECT_COMMISSION_API_REQUESTED,
  } as const;
}
export function generateLastMonthsDirectCommissionSuccess() {
  return {
    type: GENERATE_LAST_MONTHS_DIRECT_COMMISSION_API_SUCCESS,
  } as const;
}
export function generateLastMonthsDirectCommissionFailed(error: string) {
  return {
    type: GENERATE_LAST_MONTHS_DIRECT_COMMISSION_API_FAILED,
    payload: error,
  } as const;
}

export function clearCsvURL() {
  return {
    type: CLEAR_CSV_URL,
  } as const;
}

export type CommissionActionTypes = ReturnType<
  | typeof generateLastMonthsDirectCommissionRequested
  | typeof generateLastMonthsDirectCommissionSuccess
  | typeof generateLastMonthsDirectCommissionFailed
  | typeof clearCsvURL
>;

import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getIntegratorsRequested, GET_INTEGRATORS_STORE_KEY } from '../redux/integrator/integrator.actions';
import { createLoadingSelector } from '../redux/loading/loading.selector';
import { getIntegrators } from '../redux/integrator/integrator.selectors';
import { createErrorMessageSelector } from '../redux/error/error.selector';

export const useIntegrators = () => {
  const dispatch = useDispatch();
  const loadingSelector = createLoadingSelector([GET_INTEGRATORS_STORE_KEY]);
  const errorSelector = createErrorMessageSelector([GET_INTEGRATORS_STORE_KEY]);
  const isLoading = useSelector(loadingSelector);
  const error = useSelector(errorSelector);
  const integrators = useSelector(getIntegrators);

  useEffect(() => {
    dispatch(getIntegratorsRequested());
  }, [dispatch]);

  return {
    isLoading,
    integrators,
    error,
  };
};

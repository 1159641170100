import { omit } from 'lodash';

import { DELETE_TENANT_API_SUCCESS, RepositActionTypes } from '../../reposit/reposit.actions';
import { GuarantorActionTypes, deleteGuarantorSuccess } from '../../guarantor/guarantor.actions';

type TenantEntityActionTypes = RepositActionTypes & GuarantorActionTypes;

export const tenantEntityReducer = (state: any = {}, action: TenantEntityActionTypes) => {
  if (action.type === DELETE_TENANT_API_SUCCESS) {
    return omit(state, action.payload.tenancyInviteId);
  }
  if (action.type === deleteGuarantorSuccess.type) {
    const tenancyUserId: string = action.payload;
    const newTenant = { ...state[tenancyUserId], guarantor: null };
    return {
      ...state,
      [tenancyUserId]: {
        ...newTenant,
      },
    };
  }
  return state;
};

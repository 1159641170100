import React from 'react';
import styled from 'styled-components';

interface RepositStatusProps {
  status: string;
  label?: string;
}

const Container = styled.div<RepositStatusProps>`
  background: ${(props) => props.theme.colors.positive};
  border-radius: 24px;
  display: inline-block;
  color: #fff;
  font-family: ${(props) => props.theme.typography.face.secondary};
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 0 auto;
  padding: 6px 20px;

  ${(props) =>
    props.status === 'DRAFT' &&
    `
    background: ${props.theme.colors.positive}
  `}

  ${(props) =>
    props.status === 'PENDING' &&
    `
    background: ${props.theme.colors.warning}
  `}

  ${(props) =>
    props.status === 'DRAFT' &&
    `
    background: ${props.theme.colors.body2}
  `}

  ${(props) =>
    props.status === 'CANCELLED' &&
    `
    background: ${props.theme.colors.negative}
  `}

  ${(props) =>
    props.status === 'DELETED' &&
    `
    background: ${props.theme.colors.negative}
  `}

${(props) =>
    props.status === 'CLOSED' &&
    `
    background: ${props.theme.colors.negative}
  `}
  
  ${(props) =>
    props.status === 'CHECKED_OUT' &&
    `
  background: ${props.theme.colors.positive}
`}
`;

const resolveLabel = {
  DRAFT: 'Draft',
  PENDING: 'Awaiting Tenant Actions',
  COMPLETE: 'Complete',
  CANCELLED: 'Cancelled',
  DELETED: 'Deleted',
  CLOSED: 'Closed',
  CHECKED_OUT: 'Checked Out',
} as any;

const RepositStatus: React.FC<RepositStatusProps> = ({ status, label }) => {
  return <Container status={status}>{label || resolveLabel[status]}</Container>;
};

export default RepositStatus;

import { AppState } from '../root.reducer';

export const getUserList = (state: AppState) => {
  return state.userList.results.map((userId: string) => state.entities.user[userId]);
};
export const getUserListFilters = (state: AppState) => state.userList.filters;

export const getUserListPagination = (state: AppState) => ({
  count: state.userList.count,
  page: state.userList.page
});

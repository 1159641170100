import { get, map, flow, compact, first } from 'lodash';
import { AppState } from '../root.reducer';
import { FlashState } from '../../components/FlashMessage';
import { FlashMessagePayload } from './flash-messages.types';

const getFlashMessageByKey = (keys: string[], state: AppState) => {
  return map(keys, (action) => get(state, `flashMessages.${action}`));
};

const getFailedFlashMessagesByKeys = (keys: string[], state: AppState) => {
  return map(keys, (action) => get(state, `flashMessages.${action}`)).filter(
    (flashMessage: FlashMessagePayload) => flashMessage && flashMessage.state === FlashState.ERROR
  );
};

export const createFlashMessagesSelector = (actions: string[]) => (state: AppState) => {
  // returns the first error messages for actions
  // * We assume when any request fails on a page that
  //   requires multiple API calls, we shows the first error
  const createSelector = flow(getFlashMessageByKey, compact, first);
  return createSelector(actions, state);
};

export const createFailedFlashMessagesSelector = (actions: string[]) => (state: AppState) => {
  // returns the first error messages for actions
  // * We assume when any request fails on a page that
  //   requires multiple API calls, we shows the first error
  const createSelector = flow(getFailedFlashMessagesByKeys, compact, first);
  return createSelector(actions, state);
};

import styled from 'styled-components';

import { getBreakpoint } from '../../base/style';
import { Header4 } from '../../components/Typography';
import Button from '../../components/Button';

export const OrganizationRecipientsListWrapper = styled.ul`
  background: #fff;
  margin: 22px 0 36px;
  min-width: 200px;
  overflow: hidden;
  padding: 0;
`;

export const OrganizationRecipientListItem = styled.div<{ isOpen?: boolean }>`
  background: ${(props) => props.theme.colors.bgAccent};
  margin: 0 0 6px;
  padding: 0 24px 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    padding: ${(props) => (props.isOpen ? `34px` : `0 34px`)};
  }

  &:hover {
    background: ${(props) => (!props.isOpen ? `#f2f3f4` : props.theme.colors.bgAccent)};
  }
`;

export const OrganizationRecipientItemWrapper = styled.li`
  border-radius: 8px;
  cursor: default;
  box-sizing: border-box;
  margin: 0 0 6px;
  padding: 12px 0;
  height: 96px;

  @media all and (min-width: ${getBreakpoint('md')}) {
    height: 112px;
  }

  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
    height: 48px;
    margin: 0 0 6px;
    padding: 0;
  }
`;

export const OrganizationRecipientItem = styled.div`
  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
  }
`;

export const Email = styled(Header4)`
  font-size: 1em;
  margin: 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    margin: 0 10px 0 0;
  }
`;

export const ResendButton = styled(Button)`
  margin: 0 0 0 auto;
`;

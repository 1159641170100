import { AppState } from '../root.reducer';

export const getClaimListFilters = (state: AppState) => state.claimList.filters;

export const getClaimListPagination = (state: AppState) => ({
  count: state.claimList.count,
  page: state.claimList.page
});

export const getClaimList = (state: AppState) => state.claimList.results;

import Modal from '../Modal/index';
import React, { Fragment } from 'react';
import { Header3, P1 } from '../Typography/index';
import Button from '../Button/index';
import styled from 'styled-components';
import { TenantWithActions } from '../../redux/selectors/index';
import { TenantEntity } from '../../redux/entities/entities.types';
import { DELETE_GUARANTOR_STORE_KEY } from '../../redux/guarantor/guarantor.actions';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import { useSelector } from 'react-redux';

interface DeleteGuarantorModalProps {
  onDismiss: () => void;
  onSubmit: () => void;
  tenant: TenantWithActions | TenantEntity;
}

const Action = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

const BoldText = styled.span`
  font-weight: bold;
`;

export const DeleteGuarantorModal: React.FC<DeleteGuarantorModalProps> = ({ onDismiss, onSubmit, tenant }) => {
  const tenantName = tenant.firstName && tenant.lastName ? `${tenant.firstName} ${tenant.lastName}` : tenant.email;
  const isSubmittingSelector = createLoadingSelector([DELETE_GUARANTOR_STORE_KEY]);
  const isSubmitting = useSelector(isSubmittingSelector);
  return (
    <Modal onDismiss={onDismiss}>
      <Fragment>
        <Header3 style={{ marginBottom: 6 }}>Delete Guarantor</Header3>
        <P1 style={{ marginBottom: 6 }}>
          Are you sure you want to delete the guarantor for <BoldText>{tenantName}</BoldText>?
        </P1>
        <Action>
          <Button buttonType="warning" noArrow={true} onClick={onSubmit} disabled={isSubmitting}>
            Delete
          </Button>
          <Button buttonType="secondary" noArrow={true} onClick={onDismiss}>
            Cancel
          </Button>
        </Action>
      </Fragment>
    </Modal>
  );
};

import React from 'react';
import styled from 'styled-components';
import RepositLogo from '../../assets/svg/reposit.svg';
import { getBreakpoint } from '../../base/style';
import { Motion, spring, presets } from 'react-motion';

const MobileWrapper = styled.div`
  display: none;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;

  @media screen and (max-width: ${getBreakpoint('md')}) {
    display: block;
  }
`;

const MobileHeader = styled.div`
  align-items: center;
  background: #fff;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
  padding: 0 20px;
  display: flex;
  height: 48px;

  &::after {
    background: transparent url(${RepositLogo}) no-repeat center center;
    content: '';
    display: 'block';
    margin-left: auto;
    height: 20px;
    width: 20px;
  }
`;

const Actions = styled.div`
  display: flex;
  font-size: 1.75em;
`;

interface MobileHeaderProps {
  onMenuClick: () => void;
  isMobileMenuOpen: boolean;
}

export default ({ onMenuClick, isMobileMenuOpen }: MobileHeaderProps) => {
  const style = {
    overflow: 'visible',
    cursor: 'pointer',
    WebkitTapHighlightColor: 'rgba(0,0,0,0)'
  };

  return (
    <MobileWrapper>
      <MobileHeader>
        <Actions>
          <svg viewBox="0 0 96 96" height="1em" onClick={onMenuClick} style={style}>
            <Motion
              style={{
                x: spring(isMobileMenuOpen ? 1 : 0, presets.wobbly),
                y: spring(isMobileMenuOpen ? 0 : 1, presets.wobbly)
              }}
            >
              {({ x, y }) => (
                <g id="navicon" fill="none" stroke="currentColor" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round">
                  <line transform={`translate(${x * 12}, ${x * -7}) rotate(${x * 45}, 7, 26)`} x1="7" y1="26" x2="89" y2="26" />
                  <line transform={`translate(${x * 12}, ${x * 7}) rotate(${x * -45}, 7, 70)`} x1="7" y1="70" x2="89" y2="70" />
                  <line transform={`translate(${x * -96})`} opacity={y} x1="7" y1="48" x2="89" y2="48" />
                </g>
              )}
            </Motion>
          </svg>
        </Actions>
      </MobileHeader>
    </MobileWrapper>
  );
};

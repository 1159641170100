import React from 'react';
import styled from 'styled-components';
import { isString } from 'lodash';

import { Label } from '../Typography';

import { Option } from '../../interfaces/option.interface';
import Select from '../FormFields/Select/index';
import { getBreakpoint } from '../../base/style';

const SelectFilterContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 75px;

  @media screen and (max-width: ${getBreakpoint('md')}) {
    flex: 0 0 auto;
    margin: 0 0 12px;
  }
`;

const FilterLabel = styled(Label)`
  margin: 0 20px 0 0;
  width: 75px;

  @media screen and (min-width: ${getBreakpoint('md')}) {
    width: auto;
  }
`;

interface SelectFilterProps {
  label?: string;
  onSelect: (option: string | any) => void;
  selected: string;
  options: (string | Option)[];
}

const renderOptions = (props: SelectFilterProps): JSX.Element[] => {
  return props.options.map((option) => {
    const value = isString(option) ? option : option.value;
    const label = isString(option) ? option : option.label;
    return (
      <option value={value} key={value}>
        {label}
      </option>
    );
  });
};

export default (props: SelectFilterProps): JSX.Element => (
  <SelectFilterContainer>
    <FilterLabel>{props.label}</FilterLabel>
    <Select onChange={(value: any) => props.onSelect(value)} value={props.selected ? props.selected : 0}>
      {renderOptions(props)}
    </Select>
  </SelectFilterContainer>
);

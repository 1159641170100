import { OrganizationsApi } from '@reposit/api-client';
import { get } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { FlashState } from '../../components/FlashMessage/index';
import { getErrorMessage } from '../../utils/common.utils';
import { getCurrentOrganizationId } from '../account/account.selectors';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import { createOrganizationsApi, runSagaWithAuth } from '../utils/api.utils';
import {
  deleteOrganizationUserFailed,
  deleteOrganizationUserSuccess,
  DELETE_ORGANIZATION_USER_API_REQUESTED,
  DELETE_ORGANIZATION_USER_STORE_KEY,
  OrgnizationUserActionTypes
} from './organization-user.actions';

// ****************
// WORKERS
// ****************

export function* deleteOrganizationUser({ payload }: { type: string; payload: string }) {
  try {
    const organizationId: string = yield select(getCurrentOrganizationId);
    const organizationApi: OrganizationsApi = yield createOrganizationsApi();

    yield call(runSagaWithAuth(() => organizationApi.removeUser(organizationId, payload)));
    yield put<OrgnizationUserActionTypes>(deleteOrganizationUserSuccess(payload));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: DELETE_ORGANIZATION_USER_STORE_KEY,
        message: 'Success! You have deleted a team member.',
        state: FlashState.SUCCESS
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: DELETE_ORGANIZATION_USER_STORE_KEY, message: error, state: FlashState.ERROR })
    );
    yield put<OrgnizationUserActionTypes>(deleteOrganizationUserFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchOrganizationUserSagas() {
  yield takeLatest(DELETE_ORGANIZATION_USER_API_REQUESTED, deleteOrganizationUser);
}

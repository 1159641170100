import React, { Fragment } from 'react';
import styled from 'styled-components';

interface CardProps {
  onClick?: (e: any) => void;
  children: JSX.Element[] | JSX.Element;
  className?: string;
  stretch?: boolean;
  hover?: boolean;
  flush?: boolean;
  style?: any;
  flashMessage?: JSX.Element;
}

export const CardComponent = styled.div<CardProps>`
  background: #fff;
  border-radius: 12px;
  box-sizing: border-box;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  height: ${(props) => (props.stretch ? 'calc(100% - 24px)' : 'auto')};
  margin: 1rem 0 1.75rem;
  padding: 5px 0 0;
  min-width: 300px;
  transition: ${(props) => (props.hover ? 'all 0.2s' : 'none')};
  position: relative;
  z-index: 1;

  &:hover {
    box-shadow: ${(props) => (props.hover ? '0 5px 25px 0 rgba(0, 0, 0, 0.1)' : '0 2px 3px 0 rgba(0, 0, 0, 0.1)')};
    transform: ${(props) => (props.hover ? 'translate(0, -5px)' : 'none')};
  }
`;

const CardContent = styled.div<CardProps>`
  padding: ${(props) => (props.flush ? '24px 5px 2px' : '24px 40px 42px')};
`;

const Card: React.SFC<CardProps> = ({ children, className, stretch, hover, flush, onClick, flashMessage, style }) => {
  return (
    <CardComponent className={className} stretch={stretch} hover={hover} onClick={onClick} flush={flush}>
      <Fragment>
        {flashMessage}
        <CardContent flush={flush} style={style}>
          {children}
        </CardContent>
      </Fragment>
    </CardComponent>
  );
};

export default Card;

import React from 'react';
import styled from 'styled-components';

interface InputProps {
  icon?: string | null | undefined;
  value?: string | number | string[] | undefined;
  name: string;
  type?: string;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  touched?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
}

const InputWrapper = styled.div<{ icon: string | null | undefined }>`
  position: relative;

  &:before {
    background: transparent url('${(props) => props.icon}') center center;
    background-size: 12px;
    content: '';
    display: block;
    height: 12px;
    width: 12px;
    margin: 12px 14px;
    pointer-events: none;
    position: absolute;
    left: 0;
  }
`;

const Input = styled.input<InputProps>`
  background: #fff;
  border: 1px solid ${(props) => (props.error && props.touched ? props.theme.colors.negative : 'rgba(0, 0, 0, 0.1)')};
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  color: ${(props) => props.theme.colors.body};
  height: 36px;
  padding: ${(props) => (props.icon ? '0 30px' : '0 16px')};
  width: 100%;
  font-family: ${(props) => props.theme.typography.face.secondary};
  font-size: 1em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  outline: none;

  &:focus {
    box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px ${(props) => props.theme.colors.body};
  }

  &:disabled {
    background: rgba(244, 244, 244, 0.6);
    cursor: not-allowed;
    opacity: 0.45;
  }
`;

export default ({
  value = '',
  onChange,
  onBlur,
  onClick,
  onKeyDown,
  icon,
  name,
  type,
  placeholder,
  error,
  touched,
  disabled,
  onFocus,
}: InputProps) => (
  <InputWrapper icon={icon}>
    <Input
      disabled={disabled}
      value={value}
      onChange={onChange}
      icon={icon}
      onBlur={onBlur}
      onClick={onClick}
      onKeyDown={onKeyDown}
      name={name}
      type={type || 'input'}
      placeholder={placeholder}
      autoComplete="off"
      error={error}
      touched={touched}
      onFocus={onFocus}
    />
  </InputWrapper>
);

import React, { useEffect, Fragment } from 'react';
import { Wrapper } from './components/Common';
import { history } from '.';
import Routes from './Routes';
import { Router } from 'react-router';
import FlashMessageContainer from './containers/FlashMessage';
import { createLoadingSelector } from './redux/loading/loading.selector';
import { FETCH_ME_STORE_KEY, fetchMeRequested } from './redux/account/account.actions';
import { useSelector, useDispatch } from 'react-redux';
import { getAccessToken } from './redux/auth/auth.selectors';
import { getMeHasFetched } from './redux/account/account.selectors';
import { FullPageLoader } from './components/Loading';
import { getCurrentUser } from './redux/selectors/user.selectors';
import { logout } from './redux/auth/auth.actions';
import { UserDTOUserTypeIdEnum } from '@reposit/api-client';

const App: React.FC = () => {
  const getMeLoadingSelector = createLoadingSelector([FETCH_ME_STORE_KEY]);
  const token = useSelector(getAccessToken);
  const isGetMeLoading = useSelector(getMeLoadingSelector);
  const hasGetMeFetched = useSelector(getMeHasFetched);
  const user = useSelector(getCurrentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(fetchMeRequested());
    }
  }, [dispatch, token]);

  if ((isGetMeLoading && !hasGetMeFetched) || (token && !hasGetMeFetched)) {
    return <FullPageLoader />;
  }

  const userType = user ? user.userTypeId : null;
  if (userType && userType !== UserDTOUserTypeIdEnum.INTERNAL) {
    dispatch(logout());
    return null;
  }

  return (
    <Fragment>
      <Wrapper>
        <Router history={history}>
          <FlashMessageContainer />
          <Routes />
        </Router>
      </Wrapper>
    </Fragment>
  );
};

export default App;

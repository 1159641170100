import { AppState } from '../root.reducer';

export const getOrganizationList = (state: AppState) => {
  return state.organizationList.results.map((organizationId: string) => state.entities.organization[organizationId]);
};
export const getOrganizationListFilters = (state: AppState) => state.organizationList.filters;

export const getOrganizationListPagination = (state: AppState) => ({
  count: state.organizationList.count,
  page: state.organizationList.page
});

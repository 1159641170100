import React from "react";
import styled from "styled-components";
import { getBreakpoint } from "../../base/style";
import { P1, P2 } from "../Typography/index";
import HelpIcon from "../../assets/svg/help.svg";
import { RoundButton } from "../Button";

interface SecondaryPanelProps {
  title?: string;
  subtitle?: string;
  tooltip?: string;
  style?: any;
}

const Panel = styled.div`
  background: ${props => props.theme.colors.bgAccent};
  margin: 3px 0;
  padding: 24px 34px;
`;

const Container = styled.div`
  position: relative;
  @media screen and (min-width: ${getBreakpoint("lg")}) {
    display: flex;
    align-items: flex-end;
  }
`;

const Title = styled(P1)`
  @media screen and (max-width: ${getBreakpoint("lg")}) {
    margin: 0;
  }
`;

const Subtitle = styled(P2)`
  margin: 0 0 18px;

  @media screen and (min-width: ${getBreakpoint("lg")}) {
    margin-bottom: 0.875em;
    margin-left: 8px;
  }

  span {
    display: none;
    @media screen and (min-width: ${getBreakpoint("lg")}) {
      display: inline;
    }
  }
`;

const HelpButton = styled(RoundButton)`
  top: 0;
  right: 0;
  margin: 20px;
  position: absolute;
`;

const SecondaryPanel: React.SFC<SecondaryPanelProps> = ({
  title,
  subtitle,
  children,
  style,
  tooltip
}) => (
  <Panel style={{ ...style, position: "relative" }}>
    {tooltip && <HelpButton icon={HelpIcon} data-tip={tooltip} />}
    <Container>
      {title && <Title>{title}</Title>}
      {subtitle && (
        <Subtitle>
          <span>-</span> {subtitle}
        </Subtitle>
      )}
    </Container>
    {children}
  </Panel>
);

export default SecondaryPanel;

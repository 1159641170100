import { LandlordDetailsForm } from './landlord.types';

export const FETCH_LANDLORD_DETAILS_STORE_KEY = 'LANDLORD/FETCH_LANDLORD_DETAILS_STORE_KEY';
export const FETCH_LANDLORD_DETAILS_API_REQUESTED = 'LANDLORD/FETCH_LANDLORD_DETAILS_API_REQUESTED';
export const FETCH_LANDLORD_DETAILS_API_SUCCESS = 'LANDLORD/FETCH_LANDLORD_DETAILS_API_SUCCESS';
export const FETCH_LANDLORD_DETAILS_API_FAILED = 'LANDLORD/FETCH_LANDLORD_DETAILS_API_FAILED';
export const UPDATE_LANDLORD_DETAILS_STORE_KEY = 'LANDLORD/UPDATE_LANDLORD_DETAILS_STORE_KEY';
export const UPDATE_LANDLORD_DETAILS_API_REQUESTED = 'LANDLORD/UPDATE_LANDLORD_DETAILS_API_REQUESTED';
export const UPDATE_LANDLORD_DETAILS_API_SUCCESS = 'LANDLORD/UPDATE_LANDLORD_DETAILS_API_SUCCESS';
export const UPDATE_LANDLORD_DETAILS_API_FAILED = 'LANDLORD/UPDATE_LANDLORD_DETAILS_API_FAILED';

export function fetchLandlordDetailsRequested(landlordId: string) {
  return {
    type: FETCH_LANDLORD_DETAILS_API_REQUESTED,
    payload: landlordId
  };
}

export function fetchLandlordDetailsSuccess() {
  return {
    type: FETCH_LANDLORD_DETAILS_API_SUCCESS
  };
}

export function fetchLandlordDetailsFailed(error: string) {
  return {
    type: FETCH_LANDLORD_DETAILS_API_FAILED,
    payload: error
  };
}

export function updateLandlordDetailsApiRequested(landlordId: string, landlord: LandlordDetailsForm) {
  return {
    type: UPDATE_LANDLORD_DETAILS_API_REQUESTED,
    payload: {
      id: landlordId,
      landlord
    }
  };
}

export function updateLandlordDetailsApiSuccess() {
  return {
    type: UPDATE_LANDLORD_DETAILS_API_SUCCESS
  };
}

export function updateLandlordDetailsApiError() {
  return {
    type: UPDATE_LANDLORD_DETAILS_API_FAILED
  };
}

export type LandlordActionTypes = ReturnType<
  | typeof fetchLandlordDetailsRequested
  | typeof fetchLandlordDetailsSuccess
  | typeof fetchLandlordDetailsFailed
  | typeof updateLandlordDetailsApiRequested
  | typeof updateLandlordDetailsApiSuccess
  | typeof updateLandlordDetailsApiError
>;

import { denormalize } from 'normalizr';
import { get } from 'lodash';

import { AppState } from '../root.reducer';
import SCHEMA from '../schema';
import { getCurrentUserId } from '../account/account.selectors';
import { UserEntity } from '../entities/entities.types';

export const getCurrentUser = (state: AppState): UserEntity | undefined => {
  const currentUserId = getCurrentUserId(state);
  if (!state.entities.user) return undefined;
  return denormalize(state.entities.user[currentUserId], SCHEMA.user, state.entities);
};

export const getIsAboutYouComplete = (state: AppState): boolean => {
  const currentUser = getCurrentUser(state);
  return get(currentUser, 'attributes.aboutYouComplete', false);
};

export const getIsAcceptedTermsComplete = (state: AppState): boolean => {
  const currentUser = getCurrentUser(state);
  return get(currentUser, 'attributes.acceptedTerms', false);
};

export const getIsOnboardingComplete = (state: AppState): boolean => {
  const currentUser = getCurrentUser(state);
  return get(currentUser, 'attributes.completedOnboarding', false);
};

interface GetUserById {
  userId: string;
}

export const getUserById = (state: AppState, { userId }: GetUserById): UserEntity => {
  return state.entities.user[userId];
};

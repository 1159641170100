import { createGlobalStyle } from 'styled-components';
import { setConfiguration } from 'react-grid-system';
import reset from 'styled-reset';

type Dictionary = { [key: string]: number };

const breakpoints: Dictionary = {
  sm: 576,
  md: 768,
  lg: 1200,
};

export const getBreakpoint = (breakpoint: string): string => {
  return `${breakpoints[breakpoint]}px`;
};

export const GlobalStyle = createGlobalStyle`
  ${reset}

  html, body {
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
  }

  body {
    background: #FAF9FC;
    font-size: 16px;

    @media screen and (max-width: ${getBreakpoint('md')}) {
      font-size: 14px;
    }

    @media screen and (max-width: ${getBreakpoint('sm')}) {
      font-size: 13px;
    }
  }

  a {
    text-decoration:none;
  }



  .route-wrapper {
    width: 100%;
    position: relative;
  }

  .route-wrapper > div {
    width: 100%;
    position: absolute;
  }

  .tooltip {  
    opacity: 1 !important;
    padding: 0;


  }

  .__react_component_tooltip.place-top:before,  .__react_component_tooltip.place-top:after {
    display: none !important;
    opacity: 0 !important;
  }


`;

setConfiguration({
  breakpoints: [breakpoints.sm, breakpoints.md, breakpoints.lg],
  containerWidths: [540, 750, 960],
});

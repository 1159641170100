import { createAction } from '@reduxjs/toolkit';
import { FetchOrganizationBankAccounts } from './organization-bank-account.types';

export const FETCH_ORG_BANK_ACCOUNTS_STORE_KEY = 'ORG_BANK_ACCOUNTS/FETCH_ORG_BANK_ACCOUNTS';

export const fetchOrganizationBankAccountsRequested = createAction<FetchOrganizationBankAccounts>(
  `${FETCH_ORG_BANK_ACCOUNTS_STORE_KEY}_API_REQUESTED`
);
export const fetchOrganizationBankAccountsSuccess = createAction<string[]>(`${FETCH_ORG_BANK_ACCOUNTS_STORE_KEY}_API_SUCCESS`);
export const fetchOrganizationBankAccountsFailed = createAction<string[]>(`${FETCH_ORG_BANK_ACCOUNTS_STORE_KEY}_API_FAILED`);

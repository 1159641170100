import React from 'react';
import styled from 'styled-components';
import repositLogo from '../../assets/png/icon-full-colour.png';

const StyledWrapper = styled.div`
  align-items: center;
  display: flex;
  padding: 0 24px;
`;

const Avatar = styled.img`
  width: 32px;
`;

const WelcomeMessage = styled.p`
  color: ${(props) => props.theme.colors.body};
  font-family: ${(props) => props.theme.typography.face.primary};
  font-size: 16px;
  margin: 0 0 0 18px;
  max-width: 150px;
  overflow: hidden;

  span {
    font-weight: bold;
  }
`;

export interface CurrentUserProps {
  name: string;
}

export default ({ name }: CurrentUserProps) => (
  <StyledWrapper>
    <Avatar src={repositLogo} alt="Reposit Logo" />
    <WelcomeMessage>
      Hi <span>{name}</span>
    </WelcomeMessage>
  </StyledWrapper>
);

import { denormalize } from 'normalizr';
import { AppState } from '../root.reducer';
import SCHEMA from '../schema';
import { LandlordDTO } from '@reposit/api-client';

export const getLandlordsSelector = (state: AppState) => state.entities.landlord;

export const getLandlord = (state: AppState, landlordId: string) => {
  if (!landlordId) return null;
  const landlord = denormalize(state.entities.landlord[landlordId], SCHEMA.landlord, state.entities.landlord);
  return { ...landlord } as LandlordDTO;
};

import { ClaimItemDTO, ClaimProposalDTO } from '@reposit/api-client';
import moment from 'moment';
import React from 'react';
import { NumberFormat } from '../../constants/number-formats';
import { truncateFilename } from '../../utils/common.utils';
import { Td, Tr } from '../Table';
import { A } from '../Typography';
import numeral from 'numeral';

interface IndividualItemProps {
  item: ClaimItemDTO;
  showDeletedAt: boolean;
  proposals: ClaimProposalDTO[];
}

const FIRST_ROUND = 1;

const IndividualItem: React.FC<IndividualItemProps> = ({ item, showDeletedAt, proposals }) => {
  const firstProposal = proposals.find((p) => p.round === FIRST_ROUND);
  if (!firstProposal) return null;
  const itemProposal = firstProposal.itemProposals.find((i) => i.claimItemId === item.id);
  const amount = item.deletedAt ? item.originalAmount : itemProposal && itemProposal.amount;
  return (
    <Tr key={item.id}>
      <Td>{item.id}</Td>
      <Td>{item.description}</Td>
      <Td>{item.type}</Td>
      <Td>{amount ? `£${numeral(amount / 100).format(NumberFormat.THOUSANDS_PENCE)}` : '-'}</Td>
      <Td>
        {item.claimItemDocuments
          ? item.claimItemDocuments.map(({ document }) => (
              <React.Fragment key={document.id}>
                <A target="_blank" href={document.url}>
                  {truncateFilename(document.name)}
                </A>
                <br />
              </React.Fragment>
            ))
          : 'No evidence provided'}
      </Td>
      {showDeletedAt && <Td>{item.deletedAt ? moment(item.deletedAt).format('DD/MM/YYYY') : '-'}</Td>}
    </Tr>
  );
};

export default IndividualItem;

import { put, takeLatest, select, call } from 'redux-saga/effects';
import { SearchTenancyOrderResultsDTO, TenancyOrdersApi } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';

import SCHEMA from '../schema';
import {
  RepositListActionTypes,
  FETCH_REPOSITS_API_REQUESTED,
  fetchRepositsSuccess,
  fetchRepositsFailed,
} from './reposit-list.actions';
import { getRepositListFilters, getRepositListPagination } from './reposit-list.selectors';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import { getCurrentOrganizationId } from '../account/account.selectors';
import { runSagaWithAuth, createTenancyOrdersApi } from '../utils/api.utils';
import { RepositListFilters, FetchRepositListPayload } from './reposit-list.types';
import { RepositSort, LetOnlyFilter } from '../../constants/reposit';

export const REPOSIT_LIST_PAGE_SIZE = 10;

// ****************
// WORKERS
// ****************

export function* fetchRepositList({ payload }: { type: string; payload: FetchRepositListPayload }) {
  try {
    const filters: RepositListFilters = yield select(getRepositListFilters);
    const currentOrganizationId: string = yield select(getCurrentOrganizationId);
    const pagination = yield select(getRepositListPagination);
    const tenancyOrdersApi: TenancyOrdersApi = yield createTenancyOrdersApi();
    const orgId = payload.organizationId || currentOrganizationId;

    type SortField = 'START_DATE' | 'END_DATE' | 'CREATED_AT';
    type SortDirection = 'ASC' | 'DESC';
    const resolveSort = (): { sortField: SortField; sortDirection: SortDirection } => {
      switch (filters.sort) {
        case RepositSort.END_DATE_ASCENDING:
          return {
            sortField: 'END_DATE',
            sortDirection: 'ASC',
          };
        case RepositSort.END_DATE_DESCENDING:
          return {
            sortField: 'END_DATE',
            sortDirection: 'DESC',
          };
        case RepositSort.START_DATE_ASCENDING:
          return {
            sortField: 'START_DATE',
            sortDirection: 'ASC',
          };
        case RepositSort.START_DATE_DESCENDING:
          return {
            sortField: 'START_DATE',
            sortDirection: 'DESC',
          };
        case RepositSort.CREATED_AT_ASCENDING:
          return {
            sortField: 'CREATED_AT',
            sortDirection: 'ASC',
          };
        case RepositSort.CREATED_AT_DESCENDING:
          return {
            sortField: 'CREATED_AT',
            sortDirection: 'DESC',
          };
        default:
          return {
            sortField: 'CREATED_AT',
            sortDirection: 'DESC',
          };
      }
    };

    const resolveLetOnly = () => {
      switch (filters.letOnly) {
        case LetOnlyFilter.YES:
          return true;
        case LetOnlyFilter.NO:
          return false;
        default:
          return undefined;
      }
    };

    const sort = resolveSort();
    const letOnly = resolveLetOnly();

    const apiResponse: AxiosResponse<SearchTenancyOrderResultsDTO> = yield call(
      runSagaWithAuth(() =>
        tenancyOrdersApi.optimisedSearch(
          orgId,
          pagination.page,
          REPOSIT_LIST_PAGE_SIZE,
          filters.includeDeleted,
          filters.onlyDeleted,
          filters.query,
          filters.orderStatus as any,
          filters.tenancyStatus as any,
          sort.sortField,
          sort.sortDirection,
          letOnly
        )
      )
    );

    const ids: string[] = yield syncEntitiesAndGetResults(apiResponse.data.results, SCHEMA.tenancyOrders);
    yield put<RepositListActionTypes>(fetchRepositsSuccess(apiResponse.data.count, ids));
  } catch (e) {
    yield put<RepositListActionTypes>(fetchRepositsFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchRepositListSagas() {
  yield takeLatest(FETCH_REPOSITS_API_REQUESTED, fetchRepositList);
}

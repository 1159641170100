import { createReducer } from '@reduxjs/toolkit';
import { setGuarantorModal } from './guarantor.actions';
import { GuarantorState } from './guarantor.types';

const initialState: GuarantorState = {
  modal: {
    isOpen: false,
    modalState: undefined
  }
};

export default createReducer(initialState, builder => {
  builder.addCase(setGuarantorModal, (state, action) => {
    state.modal = action.payload;
  });
});

import { PaymentPlanDTO, PaymentPlanInstalmentDTOStatusEnum, TenantDTO } from '@reposit/api-client';
import { sortBy, startCase } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import { penceToPounds } from '../../utils/common.utils';
import Button from '../Button';
import InlineTable from '../InlineTable';
import Modal from '../Modal';
import RepositCard from '../Reposit/RepositCard';
import { Table, Tbody, Td, Thead, Tr } from '../Table';
import { Header3 } from '../Typography';

interface PaymentPlanProps {
  paymentPlan: PaymentPlanDTO;
  paymentPlanTenant: TenantDTO;
  manualPaymentsTotal: number;
}

interface PaymentPlanModalProps {
  paymentPlan: PaymentPlanDTO;
  onDismiss: () => void;
}

interface TableItem {
  key: string;
  value: string;
  linkTo?: string;
}

const ActionButton = styled(Button)`
  margin-left: auto;
`;

const formatISOStringDate = (date: string) => moment(date).format('DD-MM-YYYY');
const formatDate = (date: string) => moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');

const PaymentPlanModal: React.SFC<PaymentPlanModalProps> = ({ paymentPlan, onDismiss }) => {
  const { instalments } = paymentPlan;
  const sortedInstalments = sortBy(instalments, (i) => i.dueDate);
  return (
    <Modal onDismiss={onDismiss} scrollable fixHeight>
      <Header3 style={{ marginBottom: 15 }}>Payment Plan Schedule</Header3>
      <Table>
        <Thead>
          <Tr>
            <Td>Number</Td>
            <Td>Due Date</Td>
            <Td>Amount</Td>
            <Td>Status</Td>
          </Tr>
        </Thead>
        <Tbody>
          {sortedInstalments &&
            sortedInstalments.length > 0 &&
            sortedInstalments.map((instalment, i) => (
              <Tr key={instalment.id}>
                <Td>{i + 1}</Td>
                <Td>{formatDate(instalment.dueDate)}</Td>
                <Td>£{penceToPounds(instalment.amount)}</Td>
                <Td>{startCase(instalment.status)}</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </Modal>
  );
};

export const PaymentPlan: React.SFC<PaymentPlanProps> = ({ paymentPlan, paymentPlanTenant, manualPaymentsTotal }) => {
  const [isModalShowing, setIsModalShowing] = useState(false);
  const { createdAt, instalments } = paymentPlan;

  const sortedInstalments = sortBy(instalments, (i) => i.instalmentNumber);
  const firstInstalment = sortedInstalments && sortedInstalments[0];
  const lastInstalment = sortedInstalments && sortedInstalments[sortedInstalments.length - 1];
  const { firstName, lastName, userId } = paymentPlanTenant;
  const mostRecentPaidInstalment =
    instalments &&
    sortBy(
      instalments.filter((i) => i.status === PaymentPlanInstalmentDTOStatusEnum.PAID),
      (i) => i.dueDate
    ).pop();
  const paidInstalments = instalments && instalments.filter((i) => i.status === PaymentPlanInstalmentDTOStatusEnum.PAID);
  const totalPaid = paidInstalments && paidInstalments.reduce((acc, instalment) => acc + instalment.amount, 0);

  const items: TableItem[] = [
    { key: 'Id', value: paymentPlan.id },
    { key: 'Created Date', value: formatISOStringDate(createdAt) },
    { key: 'Tenant', value: `${firstName} ${lastName}`, linkTo: `/users/${userId}` },
    { key: 'Status', value: paymentPlan.status },
    { key: 'Total Paid', value: totalPaid ? `£${penceToPounds(totalPaid)}` : '£0.00' },
    { key: 'Number Of Instalments', value: `${instalments && instalments.length}` },
    { key: 'First Instalment', value: firstInstalment ? formatDate(firstInstalment.dueDate) : '' },
    { key: 'Last Instalment', value: lastInstalment ? formatDate(lastInstalment.dueDate) : '' },
    { key: 'Most Recent Paid Date', value: mostRecentPaidInstalment ? formatDate(mostRecentPaidInstalment.dueDate) : 'NA' },
    { key: 'Total Manual Payments', value: `£${penceToPounds(manualPaymentsTotal)}` },
    { key: 'Frequency', value: paymentPlan.frequency },
    { key: 'Historic Plan', value: `${paymentPlan.isHistoric}` },
    { key: 'Interest Added', value: paymentPlan.interestAmount ? `£${penceToPounds(paymentPlan.interestAmount)}` : '£0.00' },
    { key: 'Plan Total', value: `£${penceToPounds(paymentPlan.amount)}` },
  ];
  return (
    <RepositCard
      flush
      title="Payment Plan"
      headerContent={
        <ActionButton onClick={() => setIsModalShowing(true)} buttonType={'secondary'}>
          View Schedule
        </ActionButton>
      }
    >
      <>
        {isModalShowing ? <PaymentPlanModal paymentPlan={paymentPlan} onDismiss={() => setIsModalShowing(false)} /> : null}
        <InlineTable items={items} />
      </>
    </RepositCard>
  );
};

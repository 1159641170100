import { OrderCustomerDTO, TenantDTO } from '@reposit/api-client';
import { Formik, FormikProps, useFormikContext } from 'formik';
import moment from 'moment';
import React, { Fragment, useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import * as yup from 'yup';
import { PaymentPlanFormValues, MANUAL_PAYMENT_TAKEN, HISTORIC_PAYMENT_PLAN, INTEREST_ADDED } from '.';
import poundIcon from '../../assets/svg/pound-sterling.svg';
import { DATE_FORMAT } from '../../constants/dates';
import { TenantEntity } from '../../redux/entities/entities.types';
import { twoDPRegex } from '../../utils/regex/number.regex';
import Button from '../Button';
import { Input, RadioGroup } from '../FormFields';
import DatePicker from '../FormFields/DatePicker';
import FieldWithLabel from '../FormFields/FieldWithLabel';
import Select from '../FormFields/Select';
import { Header5, P2 } from '../Typography';
import FormErrorMessage from '../FormFields/ErrorMessage';

const EXTRA_INSTALMENT = 1;

interface PaymentPlanFormProps {
  orderCustomers: OrderCustomerDTO[];
  tenants: TenantDTO[];
  totalBalance: number;
  originalAmountRemaining: number;
  onSubmit: (values: PaymentPlanFormValues) => Promise<any>;
  isSubmitting: boolean;
  onDismiss: () => void;
  setManualPaymentsTotal: (total: number) => void;
  setInterestAmount: (total: number) => void;
  interestAmount: number;
}

const Schema = (originalAmountRemaining: number) =>
  yup.object().shape({
    orderCustomerId: yup.string().required('You must choose a tenant to set up a payment plan for'),
    startDate: yup
      .date()
      .typeError('Start date must be a valid date')
      .required('Required')
      .test('startDate', 'The start date must be after the activation date', function (value: Date) {
        const daysToActivate = this.parent.isHistoric === HISTORIC_PAYMENT_PLAN.YES ? 14 : 7;
        const earliestStartDate = daysToActivate + 1;
        const minDate = moment().startOf('day').add(earliestStartDate, 'days');
        return moment(value).isSameOrAfter(minDate);
      }),
    manualPaymentsTaken: yup.string(),
    isHistoric: yup.string(),
    manualPaymentsTotal: yup
      .number()
      .min(0.01, 'Amount must be above 0')
      .test('manual-payments', 'Total must be less than the amount remaining', function (value: number) {
        if (this.parent.manualPaymentsTaken === MANUAL_PAYMENT_TAKEN.NO) return true;
        if (!originalAmountRemaining) return true;
        return value * 100 < originalAmountRemaining;
      })
      .test('two-decimals', 'Amount must have a maximum of two decimal places', function (value) {
        if (this.parent.manualPaymentsTaken === MANUAL_PAYMENT_TAKEN.NO) return true;
        return twoDPRegex.test(value);
      }),
    amount: yup
      .number()
      .required('You must choose an amount')
      .min(0.01, 'Amount must be above 0')
      .test('two-decimals', 'Item amount must have a maximum of two decimal places', (value) => {
        return twoDPRegex.test(value);
      }),
    instalments: yup.number().min(0, 'Must have at least one instalment').required(),
    interestAdded: yup.string(),
    interestAmount: yup
      .number()
      .min(0.01, 'Amount must be above 0')
      .test('two-decimals', 'Amount must have a maximum of two decimal places', function (value) {
        if (this.parent.interestAdded === INTEREST_ADDED.NO) return true;
        return twoDPRegex.test(value);
      }),
  });

const PaymentPlanForm: React.FC<PaymentPlanFormProps> = ({
  tenants,
  orderCustomers,
  totalBalance,
  onSubmit,
  isSubmitting,
  onDismiss,
  setManualPaymentsTotal,
  setInterestAmount,
  originalAmountRemaining,
  interestAmount,
}) => {
  const firstTenant = tenants && tenants[0];
  const foundOC = orderCustomers && orderCustomers.find((oc) => oc.customer.userId === firstTenant.userId);
  const initialValues: PaymentPlanFormValues = {
    orderCustomerId: foundOC ? foundOC.id : '',
    startDate: moment().startOf('day').add(8, 'days').toDate(),
    manualPaymentsTaken: MANUAL_PAYMENT_TAKEN.NO,
    isHistoric: HISTORIC_PAYMENT_PLAN.NO,
    manualPaymentsTotal: undefined,
    amount: 0,
    instalments: 0,
    interestAdded: INTEREST_ADDED.NO,
    interestAmount: undefined,
  };

  const interestPenceValue = interestAmount * 100;
  const finalBalance = totalBalance + interestPenceValue;

  const renderTenantOptions = (tenants: TenantEntity[]): JSX.Element[] => {
    return tenants.map((t) => {
      const oc = orderCustomers.find((oc) => oc.customer.userId === t.userId);
      return (
        <option key={t.id} value={oc ? oc.id : ''}>
          {t.firstName} {t.lastName}
        </option>
      );
    });
  };

  // could just do this in the onChange
  // but thought it would be good to have this example in the codebase
  const ClearValuesOnManualPaymentChange = () => {
    const {
      setFieldValue,
      values: { manualPaymentsTotal },
      isSubmitting,
    } = useFormikContext<PaymentPlanFormValues>();
    useEffect(() => {
      if (manualPaymentsTotal && !isSubmitting) {
        setFieldValue('amount', 0);
        setFieldValue('instalments', 0);
      }
    }, [setFieldValue, manualPaymentsTotal, isSubmitting]);
    return null;
  };

  const ClearValuesOnInterestAmountChange = () => {
    const {
      setFieldValue,
      values: { interestAmount },
      isSubmitting,
    } = useFormikContext<PaymentPlanFormValues>();
    useEffect(() => {
      if (interestAmount && !isSubmitting) {
        setFieldValue('amount', 0);
        setFieldValue('instalments', 0);
      }
    }, [setFieldValue, interestAmount, isSubmitting]);
    return null;
  };

  return (
    <Formik initialValues={initialValues} validationSchema={Schema(originalAmountRemaining)} onSubmit={onSubmit}>
      {({ values, handleSubmit, handleBlur, touched, errors, setFieldValue }: FormikProps<PaymentPlanFormValues>) => {
        return (
          <form onSubmit={handleSubmit}>
            <Container fluid>
              <ClearValuesOnManualPaymentChange />
              <ClearValuesOnInterestAmountChange />
              <Row>
                <Col lg={12} style={{ padding: 0 }}>
                  <FieldWithLabel label="Tenant*">
                    <Select
                      onChange={(orderCustomerId: string) => {
                        setFieldValue('orderCustomerId', orderCustomerId);
                      }}
                      value={values.orderCustomerId}
                    >
                      {renderTenantOptions(tenants)}
                    </Select>
                  </FieldWithLabel>
                </Col>
              </Row>
              <Row>
                <Header5 style={{ marginTop: 5, marginBottom: 5 }}>Have any off platform payments been taken so far?*</Header5>
              </Row>
              <Row>
                <P2 style={{ marginBottom: 6 }}>
                  Off platform payments are monies collected outside of the platform, for example, a bank transfer. The amount you
                  enter will be deducted from the total amount remaining
                </P2>
              </Row>
              <Row>
                <Fragment>
                  <RadioGroup
                    name="manualPaymentsTaken"
                    // has to be string here annoyingly
                    options={[
                      { value: MANUAL_PAYMENT_TAKEN.YES, label: 'Yes' },
                      { value: MANUAL_PAYMENT_TAKEN.NO, label: 'No' },
                    ]}
                    onChange={(e) => {
                      setFieldValue('manualPaymentsTaken', e.target.value);
                      if (e.target.value === MANUAL_PAYMENT_TAKEN.NO) {
                        setFieldValue('manualPaymentsTotal', undefined);
                        setManualPaymentsTotal(0);
                      }
                    }}
                    onBlur={handleBlur}
                    error={errors.manualPaymentsTaken}
                    touched={touched.manualPaymentsTaken}
                    selected={values.manualPaymentsTaken}
                  />
                  {values.manualPaymentsTaken === MANUAL_PAYMENT_TAKEN.YES ? (
                    <div style={{ marginLeft: 20, flexGrow: 1 }}>
                      <Input
                        name="manualPaymentsTotal"
                        type="number"
                        icon={poundIcon}
                        error={errors.manualPaymentsTotal}
                        touched={touched.manualPaymentsTotal}
                        onChange={(e) => {
                          setFieldValue('manualPaymentsTotal', e.target.valueAsNumber);
                          setManualPaymentsTotal(e.target.valueAsNumber);
                        }}
                        value={values.manualPaymentsTotal ? values.manualPaymentsTotal : ''}
                        onBlur={handleBlur}
                      />
                      {errors.manualPaymentsTotal && touched.manualPaymentsTotal ? (
                        <FormErrorMessage error={errors.manualPaymentsTotal} />
                      ) : null}
                    </div>
                  ) : null}
                </Fragment>
              </Row>

              <Row>
                <Header5 style={{ marginTop: 5, marginBottom: 5 }}>Is there any interest to be added?*</Header5>
              </Row>
              <Row>
                <P2 style={{ marginBottom: 6 }}>
                  Interest to be added to the payment plan. This will be added to the total amount remaining, but will not be
                  payable if the tenant chooses to pay off the balance early.
                </P2>
              </Row>
              <Row>
                <Fragment>
                  <RadioGroup
                    name="interestAdded"
                    // has to be string here annoyingly
                    options={[
                      { value: INTEREST_ADDED.YES, label: 'Yes' },
                      { value: INTEREST_ADDED.NO, label: 'No' },
                    ]}
                    onChange={(e) => {
                      setFieldValue('interestAdded', e.target.value);
                      if (e.target.value === INTEREST_ADDED.NO) {
                        setFieldValue('interestAmount', undefined);
                      }
                    }}
                    onBlur={handleBlur}
                    error={errors.interestAdded}
                    touched={touched.interestAdded}
                    selected={values.interestAdded}
                  />
                  {values.interestAdded === INTEREST_ADDED.YES ? (
                    <div style={{ marginLeft: 20, flexGrow: 1 }}>
                      <Input
                        name="interestAmount"
                        type="number"
                        icon={poundIcon}
                        error={errors.interestAmount}
                        touched={touched.interestAmount}
                        onChange={(e) => {
                          setFieldValue('interestAmount', e.target.valueAsNumber || 0);
                          setInterestAmount(e.target.valueAsNumber || 0);
                        }}
                        value={values.interestAmount ? values.interestAmount : ''}
                        onBlur={handleBlur}
                      />
                      {errors.interestAmount && touched.interestAmount ? (
                        <FormErrorMessage error={errors.interestAmount} />
                      ) : null}
                    </div>
                  ) : null}
                </Fragment>
              </Row>

              <Row>
                <Header5 style={{ marginTop: 5, marginBottom: 5 }}>Is this a historic payment plan?*</Header5>
              </Row>
              <Row>
                <P2 style={{ marginBottom: 6 }}>
                  Tenants are given longer to activate historic payment plans and won't receive any automated email communications
                  until the plan is activated
                </P2>
              </Row>
              <Row>
                <Fragment>
                  <RadioGroup
                    name="isHistoric"
                    // has to be string here annoyingly
                    options={[
                      { value: HISTORIC_PAYMENT_PLAN.YES, label: 'Yes' },
                      { value: HISTORIC_PAYMENT_PLAN.NO, label: 'No' },
                    ]}
                    onChange={(e) => {
                      setFieldValue('isHistoric', e.target.value);
                      if (!touched.startDate) {
                        if (e.target.value === HISTORIC_PAYMENT_PLAN.YES) {
                          setFieldValue('startDate', moment().startOf('day').add(15, 'days').toDate());
                        } else {
                          setFieldValue('startDate', moment().startOf('day').add(8, 'days').toDate());
                        }
                      }
                    }}
                    onBlur={handleBlur}
                    error={errors.isHistoric}
                    touched={touched.isHistoric}
                    selected={values.isHistoric}
                  />
                </Fragment>
              </Row>

              <Row>
                <Col lg={12} style={{ padding: 0 }}>
                  <FieldWithLabel label="Start Date*">
                    <DatePicker
                      name="startDate"
                      value={values.startDate}
                      dateFormat={DATE_FORMAT}
                      onBlur={handleBlur}
                      touched={touched.startDate}
                      placeholder={'DD/MM/YYYY'}
                      error={errors.startDate}
                      minDate={
                        values.isHistoric === HISTORIC_PAYMENT_PLAN.YES
                          ? moment().startOf('day').add(15, 'days').toDate()
                          : moment().startOf('day').add(8, 'days').toDate()
                      }
                      onChange={(date) => {
                        setFieldValue('startDate', date);
                      }}
                    />
                    {errors.startDate && touched.startDate ? <FormErrorMessage error={errors.startDate} /> : null}
                  </FieldWithLabel>
                </Col>
              </Row>
              <Row>
                <Col lg={12} style={{ padding: 0 }}>
                  <FieldWithLabel label="Amount per instalment*" error={errors.amount} touched={touched.amount}>
                    <Input
                      name="amount"
                      type="number"
                      icon={poundIcon}
                      error={errors.amount}
                      touched={touched.amount}
                      onChange={(e) => {
                        const calculatedInstalments = calculateInstalmentsFromAmount(e.target.valueAsNumber * 100, finalBalance);
                        setFieldValue('instalments', calculatedInstalments);
                        setFieldValue('amount', e.target.valueAsNumber ? e.target.valueAsNumber : 0);
                      }}
                      value={values.amount ? values.amount : ''}
                      onBlur={handleBlur}
                    />
                  </FieldWithLabel>
                </Col>
              </Row>
              <Row>
                <Col lg={12} style={{ padding: 0 }}>
                  <FieldWithLabel label="Number of instalments" error={errors.instalments} touched={touched.instalments}>
                    <Input
                      name="instalments"
                      type="number"
                      error={errors.instalments}
                      touched={touched.instalments}
                      onChange={(e) => {
                        const calculatedAmount = calculateAmountFromInstalments(e.target.valueAsNumber, finalBalance);
                        setFieldValue('amount', calculatedAmount);
                        setFieldValue('instalments', e.target.valueAsNumber ? e.target.valueAsNumber : 0);
                      }}
                      value={`${values.instalments}`}
                      onBlur={handleBlur}
                    />
                  </FieldWithLabel>
                </Col>
              </Row>

              <Row style={{ display: 'flex', justifyContent: 'flex-end', columnGap: '8px', paddingTop: '24px' }}>
                <Button buttonType="secondary" noArrow={true} onClick={onDismiss}>
                  Cancel
                </Button>
                <Button disabled={isSubmitting} type="submit">
                  Confirm
                </Button>
              </Row>
            </Container>
          </form>
        );
      }}
    </Formik>
  );
};

export default PaymentPlanForm;

const calculateInstalmentsFromAmount = (amount: number, totalBalance: number) => {
  if (!amount) {
    return 0;
  }
  const remainder = totalBalance % amount;
  const quotient = Math.floor(totalBalance / amount);

  return remainder ? quotient + EXTRA_INSTALMENT : quotient;
};

const calculateAmountFromInstalments = (instalments: number, totalBalance: number) => {
  if (!instalments) {
    return 0;
  }
  const monthlyAmount = totalBalance / instalments / 100;
  return Number(monthlyAmount.toFixed(2));
};

import React, { useCallback, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import { Action } from '../../../components/Reposit/RepositSummary/styles';
import { Header2, Header3, Header4 } from '../../../components/Typography';
import { createErrorMessageSelector } from '../../../redux/error/error.selector';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import { FullPageLoader } from '../../../components/Loading';
import {
  fetchCompletedRepositsRequested,
  FETCH_COMPLETED_REPOSITS_STORE_KEY,
} from '../../../redux/internal-reports/internal-reports.actions';

const Wrapper = styled.div`
  margin-top: 1em;
`;

export const CompletedRepositsReport = () => {
  const dispatch = useDispatch();
  const [isModalShowing, setIsModalShowing] = useState(false);

  const errorSelect = createErrorMessageSelector([FETCH_COMPLETED_REPOSITS_STORE_KEY]);
  const error = useSelector(errorSelect);
  const isLoadingSelector = createLoadingSelector([FETCH_COMPLETED_REPOSITS_STORE_KEY]);
  const isLoading = useSelector(isLoadingSelector);

  const onClick = useCallback(() => {
    setIsModalShowing(true);
  }, []);

  if (isLoading) return <FullPageLoader />;

  return (
    <Container>
      {isModalShowing && (
        <Modal onDismiss={() => setIsModalShowing(false)}>
          <>
            <Header3>Download completed Reposits report?</Header3>
            <Header4>This will download a CSV file</Header4>
            <Action>
              <Button buttonType="secondary" noArrow={true} onClick={() => setIsModalShowing(false)}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setIsModalShowing(false);
                  dispatch(fetchCompletedRepositsRequested());
                }}
              >
                Download
              </Button>
            </Action>
          </>
        </Modal>
      )}
      <Row>
        <Col sm={12}>
          <Header2>Completed Reposits Report</Header2>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Header4>Please click below to download a csv file containing a report of all completed Reposits</Header4>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Wrapper>
            <Button onClick={onClick} buttonType="secondary" disabled={isLoading}>
              Download
            </Button>
          </Wrapper>
        </Col>
      </Row>
      {error && (
        <Row>
          <Col sm={12}>
            <Wrapper>
              <Header4>Oh no something went wrong</Header4>
            </Wrapper>
          </Col>
        </Row>
      )}
    </Container>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClaimsApi } from '@reposit/api-client';
import { FlashState } from '../../components/FlashMessage';
import { getErrorMessage } from '../../utils/common.utils';
import { fetchClaimRequested, WAIVE_CLAIM_DISPUTE_FEE_STORE_KEY, GENERATE_ADR_FORM_STORE_KEY } from '../claim/claim.actions';
import { setFlashMessage } from '../flash-messages/flash-messages.actions';
import { AppState } from '../root.reducer';
import { createStandardClaimsApi, runThunkWithAuth } from '../utils/api.utils';
import { WaiveClaimDisputeFeePayload, CreateADRFormPayload } from './claim.types';

export const waiveClaimDisputeFeeThunk = createAsyncThunk<
  void,
  WaiveClaimDisputeFeePayload,
  {
    state: AppState;
  }
>('payment-plan/create', async (payload, thunkAPI) => {
  const state = thunkAPI.getState();
  const dispatch = thunkAPI.dispatch;
  try {
    const api: ClaimsApi = createStandardClaimsApi(state);
    const { claimId } = payload;

    await runThunkWithAuth(() => api.waiveDisputeFee(claimId), dispatch);

    dispatch(fetchClaimRequested({ claimId }));
    dispatch(
      setFlashMessage({
        key: WAIVE_CLAIM_DISPUTE_FEE_STORE_KEY,
        message: `Success! Dispute fee waived for ${claimId}`,
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch(
      setFlashMessage({
        key: WAIVE_CLAIM_DISPUTE_FEE_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
});

export const generateADRFormThunk = createAsyncThunk<
  void,
  CreateADRFormPayload,
  {
    state: AppState;
  }
>('claim/adr-form', async (payload, thunkAPI) => {
  const state = thunkAPI.getState();
  const dispatch = thunkAPI.dispatch;
  try {
    const api: ClaimsApi = createStandardClaimsApi(state);
    const { claimId } = payload;

    await runThunkWithAuth(() => api.generateADRForm(claimId), dispatch);

    dispatch(fetchClaimRequested({ claimId }));
    dispatch(
      setFlashMessage({
        key: GENERATE_ADR_FORM_STORE_KEY,
        message: `Success! ADR form for ${claimId} will be sent to Slack`,
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch(
      setFlashMessage({
        key: GENERATE_ADR_FORM_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
});

import {
  AddressDTO,
  AddressesApi,
  CheckoutDTO,
  CreateAddressWithOrgDTO,
  CreateTenancyInviteDTORoleIdEnum,
  CreateTenancyRequestDTO,
  TenanciesApi,
  TenancyCheckoutsApi,
  TenancyDTO,
  TenancyExpiriesApi,
  TenancyInvitesApi,
  TenancyOrderDTO,
  TenancyOrderNoteDTO,
  TenancyOrderNotesApi,
  TenancyOrdersApi,
  TenancyOrderWithTenancyAndOrderCustomersDTO,
  TenantsApi,
} from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import { get } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { FlashState } from '../../components/FlashMessage/index';
import { getErrorMessage } from '../../utils/common.utils';
import { addAddressToOrganizationAddresses, AddressesActionTypes, setIsShowingSearchModal } from '../address/address.actions';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import { getTenancyById } from '../entities/entities.selectors';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import SCHEMA from '../schema';
import {
  createAddressesApi,
  createTenanciesApi,
  createTenancyCheckoutsApi,
  createTenancyExpiriesApi,
  createTenancyInviteApi,
  createTenancyOrderNotesApi,
  createTenancyOrdersApi,
  createTenantsApi,
  runSagaWithAuth,
} from '../utils/api.utils';
import {
  addRepositNoteFailed,
  addRepositNoteSuccess,
  AddressForm,
  addTenantOnRepositFailed,
  addTenantOnRepositSuccess,
  ADD_REPOSIT_NOTE_API_REQUESTED,
  ADD_REPOSIT_NOTE_STORE_KEY,
  ADD_TENANT_ON_REPOSIT_API_REQUESTED,
  ADD_TENANT_ON_REPOSIT_STORE_KEY,
  checkoutRepositFailed,
  checkoutRepositSuccess,
  CHECKOUT_REPOSIT_API_REQUESTED,
  CHECKOUT_REPOSIT_STORE_KEY,
  createAddressForRepositFailed,
  createAddressForRepositSuccess,
  createTenancyWithPropertyFailed,
  createTenancyWithPropertySuccess,
  createTenantFailed,
  createTenantSuccess,
  CREATE_ADDRESS_API_REQUESTED,
  CREATE_TENANCY_WITH_PROPERTY_API_REQUESTED,
  CREATE_TENANCY_WITH_PROPERTY_STORE_KEY,
  CREATE_TENANT_API_REQUESTED,
  CREATE_TENANT_STORE_KEY,
  deleteRepositNoteFailed,
  deleteRepositNoteSuccess,
  deleteTenantFailed,
  deleteTenantSuccess,
  DELETE_REPOSIT_NOTE_API_REQUESTED,
  DELETE_REPOSIT_NOTE_STORE_KEY,
  DELETE_TENANT_API_REQUESTED,
  DELETE_TENANT_STORE_KEY,
  disableTopUpsFailed,
  disableTopUpsSuccess,
  DISABLE_TOP_UPS_API_REQUESTED,
  DISABLE_TOP_UPS_STORE_KEY,
  discardRepositFailed,
  discardRepositSuccess,
  DISCARD_REPOSIT_API_REQUESTED,
  DISCARD_REPOSIT_STORE_KEY,
  extendOutcomeDeadlineFailed,
  extendOutcomeDeadlineSuccess,
  extendRepositFailed,
  extendRepositSuccess,
  EXTEND_OUTCOME_DEADLINE_API_REQUESTED,
  EXTEND_OUTCOME_DEADLINE_STORE_KEY,
  EXTEND_REPOSIT_API_REQUESTED,
  EXTEND_REPOSIT_STORE_KEY,
  fetchTenancyOrderFailed,
  fetchTenancyOrderRequested,
  fetchTenancyOrderSuccess,
  FETCH_TENANCY_ORDER_API_REQUESTED,
  publishRepositFailed,
  publishRepositSuccess,
  PUBLISH_REPOSIT_API_REQUESTED,
  PUBLISH_REPOSIT_STORE_KEY,
  removeTenantOnRepositFailed,
  removeTenantOnRepositSuccess,
  REMOVE_TENANT_ON_REPOSIT_API_REQUESTED,
  REMOVE_TENANT_ON_REPOSIT_STORE_KEY,
  reopenRepositFailed,
  reopenRepositSuccess,
  REOPEN_REPOSIT_API_REQUESTED,
  REOPEN_REPOSIT_STORE_KEY,
  RepositActionTypes,
  resendTenantInviteFailed,
  resendTenantInviteSuccess,
  RESEND_TENANT_INVITE_API_REQUESTED,
  RESEND_TENANT_INVITE_STORE_KEY,
  setCurrentRepositActionModal,
  setIsPropertyFormOpen,
  setIsTenantFormOpen,
  setIsTransferModalOpen,
  setIsUpdatePropertyModalOpen,
  setRepositCurrentAddressForm,
  setSelectedAddressId,
  transferRepositFailed,
  transferRepositSuccess,
  TRANSFER_REPOSIT_API_REQUESTED,
  TRANSFER_REPOSIT_STORE_KEY,
  updatePropertyFailed,
  updatePropertySuccess,
  updateTenancyFailed,
  updateTenancySuccess,
  UPDATE_PROPERTY_API_REQUESTED,
  UPDATE_PROPERTY_STORE_KEY,
  UPDATE_TENANCY_API_REQUESTED,
  UPDATE_TENANCY_STORE_KEY,
  updateTenantDetailsSuccess,
  setTenantEditFormOpenStatus,
  UPDATE_TENANT_DETAILS_STORE_KEY,
  updateTenantDetailsFailed,
  UPDATE_TENANT_DETAILS_API_REQUESTED,
} from './reposit.actions';
import {
  AddRepositNotePayload,
  AddTenantPayload,
  CreateTenantPayload,
  DeleteRepositNotePayload,
  DeleteTenantPayload,
  ExtendRepositPayload,
  RemoveTenantPayload,
  ReopenRepositPayload,
  ResendTenantInterfacePayload,
  TransferRepositRequestedPayload,
  UpdatePropertyPayload,
  UpdateTenancyPayload,
  UpdateTenantDetailsPayload,
} from './reposit.types';

// ****************
// WORKERS
// ****************

export function* createTenancyWithProperty({ payload }: { type: string; payload: CreateTenancyRequestDTO }) {
  try {
    const tenanciesApi: TenanciesApi = yield createTenanciesApi();
    const apiResponse: AxiosResponse<TenancyDTO> = yield call(runSagaWithAuth(() => tenanciesApi.createTenancy(payload)));
    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.tenancy);
    yield put<RepositActionTypes>(createTenancyWithPropertySuccess());
    yield put<RepositActionTypes>(setIsPropertyFormOpen(false));
    yield put<RepositActionTypes>(setIsUpdatePropertyModalOpen(false));
    const tenancyOrderId = get(apiResponse.data, 'orders.0.id');

    yield put(push(`/reposits/${tenancyOrderId}`));

    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: CREATE_TENANCY_WITH_PROPERTY_STORE_KEY,
        // SPECIFICALLY MENTIONING PROPERTY HERE FOR CLARITY TO USER
        message: 'Success! Property details have been added.',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: CREATE_TENANCY_WITH_PROPERTY_STORE_KEY, message: error, state: FlashState.ERROR })
    );
    yield put<RepositActionTypes>(createTenancyWithPropertyFailed(error));
  }
}

export function* updateTenancy({ payload }: { type: string; payload: UpdateTenancyPayload }) {
  try {
    const tenanciesApi: TenanciesApi = yield createTenanciesApi();
    const apiResponse: AxiosResponse<TenancyDTO> = yield call(
      runSagaWithAuth(() => tenanciesApi.updateTenancy(payload.tenancyId, payload.updates))
    );
    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.tenancy);
    yield put<RepositActionTypes>(updateTenancySuccess());
    yield put<RepositActionTypes>(fetchTenancyOrderRequested(payload.tenancyOrderId));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_TENANCY_STORE_KEY,
        message: 'Success! Tenancy details have been updated.',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: UPDATE_TENANCY_STORE_KEY, message: error, state: FlashState.ERROR })
    );
    yield put<RepositActionTypes>(updateTenancyFailed(error));
  }
}

export function* fetchTenancyOrderById({ payload }: { type: string; payload: string }) {
  try {
    const tenancyOrdersApi: TenancyOrdersApi = yield createTenancyOrdersApi();
    const apiResponse: AxiosResponse<TenancyDTO> = yield call(
      runSagaWithAuth(() => tenancyOrdersApi.getTenancyOrderById(payload, true))
    );

    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.tenancyOrder);
    yield put<RepositActionTypes>(fetchTenancyOrderSuccess());
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<RepositActionTypes>(fetchTenancyOrderFailed(error));
  }
}

export function* createTenant({ payload }: { type: string; payload: CreateTenantPayload }) {
  try {
    const tenancyInviteApi: TenancyInvitesApi = yield createTenancyInviteApi();
    const apiResponse: AxiosResponse<TenancyDTO> = yield call(
      runSagaWithAuth(() =>
        tenancyInviteApi.createTenancyInvite(payload.tenancyId, {
          email: payload.email,
          details: payload.details,
          roleId: CreateTenancyInviteDTORoleIdEnum.TENANT,
        })
      )
    );
    const id = yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.tenant);

    const tenancy = yield select(getTenancyById(payload.tenancyId));
    const updatedTenancy = {
      ...tenancy,
      tenants: [...tenancy.tenants, id],
    };
    yield syncEntitiesAndGetResults(updatedTenancy, SCHEMA.tenancy);
    yield put<RepositActionTypes>(createTenantSuccess());

    yield put(setIsTenantFormOpen(false));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: CREATE_TENANT_STORE_KEY, message: 'Success! You have add a tenant.', state: FlashState.SUCCESS })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: CREATE_TENANT_STORE_KEY, message: error, state: FlashState.ERROR })
    );

    yield put<RepositActionTypes>(createTenantFailed(error));
  }
}

export function* deleteTenant({ payload }: { type: string; payload: DeleteTenantPayload }) {
  try {
    const tenancyInviteApi: TenancyInvitesApi = yield createTenancyInviteApi();
    yield call(runSagaWithAuth(() => tenancyInviteApi.deleteTenancyInvite(payload.tenancyInviteId, payload.tenancyId)));
    yield put<RepositActionTypes>(deleteTenantSuccess(payload));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: DELETE_TENANT_STORE_KEY, message: 'Success! You have removed a tenant.', state: FlashState.SUCCESS })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: DELETE_TENANT_STORE_KEY, message: error, state: FlashState.ERROR })
    );
    yield put<RepositActionTypes>(deleteTenantFailed(error));
  }
}

export function* updateTenant({ payload }: { type: string; payload: UpdateTenantDetailsPayload }) {
  try {
    const tenancyInviteApi: TenancyInvitesApi = yield createTenancyInviteApi();

    yield call(
      runSagaWithAuth(() => tenancyInviteApi.updateTenancyInvite(payload.tenancyInviteId, payload.tenancyId, payload.data))
    );

    yield put<RepositActionTypes>(fetchTenancyOrderRequested(payload.tenancyOrderId));
    yield put<RepositActionTypes>(updateTenantDetailsSuccess());
    yield put<RepositActionTypes>(setTenantEditFormOpenStatus({ tenantId: payload.tenancyInviteId, isOpen: false }));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_TENANT_DETAILS_STORE_KEY,
        message: 'Success! You have updated a tenant.',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: UPDATE_TENANT_DETAILS_STORE_KEY, message: error, state: FlashState.ERROR })
    );
    yield put<RepositActionTypes>(updateTenantDetailsFailed(error));
  }
}

export function* updateProperty({ payload }: { type: string; payload: UpdatePropertyPayload }) {
  try {
    const { tenancyId, propertyId, property }: UpdatePropertyPayload = payload;
    const tenanciesApi: TenanciesApi = yield createTenanciesApi();
    const apiResponse: AxiosResponse<TenancyDTO> = yield call(
      runSagaWithAuth(() => tenanciesApi.updateProperty(tenancyId, { propertyId, property }))
    );
    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.tenancy);
    yield put<RepositActionTypes>(updatePropertySuccess(apiResponse.data));
    yield put<RepositActionTypes>(setIsPropertyFormOpen(false));
    yield put<RepositActionTypes>(setIsUpdatePropertyModalOpen(false));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_PROPERTY_STORE_KEY,
        message: 'Success! You have updated the property.',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);

    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: UPDATE_PROPERTY_STORE_KEY, message: error, state: FlashState.ERROR })
    );
    yield put<RepositActionTypes>(updatePropertyFailed(error));
  }
}

export function* createAddressForReposit({ payload }: { type: string; payload: CreateAddressWithOrgDTO }) {
  try {
    const addressesApi: AddressesApi = yield createAddressesApi();
    const apiResponse: AxiosResponse<AddressDTO> = yield call(runSagaWithAuth(() => addressesApi.createAddress(payload)));
    const addressId: string = yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.address);
    yield put<RepositActionTypes>(createAddressForRepositSuccess(apiResponse.data));
    yield put<RepositActionTypes>(setSelectedAddressId(addressId));
    yield put<AddressesActionTypes>(setIsShowingSearchModal(false));
    yield put<AddressesActionTypes>(addAddressToOrganizationAddresses(addressId));
    yield put<RepositActionTypes>(setRepositCurrentAddressForm(AddressForm.ADDRESS_LIST));
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<RepositActionTypes>(createAddressForRepositFailed(error));
  }
}

export function* discardReposit({ payload }: { type: string; payload: { tenancyOrderId: string; reason: string } }) {
  try {
    const { tenancyOrderId, reason } = payload;
    const tenancyOrdersApi: TenancyOrdersApi = yield createTenancyOrdersApi();
    const apiResponse: AxiosResponse<TenancyOrderDTO> = yield call(
      runSagaWithAuth(() => tenancyOrdersApi.removeTenancyOrderById(tenancyOrderId, { reason }))
    );

    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.tenancyOrder);
    yield put<RepositActionTypes>(discardRepositSuccess(apiResponse.data));
    yield put<RepositActionTypes>(setCurrentRepositActionModal({ name: '' }));
    yield put(push('/reposits'));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: DISCARD_REPOSIT_STORE_KEY,
        message: 'Reposit has been successfully deleted',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<RepositActionTypes>(discardRepositFailed(error));
    yield put<RepositActionTypes>(setCurrentRepositActionModal({ name: '' }));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: DISCARD_REPOSIT_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* publishReposit({ payload }: { type: string; payload: any }) {
  try {
    const tenanciesApi: TenanciesApi = yield createTenanciesApi();
    const apiResponse: AxiosResponse<TenancyDTO> = yield call(runSagaWithAuth(() => tenanciesApi.publish(payload)));
    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.tenancy);
    yield put<RepositActionTypes>(publishRepositSuccess(apiResponse.data));
    yield put<RepositActionTypes>(setCurrentRepositActionModal({ name: '' }));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: PUBLISH_REPOSIT_STORE_KEY,
        message: 'Reposit has been successfully created',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<RepositActionTypes>(publishRepositFailed(error));
    yield put<RepositActionTypes>(setCurrentRepositActionModal({ name: '' }));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: PUBLISH_REPOSIT_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* checkoutReposit({ payload }: { type: string; payload: any }) {
  try {
    const api: TenancyCheckoutsApi = yield createTenancyCheckoutsApi();
    const apiResponse: AxiosResponse<CheckoutDTO> = yield call(runSagaWithAuth(() => api.createCheckout(payload.tenancy.id)));
    yield put<RepositActionTypes>(checkoutRepositSuccess(apiResponse.data));

    yield put(fetchTenancyOrderRequested(payload.id));

    yield put<RepositActionTypes>(setCurrentRepositActionModal({ name: '' }));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: CHECKOUT_REPOSIT_STORE_KEY,
        message: 'Reposit has been successfully checked out',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<RepositActionTypes>(checkoutRepositFailed(error));
    yield put<RepositActionTypes>(setCurrentRepositActionModal({ name: '' }));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: CHECKOUT_REPOSIT_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* extendReposit({ payload }: { type: string; payload: ExtendRepositPayload }) {
  try {
    const api: TenancyExpiriesApi = yield createTenancyExpiriesApi();
    yield call(runSagaWithAuth(() => api.createTenancyExpiry(payload.tenancyId, { endDate: payload.endDate })));

    yield put<RepositActionTypes>(extendRepositSuccess());
    yield put<RepositActionTypes>(setCurrentRepositActionModal({ name: '' }));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: EXTEND_REPOSIT_STORE_KEY,
        message: 'Reposit has been successfully extended',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<RepositActionTypes>(extendRepositFailed(error));
    yield put<RepositActionTypes>(setCurrentRepositActionModal({ name: '' }));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: EXTEND_REPOSIT_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* addTenant({ payload }: { type: string; payload: AddTenantPayload }) {
  try {
    const tenantsApi: TenantsApi = yield createTenantsApi();
    const apiResponse: AxiosResponse<TenancyDTO> = yield call(
      runSagaWithAuth(() =>
        tenantsApi.addTenant(payload.tenancyId, {
          newTenantEmail: payload.newTenantEmail,
        })
      )
    );
    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.tenancy);
    yield put<RepositActionTypes>(addTenantOnRepositSuccess());
    yield put<RepositActionTypes>(setCurrentRepositActionModal({ name: '' }));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: ADD_TENANT_ON_REPOSIT_STORE_KEY,
        message: 'Success! Tenant has been added.',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<RepositActionTypes>(setCurrentRepositActionModal({ name: '' }));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: ADD_TENANT_ON_REPOSIT_STORE_KEY, message: error, state: FlashState.ERROR })
    );
    yield put<RepositActionTypes>(addTenantOnRepositFailed(error));
  }
}

export function* removeTenant({ payload }: { type: string; payload: RemoveTenantPayload }) {
  try {
    const { tenantId, tenancyId } = payload;
    const tenantsApi: TenantsApi = yield createTenantsApi();
    const apiResponse: AxiosResponse<TenancyDTO> = yield call(runSagaWithAuth(() => tenantsApi.deleteById(tenantId, tenancyId)));
    yield put<RepositActionTypes>(removeTenantOnRepositSuccess(payload));
    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.tenancy);
    yield put<RepositActionTypes>(setCurrentRepositActionModal({ name: '' }));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: REMOVE_TENANT_ON_REPOSIT_STORE_KEY,
        message: 'Success! Tenant has been removed.',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<RepositActionTypes>(setCurrentRepositActionModal({ name: '' }));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: REMOVE_TENANT_ON_REPOSIT_STORE_KEY, message: error, state: FlashState.ERROR })
    );
    yield put<RepositActionTypes>(removeTenantOnRepositFailed(error));
  }
}

export function* resendTenantInvite({ payload }: { type: string; payload: ResendTenantInterfacePayload }) {
  try {
    const api: TenancyInvitesApi = yield createTenancyInviteApi();
    yield call(runSagaWithAuth(() => api.resendTenancyInvite(payload.tenancyInviteId, payload.tenancyId)));
    yield put<RepositActionTypes>(resendTenantInviteSuccess());
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: RESEND_TENANT_INVITE_STORE_KEY,
        message: 'Tenant invite resent!',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<RepositActionTypes>(resendTenantInviteFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: RESEND_TENANT_INVITE_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* transferReposit({ payload }: { type: string; payload: TransferRepositRequestedPayload }) {
  try {
    const api: TenancyOrdersApi = yield createTenancyOrdersApi();
    yield call(
      runSagaWithAuth(() =>
        api.transferTenancyOrderToOrganization(payload.tenancyOrderId, {
          newOrganizationId: payload.organizationId,
        })
      )
    );

    yield put<RepositActionTypes>(transferRepositSuccess());
    yield put<RepositActionTypes>(setIsTransferModalOpen(false));
    yield put(push(`/reposits/${payload.tenancyOrderId}`));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: TRANSFER_REPOSIT_STORE_KEY,
        message: 'Reposit successfully transferred!',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<RepositActionTypes>(transferRepositFailed(error));
    yield put<RepositActionTypes>(setIsTransferModalOpen(false));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: TRANSFER_REPOSIT_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* extendOutcomeDeadline({ payload }: { type: string; payload: string }) {
  try {
    const api: TenanciesApi = yield createTenanciesApi();
    const apiResponse: AxiosResponse<TenancyDTO> = yield call(runSagaWithAuth(() => api.extendOutcomeDeadline(payload)));

    yield put<RepositActionTypes>(extendOutcomeDeadlineSuccess(apiResponse.data));
    yield put<RepositActionTypes>(setCurrentRepositActionModal({ name: '' }));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: EXTEND_OUTCOME_DEADLINE_STORE_KEY,
        message: 'Reposit outcome deadline successfully extended!',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<RepositActionTypes>(extendOutcomeDeadlineFailed(error));
    yield put<RepositActionTypes>(setCurrentRepositActionModal({ name: '' }));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: EXTEND_OUTCOME_DEADLINE_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* disableTopUps({ payload }: { type: string; payload: string }) {
  try {
    const api: TenanciesApi = yield createTenanciesApi();
    const apiResponse: AxiosResponse<TenancyDTO> = yield call(runSagaWithAuth(() => api.disableTopUps(payload)));

    yield put<RepositActionTypes>(disableTopUpsSuccess(apiResponse.data));
    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.tenancy);
    yield put<RepositActionTypes>(setCurrentRepositActionModal({ name: '' }));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: DISABLE_TOP_UPS_STORE_KEY,
        message: 'Annual fees successfully disabled!',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<RepositActionTypes>(disableTopUpsFailed(error));
    yield put<RepositActionTypes>(setCurrentRepositActionModal({ name: '' }));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: DISABLE_TOP_UPS_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* reopenReposit({ payload }: { type: string; payload: ReopenRepositPayload }) {
  try {
    const api: TenancyOrdersApi = yield createTenancyOrdersApi();
    const apiResponse: AxiosResponse<TenancyOrderWithTenancyAndOrderCustomersDTO> = yield call(
      runSagaWithAuth(() => api.reopenTenancyOrder(payload.tenancyOrderId, { outcomeDeadline: payload.outcomeDeadline }))
    );

    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.tenancyOrder);
    yield put<RepositActionTypes>(reopenRepositSuccess(payload.tenancyId));
    yield put<RepositActionTypes>(setCurrentRepositActionModal({ name: '' }));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: REOPEN_REPOSIT_STORE_KEY,
        message: 'Reposit successfully reopened!',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<RepositActionTypes>(reopenRepositFailed(error));
    yield put<RepositActionTypes>(setCurrentRepositActionModal({ name: '' }));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: REOPEN_REPOSIT_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* addRepositNote({ payload }: { type: string; payload: AddRepositNotePayload }) {
  try {
    const api: TenancyOrderNotesApi = yield createTenancyOrderNotesApi();
    const apiResponse: AxiosResponse<TenancyOrderNoteDTO> = yield call(
      runSagaWithAuth(() => api.createTenancyOrderNote(payload.tenancyOrderId, { note: payload.note }))
    );

    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.tenancyOrderNote);
    yield put<RepositActionTypes>(addRepositNoteSuccess());
    yield put<RepositActionTypes>(setCurrentRepositActionModal({ name: '' }));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: ADD_REPOSIT_NOTE_STORE_KEY,
        message: 'Note added successfully!',
        state: FlashState.SUCCESS,
      })
    );
    yield put(push(`/reposits/${payload.tenancyOrderId}`));
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<RepositActionTypes>(addRepositNoteFailed(error));
    yield put<RepositActionTypes>(setCurrentRepositActionModal({ name: '' }));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: ADD_REPOSIT_NOTE_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* deleteRepositNote({ payload }: { type: string; payload: DeleteRepositNotePayload }) {
  try {
    const api: TenancyOrderNotesApi = yield createTenancyOrderNotesApi();
    yield call(runSagaWithAuth(() => api.deleteNote(payload.tenancyOrderNoteId, payload.tenancyOrderId)));
    yield put<RepositActionTypes>(deleteRepositNoteSuccess(payload));
    yield put<RepositActionTypes>(setCurrentRepositActionModal({ name: '' }));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: DELETE_REPOSIT_NOTE_STORE_KEY,
        message: 'Note deleted successfully!',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<RepositActionTypes>(deleteRepositNoteFailed(error));
    yield put<RepositActionTypes>(setCurrentRepositActionModal({ name: '' }));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: DELETE_REPOSIT_NOTE_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

// ****************
// WATCHERS
// ****************
export function* watchRepositSagas() {
  yield takeLatest(CREATE_TENANCY_WITH_PROPERTY_API_REQUESTED, createTenancyWithProperty);
  yield takeLatest(UPDATE_TENANCY_API_REQUESTED, updateTenancy);
  yield takeLatest(FETCH_TENANCY_ORDER_API_REQUESTED, fetchTenancyOrderById);
  yield takeLatest(CREATE_TENANT_API_REQUESTED, createTenant);
  yield takeLatest(DELETE_TENANT_API_REQUESTED, deleteTenant);
  yield takeLatest(UPDATE_PROPERTY_API_REQUESTED, updateProperty);
  yield takeLatest(CREATE_ADDRESS_API_REQUESTED, createAddressForReposit);
  yield takeLatest(DISCARD_REPOSIT_API_REQUESTED, discardReposit);
  yield takeLatest(PUBLISH_REPOSIT_API_REQUESTED, publishReposit);
  yield takeLatest(CHECKOUT_REPOSIT_API_REQUESTED, checkoutReposit);
  yield takeLatest(EXTEND_REPOSIT_API_REQUESTED, extendReposit);
  yield takeLatest(RESEND_TENANT_INVITE_API_REQUESTED, resendTenantInvite);
  yield takeLatest(TRANSFER_REPOSIT_API_REQUESTED, transferReposit);
  yield takeLatest(ADD_TENANT_ON_REPOSIT_API_REQUESTED, addTenant);
  yield takeLatest(EXTEND_OUTCOME_DEADLINE_API_REQUESTED, extendOutcomeDeadline);
  yield takeLatest(DISABLE_TOP_UPS_API_REQUESTED, disableTopUps);
  yield takeLatest(REMOVE_TENANT_ON_REPOSIT_API_REQUESTED, removeTenant);
  yield takeLatest(REOPEN_REPOSIT_API_REQUESTED, reopenReposit);
  yield takeLatest(ADD_REPOSIT_NOTE_API_REQUESTED, addRepositNote);
  yield takeLatest(DELETE_REPOSIT_NOTE_API_REQUESTED, deleteRepositNote);
  yield takeLatest(UPDATE_TENANT_DETAILS_API_REQUESTED, updateTenant);
}

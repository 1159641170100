import {
  OrganizationWithRepositsAndRolesOrganizationTypeIdEnum,
  OrganizationInternalOwnerDTOResponsibleForEnum,
} from '@reposit/api-client';
import { get } from 'lodash';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getBreakpoint } from '../../base/style';
import IntegrationInfo from '../../components/IntegrationInfo';
import RepositCard from '../../components/Reposit/RepositCard';
import StatusItem from '../../components/TenantProgress/StatusItem';
import { getOrganization } from '../../redux/selectors/organization.selectors';
import { AppState } from '../../redux/root.reducer';
import { getOrganizationInternalOwners } from '../../redux/organization-internal-owner/organization-internal-owner.selectors';
import { Table, Tbody, Tr, Th, Td } from '../../components/Table';

const StatusItems = styled.div`
  border-radius: 6px;
  overflow: hidden;
  display: block;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 16px;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    border-radius: 12px;
    align-items: center;
    display: flex;
    margin: 0;
  }
`;
interface OrganizationOnboardingProps {
  organizationId: string;
}

interface OnboardingStep {
  title: string;
  completed: boolean;
}

export const OrganizationAccount: React.FC<OrganizationOnboardingProps> = ({ organizationId }) => {
  const org = useSelector(getOrganization(organizationId));
  const internalOwners = useSelector((state: AppState) => getOrganizationInternalOwners(state, organizationId));
  const salesOwner = internalOwners.find(
    (owner) => owner.responsibleFor === OrganizationInternalOwnerDTOResponsibleForEnum.SALES
  );

  const attributes = org && org.attributes;

  const astStep = { title: 'AST', completed: !!(attributes && attributes.updatedAST) };
  const bankAccountStep = { title: 'Bank Accounts', completed: !!(attributes && attributes.bankAccountsConfirmed) };
  const usefulDocsStep = { title: 'Useful Documents', completed: !!(attributes && attributes.informedLandlords) };
  const welcomeStep = { title: 'Welcome', completed: !!(attributes && attributes.welcomeLandlordCompleted) };
  const tenancyGuideStep = { title: 'Tenancy Guide', completed: !!(attributes && attributes.landlordInformationCompleted) };
  const termsStep = { title: 'T&Cs', completed: !!(attributes && attributes.acceptedTerms) };
  const yourCompanyStep = { title: 'Your Company', completed: !!(attributes && attributes.yourCompanyComplete) };
  const integrationToken = get(attributes, 'legacyIntegrationToken', '');

  const steps =
    org && org.organizationTypeId === OrganizationWithRepositsAndRolesOrganizationTypeIdEnum.PRIVATE
      ? [welcomeStep, astStep, bankAccountStep, usefulDocsStep, tenancyGuideStep, termsStep]
      : [yourCompanyStep, astStep, bankAccountStep, usefulDocsStep, termsStep];

  return (
    <Container>
      <RepositCard title="Onboarding Status">
        <Row>
          <Col lg={12} style={{ padding: 0 }}>
            <StatusItems>
              {steps.map((item) => (
                <StatusItem key={item.title} label={item.title} status={item.completed ? 'DONE' : 'INCOMPLETE'} />
              ))}
            </StatusItems>
          </Col>
        </Row>
      </RepositCard>
      <RepositCard title="Account Managers">
        <Container>
          <Row>
            <Col lg={12} style={{ padding: 0 }}>
              <Table>
                <Tbody>
                  <Tr>
                    <Th width={250}>Sales</Th>
                    <Td style={{ paddingLeft: 10 }}>{salesOwner ? salesOwner.internalTeamMember.displayName : 'na'}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </RepositCard>
      <IntegrationInfo integrationToken={integrationToken} />
    </Container>
  );
};

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import { Header3, P1 } from '../../../components/Typography';
import {
  setCurrentRepositActionModal,
  removeTenantOnRepositRequested,
  REMOVE_TENANT_ON_REPOSIT_STORE_KEY,
} from '../../../redux/reposit/reposit.actions';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';

interface RemoveTenantModalProps {
  currentTenancyUserId: string;
  tenancyId: string;
  currentTenancyUserEmail: string;
}

const ActionBar = styled.div`
  display: flex;
  padding: 24px 0;
  justify-content: flex-end;
  flex-wrap: wrap;
  row-gap: 10px;

  button {
    margin-right: 10px;
  }
`;

export const RemoveTenantModal: React.FC<RemoveTenantModalProps> = ({
  tenancyId,
  currentTenancyUserEmail,
  currentTenancyUserId,
}) => {
  const dispatch = useDispatch();
  const isSubmittingSelector = createLoadingSelector([REMOVE_TENANT_ON_REPOSIT_STORE_KEY]);
  const isSubmitting = useSelector(isSubmittingSelector);
  return (
    <Modal onDismiss={() => dispatch(setCurrentRepositActionModal({ name: '' }))}>
      <Header3>Remove Tenant</Header3>
      <P1 style={{ marginBottom: 6 }}>
        Are you sure you would like to remove <span style={{ fontWeight: 'bold' }}>{currentTenancyUserEmail}</span> from this
        tenancy?{' '}
      </P1>
      <ActionBar>
        <Button buttonType="warning" onClick={() => dispatch(setCurrentRepositActionModal({ name: '' }))}>
          Cancel
        </Button>
        <Button
          disabled={isSubmitting}
          onClick={() => dispatch(removeTenantOnRepositRequested({ tenancyId, tenantId: currentTenancyUserId }))}
        >
          Confirm
        </Button>
      </ActionBar>
    </Modal>
  );
};

import React from 'react';
import styled from 'styled-components';
import LoadingIcon from '../../assets/svg/loader.svg';

const Loader = styled.div`
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: center;
  height: 140px;
`;

const FullPageWrapper = styled.div`
  align-items: center;
  display: flex;
  position: fixed;
  justify-content: center;
  height: 100vh;
  left: 0;
  top: 0;
  width: 100vw;
`;

export default () => (
  <Loader>
    <img src={LoadingIcon} width="40" height="40" alt="Loading" />
  </Loader>
);

export const FullPageLoader = () => (
  <FullPageWrapper>
    <Loader>
      <img src={LoadingIcon} width="40" height="40" alt="Loading" />
    </Loader>
  </FullPageWrapper>
);

import React, { Fragment } from 'react';
import { Caption } from '../Typography';
import styled from 'styled-components';

interface ErrorProps {
  error: any;
}

const Title = styled.h2`
  font-family: ${props => props.theme.typography.face.primary};
  font-size: 48px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 0.1px;
  text-align: center;
  color: ${props => props.theme.colors.placeholder};
  margin-top: 72px;
`;

const Message = styled(Caption)`
  max-width: 660px;
  margin: 20px auto 0;
  text-align: center;
`;

export default ({ error }: ErrorProps) => {
  return (
    <Fragment>
      <Title>Whoops!</Title>
      <Message>Not sure what happened there. Looks like a bug found it’s way into our system. Try refreshing the page.</Message>
    </Fragment>
  );
};

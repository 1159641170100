import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { getBreakpoint } from '../../../base/style';
import { RepositTheme } from '../../../base/theme';
import { AddressEntity, ClaimEntity, ClaimItemEntity } from '../../../redux/entities/entities.types';
import { AppState } from '../../../redux/root.reducer';
import { getIsAgentDeciding, getIsTenantDeciding } from '../../../redux/selectors/mediation.selectors';
import { getAddressFirstLine, getClaimStatus } from '../../../utils/common.utils';
import Card from '../../Card';
import { Caption, Header3, P2, P3 } from '../../Typography';

export enum ClaimStatusOptions {
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  DRAFT = 'DRAFT',
}

interface ClaimSummaryProps {
  claim: ClaimEntity;
  items: ClaimItemEntity[];
  address?: AddressEntity;
}

interface ClaimStatusProps {
  status: string;
}

const resolveClaimStatusColor = (status: string) => {
  switch (status) {
    case 'DRAFT':
      return RepositTheme.colors.body2;
    case 'PENDING':
      return RepositTheme.colors.warning;
    case 'DEACTIVATED':
      return RepositTheme.colors.negative;
    case 'ARBITRATED':
      return RepositTheme.colors.warning;
    default:
      return RepositTheme.colors.positive;
  }
};

const SummaryContainer = styled.div`
  @media all and (min-width: ${getBreakpoint('lg')}) {
    display: flex;
    justify-content: space-between;
  }
`;

const ColLeft = styled.div`
  width: 100%;
`;

const ColRight = styled.div`
  flex: 0 0 280px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    flex-direction: column;
    max-width: 280px;
  }
`;

const ClaimStatus = styled(P2)<ClaimStatusProps>`
  align-items: center;
  color: ${(props) => resolveClaimStatusColor(props.status)};
  display: flex;

  &:before {
    background: ${(props) => resolveClaimStatusColor(props.status)};
    border-radius: 12px;
    content: '';
    display: block;
    height: 10px;
    margin: -2px 10px 0 0;
    width: 10px;
  }
`;

const Action = styled.div`
  display: flex;
  justify-content: space-between;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    justify-content: flex-end;
  }
`;

const SmallLabel = styled(P3)`
  margin: 0;
`;

const NoAddress = styled(P2)`
  font-style: italic;
`;

const Address = styled.div`
  width: 100%;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
  }
`;

const FirstLineAddress = styled(Header3)`
  line-height: 1.2;
  margin: 0;
  padding: 90;
`;

const TownPostcode = styled(Caption)`
  padding: 2px 0 0;
  @media screen and (min-width: ${getBreakpoint('lg')}) {
    margin: 0 0 0 12px;
  }
`;

export default (props: ClaimSummaryProps): JSX.Element => {
  if (!props.claim) return <>No Claim found</>;
  const isAgentDeciding = useSelector((state: AppState) => getIsAgentDeciding(state, props.claim.id));
  const isTenantDeciding = useSelector((state: AppState) => getIsTenantDeciding(state, props.claim.id));
  return (
    <NavLink to={`/claims/${props.claim.id}`} style={{ textDecoration: 'none' }}>
      <Card hover flush={false} style={{ padding: '12px 20px 32px 40px' }}>
        <SummaryContainer>
          <ColLeft>
            <ClaimStatus status={props.claim.status}>
              {getClaimStatus(props.claim, isAgentDeciding, isTenantDeciding)}
            </ClaimStatus>
            {props.address ? (
              <Address>
                <FirstLineAddress>{getAddressFirstLine(props.address)}</FirstLineAddress>
                <TownPostcode>{props.address.postcode}</TownPostcode>
              </Address>
            ) : (
              <NoAddress>You haven't added a property to this Reposit yet.</NoAddress>
            )}
          </ColLeft>
          <ColRight>
            <Action>
              <SmallLabel>Created {moment(props.claim.createdAt).fromNow()}</SmallLabel>
            </Action>
          </ColRight>
        </SummaryContainer>
      </Card>
    </NavLink>
  );
};

import { InternalTeamMembersApi, InternalTeamMemberDTO } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import SCHEMA from '../schema';
import { createInternalTeamMembersApi, runSagaWithAuth } from '../utils/api.utils';
import {
  getInternalTeamMembersFailed,
  getInternalTeamMembersSuccess,
  GET_INTERNAL_TEAM_MEMBERS_API_REQUESTED,
  InternalTeamMembersActions,
} from './internal-team-member.actions';

// ****************
// WORKERS
// ****************

export function* fetchInternalTeamMembers(args: { type: string }) {
  try {
    const api: InternalTeamMembersApi = yield createInternalTeamMembersApi();

    const apiResponse: AxiosResponse<InternalTeamMemberDTO> = yield call(runSagaWithAuth(() => api.findAll()));
    const ids: string[] = yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.internalTeamMembers);
    yield put<InternalTeamMembersActions>(getInternalTeamMembersSuccess(ids));
  } catch (e) {
    yield put<InternalTeamMembersActions>(getInternalTeamMembersFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchIntegratorSagas() {
  yield takeLatest(GET_INTERNAL_TEAM_MEMBERS_API_REQUESTED, fetchInternalTeamMembers);
}

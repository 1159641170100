import styled from "styled-components";
import { getBreakpoint } from "../../base/style";
import { sideMenuWidth } from "../SideMenu/components";

export const Wrapper = styled.div`
  align-items: stretch;
  min-height: 100vh;
  display: flex;
`;

interface MainContentProps {
  isFullWidth?: boolean;
  isMobileMenuOpen: boolean;
}

export const MainContent = styled.div<MainContentProps>`
  background: ${props => props.theme.colors.bgAccent};
  padding: ${props => (props.isFullWidth ? 0 : `0 0 0 ${sideMenuWidth}px`)};
  transition: transform 0.3s ease-out;
  width: 100%;

  @media screen and (max-width: ${getBreakpoint("md")}) {
    padding: 0;
    transform: ${props =>
      props.isMobileMenuOpen
        ? "translate3d(240px, 0px,0px);"
        : "translate3d(0px, 0px,0px);"};
  }
`;

export const InnerWrapper = styled.div`
  margin: 42px 0;
  padding: 0 4%;

  @media screen and (min-width: ${getBreakpoint("md")}) {
    margin: 0;
    padding: 0 5%;
  }
`;

export const ContentContainer = styled.div`
  @media screen and (min-width: ${getBreakpoint("lg")}) {
    margin: 1.5em 0 1em;
  }
`;

export const DisableComponent = styled.div<any>`
  pointer-events: ${props => (props.disabled ? "none" : "default")};
  cursor: ${props => (props.disabled ? "not-allowed" : "default")};
  opacity: ${props => (props.disabled ? 0.4 : 1)};
  user-select: none;
`;

import { createReducer } from '@reduxjs/toolkit';
import { fetchOrganizationRecipientSuccess } from './organization-recipient.actions';
import { OrganizationRecipientState } from './organization-recipient.types';

const initialState: OrganizationRecipientState = {
  organizationRecipients: [],
};

export default createReducer(initialState, (builder) => {
  builder.addCase(fetchOrganizationRecipientSuccess, (state, action) => {
    const orgIds = action.payload;
    state.organizationRecipients = orgIds;
  });
});

import React, { FC } from 'react';
import { ClaimStatusDTO } from '@reposit/api-client';
import moment from 'moment';

import RepositCard from '../Reposit/RepositCard';
import { Table, Thead, Tbody, Td, Tr } from '../Table';

interface ClaimStatuses {
  statuses: ClaimStatusDTO[];
}

export const ClaimStatuses: FC<ClaimStatuses> = ({ statuses }) => {
  return (
    <RepositCard flush title="Claim Statuses" tooltip="lorem">
      <Table>
        <Thead>
          <Tr>
            <Td>Id</Td>
            <Td>Status</Td>
            <Td>Created At</Td>
          </Tr>
        </Thead>
        <Tbody>
          {statuses &&
            statuses.length > 0 &&
            statuses.map(status => (
              <Tr key={status.id}>
                <Td>{status.id}</Td>
                <Td>{status.statusId}</Td>
                <Td>{moment(status.createdAt).format('DD/MM/YYYY HH:mm:ss')}</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </RepositCard>
  );
};

import React from 'react';

import Modal from '../Modal/index';
import { Header3, P2 } from '../Typography';
import styled from 'styled-components';
import Button from '../Button';
import { useDispatch } from 'react-redux';
import { resetUserPasswordRequested } from '../../redux/user/user.actions';

interface ResetPasswordModalProps {
  userId: string;
  email: string;
  isLoading: boolean;
  onDismiss: () => void;
}

const ActionBar = styled.div`
  display: flex;
  padding: 24px 0;
  justify-content: flex-end;
  flex-wrap: wrap;
  row-gap: 10px;

  button {
    margin-right: 10px;
  }
`;

export const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({ onDismiss, email, userId, isLoading }) => {
  const dispatch = useDispatch();
  return (
    <Modal onDismiss={onDismiss}>
      <Header3>Send reset password email</Header3>
      <P2>{`Do you wish to send a reset password email to ${email}?`}</P2>
      <ActionBar>
        <Button disabled={isLoading} buttonType="warning" onClick={onDismiss}>
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={() => dispatch(resetUserPasswordRequested(userId, email))}>
          Confirm
        </Button>
      </ActionBar>
    </Modal>
  );
};

import { ClaimItemState } from './claim-item.types';
import { ClaimItemActionTypes } from './claim-item.actions';

const initialState: ClaimItemState = {};

export default (state = initialState, action: ClaimItemActionTypes): ClaimItemState => {
  switch (action.type) {
    // requested and failed are handled in loading and error reducers
    default:
      return state;
  }
};

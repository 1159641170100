import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import styled from 'styled-components';
import Button from '../../components/Button';
import { InnerWrapper } from '../../components/Common';
import { getBreakpoint } from '../../base/style';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router';

interface AppHeaderProps {
  showAddAgentButton?: boolean;
  showBackButton?: boolean;
}

const AppHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 36px;
  margin: 2.625em 0 3.25em;

  @media screen and (max-width: ${getBreakpoint('md')}) {
    display: none;
  }
`;

export default ({ showAddAgentButton, showBackButton }: AppHeaderProps) => {
  const history = useHistory();
  return (
    <InnerWrapper>
      <Container>
        <Row>
          <Col sm={12}>
            <AppHeaderContainer>
              {showBackButton && (
                <Button buttonType="secondary" reverse onClick={() => history.goBack()}>
                  Go Back
                </Button>
              )}

              {showAddAgentButton && (
                <NavLink to="/organisations/new" style={{ marginLeft: 'auto', textDecoration: 'none' }}>
                  <Button buttonType="secondary">Add an Organisation</Button>
                </NavLink>
              )}
            </AppHeaderContainer>
          </Col>
        </Row>
      </Container>
    </InnerWrapper>
  );
};

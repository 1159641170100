import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from '../../../redux/root.reducer';
import { getTenancyOrder } from '../../../redux/selectors';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import {
  FETCH_TENANCY_ORDER_STORE_KEY,
  fetchTenancyOrderRequested,
  addRepositNoteRequested,
  ADD_REPOSIT_NOTE_STORE_KEY,
} from '../../../redux/reposit/reposit.actions';
import { FullPageLoader } from '../../../components/Loading';
import React, { useEffect, Fragment, useState } from 'react';
import * as yup from 'yup';
import { Col, Container, Row } from 'react-grid-system';
import { Header2, Caption } from '../../../components/Typography';
import { getDisplayAddress } from '../../../utils/common.utils';
import RepositCard from '../../../components/Reposit/RepositCard';
import NotesIcon from '../../../assets/svg/notes.svg';
import TextArea from '../../../components/FormFields/TextArea';
import { Formik, FormikProps } from 'formik';
import styled from 'styled-components';
import AddRepositNoteModal from './AddRepositNoteModal';
import Button from '../../../components/Button';
import { getCurrentUser } from '../../../redux/selectors/user.selectors';
import { useFlashMessage, FLASH_MESSAGE_TIMEOUT } from '../../FlashMessage';
import FlashMessage from '../../../components/FlashMessage';

const ActionsContainer = styled.div`
  margin: 16px 0 96px;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

interface RepositNoteView {
  id: string;
}

interface RepositNoteProps {
  note: string;
}

export interface RepositNoteFormValues {
  note: string;
}

const RepositNoteContainer: React.FC<RepositNoteView> = (props) => {
  const dispatch = useDispatch();
  const [isModalShowing, showModal] = useState<boolean>(false);
  const [flashMessagePayload, dismissFlashMessage] = useFlashMessage([ADD_REPOSIT_NOTE_STORE_KEY]);
  const { id } = useParams<RepositNoteView>();
  const tenancyOrder = useSelector((state: AppState) => getTenancyOrder(state, { tenancyOrderId: id }));
  const loadingSelector = createLoadingSelector([FETCH_TENANCY_ORDER_STORE_KEY]);
  const currentUser = useSelector(getCurrentUser);
  const isLoading = useSelector(loadingSelector);

  useEffect(() => {
    dispatch(fetchTenancyOrderRequested(id));
  }, [dispatch, id]);

  if (!tenancyOrder || !currentUser || isLoading) return <FullPageLoader />;

  const addRepositNote = (values: RepositNoteFormValues) => {
    dispatch(addRepositNoteRequested({ note: values.note, userId: currentUser.id, tenancyOrderId: tenancyOrder.id }));
  };

  const VALIDATION_SCHEMA = yup.object().shape({
    note: yup.string().required('Required'),
  });

  const initialValues: RepositNoteFormValues = {
    note: '',
  };

  return (
    <Fragment>
      <Container>
        <Row>
          <Col lg={12}>
            <Header2>Reposit Notes</Header2>
            <Caption>{getDisplayAddress(tenancyOrder.tenancy.property.address)}</Caption>
          </Col>
        </Row>
        <Formik initialValues={initialValues} onSubmit={() => showModal(true)} validationSchema={VALIDATION_SCHEMA}>
          {({ values, handleChange, handleSubmit, handleBlur }: FormikProps<RepositNoteProps>) => {
            return (
              <form onSubmit={handleSubmit}>
                <Fragment>
                  <Container>
                    <Row>
                      <Col lg={10} push={{ lg: 1 }}>
                        <RepositCard
                          title="Add a note"
                          icon={NotesIcon}
                          flashMessage={
                            flashMessagePayload ? (
                              <FlashMessage
                                onDismiss={dismissFlashMessage}
                                timeRemaining={FLASH_MESSAGE_TIMEOUT}
                                payload={flashMessagePayload}
                              />
                            ) : undefined
                          }
                        >
                          <TextArea name="note" value={values.note} onChange={handleChange} onBlur={handleBlur} />
                        </RepositCard>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={10} push={{ lg: 1 }}>
                        <ActionsContainer>
                          <Button type="submit">Add Reposit Note</Button>
                        </ActionsContainer>
                      </Col>
                    </Row>
                  </Container>
                </Fragment>
                {isModalShowing ? (
                  <AddRepositNoteModal onDismiss={() => showModal(false)} onSubmit={() => addRepositNote(values)} />
                ) : null}
              </form>
            );
          }}
        </Formik>
      </Container>
    </Fragment>
  );
};

export default RepositNoteContainer;

import React, { useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';

import LoadingIcon from '../../assets/svg/loader.svg';
import { Container, OptionsContainer, OptionsList, OptionItem, SelectContainer, LoaderContainer } from './styles';

interface SearchableTextInputProps {
  name: string;
  items: Array<any>;
  keyProperty: string;
  valueProperty: string;
  placeholder?: string;
  loading?: boolean;
  onItemSelected: (selected: any) => void;
  onChange?: (term: string) => void;
}

export interface SearchResult {
  key: string;
  value: string;
}

enum KeyCode {
  ENTER = 13,
  ARROW_UP = 38,
  ARROW_DOWN = 40,
}

const SearchableTextInput: React.FC<SearchableTextInputProps> = ({
  name,
  placeholder,
  items,
  keyProperty,
  valueProperty,
  loading,
  onItemSelected,
  onChange,
}) => {
  const [term, setTerm] = useState<string>('');
  const [showResults, setShowResults] = useState<boolean>();
  const [activeSuggestion, setActiveSuggestion] = useState<number>(-1);
  const [searchResults, setSearchResults] = useState<Array<any>>([]);
  const [shouldSearch, setShouldSearch] = useState<boolean>(false);
  const [typing, setTyping] = useState<boolean>(false);
  const [debouncedTerm] = useDebounce(term, 500);

  /**
   * Rules for showing suggestions:
   * - User should not be typing
   * - Should have a search term
   * - State showResults should be true
   * - Should have at least one search result item
   */
  const showSuggestions = !typing && term && showResults && searchResults && searchResults.length > 0;

  /**
   * Fire this effect every time the component receive new items.
   * Resets the active suggestion index and only show the results
   * if the items received are different from the previous ones.
   */
  useEffect(() => {
    if (items) {
      setShouldSearch(false);
      setActiveSuggestion(-1);
      setSearchResults(items);

      const diff = JSON.stringify(items) !== JSON.stringify(searchResults);
      if (diff) setShowResults(true);
    }
  }, [items, searchResults]);

  /**
   * Fire this effect every time the debouncedTerm receives a new value.
   * Here's the moment when the user stop typing and we're allowed to call the onChange
   * function and do a new search.
   */
  useEffect(() => {
    setTyping(false);
    if (debouncedTerm && shouldSearch) {
      setShouldSearch(false);
      if (onChange) onChange(debouncedTerm);
    }
  }, [debouncedTerm, onChange, shouldSearch]);

  /**
   * While the user is typing, the results should be hidden.
   * This effect listen to the typing state and checks if the user stop typing
   * so we can show the results again.
   */
  useEffect(() => {
    if (!typing) {
      setShowResults(true);
    }
  }, [typing]);

  /**
   * Handles the user selection, whether the user used the mouse or keyboard.
   * Also, hides the suggestions and stops searching.
   * @param suggestion the selected suggestion
   */
  const handleSelectSuggestion = (suggestion: any) => {
    setShouldSearch(false);
    setShowResults(false);
    setTerm(suggestion[valueProperty]);
    if (onItemSelected) {
      onItemSelected(suggestion);
    }
  };

  /**
   * Handles the search term change, hides the suggestions and flags the typing state.
   * @param term the search term
   */
  const handleChange = (term: string) => {
    setShowResults(false);
    setTyping(true);
    setTerm(term);
    setShouldSearch(true);
  };

  /**
   * Handles keyboard events (up arrow, down arrow and enter)
   * @param event the keyboard event
   */
  const handleKeyboardSelection = (event: React.KeyboardEvent) => {
    const keyCode = event.keyCode;
    if (searchResults && searchResults.length) {
      switch (keyCode) {
        case KeyCode.ENTER:
          event.preventDefault();
          setActiveSuggestion(-1);
          return handleSelectSuggestion(searchResults[activeSuggestion]);
        case KeyCode.ARROW_UP:
          if (activeSuggestion === 0) return;
          return setActiveSuggestion(activeSuggestion - 1);
        case KeyCode.ARROW_DOWN:
          if (activeSuggestion === searchResults.length - 1) return;
          return setActiveSuggestion(activeSuggestion + 1);
      }
    }
  };

  return (
    <Container>
      <SelectContainer>
        <input
          type="text"
          autoComplete="off"
          name={name}
          placeholder={placeholder || 'Search here...'}
          onChange={(e) => handleChange(e.target.value)}
          onClick={() => setShowResults(true)}
          onKeyDown={(e) => handleKeyboardSelection(e)}
          value={term}
          onBlur={() => setTimeout(() => setShowResults(false), 100)}
        />
        {(typing || loading) && (
          <LoaderContainer>
            <img src={LoadingIcon} width="20" height="20" alt="Loading" />
          </LoaderContainer>
        )}
      </SelectContainer>
      {showSuggestions && (
        <OptionsContainer>
          <OptionsList>
            {searchResults.map((suggestion, index) => (
              <OptionItem
                onMouseOver={() => setActiveSuggestion(index)}
                onClick={() => handleSelectSuggestion(suggestion)}
                key={suggestion[keyProperty]}
                selected={index === activeSuggestion}
              >
                {suggestion[valueProperty]}
              </OptionItem>
            ))}
          </OptionsList>
        </OptionsContainer>
      )}
    </Container>
  );
};

export default SearchableTextInput;

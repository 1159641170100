import {
  ClaimItemDTOTypeEnum,
  ClaimItemProposalDTOSettledSourceEnum,
  ClaimProposalDTOSourceEnum,
  ClaimProposalDTOStatusEnum,
  ClaimProposalDTOTypeEnum,
} from '@reposit/api-client';
import { get, startCase, toLower } from 'lodash';
import React from 'react';
import { Visible } from 'react-grid-system';
import { DocumentEntity } from '../../redux/entities/entities.types';
import { ExpandableContent } from '../ExpandableContent';
import { Header3, Header4, P1, P2, P3 } from '../Typography';
import AuditTrail, { isProposalPublished } from './AuditTrail/AuditTrail';
import { Amount, DescriptionContainer, ItemInfoContainer, Panel } from './AuditTrail/components';

interface ClaimItem {
  id: string;
  description: string;
  type: ClaimItemDTOTypeEnum;
  claimId: string;
  itemProposals: ItemProposal[];
  isSettled: boolean;
}

interface ItemProposal {
  id: string;
  claimProposal: ClaimProposal;
  settled?: boolean;
  settledSource?: ClaimItemProposalDTOSettledSourceEnum;
  amount: number;
  explanation?: string;
  documents?: DocumentEntity[];
  createdAt: string;
  updatedAt: string;
}

interface ClaimProposal {
  id: string;
  type: ClaimProposalDTOTypeEnum;
  round: number;
  source: ClaimProposalDTOSourceEnum;
  status: ClaimProposalDTOStatusEnum;
}
export enum ClaimItemComponentStatus {
  NEGOTIABLE = 'NEGOTIABLE',
  VIEW_ONLY = 'VIEW_ONLY',
  UPLOAD_ONLY = 'UPLOAD_ONLY',
}

interface ClaimItemProps {
  claimItem: ClaimItem;
}

export enum ShowMoreType {
  EVIDENCE = 'EVIDENCE',
  FORM = 'FORM',
}

export enum ClaimItemState {
  INITIAL = 'INITIAL',
  VIEW_EVIDENCE = 'VIEW_EVIDENCE',
  REJECTED_TENANT_COUNTER = 'REJECTED_TENANT_COUNTER',
  UPLOADING_EVIDENCE = 'UPLOADING_EVIDENCE',
}

const MediationClaimItem: React.FC<ClaimItemProps> = ({ claimItem }) => {
  const initialAgentProposal = claimItem.itemProposals.find((ip) => ip.claimProposal.round === 1);
  const initialAgentProposalAmount = get(initialAgentProposal, 'amount', 0);
  const secondAgentProposal = claimItem.itemProposals.find((ip) => ip.claimProposal.round === 3);
  const secondAgentProposalAmount = get(secondAgentProposal, 'amount');

  const tenantProposal = claimItem.itemProposals.find((ip) => ip.claimProposal.round === 2);
  const tenantProposalAmount = get(tenantProposal, 'amount');

  const getAmount = () => {
    if (
      secondAgentProposalAmount !== undefined &&
      secondAgentProposal &&
      isProposalPublished(secondAgentProposal.claimProposal)
    ) {
      return secondAgentProposalAmount;
    } else if (tenantProposalAmount !== undefined && tenantProposal && isProposalPublished(tenantProposal.claimProposal)) {
      return tenantProposalAmount;
    } else return initialAgentProposalAmount;
  };

  const getAmountText = () => 'Latest amount';

  return (
    <Panel>
      <Visible xs sm>
        <ItemInfoContainer column>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Header3 style={{ marginBottom: 0 }}>{claimItem.description}</Header3> <Header4>({claimItem.id})</Header4>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
            <P1 style={{ marginBottom: 0, marginRight: 5, width: 150 }}>{getAmountText()}:</P1>
            <Amount amount={getAmount()} />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: 8,
              marginBottom: 6,
              flexDirection: 'row',
            }}
          >
            <P2 style={{ marginBottom: 0, marginRight: 6 }}>{startCase(toLower(claimItem.type))}</P2>
          </div>
        </ItemInfoContainer>
      </Visible>
      <Visible md lg xl>
        <ItemInfoContainer column={false}>
          <DescriptionContainer>
            <Header4 style={{ marginBottom: 0 }}>{claimItem.description}</Header4> <P2>({claimItem.id})</P2>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 8,
                flexDirection: 'row',
              }}
            >
              <P3 style={{ marginBottom: 0, marginRight: 6 }}>{startCase(toLower(claimItem.type))}</P3>
            </div>
          </DescriptionContainer>
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <P3 style={{ marginBottom: 0, marginRight: 5 }}>{getAmountText()}:</P3>
              <Amount amount={getAmount()} />
            </div>
          </div>
        </ItemInfoContainer>
      </Visible>
      <ExpandableContent hiddenContent={<AuditTrail itemProposals={claimItem.itemProposals} claimItemId={claimItem.id} />} />
    </Panel>
  );
};
export default MediationClaimItem;

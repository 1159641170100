import React, { useCallback, useEffect, Fragment } from 'react';
import { Container, Col, Row } from 'react-grid-system';
import { Header2, P1, Header3 } from '../../components/Typography';
import Search from '../../components/Search';
import Filter from '../../components/Filter';
import Sort from '../../components/Sort';
import { ContentContainer } from '../../components/Common';
import UserCard from '../../components/UserCard';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchUsersRequested,
  updateFilters as updateFiltersActionCreator,
  FETCH_USERS_STORE_KEY,
  Page,
  goToPage
} from '../../redux/user-list/user-list.actions';
import { getUserListFilters, getUserListPagination, getUserList } from '../../redux/user-list/user-list.selectors';
import { useDebounce } from 'use-debounce/lib';
import { UserListFilters } from '../../redux/user-list/user-list.types';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import { createErrorMessageSelector } from '../../redux/error/error.selector';
import Error from '../../components/Error';
import Loading from '../../components/Loading';
import Pagination from '../../components/Pagination';
import { UsersFilter } from '../../constants/users';
import Card from '../../components/Card';

interface UsersProps {}

export interface UserFilterOption {
  value: UsersFilter;
  label: string;
}

const filters: UserFilterOption[] = [
  {
    value: UsersFilter.ALL,
    label: 'All'
  },
  {
    value: UsersFilter.ORGANIZATION_ADMIN,
    label: 'Org Admin'
  },
  {
    value: UsersFilter.AGENT,
    label: 'Agent'
  },
  {
    value: UsersFilter.TENANT,
    label: 'Tenant'
  },
  {
    value: UsersFilter.LANDLORD,
    label: 'LANDLORD'
  }
];

const Users: React.FC<UsersProps> = () => {
  const dispatch = useDispatch();
  const fetchUsers = useCallback(() => dispatch(fetchUsersRequested()), [dispatch]);
  const userListLoadingSelector = createLoadingSelector([FETCH_USERS_STORE_KEY]);
  const getUserListError = createErrorMessageSelector([FETCH_USERS_STORE_KEY]);
  const usersGotoPage = useCallback((page: Page) => dispatch(goToPage(page)), [dispatch]);
  const isRepositListFetching = useSelector(userListLoadingSelector);
  const error = useSelector(getUserListError);

  const { sort, query, filter, tenancyStatus } = useSelector(getUserListFilters);
  const [debouncedQuery] = useDebounce(query, 500);
  const { page, count } = useSelector(getUserListPagination);
  const updateFilters = useCallback((filters: Partial<UserListFilters>) => dispatch(updateFiltersActionCreator(filters)), [
    dispatch
  ]);

  const users = useSelector(getUserList);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers, debouncedQuery, sort, filter, tenancyStatus, page]);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      updateFilters({ query });
    },
    [query, updateFilters]
  );

  const renderUsers = () => {
    if (error) return <Error error={error} />;
    if (isRepositListFetching) return <Loading />;
    if (!isRepositListFetching && !users.length)
      return (
        <Card>
          <Header3>No user found.</Header3>
          <P1>Please try another query or reset the filters and try again.</P1>
        </Card>
      );

    return (
      <Fragment>
        {users.map((user: any) => (
          <UserCard key={user.id} user={user} />
        ))}
        <Pagination count={count} currentPage={page} onNavigate={usersGotoPage} />
      </Fragment>
    );
  };

  return (
    <Container>
      <Row>
        <Col sm={12}>
          <Header2>Users</Header2>
          <form onSubmit={handleSubmit}>
            <Search onChange={query => updateFilters({ query })} value={query} placeholder="Search users..." />
          </form>
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <Filter
            label="Filter by"
            onSelect={filter => {
              updateFilters({ filter });
            }}
            options={filters}
            selected={filter}
          />
        </Col>
        <Col sm={4}>
          <Sort label="Sort by" onSelect={sort => updateFilters({ sort })} options={['Created at']} selected={'0'} />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <ContentContainer style={{ marginTop: 48 }}>{renderUsers()}</ContentContainer>
        </Col>
      </Row>
    </Container>
  );
};

export default Users;

import { LandlordDTO } from '@reposit/api-client';
import { Formik, FormikProps } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import FlashMessage from '../../components/FlashMessage';
import Loading from '../../components/Loading';
import RepositCard from '../../components/Reposit/RepositCard';
import { Caption, Header2 } from '../../components/Typography';
import {
  fetchLandlordDetailsRequested,
  FETCH_LANDLORD_DETAILS_STORE_KEY,
  updateLandlordDetailsApiRequested,
  UPDATE_LANDLORD_DETAILS_STORE_KEY,
} from '../../redux/landlord/landlord.actions';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import { AppState } from '../../redux/root.reducer';
import { getLandlord } from '../../redux/selectors';
import { getLandlordFullName } from '../../utils/common.utils';
import { FLASH_MESSAGE_TIMEOUT, useFlashMessage } from '../FlashMessage';
import LandlordForm from './LandlordFormFields';
import styled from 'styled-components';
import Button from '../../components/Button';

interface LandlordViewProps {}

interface LandlordPageParams {
  id: string;
}

const Action = styled.div`
  padding: 24px 0 0;
  text-align: right;
`;

export interface LandlordFormValues {
  landlord: {
    firstName: string;
    lastName: string;
    companyName: string;
    type: string;
    email: string;
  };
}

const VALIDATION_SCHEMA = yup.object().shape({
  landlord: yup.object({
    type: yup.string().required('Required'),
    email: yup.string().email().required('Required'),
    firstName: yup.string().when('type', {
      is: (type: string) => type === 'SOLE_PERSON',
      then: yup.string().required('Required'),
      otherwise: yup.string(),
    }),
    lastName: yup.string().when('type', {
      is: (type: string) => type === 'SOLE_PERSON',
      then: yup.string().required('Required'),
      otherwise: yup.string(),
    }),
    companyName: yup.string().when('type', {
      is: (type: string) => type === 'COMPANY',
      then: yup.string().required('Required'),
      otherwise: yup.string(),
    }),
  }),
});

const LandlordView: React.FC<LandlordViewProps> = () => {
  const dispatch = useDispatch();
  const { id } = useParams<LandlordPageParams>();
  const fetchLandlordDetails = useCallback(() => dispatch(fetchLandlordDetailsRequested(id)), [dispatch, id]);
  const landlordDetails = useSelector((state: AppState) => getLandlord(state, id));
  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage([
    UPDATE_LANDLORD_DETAILS_STORE_KEY,
    FETCH_LANDLORD_DETAILS_STORE_KEY,
  ]);
  const isLoading = useSelector(createLoadingSelector([UPDATE_LANDLORD_DETAILS_STORE_KEY]));

  useEffect(() => {
    fetchLandlordDetails();
  }, [fetchLandlordDetails]);

  const onSubmit = ({ landlord }: LandlordFormValues) => {
    if (landlord) {
      if (landlord.type === 'SOLE_PERSON') {
        landlord.companyName = '';
      } else {
        landlord.firstName = '';
        landlord.lastName = '';
      }
      dispatch(updateLandlordDetailsApiRequested(id, landlord));
    }
  };

  const renderLandlordDetails = (landlord: LandlordDTO) => {
    return (
      <>
        <Row>
          <Col lg={12}>
            <Header2>{getLandlordFullName(landlord)}</Header2>
            <Caption>{landlord.email}</Caption>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <RepositCard
              title="Landlord Details"
              flashMessage={
                flashMessagePayload ? (
                  <FlashMessage
                    onDismiss={onDismissFlashMessage}
                    timeRemaining={FLASH_MESSAGE_TIMEOUT}
                    payload={flashMessagePayload}
                  />
                ) : undefined
              }
              tooltip="???"
            >
              <Formik
                initialValues={{
                  landlord: {
                    firstName: landlord.firstName || '',
                    lastName: landlord.lastName || '',
                    email: landlord.email || '',
                    companyName: landlord.companyName || '',
                    type: landlord.type || 'SOLE_PERSON',
                  },
                }}
                onSubmit={onSubmit}
                validationSchema={VALIDATION_SCHEMA}
                enableReinitialize
              >
                {({
                  values,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  errors,
                  touched,
                  isValid,
                }: FormikProps<LandlordFormValues>) => (
                  <form onSubmit={handleSubmit}>
                    <LandlordForm
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      values={{ ...values.landlord }}
                      errors={{ ...errors.landlord }}
                      touched={{ ...touched.landlord }}
                    />
                    <Row>
                      <Col lg={12} style={{ padding: 0 }}>
                        <Action>
                          <Button buttonType="secondary" disabled={!isValid || isLoading}>
                            Update
                          </Button>
                        </Action>
                      </Col>
                    </Row>
                  </form>
                )}
              </Formik>
            </RepositCard>
          </Col>
        </Row>
      </>
    );
  };

  return <Container>{landlordDetails ? renderLandlordDetails(landlordDetails) : <Loading />}</Container>;
};

export default LandlordView;

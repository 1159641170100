import { KpiDocumentsActionTypes, FETCH_KPI_DOCUMENTS_API_SUCCESS } from './kpi-document.actions';
import { KPI_DOCUMENTS_PAGE_SIZE } from './kpi-document.saga';
import { KpiDocumentsState } from './kpi-document.types';

const initialState: KpiDocumentsState = {
  results: [],
  count: 0,
  page: 1,
  lastPage: undefined,
};

export default (state = initialState, action: KpiDocumentsActionTypes): KpiDocumentsState => {
  switch (action.type) {
    case FETCH_KPI_DOCUMENTS_API_SUCCESS:
      const last = Math.ceil(action.payload.count / KPI_DOCUMENTS_PAGE_SIZE);

      return {
        ...state,
        count: action.payload.count,
        lastPage: last,
        results: [...action.payload.ids],
      };

    default:
      return state;
  }
};

import { createAction } from '@reduxjs/toolkit';
import { CreateOrganizationInvitePayload, CreateOrganizationPayload, UpdateOrganizationNotesPayload } from './organization.types';

export const FETCH_ORGANIZATION_BY_ID_STORE_KEY = 'ORGANIZATIONS/FETCH_ORGANIZATION_BY_ID_STORE_KEY';
export const FETCH_ORGANIZATION_BY_ID_API_REQUESTED = 'ORGANIZATIONS/FETCH_ORGANIZATION_BY_ID_API_REQUESTED';
export const FETCH_ORGANIZATION_BY_ID_API_SUCCESS = 'ORGANIZATIONS/FETCH_ORGANIZATION_BY_ID_API_SUCCESS';
export const FETCH_ORGANIZATION_BY_ID_API_FAILED = 'ORGANIZATIONS/FETCH_ORGANIZATION_BY_ID_API_FAILED';

export const CREATE_ORGANIZATION_STORE_KEY = 'ORGANIZATIONS/CREATE_ORGANIZATION';
export const CREATE_ORGANIZATION_API_REQUESTED = 'ORGANIZATIONS/CREATE_ORGANIZATION_API_REQUESTED';
export const CREATE_ORGANIZATION_API_SUCCESS = 'ORGANIZATIONS/CREATE_ORGANIZATION_API_SUCCESS';
export const CREATE_ORGANIZATION_API_FAILED = 'ORGANIZATIONS/CREATE_ORGANIZATION_API_FAILED';

export const CREATE_ORGANIZATION_INVITE_STORE_KEY = 'ORGANIZATIONS/CREATE_ORGANIZATION_INVITE';
export const CREATE_ORGANIZATION_INVITE_API_REQUESTED = 'ORGANIZATIONS/CREATE_ORGANIZATION_INVITE_API_REQUESTED';
export const CREATE_ORGANIZATION_INVITE_API_SUCCESS = 'ORGANIZATIONS/CREATE_ORGANIZATION_INVITE_API_SUCCESS';
export const CREATE_ORGANIZATION_INVITE_API_FAILED = 'ORGANIZATIONS/CREATE_ORGANIZATION_INVITE_API_FAILED';

export const UPDATE_ORG_NOTES_STORE_KEY = 'ORGANIZATIONS/UPDATE_NOTES';

// TODO change these to use Redux Toolkit

export function fetchOrganizationByIdRequested(organizationId: string) {
  return {
    type: FETCH_ORGANIZATION_BY_ID_API_REQUESTED,
    payload: {
      organizationId,
    },
  } as const;
}

export function fetchOrganizationByIdSuccess() {
  return {
    type: FETCH_ORGANIZATION_BY_ID_API_SUCCESS,
    payload: {},
  } as const;
}

export function fetchOrganizationByIdFailed(error: string) {
  return {
    type: FETCH_ORGANIZATION_BY_ID_API_FAILED,
    payload: error,
  } as const;
}

export function createOrganizationRequested(payload: CreateOrganizationPayload) {
  return {
    type: CREATE_ORGANIZATION_API_REQUESTED,
    payload,
  } as const;
}

export function createOrganizationSuccess() {
  return {
    type: CREATE_ORGANIZATION_API_SUCCESS,
    payload: {},
  } as const;
}

export function createOrganizationFailed(error: string) {
  return {
    type: CREATE_ORGANIZATION_API_FAILED,
    payload: error,
  } as const;
}

export function createOrganizationInviteRequested(payload: CreateOrganizationInvitePayload) {
  return {
    type: CREATE_ORGANIZATION_INVITE_API_REQUESTED,
    payload,
  } as const;
}

export function createOrganizationInviteSuccess() {
  return {
    type: CREATE_ORGANIZATION_INVITE_API_SUCCESS,
    payload: {},
  } as const;
}

export function createOrganizationInviteFailed(error: string) {
  return {
    type: CREATE_ORGANIZATION_INVITE_API_FAILED,
    payload: error,
  } as const;
}

export const updateOrganizationNotesRequested = createAction<UpdateOrganizationNotesPayload>(
  `${UPDATE_ORG_NOTES_STORE_KEY}_REQUESTED`
);
export const updateOrganizationNotesSuccess = createAction(`${UPDATE_ORG_NOTES_STORE_KEY}_SUCCESS`);
export const updateOrganizationNotesFailed = createAction<string>(`${UPDATE_ORG_NOTES_STORE_KEY}_FAILED`);

export type OrganizationActionTypes = ReturnType<
  | typeof fetchOrganizationByIdRequested
  | typeof fetchOrganizationByIdSuccess
  | typeof fetchOrganizationByIdFailed
  | typeof createOrganizationRequested
  | typeof createOrganizationSuccess
  | typeof createOrganizationFailed
  | typeof createOrganizationInviteRequested
  | typeof createOrganizationInviteSuccess
  | typeof createOrganizationInviteFailed
  | typeof updateOrganizationNotesRequested
  | typeof updateOrganizationNotesSuccess
  | typeof updateOrganizationNotesFailed
>;

import {
  FETCH_ORGANIZATION_INTERNAL_OWNERS_BY_ID_API_SUCCESS,
  OrganizationInternalOwnerActions,
} from './organization-internal-owner.actions';

export interface OrganizationInternalOwnerState {
  organizationInternalOwners: string[];
}

const initialState: OrganizationInternalOwnerState = {
  organizationInternalOwners: [],
};

export default (state = initialState, action: OrganizationInternalOwnerActions): OrganizationInternalOwnerState => {
  switch (action.type) {
    case FETCH_ORGANIZATION_INTERNAL_OWNERS_BY_ID_API_SUCCESS:
      return {
        ...state,
        organizationInternalOwners: action.payload.ids,
      };

    default:
      return state;
  }
};

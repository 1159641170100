import { createAction } from '@reduxjs/toolkit';

export const FETCH_CREATED_REPOSITS_STORE_KEY = 'INTERNAL_REPORTS/FETCH_CREATED_REPOSITS_REPORT';

export const fetchCreatedRepositsRequested = createAction(`${FETCH_CREATED_REPOSITS_STORE_KEY}_API_REQUESTED`);
export const fetchCreatedRepositsSuccess = createAction(`${FETCH_CREATED_REPOSITS_STORE_KEY}_API_SUCCESS`);
export const fetchCreatedRepositsFailed = createAction<string>(`${FETCH_CREATED_REPOSITS_STORE_KEY}_API_FAILED`);

export const FETCH_COMPLETED_REPOSITS_STORE_KEY = 'INTERNAL_REPORTS/FETCH_COMPLETED_REPOSITS_REPORT';

export const fetchCompletedRepositsRequested = createAction(`${FETCH_COMPLETED_REPOSITS_STORE_KEY}_API_REQUESTED`);
export const fetchCompletedRepositsSuccess = createAction(`${FETCH_COMPLETED_REPOSITS_STORE_KEY}_API_SUCCESS`);
export const fetchCompletedRepositsFailed = createAction<string>(`${FETCH_COMPLETED_REPOSITS_STORE_KEY}_API_FAILED`);

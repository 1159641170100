import { OrganizationRecipientDTOGroupEnum } from '@reposit/api-client';
import { AppState } from '../root.reducer';

export const getOrganizationRecipientsByGroup = (group?: OrganizationRecipientDTOGroupEnum) => (state: AppState) => {
  const recipients = state.recipients;
  const orgRecips = recipients.organizationRecipients.map((r) => state.entities.organizationRecipient[r]);
  return orgRecips.filter((r) => (group ? r.group === group : true));
};

export const getAllOrganizationRecipients = (organizationId: string) => (state: AppState) => {
  const organization = state.entities.organization[organizationId];
  const organizationRecipients = organization.organizationRecipients;
  return organizationRecipients;
};

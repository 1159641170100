import { OrganizationRecipientDTOGroupEnum } from '@reposit/api-client';
import React, { useEffect } from 'react';
import { Container } from 'react-grid-system';
import OrganizationRecipientGroup from './OrganizationRecipientGroup';
import { useDispatch } from 'react-redux';
import { fetchOrganizationRecipientRequested } from '../../redux/organization-recipient/organization-recipient.actions';

interface OrganizationRecipientsProps {
  organizationId: string;
}

const OrganizationRecipients: React.FC<OrganizationRecipientsProps> = ({ organizationId }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOrganizationRecipientRequested({ organizationId }));
  }, [dispatch, organizationId]);
  return (
    <Container>
      <OrganizationRecipientGroup group={OrganizationRecipientDTOGroupEnum.INITIAL} />
      <OrganizationRecipientGroup group={OrganizationRecipientDTOGroupEnum.ADMIN} />
    </Container>
  );
};

export default OrganizationRecipients;

import Modal from '../Modal/index';
import React, { Fragment } from 'react';
import { Header3 } from '../Typography/index';
import GuarantorForm, { GuarantorFormValues } from './guarantor-form';
import Button from '../Button/index';
import styled from 'styled-components';
import { useFailedFlashMessage } from '../../containers/FlashMessage/index';
import { UPDATE_GUARANTOR_STORE_KEY, ADD_GUARANTOR_STORE_KEY } from '../../redux/guarantor/guarantor.actions';
import FlashMessage from '../FlashMessage/index';
import { TenantWithActions } from '../../redux/selectors';
import { TenantEntity } from '../../redux/entities/entities.types';

interface GuarantorModalProps {
  modalType: 'edit' | 'add';
  onDismiss: () => void;
  initialValues?: GuarantorFormValues;
  onSubmit: (values: GuarantorFormValues) => void;
  isLoading: boolean;
  tenant: TenantWithActions | TenantEntity;
  onDelete: () => void;
}

const Action = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

export const GuarantorModal: React.FC<GuarantorModalProps> = ({
  modalType,
  onDismiss,
  initialValues,
  onSubmit,
  isLoading,
  tenant,
}) => {
  const tenantName = tenant.firstName && tenant.lastName ? `${tenant.firstName} ${tenant.lastName}` : tenant.email;
  const header = modalType === 'edit' ? `Edit the guarantor for ${tenantName}` : `Add a guarantor for ${tenantName}`;

  const buttonText = modalType === 'edit' ? 'Update' : 'Add';

  const [flashMessage, onDismissFlashMessage] = useFailedFlashMessage([UPDATE_GUARANTOR_STORE_KEY, ADD_GUARANTOR_STORE_KEY]);
  return (
    <Modal
      onDismiss={onDismiss}
      flashMessage={
        flashMessage ? <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={5000} payload={flashMessage} /> : undefined
      }
    >
      <Fragment>
        <Header3 style={{ marginBottom: 6 }}>{header}</Header3>
        <GuarantorForm
          onSubmit={onSubmit}
          formActions={
            <Action>
              <Button disabled={isLoading}>{buttonText}</Button>
              <Button buttonType="secondary" noArrow={true} onClick={onDismiss}>
                Cancel
              </Button>
            </Action>
          }
          initialValues={initialValues}
        />
      </Fragment>
    </Modal>
  );
};

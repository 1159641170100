import React from 'react';
import styled from 'styled-components';
import { OrganizationBankAccountDTOAccountTypeEnum } from '@reposit/api-client';

interface BankAccountTypeProps {
  accountType: OrganizationBankAccountDTOAccountTypeEnum;
  label?: string;
}

const Container = styled.div<BankAccountTypeProps>`
  background: ${(props) => props.theme.colors.positive};
  border-radius: 24px;
  display: inline-block;
  color: #fff;
  font-family: ${(props) => props.theme.typography.face.secondary};
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 0 auto;
  padding: 6px 20px;
  min-width: 56px;
  text-align: center;

  ${(props) =>
    props.accountType === 'DEFAULT' &&
    `
    background: ${props.theme.colors.positive}
  `}

  ${(props) =>
    props.accountType === 'CLAIM' &&
    `
    background: ${props.theme.colors.warning}
  `}
`;

const resolveLabel = {
  DEFAULT: 'Primary',
  CLAIM: 'Claim',
} as any;

const BankAccountType: React.FC<BankAccountTypeProps> = ({ accountType, label }) => {
  return <Container accountType={accountType}>{label || resolveLabel[accountType]}</Container>;
};

export default BankAccountType;

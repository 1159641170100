import React from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import styled from 'styled-components';

import Modal from '../../../components/Modal';
import {
  setCurrentRepositActionModal,
  addTenantOnRepositRequested,
  ADD_TENANT_ON_REPOSIT_STORE_KEY,
} from '../../../redux/reposit/reposit.actions';
import { Header3 } from '../../../components/Typography';
import FieldWithLabel from '../../../components/FormFields/FieldWithLabel';
import { Input } from '../../../components/FormFields';
import Button from '../../../components/Button';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';

interface AddTenantModalProps {
  tenancyId: string;
}

const AddTenantSchema = Yup.object().shape({
  newTenantEmail: Yup.string().email('Must be a valid email').required('Required'),
});

const ActionBar = styled.div`
  display: flex;
  padding: 24px 0;
  justify-content: flex-end;
  flex-wrap: wrap;
  row-gap: 10px;

  button {
    margin-right: 10px;
  }
`;

export const AddTenantModal: React.FC<AddTenantModalProps> = ({ tenancyId }) => {
  const dispatch = useDispatch();
  const isSubmittingSelector = createLoadingSelector([ADD_TENANT_ON_REPOSIT_STORE_KEY]);
  const isSubmitting = useSelector(isSubmittingSelector);
  return (
    <Modal onDismiss={() => dispatch(setCurrentRepositActionModal({ name: '' }))}>
      <Header3 style={{ marginBottom: 6 }}>Please enter the email for the new tenant </Header3>
      <Formik
        initialValues={{ newTenantEmail: '' }}
        onSubmit={(values) => {
          dispatch(
            addTenantOnRepositRequested({
              tenancyId: tenancyId,
              newTenantEmail: values.newTenantEmail,
            })
          );
        }}
        validationSchema={AddTenantSchema}
      >
        {({ touched, errors, values, handleBlur, handleChange, handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FieldWithLabel
                label="New tenant email address"
                style={{ marginBottom: 24 }}
                touched={touched.newTenantEmail}
                error={errors.newTenantEmail}
              >
                <Input
                  value={values.newTenantEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="newTenantEmail"
                  touched={touched.newTenantEmail}
                  error={errors.newTenantEmail}
                />
              </FieldWithLabel>

              <ActionBar>
                <Button disabled={isSubmitting} type="submit">
                  Confirm
                </Button>
              </ActionBar>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

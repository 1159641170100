import React from 'react';
import { Container, Row } from 'react-grid-system';
import { FLASH_MESSAGE_TIMEOUT, useFlashMessage } from '../../containers/FlashMessage';
import { DELETE_PAYMENT_PLAN_STORE_KEY } from '../../redux/payment-plan/payment-plan.actions';
import Button from '../Button';
import FlashMessage from '../FlashMessage';
import Modal from '../Modal';
import { Header3, P1 } from '../Typography';

interface DeletePaymentPlanModalProps {
  onDismiss: () => void;
  onSubmit: () => void;
  isSubmitting: boolean;
}

const DeletePaymentPlanModal: React.FC<DeletePaymentPlanModalProps> = ({ onDismiss, onSubmit, isSubmitting }) => {
  const [flashMessagePayload, dismissFlashMessage] = useFlashMessage([DELETE_PAYMENT_PLAN_STORE_KEY]);
  return (
    <Modal onDismiss={onDismiss}>
      {flashMessagePayload ? (
        <FlashMessage payload={flashMessagePayload} onDismiss={dismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMEOUT} />
      ) : null}
      <Header3 style={{ marginBottom: 15 }}>Delete Payment Plan</Header3>
      <P1 style={{ marginBottom: 20 }}>Are you sure you would like to delete this payment plan?</P1>
      <>
        <Container fluid>
          <Row style={{ display: 'flex', justifyContent: 'flex-end', columnGap: '8px', paddingTop: '24px' }}>
            <Button buttonType="secondary" noArrow={true} onClick={onDismiss}>
              Cancel
            </Button>
            <Button disabled={isSubmitting} onClick={onSubmit}>
              Confirm
            </Button>
          </Row>
        </Container>
      </>
    </Modal>
  );
};

export default DeletePaymentPlanModal;

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { appConfig } from '../../../appConfig';
import Button from '../../../components/Button';
import DatePicker from '../../../components/FormFields/DatePicker';
import { A, Header2, Header4, P2 } from '../../../components/Typography';
import { DATE_FORMAT } from '../../../constants/dates';
import { fetchKpiDocumentsRequested, FETCH_KPI_DOCUMENTS_STORE_KEY } from '../../../redux/kpi-document/kpi-document.actions';
import { getKpiDocuments } from '../../../redux/kpi-document/kpi-document.selectors';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import { useFlashMessage, FLASH_MESSAGE_TIMEOUT } from '../../FlashMessage';
import FlashMessage from '../../../components/FlashMessage';

const Wrapper = styled.div`
  margin-top: 1em;
`;

export const Kpis = () => {
  const dispatch = useDispatch();
  const [kpiDate, setKpiDate] = useState(new Date());

  const kpiDocuments = useSelector(getKpiDocuments);

  const isSearchingSelector = createLoadingSelector([FETCH_KPI_DOCUMENTS_STORE_KEY]);
  const isSearching = useSelector(isSearchingSelector);
  const [globalFlashMessagePayload, onDismissGlobalFlashMessage] = useFlashMessage([FETCH_KPI_DOCUMENTS_STORE_KEY]);

  // fetch on initial render
  useEffect(() => {
    dispatch(fetchKpiDocumentsRequested(moment().format('YYYY-MM-DD')));
  }, [dispatch]);

  const onClick = () => {
    dispatch(fetchKpiDocumentsRequested(moment(kpiDate).format('YYYY-MM-DD')));
  };

  return (
    <Container>
      <Row>
        <Col sm={12}>
          {globalFlashMessagePayload ? (
            <>
              <br />
              <FlashMessage
                onDismiss={onDismissGlobalFlashMessage}
                timeRemaining={FLASH_MESSAGE_TIMEOUT}
                payload={globalFlashMessagePayload}
              />
            </>
          ) : undefined}
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Header2>KPIs</Header2>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Header4>Please choose a date below to fetch daily KPI summary data</Header4>
          <P2>A new file will be generated every day with KPIs as at the close of the previous day</P2>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <DatePicker
            name="kpiDocumentsDate"
            value={kpiDate}
            dateFormat={DATE_FORMAT}
            onBlur={() => {}}
            placeholder={'DD/MM/YYYY'}
            minDate={moment(appConfig.earliestKpiDate, 'YYYY-MM-DD').toDate()}
            maxDate={moment().toDate()}
            onChange={(date) => {
              date && setKpiDate(date);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Wrapper>
            <Button onClick={onClick} buttonType="secondary" disabled={isSearching}>
              Get KPI Documents
            </Button>
          </Wrapper>
        </Col>
      </Row>
      {/* This may need pagination in the future */}
      {kpiDocuments &&
        !!kpiDocuments.length &&
        kpiDocuments.map(({ document }) => {
          return (
            <Row key={document && document.id}>
              <Col sm={12}>
                <Wrapper>
                  <A href={document && document.url} download>
                    {document && document.name}
                  </A>
                </Wrapper>
              </Col>
            </Row>
          );
        })}

      {kpiDocuments && !kpiDocuments.length && (
        <Row>
          <Col sm={12}>
            <Wrapper>
              <Header4>No KPI documents found for this date</Header4>
            </Wrapper>
          </Col>
        </Row>
      )}
    </Container>
  );
};

import { UserListFilters } from './user-list.types';

export const FETCH_USERS_STORE_KEY = 'USER_LIST/FETCH_USERS';
export const FETCH_USERS_API_REQUESTED = 'USER_LIST/FETCH_USERS_API_REQUESTED';
export const FETCH_USERS_API_SUCCESS = 'USER_LIST/FETCH_USERS_API_SUCCESS';
export const FETCH_USERS_API_FAILED = 'USER_LIST/FETCH_USERS_API_FAILED';

export const USERS_GOTO_PAGE = 'USER_LIST/USERS_GOTO_PAGE';
export const USERS_UPDATE_FILTERS = 'USER_LIST/UPDATE_FILTERS';

export function fetchUsersRequested() {
  return {
    type: FETCH_USERS_API_REQUESTED
  } as const;
}

export function fetchUsersSuccess(count: number, ids: string[]) {
  return {
    type: FETCH_USERS_API_SUCCESS,
    payload: {
      count,
      ids
    }
  } as const;
}

export function fetchUsersFailed(error: string) {
  return {
    type: FETCH_USERS_API_FAILED,
    payload: error
  } as const;
}

export type Page = number | 'next' | 'prev';
export function goToPage(page: Page) {
  return {
    type: USERS_GOTO_PAGE,
    payload: page
  } as const;
}
export function updateFilters(filters: Partial<UserListFilters>) {
  return {
    type: USERS_UPDATE_FILTERS,
    payload: filters
  } as const;
}

export type UserListActionTypes = ReturnType<
  typeof fetchUsersRequested | typeof fetchUsersSuccess | typeof fetchUsersFailed | typeof goToPage | typeof updateFilters
>;

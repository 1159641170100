import { filter } from 'lodash';
import { OrganizationBankAccountEntity } from '../entities/entities.types';
import { AppState } from '../root.reducer';

export const getOrganizationBankAccounts = (state: AppState, organizationId: string): OrganizationBankAccountEntity[] => {
  if (!state.entities.organizationBankAccount) return [];

  if (!organizationId) return [];

  return filter(state.entities.organizationBankAccount, (item) => item.organizationId === organizationId);
};

import { InternalReportingApi } from '@reposit/api-client';
import { get } from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { runSagaWithAuth } from '../utils/api.utils';
import { AxiosResponse } from 'axios';
import {
  fetchCompletedRepositsSuccess,
  fetchCompletedRepositsFailed,
  fetchCompletedRepositsRequested,
  fetchCreatedRepositsSuccess,
  fetchCreatedRepositsFailed,
  fetchCreatedRepositsRequested,
} from './internal-reports.actions';
import { createInternalReportingApi } from '../utils/api.utils';

// ****************
// WORKERS
// ****************

export function* getCompletedRepositsReport() {
  try {
    const api: InternalReportingApi = yield createInternalReportingApi();

    const result: AxiosResponse<any> = yield call(runSagaWithAuth(() => api.getCompletedReposits()));

    const fileBlob = new Blob([result.data], { type: 'text/csv' });
    const url = URL.createObjectURL(fileBlob);
    window.open(url);
    URL.revokeObjectURL(url);
    yield put(fetchCompletedRepositsSuccess());
  } catch (e) {
    yield put(fetchCompletedRepositsFailed(get(e, 'response.data.message', e)));
  }
}

export function* getCreatedRepositsReport() {
  try {
    const api: InternalReportingApi = yield createInternalReportingApi();

    const result: AxiosResponse<any> = yield call(runSagaWithAuth(() => api.getCreatedReposits()));

    const fileBlob = new Blob([result.data], { type: 'text/csv' });
    const url = URL.createObjectURL(fileBlob);
    window.open(url);
    URL.revokeObjectURL(url);
    yield put(fetchCreatedRepositsSuccess());
  } catch (e) {
    yield put(fetchCreatedRepositsFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchInternalReportsSagas() {
  yield takeLatest(fetchCompletedRepositsRequested.type, getCompletedRepositsReport);
  yield takeLatest(fetchCreatedRepositsRequested.type, getCreatedRepositsReport);
}

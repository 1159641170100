import { ClaimListFilters } from './claim-list.types';

export const FETCH_CLAIMS_STORE_KEY = `CLAIM_LIST/FETCH_CLAIMS`;
export const FETCH_CLAIMS_API_REQUESTED = `CLAIM_LIST/FETCH_CLAIMS_API_REQUESTED`;
export const FETCH_CLAIMS_API_SUCCESS = `CLAIM_LIST/FETCH_CLAIMS_API_SUCCESS`;
export const FETCH_CLAIMS_API_FAILED = `CLAIM_LIST/FETCH_CLAIMS_API_FAILED`;

export const CLAIMS_GOTO_PAGE = 'CLAIM_LIST/CLAIMS_GOTO_PAGE';

export const CLAIMS_UPDATE_FILTERS = 'CLAIM_LIST/UPDATE_FILTERS';
export const CLEAR_FILTERS = 'CLAIM_LIST/CLEAR_FILTERS';

export function fetchClaimsRequested(organizationId?: string) {
  return {
    type: FETCH_CLAIMS_API_REQUESTED,
    payload: {
      organizationId
    }
  } as const;
}
export function fetchClaimsSuccess(count: number, ids: string[]) {
  return {
    type: FETCH_CLAIMS_API_SUCCESS,
    payload: {
      count,
      ids
    }
  } as const;
}
export function fetchClaimsFailed(error: string) {
  return {
    type: FETCH_CLAIMS_API_FAILED,
    payload: error
  } as const;
}
export type Page = number | 'next' | 'prev';
export function goToPage(page: Page) {
  return {
    type: CLAIMS_GOTO_PAGE,
    payload: page
  } as const;
}
export function updateFilters(filters: Partial<ClaimListFilters>) {
  return {
    type: CLAIMS_UPDATE_FILTERS,
    payload: filters
  } as const;
}

export function clearFilters() {
  return {
    type: CLEAR_FILTERS
  } as const;
}

export type ClaimListActionTypes = ReturnType<
  | typeof fetchClaimsRequested
  | typeof fetchClaimsSuccess
  | typeof fetchClaimsFailed
  | typeof goToPage
  | typeof updateFilters
  | typeof clearFilters
>;

import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-grid-system';
import { useDebounce } from 'use-debounce';
import { startCase, toLower } from 'lodash';

import { Header2 } from '../../../components/Typography';
import { ContentContainer } from '../../../components/Common';
import Search from '../../../components/Search';
import Sort from '../../../components/Sort';
import ClaimList from '../../../components/Claim/ClaimList';
import {
  fetchClaimsRequested,
  updateFilters as updateFiltersActionCreator,
  FETCH_CLAIMS_STORE_KEY,
  goToPage,
  Page,
} from '../../../redux/claim-list/claim-list.actions';
import { getClaimList, getClaimListFilters, getClaimListPagination } from '../../../redux/claim-list/claim-list.selectors';
import { ClaimListFilters } from '../../../redux/claim-list/claim-list.types';
import { ClaimSort, ClaimFilter } from '../../../constants/claims';
import { createErrorMessageSelector } from '../../../redux/error/error.selector';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import Loading from '../../../components/Loading';
import Error from '../../../components/Error';
import Pagination from '../../../components/Pagination';
import NoClaims from '../../../components/Claim/NoClaimsList/index';
import SelectFilter from '../../../components/SelectFilter';

interface ClaimListContainerProps {
  organizationId?: string;
}

const sortOptions = Object.values(ClaimSort).map((value) => ({ label: startCase(toLower(value)), value }));

const filters = Object.values(ClaimFilter).map((value: ClaimFilter) => {
  let label;
  if (!value) {
    label = 'All';
  } else if (value === ClaimFilter.IS_PAID) {
    label = 'Agent Paid';
  } else {
    label = value ? startCase(toLower(value)) : 'All';
  }
  return {
    label,
    value,
  };
});

const ClaimListContainer: React.FC<ClaimListContainerProps> = ({ organizationId }) => {
  const dispatch = useDispatch();
  const getClaimListError = createErrorMessageSelector([FETCH_CLAIMS_STORE_KEY]);
  const claimListLoadingSelector = createLoadingSelector([FETCH_CLAIMS_STORE_KEY]);
  const fetchClaims = useCallback(() => dispatch(fetchClaimsRequested(organizationId)), [dispatch, organizationId]);
  const claims = useSelector(getClaimList);
  const { filter, sort, query } = useSelector(getClaimListFilters);
  const [debouncedQuery] = useDebounce(query, 800);
  const { page, count } = useSelector(getClaimListPagination);
  const error = useSelector(getClaimListError);
  const isClaimListFetching = useSelector(claimListLoadingSelector);
  const claimsGotoPage = useCallback((page: Page) => dispatch(goToPage(page)), [dispatch]);

  const updateFilters = useCallback(
    (filters: Partial<ClaimListFilters>) => dispatch(updateFiltersActionCreator(filters)),
    [dispatch]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      updateFilters({ query });
    },
    [query, updateFilters]
  );

  useEffect(() => {
    fetchClaims();
  }, [fetchClaims, debouncedQuery, page, filter, sort]);

  const renderList = () => {
    if (error) return <Error error={error} />;
    if (isClaimListFetching) return <Loading />;
    if (!isClaimListFetching && claims.length === 0) return <NoClaims currentFilter={filter} />;

    return (
      <>
        <ClaimList claims={claims} />
        <Pagination count={count} currentPage={page} onNavigate={claimsGotoPage} />
      </>
    );
  };

  return (
    <Container>
      <Row>
        <Col sm={12}>
          <Header2>Claims</Header2>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <ContentContainer>
            <form onSubmit={handleSubmit}>
              <Search onChange={(query) => updateFilters({ query })} value={query} placeholder="Search Claims" />
            </form>
          </ContentContainer>
        </Col>
      </Row>
      <Row>
        <Col lg={8}>
          <SelectFilter
            label="Filter by status"
            onSelect={(filter) => updateFilters({ filter })}
            options={filters}
            selected={filter}
          />
        </Col>
        <Col lg={4} style={{ paddingBottom: 12 }}>
          <Sort label="Sort Reposits by" onSelect={(sort) => updateFilters({ sort })} options={sortOptions} selected={''} />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>{renderList()}</Col>
      </Row>
    </Container>
  );
};

export default ClaimListContainer;

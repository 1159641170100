export const FETCH_TENANCY_ANNIVERSARIES_STORE_KEY = 'REPOSIT/FETCH_TENANCY_ANNIVERSARIES';
export const FETCH_TENANCY_ANNIVERSARIES_API_REQUESTED = 'REPOSIT/FETCH_TENANCY_ANNIVERSARIES_API_REQUESTED';
export const FETCH_TENANCY_ANNIVERSARIES_API_SUCCESS = 'REPOSIT/FETCH_TENANCY_ANNIVERSARIES_API_SUCCESS';
export const FETCH_TENANCY_ANNIVERSARIES_API_FAILED = 'REPOSIT/FETCH_TENANCY_ANNIVERSARIES_API_FAILED';

/* -------------------------------------------- FETCH TENANCY ORDER -------------------------------------------- */
export function fetchTenancyAnniversariesRequested(payload: string) {
  return {
    type: FETCH_TENANCY_ANNIVERSARIES_API_REQUESTED,
    payload,
  } as const;
}
export function fetchTenancyAnniversariesSuccess(payload: string[]) {
  return {
    type: FETCH_TENANCY_ANNIVERSARIES_API_SUCCESS,
    payload,
  } as const;
}
export function fetchTenancyAnniversariesFailed(error: string) {
  return {
    type: FETCH_TENANCY_ANNIVERSARIES_API_FAILED,
    payload: error,
  } as const;
}

export type TenancyAnniversaryActionTypes = ReturnType<
  typeof fetchTenancyAnniversariesRequested | typeof fetchTenancyAnniversariesSuccess | typeof fetchTenancyAnniversariesFailed
>;

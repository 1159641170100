import styled, { css } from 'styled-components';
import React from 'react';
import { P3, P2, Header4 } from '../../Typography';
import { penceToPounds } from '../../../utils/common.utils';
import Button from '../../Button';
import { getBreakpoint } from '../../../base/style';

const EvidenceChecklistStyles = css`
  color: ${(props) => props.theme.colors.primaryBrand};
  cursor: pointer;
  display: block;
  display: flex;
  margin-bottom: 0px;

  span {
    text-decoration: underline;
  }

  &:after {
    content: '+';
    display: block;
    margin: 0 6px;
  }
`;

export const EvidenceChecklistP3 = styled(P3)`
  ${EvidenceChecklistStyles}
`;

export const EvidenceChecklistP2 = styled(P2)`
  ${EvidenceChecklistStyles}
`;

export const StatusIcon = styled.i<{ icon?: string }>`
  background: ${(props) => (props.icon ? `${props.theme.colors.bgAccent} url(${props.icon}) no-repeat center center` : 'none')};
  background-size: 25px;
  border-radius: 48px;
  display: block;
  height: 25px;
  width: 25px;
`;

export const AmountContainer = styled(Header4)`
  padding: 0;
  margin-bottom: 0;
`;

interface AmountProps {
  amount: number;
  style?: React.CSSProperties;
}

export const Amount: React.FC<AmountProps> = ({ amount, style }) => {
  return <AmountContainer style={style}>£{penceToPounds(amount)}</AmountContainer>;
};

export const Panel = styled.div`
  background: ${(props) => props.theme.colors.bgAccent};
  border: none;
  transition: background-color 0.75s ease;
  margin: 8px 0;
  padding: 24px 34px;
`;

export const ItemInfoContainer = styled.div<{ column: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

export const EditButtonContainer = styled.div`
  display: flex;
  margin-top: 8px;
  justify-content: flex-end;
`;

export const EditButton = styled(Button)`
  font-size: 0.76em;
  width: 100%;
  justify-content: center;
  padding: 8px 4px;

  @media screen and (min-width: ${getBreakpoint('md')}) {
    font-size: 0.68em;
    width: auto;
    // original - had to set because we changed it above - ugh
    padding: 0.5em 1.5em 0.5em 1.75em;
  }
`;

const ButtonStyles = css`
  font-size: 0.76em;
  width: 50%;
  justify-content: center;
  padding: 8px 4px;

  @media screen and (min-width: ${getBreakpoint('md')}) {
    font-size: 0.68em;
    width: auto;
    // original - had to set because we changed it above - ugh
    padding: 0.5em 1.5em 0.5em 1.75em;
  }
`;

export const AgreeButton = styled(Button)`
  ${ButtonStyles}
  margin-right: 10px;
  background-image: ${(props) => `linear-gradient(to bottom, ${props.theme.colors.positive}, #669966)`};
  &:hover {
    background-image: ${(props) => `linear-gradient(to bottom, ${props.theme.colors.positive}, ${props.theme.colors.positive})`};
  }
`;

export const DisagreeButton = styled(Button)`
  ${ButtonStyles}
  background-image: linear-gradient(to bottom, ${(props) => props.theme.colors.negative}, #b01e1e);
  &:hover {
    background-image: linear-gradient(
      to bottom,
      ${(props) => props.theme.colors.negative},
      ${(props) => props.theme.colors.negative}
    );
  }
`;

export const UploadButton = styled(Button)`
  align-self: flex-end;
  margin-top: 10px;
`;

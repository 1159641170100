import { TenancyAnniversaryActionTypes, FETCH_TENANCY_ANNIVERSARIES_API_SUCCESS } from './tenancy-anniversary.actions';
import { TenancyAnniversaryState } from './tenancy-anniversary.types';

const initialState: TenancyAnniversaryState = {
  anniversaries: [],
};

export default (state = initialState, action: TenancyAnniversaryActionTypes): TenancyAnniversaryState => {
  switch (action.type) {
    // requested and failed are handled in loading and error reducers
    case FETCH_TENANCY_ANNIVERSARIES_API_SUCCESS: {
      return {
        ...state,
        anniversaries: action.payload,
      };
    }

    default:
      return state;
  }
};

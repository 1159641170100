import React from "react";
import styled from "styled-components";

export interface AccountSelectorProps {
  activeAccount: string;
  onChange: ((event: React.ChangeEvent<HTMLSelectElement>) => void) | undefined;
  organizations: any[];
}

const StyledSelect = styled.div`
  width: 240px;
  height: 48px;
  margin: 24px 0 36px;
  overflow: hidden;
  background: #fff;
  transition: all 0.2s;
  position: relative;
`;

const Selector = styled.select`
  background: transparent;
  border: none;
  border-bottom: 1px solid ${props => props.theme.colors.dividerLight};
  color: ${props => props.theme.colors.negative};
  cursor: pointer;
  display: block;
  font-family: ${props => props.theme.typography.face.primary};
  font-size: 14px;
  font-weight: bold;
  padding: 14px 24px;
  outline: none;
  height: 48px;
  width: 100%;
  -webkit-appearance: none;

  &:disabled {
    cursor: default;
  }
`;

const AccountSelector: React.SFC<AccountSelectorProps> = ({
  onChange,
  activeAccount
}) => {
  return (
    <StyledSelect>
      <Selector onChange={onChange} defaultValue={activeAccount} disabled>
        <option key={`org.id`} value={`org.id`}>
          REPOSIT ADMIN
        </option>
      </Selector>
    </StyledSelect>
  );
};

export default AccountSelector;

export enum OrganizationFilter {
  ALL = '',
  AGENCY = 'AGENCY',
  BUILD_TO_RENT = 'BUILD_TO_RENT',
  PRIVATE = 'PRIVATE',
  INTEGRATOR_REFERRAL = 'INTEGRATOR_REFERRAL',
}

export enum OrganizationSort {
  CREATED_AT = 'CREATED_AT',
  ALPHABETICAL = 'ALPHABETICAL',
}

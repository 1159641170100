import {
  DeleteClaimDocumentPayload,
  DeleteClaimDocumentSuccessPayload,
  CreateClaimDocumentPayload,
  CreateClaimDocumentSuccessPayload
} from './claim-document.types';

export const DELETE_CLAIM_DOCUMENT_STORE_KEY = `CLAIM_DOCUMENT/DELETE_CLAIM_DOCUMENT`;
export const DELETE_CLAIM_DOCUMENT_API_REQUESTED = `CLAIM_DOCUMENT/DELETE_CLAIM_DOCUMENT_API_REQUESTED`;
export const DELETE_CLAIM_DOCUMENT_API_SUCCESS = `CLAIM_DOCUMENT/DELETE_CLAIM_DOCUMENT_API_SUCCESS`;
export const DELETE_CLAIM_DOCUMENT_API_FAILED = `CLAIM_DOCUMENT/DELETE_CLAIM_DOCUMENT_API_FAILED`;

export const CREATE_CLAIM_DOCUMENT_STORE_KEY = `CLAIM_DOCUMENT/CREATE_CLAIM_DOCUMENT`;
export const CREATE_CLAIM_DOCUMENT_API_REQUESTED = `CLAIM_DOCUMENT/CREATE_CLAIM_DOCUMENT_API_REQUESTED`;
export const CREATE_CLAIM_DOCUMENT_API_SUCCESS = `CLAIM_DOCUMENT/CREATE_CLAIM_DOCUMENT_API_SUCCESS`;
export const CREATE_CLAIM_DOCUMENT_API_FAILED = `CLAIM_DOCUMENT/CREATE_CLAIM_DOCUMENT_API_FAILED`;

/* -------------------------------------------- DELETE CLAIM DOCUMENT -------------------------------------------- */
export function deleteClaimDocumentRequested(payload: DeleteClaimDocumentPayload) {
  return {
    type: DELETE_CLAIM_DOCUMENT_API_REQUESTED,
    payload
  } as const;
}
export function deleteClaimDocumentSuccess(payload: DeleteClaimDocumentSuccessPayload) {
  return {
    type: DELETE_CLAIM_DOCUMENT_API_SUCCESS,
    payload
  } as const;
}
export function deleteClaimDocumentFailed(error: string) {
  return {
    type: DELETE_CLAIM_DOCUMENT_API_FAILED,
    payload: error
  } as const;
}

/* -------------------------------------------- CREATE CLAIM DOCUMENT -------------------------------------------- */
export function createClaimDocumentRequested(payload: CreateClaimDocumentPayload) {
  return {
    type: CREATE_CLAIM_DOCUMENT_API_REQUESTED,
    payload
  } as const;
}
export function createClaimDocumentSuccess(payload: CreateClaimDocumentSuccessPayload) {
  return {
    type: CREATE_CLAIM_DOCUMENT_API_SUCCESS,
    payload
  } as const;
}
export function createClaimDocumentFailed(error: string) {
  return {
    type: CREATE_CLAIM_DOCUMENT_API_FAILED,
    payload: error
  } as const;
}

export type ClaimDocumentActionTypes = ReturnType<
  | typeof deleteClaimDocumentRequested
  | typeof deleteClaimDocumentSuccess
  | typeof deleteClaimDocumentFailed
  | typeof createClaimDocumentRequested
  | typeof createClaimDocumentSuccess
  | typeof createClaimDocumentFailed
>;

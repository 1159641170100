import { OrderCustomerActionDTOTypeIdEnum, OrderDTOStatusIdEnum } from '@reposit/api-client';
import { get } from 'lodash';
import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { getBreakpoint } from '../../base/style';
import {
  ADD_GUARANTOR_STORE_KEY,
  UPDATE_GUARANTOR_STORE_KEY,
  addGuarantorRequested,
  deleteGuarantorRequested,
  setGuarantorModal,
  updateGuarantorRequested,
} from '../../redux/guarantor/guarantor.actions';
import { getGuarantorModal } from '../../redux/guarantor/guarantor.selectors';
import { GuarantorModalPayload } from '../../redux/guarantor/guarantor.types';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import {
  UPDATE_TENANT_DETAILS_STORE_KEY,
  setTenantEditFormOpenStatus,
  updateTenantDetailsRequested,
} from '../../redux/reposit/reposit.actions';
import { getTenantEditFormsOpen } from '../../redux/reposit/reposit.selectors';
import { RepositAction, TenantCompletionStatus, TenantWithActions, getTenantActions } from '../../redux/selectors';
import Button from '../Button';
import TenantForm from '../TenantForm';
import TenantProgress from '../TenantProgress';
import { Header4, P2, P3, StyledLink } from '../Typography/index';
import { DeleteGuarantorModal } from './delete-guarantor-modal';
import { GuarantorFormValues } from './guarantor-form';
import { GuarantorModal } from './guarantor-modal';

interface TenantListItemProps {
  tenant: TenantWithActions;
  onDeleteClick?: (id: string) => void;
  hideButtons?: boolean;
  tenancyId: string;
  tenancyOrderStatus: string;
  tenancyOrderId: string;
}

const TenantItem = styled.div<{ isOpen?: boolean }>`
  background: ${(props) => props.theme.colors.bgAccent};
  margin: 0 0 6px;
  padding: 0 24px 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    padding: 0 34px;
  }

  &:hover {
    background: ${(props) => (!props.isOpen ? `#f2f3f4` : props.theme.colors.bgAccent)};
  }
`;

const TenantItemInner = styled.li`
  border-radius: 8px;
  cursor: default;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 6px;
  padding-top: 15px;
`;

const Tenant = styled.div`
  align-items: center;
  display: flex;
`;

const TenantName = styled(Header4)`
  font-size: 1em;
  margin: 0;
`;

const TenantEmail = styled(P2)`
  margin: 0 0 0 10px;
`;

const ViewReference = styled(NavLink)`
  margin: 12px 0px 12px auto;
`;

const NoReference = styled(P2)`
  margin: 0 0 0 auto;
`;

const EditAction = styled.div`
  margin: 0 0 0 auto;
`;

const TenantLiability = styled(P2)`
  background: ${(props) => props.theme.colors.positive};
  border-radius: 8px;
  color: #fff;
  margin: 0 12px;
  padding: 2px 8px;
`;

const TenantSwap = styled(P2)`
  background: ${(props) => props.theme.colors.primaryBrand};
  border-radius: 8px;
  color: #fff;
  margin: 0 12px;
  padding: 2px 8px;
`;

const RemoveButton = styled(Button)`
  background: #ffe6e6;
  border-color: #ffe6e6;
  color: #f03838;
  margin: 0 0 0 10px;

  &:hover {
    background: #f03838;
    border-color: #f03838;
    color: #ffe6e6;
  }
`;

const Link = styled(StyledLink)`
  margin: 0 20px;
`;

const TextLink = styled(P3)`
  margin: 0;
  padding: 0;

  button {
    background: none;
    color: ${(props) => props.theme.colors.primaryBrand};
    cursor: pointer;
    display: inline;
    border: none;
    margin: 0 0 0 10px;
    padding: 0;
    text-decoration: underline;
  }
`;

const DeleteLink = styled(TextLink)`
  button {
    color: ${(props) => props.theme.colors.negative};
  }
`;

const getTenantReferenceId = (tenant: TenantWithActions): string | null => {
  const referenceAction =
    tenant.actions && tenant.actions.find((action) => action.typeId === OrderCustomerActionDTOTypeIdEnum.REPOSITREFERENCING);
  if (!referenceAction) return null;

  const referenceId = get(referenceAction.details, 'referenceId', null);
  return referenceId;
};

const hasReferenceStep = (tenant: TenantWithActions): boolean => {
  return (
    tenant.actions && !!tenant.actions.find((action) => action.typeId === OrderCustomerActionDTOTypeIdEnum.REPOSITREFERENCING)
  );
};

const TenantListItem: React.SFC<TenantListItemProps> = ({
  tenant,
  onDeleteClick,
  hideButtons,
  tenancyId,
  tenancyOrderStatus,
  tenancyOrderId,
}) => {
  const dispatch = useDispatch();
  const actions = getTenantActions(tenant);
  const referenceId = getTenantReferenceId(tenant);
  const guarantorModal = useSelector(getGuarantorModal);

  const modalLoadingSelector = createLoadingSelector([ADD_GUARANTOR_STORE_KEY, UPDATE_GUARANTOR_STORE_KEY]);
  const isModalLoading = useSelector(modalLoadingSelector);

  const tenantFormOpens = useSelector(getTenantEditFormsOpen);
  const isEditFormOpen = tenantFormOpens[tenant.id];

  const isEditingSelector = createLoadingSelector([UPDATE_TENANT_DETAILS_STORE_KEY]);
  const isEditing = useSelector(isEditingSelector);

  const isTenantEditable = (actions: RepositAction[]) => {
    const registerAction = actions.find((action: RepositAction) => action.id === 'REPOSIT_REGISTERED');
    return !!(
      registerAction &&
      registerAction.status === TenantCompletionStatus.INCOMPLETE &&
      tenancyOrderStatus === OrderDTOStatusIdEnum.PENDING
    );
  };

  const showEditButton = isTenantEditable(actions);

  const [emailTruncated, setEmailTruncated] = useState(tenant.email.length > 36);
  const handleEmailTruncate = () => {
    if (tenant.email.length > 36) {
      setEmailTruncated(!emailTruncated);
    }
  };

  const guarantorRequest = (values: GuarantorFormValues) => {
    const payload = { tenancyId, tenancyUserId: tenant.id, ...values };
    if (guarantorModal.modalState === 'add') {
      dispatch(addGuarantorRequested(payload));
    } else {
      dispatch(updateGuarantorRequested(payload));
    }
  };

  const deleteGuarantor = () => {
    dispatch(deleteGuarantorRequested({ tenancyUserId: tenant.id, tenancyId }));
  };

  const dispatchGuarantorModal = (payload: GuarantorModalPayload) => {
    dispatch(setGuarantorModal(payload));
  };

  const renderReferenceDetails = () => {
    if (hasReferenceStep(tenant)) {
      if (!referenceId) {
        return <NoReference>No Reference Yet</NoReference>;
      }

      return (
        <ViewReference to={`/references/${referenceId}`} style={{ textDecoration: 'none' }}>
          <Button buttonType="secondary" noArrow={true}>
            View Reference
          </Button>
        </ViewReference>
      );
    }
  };

  return (
    <TenantItem key={tenant.id}>
      {showEditButton && isEditFormOpen ? (
        <div style={{ padding: '24px 0' }}>
          <TenantForm
            onSubmit={(values) => {
              const data = {
                firstName: values.firstName || '',
                lastName: values.lastName || '',
                email: values.email,
                guarantor: values.guarantor,
              };
              // if guarantor is obj with empty values
              // it should be null
              // as any until we can fix the api client :(
              if (values.guarantor && !values.guarantor.firstName && !values.guarantor.lastName && !values.guarantor.email) {
                data.guarantor = null as any;
              }
              dispatch(
                updateTenantDetailsRequested({
                  tenancyInviteId: tenant.id,
                  tenancyId,
                  tenancyOrderId,
                  data,
                })
              );
            }}
            formActions={
              <Fragment>
                <Link
                  to="#"
                  onClick={() => {
                    dispatch(setTenantEditFormOpenStatus({ tenantId: tenant.id, isOpen: false }));
                  }}
                >
                  Cancel edit tenant
                </Link>
                <Button disabled={isEditing}>Save</Button>
              </Fragment>
            }
            initialValues={{
              firstName: tenant.firstName,
              lastName: tenant.lastName,
              email: tenant.email,
              requireGuarantor: tenant.guarantor ? 'YES' : 'NO',
              guarantor: tenant.guarantor,
            }}
          />
        </div>
      ) : (
        <TenantItemInner>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Tenant>
              <TenantName>
                {tenant.firstName} {tenant.lastName}
              </TenantName>
              <TenantEmail onClick={handleEmailTruncate}>
                {emailTruncated ? `${tenant.email.substring(0, 36)}…` : tenant.email}
              </TenantEmail>
              {tenant.tenantLiability && tenant.tenantLiability > 1 ? (
                <TenantLiability>Liable for {tenant.tenantLiability} tenants</TenantLiability>
              ) : undefined}
              {tenant.isTenantSwap && <TenantSwap>Tenant swap</TenantSwap>}
            </Tenant>
            <div style={{ display: 'flex' }}>
              {renderReferenceDetails()}
              {showEditButton && !hideButtons && (
                <EditAction>
                  <Button
                    mini="true"
                    buttonType="secondary"
                    noArrow
                    onClick={() => {
                      dispatch(setTenantEditFormOpenStatus({ tenantId: tenant.id, isOpen: true }));
                    }}
                  >
                    Edit
                  </Button>
                </EditAction>
              )}
              {!hideButtons && (
                <RemoveButton buttonType="tertiary" mini="true" onClick={() => onDeleteClick && onDeleteClick(tenant.id)}>
                  Remove
                </RemoveButton>
              )}
            </div>
          </div>

          {tenant.guarantor ? (
            <div style={{ display: 'flex' }}>
              <P3 style={{ marginBottom: 0 }}>
                Guarantor: {tenant.guarantor.firstName} {tenant.guarantor.lastName} ({tenant.guarantor.email}
                {tenant.guarantor.mobileNumber ? `, ${tenant.guarantor.mobileNumber}` : ''})
              </P3>
              {tenant.accepted && !hideButtons ? (
                <Fragment>
                  <TextLink>
                    <button onClick={() => dispatchGuarantorModal({ isOpen: true, modalState: 'edit', tenantId: tenant.id })}>
                      Edit
                    </button>
                  </TextLink>
                  <DeleteLink>
                    <button onClick={() => dispatchGuarantorModal({ isOpen: true, modalState: 'delete', tenantId: tenant.id })}>
                      Delete
                    </button>
                  </DeleteLink>
                </Fragment>
              ) : null}
            </div>
          ) : !hideButtons && !showEditButton ? (
            <div style={{ paddingTop: 10 }}>
              <Button
                buttonType="secondary"
                mini="true"
                onClick={() => dispatchGuarantorModal({ isOpen: true, modalState: 'add', tenantId: tenant.id })}
              >
                Add Guarantor
              </Button>
            </div>
          ) : null}
        </TenantItemInner>
      )}

      <TenantProgress actions={actions} />
      {guarantorModal.isOpen && guarantorModal.modalState && guarantorModal.tenantId === tenant.id ? (
        guarantorModal.modalState !== 'delete' ? (
          <GuarantorModal
            modalType={guarantorModal.modalState}
            onSubmit={guarantorRequest}
            onDismiss={() => dispatchGuarantorModal({ isOpen: false, modalState: undefined })}
            initialValues={tenant.guarantor}
            isLoading={isModalLoading}
            tenant={tenant}
            onDelete={deleteGuarantor}
          />
        ) : (
          <DeleteGuarantorModal
            onDismiss={() => dispatchGuarantorModal({ isOpen: false })}
            onSubmit={deleteGuarantor}
            tenant={tenant}
          />
        )
      ) : null}
    </TenantItem>
  );
};

export default TenantListItem;

import React, { Fragment } from "react";
import ClaimSummaryContainer from "../../../containers/Claim/ClaimSummary";

interface ClaimListProps {
  claims: any[];
}

const ClaimList: React.FC<ClaimListProps> = ({ claims }) => {
  const claimsList = claims.map(claimId => {
    return <ClaimSummaryContainer key={claimId} claimId={claimId} />;
  });

  return <Fragment>{claimsList}</Fragment>;
};

export default ClaimList;

import { AppState } from '../root.reducer';
import { OrganizationInviteEntity } from '../entities/entities.types';

export const getCurrentOrganizationInviteIds = (state: AppState) => state.invite.currentOrganizationInvites;
export const getHaveCurrentOrganizationInvitesBeenFetched = (state: AppState) =>
  state.invite.haveCurrentOrganizationInvitesBeenFetched;
export const getIsInviteUserFormOpen = (state: AppState) => state.invite.isInviteUserFormOpen;

export const getCurrentOrganizationInvites = (state: AppState): OrganizationInviteEntity[] => {
  const ids = state.invite.currentOrganizationInvites;
  if (!ids) return [];
  return ids.map((id) => state.entities.organizationInvite[id]);
};

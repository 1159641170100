import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import styled from 'styled-components';
import Checks from '../../components/Checks';
import { Header2, P1, Caption, P3 } from '../../components/Typography';
import RepositCard from '../../components/Reposit/RepositCard';
import { NavLink } from 'react-router-dom';
import Button from '../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  pollForReferenceRequested,
  POLL_FOR_REFERENCE_STORE_KEY,
  getReferenceChecksRequested,
  GET_REFERENCE_CHECKS_STORE_KEY
} from '../../redux/referencing/referencing.actions';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import { FullPageLoader } from '../../components/Loading';
import { getReference, getLatestIdentityCheck } from '../../redux/referencing/referencing.selectors';
import { fetchUserByIdRequested } from '../../redux/user/user.actions';
import { getUserById } from '../../redux/selectors/user.selectors';
import { AppState } from '../../redux/root.reducer';
import ReferenceDocuments from '../../components/ReferenceDocuments';

interface ReferenceViewProps {
  match: any;
}

const ClaimStatus = styled.div`
  background: ${props => props.theme.colors.positive};
  border-radius: 24px;
  display: inline-block;
  color: #fff;
  font-family: ${props => props.theme.typography.face.secondary};
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 0 auto;
  padding: 4px 20px;

  &.EMPTY {
    background: ${props => props.theme.colors.inactive};
  }

  &.PENDING {
    background: ${props => props.theme.colors.disabled};
  }

  &.PASS {
    background: ${props => props.theme.colors.positive};
  }

  &.FAIL {
    background: ${props => props.theme.colors.negative};
  }

  &.INFO_REQUIRED {
    background: ${props => props.theme.colors.placeholder};
  }
  &.IN_REVIEW {
    background: ${props => props.theme.colors.placeholder};
  }
`;
const ReferenceView: React.FC<ReferenceViewProps> = props => {
  const dispatch = useDispatch();
  const referenceId = props.match.params.referenceId;
  const getReferenceLoadingSelector = createLoadingSelector([POLL_FOR_REFERENCE_STORE_KEY, GET_REFERENCE_CHECKS_STORE_KEY]);
  const isReferenceLoading = useSelector(getReferenceLoadingSelector);
  const reference = useSelector(getReference);
  const check = useSelector(getLatestIdentityCheck);

  useEffect(() => {
    dispatch(pollForReferenceRequested(referenceId));
    dispatch(getReferenceChecksRequested(referenceId));
  }, [dispatch, referenceId]);

  const userId = reference && reference.applicant.userId;
  const user = useSelector((state: AppState) => getUserById(state, { userId }));

  useEffect(() => {
    dispatch(fetchUserByIdRequested(userId));
  }, [dispatch, userId]);

  if (isReferenceLoading || !reference || !user) {
    return <FullPageLoader />;
  }

  const documents = reference.documents;

  return (
    <Container style={{ marginBottom: 120 }}>
      <Row>
        <Col lg={12} style={{ paddingBottom: 24 }}>
          <Header2>Reference {reference.id}</Header2>
          <Caption>User {user.id}</Caption>
          <ClaimStatus className={`${reference.status}`}>{reference.status}</ClaimStatus>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <RepositCard title="User">
            <P1 style={{ margin: 0 }}>
              {user.firstName} {user.lastName}
            </P1>
            <P3 style={{ fontStyle: 'italic' }}>(#{user.id})</P3>
            <NavLink to={`/users/${user.id}`}>
              <Button buttonType="secondary">View User</Button>
            </NavLink>
          </RepositCard>
        </Col>
      </Row>
      {check && <Checks checks={[check]} />}
      {documents && documents.length > 0 && (
        <Row>
          <Col lg={12}>
            <ReferenceDocuments documents={documents} />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default ReferenceView;

import { TenancyOrderNoteDTO } from '@reposit/api-client';
import React, { useState } from 'react';
import Card from '../../Card';
import { P1, P2 } from '../../Typography';
import moment from 'moment';
import styled from 'styled-components';
import Button from '../../Button';
import { UserEntity } from '../../../redux/entities/entities.types';
import ConfirmationModal from '../../ConfirmationModal';
import { useDispatch } from 'react-redux';
import { deleteRepositNoteRequested } from '../../../redux/reposit/reposit.actions';

interface IndividualNoteProps {
  note: TenancyOrderNoteDTO;
  currentUser: UserEntity | undefined;
}

const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const DeleteButton = styled(Button)`
  margin-left: auto;
  background: #ffe6e6;
  border-color: #ffe6e6;
  color: ${(props) => props.theme.colors.negative};

  &:hover {
    background: ${(props) => props.theme.colors.negative};
    border-color: ${(props) => props.theme.colors.negative};
    color: #ffe6e6;
  }
`;

const IndividualNote: React.FC<IndividualNoteProps> = ({ note, currentUser }) => {
  const dispatch = useDispatch();
  const [showingDeleteModal, setShowingDeleteModal] = useState<boolean>(false);
  const userCreatedNote = currentUser && currentUser.id === note.user.id;
  const handleDelete = () => {
    dispatch(deleteRepositNoteRequested({ tenancyOrderId: note.tenancyOrderId, tenancyOrderNoteId: note.id }));
    setShowingDeleteModal(false);
  };
  const handleDismissDelete = () => {
    setShowingDeleteModal(false);
  };
  return (
    <>
      {showingDeleteModal && (
        <ConfirmationModal
          title="Do you want to delete this note?"
          text={`This note will not be able to be recovered once deleted.`}
          onDismiss={handleDismissDelete}
          onSubmit={handleDelete}
        />
      )}
      <Card hover flush style={{ padding: '24px 15px 6px' }}>
        <P1>{note.note}</P1>
        <P2>{`${note.user.firstName}, ${moment(note.createdAt).format('DD/MM/YYYY HH:mm')}`}</P2>
        {userCreatedNote ? (
          <ButtonContainer>
            <DeleteButton buttonType="tertiary" mini="true" onClick={() => setShowingDeleteModal(true)}>
              Delete
            </DeleteButton>
          </ButtonContainer>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
};

export default IndividualNote;

import {
  ReconciliationDocumentsActionTypes,
  FETCH_RECONCILIATION_DOCUMENTS_API_SUCCESS,
} from './reconciliation-document.actions';
import { ReconciliationDocumentsState } from './reconciliation-document.types';
import { RECONCILIATION_DOCUMENTS_PAGE_SIZE } from './reconciliation-document.saga';

const initialState: ReconciliationDocumentsState = {
  results: [],
  count: 0,
  page: 1,
  lastPage: undefined,
};

export default (state = initialState, action: ReconciliationDocumentsActionTypes): ReconciliationDocumentsState => {
  switch (action.type) {
    case FETCH_RECONCILIATION_DOCUMENTS_API_SUCCESS:
      const last = Math.ceil(action.payload.count / RECONCILIATION_DOCUMENTS_PAGE_SIZE);

      return {
        ...state,
        count: action.payload.count,
        lastPage: last,
        results: [...action.payload.ids],
      };

    default:
      return state;
  }
};

import { OrderWithOrderCustomersDTOStatusIdEnum, TenancyAnniversaryWithTenancyDTO } from '@reposit/api-client';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { AnniversaryStatusEnum } from '../../../components/AnniversaryStatus';
import { ExpandableContent } from '../../../components/ExpandableContent';
import RepositCard from '../../../components/Reposit/RepositCard';
import { TenancyAnniversaryHeader } from '../../../components/TenancyAnniversaryHeader/TenancyAnniversaryHeader';
import { getAnniversaries } from '../../../redux/tenancy-anniversary/tenancy-anniversary.selectors';
import TenantList from '../../../components/TenantList';
import { TenantWithActions, getTenancyOrder } from '../../../redux/selectors';
import { useParams } from 'react-router';
import { AppState } from '../../../redux/root.reducer';

interface TenancyAnniversariesProps {}

const Container = styled('div')`
  padding: 10px 35px;
  margin-bottom: 5px;
`;

const calculateAnniversaryStatus = (a: TenancyAnniversaryWithTenancyDTO): AnniversaryStatusEnum => {
  if (!a.chargable) return AnniversaryStatusEnum.NOT_CHARGABLE;
  // is chargable
  if (!a.order) return AnniversaryStatusEnum.NOT_DUE;
  const orderStatusId = a.order.statusId;
  if (orderStatusId === OrderWithOrderCustomersDTOStatusIdEnum.COMPLETE) return AnniversaryStatusEnum.COMPLETE;
  if (orderStatusId === OrderWithOrderCustomersDTOStatusIdEnum.CANCELLED) return AnniversaryStatusEnum.CANCELLED;
  return AnniversaryStatusEnum.PENDING;
};

const generateTenants = (a: TenancyAnniversaryWithTenancyDTO): TenantWithActions[] => {
  if (!a.order) return [];
  return a.order.orderCustomers.map((oc) => {
    return {
      ...oc.customer.user,
      accepted: true,
      actions: oc.actions || [],
    };
  });
};

const TenancyAnniversaries: React.FC<TenancyAnniversariesProps> = (props) => {
  const { id: tenancyOrderId } = useParams<{ id: string }>();
  const tenancyOrder = useSelector((state: AppState) => getTenancyOrder(state, { tenancyOrderId }));
  const anniversaries = useSelector(getAnniversaries);
  if (anniversaries && anniversaries.length) {
    return (
      <RepositCard flush title="Tenancy Anniversaries">
        {anniversaries.map((a) => {
          const status = calculateAnniversaryStatus(a);
          const tenants = generateTenants(a);
          return (
            <Container key={a.id}>
              <ExpandableContent
                header={<TenancyAnniversaryHeader periodStart={a.periodStart} status={status} />}
                hiddenContent={
                  tenants.length ? (
                    <TenantList
                      tenants={tenants}
                      hideButtons
                      // this status isn't used here
                      // Tenant List component is in two places
                      // best to add it in rather than an empty string
                      tenancyOrderStatus={(tenancyOrder && tenancyOrder.order.statusId) || ''}
                      tenancyId={a.tenancyId}
                    />
                  ) : undefined
                }
              />
            </Container>
          );
        })}
      </RepositCard>
    );
  }
  return null;
};

export { TenancyAnniversaries };

import { AccountState } from './account.types';
import {
  fetchMeSuccess,
  fetchMeFailed,
  setOrganizationId,
  setAccountCurrentAddressForm,
  setHasRequestedPasswordReset,
} from './account.actions';
import { AddressForm } from '../reposit/reposit.actions';
import { createReducer } from '@reduxjs/toolkit';
import { resetPasswordSuccess } from '../auth/auth.actions';

const initialState: AccountState = {
  currentUserId: '',
  currentOrganizationId: '',
  currentOrganizationRoles: [],
  hasFetched: false,
  currentAddressForm: AddressForm.POSTCODE,
  internalRoles: [],
  hasRequestedResetPassword: false,
  hasResetPassword: false,
  persistedEmail: '',
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(fetchMeSuccess, (state, action) => {
      state.currentUserId = action.payload.currentUserId;
      state.currentOrganizationId = action.payload.currentOrganizationId;
      state.currentOrganizationRoles = action.payload.currentOrganizationRoles;
      state.hasFetched = true;
      state.internalRoles = action.payload.internalRoles;
    })
    .addCase(fetchMeFailed, (state, action) => {
      state.error = action.payload;
      state.hasFetched = true;
    })
    .addCase(setOrganizationId, (state, action) => {
      state.currentOrganizationId = action.payload;
    })
    .addCase(setAccountCurrentAddressForm, (state, action) => {
      state.currentAddressForm = action.payload;
    })
    .addCase(setHasRequestedPasswordReset, (state, action) => {
      state.hasRequestedResetPassword = action.payload;
    })
    .addCase(resetPasswordSuccess, (state, action) => {
      state.hasResetPassword = true;
      state.persistedEmail = action.payload;
    });
});

const webURL = 'https://demo.admin.reposit.co.uk';
const clientId = 'ExpRBq14pNQjlZ3JDfz22bods6ctUtOh';

export const appConfig = {
  links: {
    auth0: {
      logout: `https://reposit-demo.eu.auth0.com/v2/logout?client_id=${clientId}`,
      login: `https://reposit-demo.eu.auth0.com/authorize?response_type=token&client_id=${clientId}&redirect_uri=${webURL}/authenticate&audience=https://demo.api.com`,
    },
    local: {
      login: `${webURL}/login`,
    },
  },
  api: {
    basePath: 'https://demo.api.reposit.co.uk',
  },
  earliestKpiDate: '2020-06-26',
  earliestReconciliationDate: '2023-01-01',
  inMaintenanceMode: false,
};

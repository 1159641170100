import { LoadingPayload } from './loading.types';

export const SET_LOADING = 'LOADING/SET_LOADING';

export function setLoading(payload: LoadingPayload) {
  return {
    type: SET_LOADING,
    payload
  } as const;
}

export type LoadingActionTypes = ReturnType<typeof setLoading>;

import React from 'react';
import styled from 'styled-components';
import numeral from 'numeral';
import moment from 'moment';

import RepositCard from '../Reposit/RepositCard';
import { Container, Row, Col } from 'react-grid-system';
import { NumberFormat } from '../../constants/number-formats';
import { ClaimWithCheckoutRelationsDTO } from '@reposit/api-client';
import InlineTable from '../InlineTable';

interface ClaimDetailsProps {
  claim: ClaimWithCheckoutRelationsDTO;
}

const ClaimDetailsCard = styled(RepositCard)`
  width: 100%;
`;

const ClaimDetails: React.FC<ClaimDetailsProps> = ({ claim }) => {
  const { firstName, lastName, id: createdById } = claim.createdByUser;
  const mediationEnabled = claim.mediationEnabled;

  const items: Array<{ key: string; value: string; linkTo?: string }> = [
    {
      key: 'Id',
      value: claim.id,
    },
    {
      key: 'Max Liability',
      value: `£${numeral(claim.limit / 100).format(NumberFormat.THOUSANDS_PENCE)}`,
    },
    {
      key: 'Created At',
      value: moment(claim.createdAt).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      key: mediationEnabled ? 'Original Amount' : 'Claimed Amount',
      value: `£${numeral(claim.originalAmount / 100).format(NumberFormat.THOUSANDS_PENCE)}`,
    },
    {
      key: 'Created By',
      value: `${firstName} ${lastName}`,
      linkTo: `/users/${createdById}`,
    },
    {
      key: 'Auto Charge Status',
      value: claim.autoChargeEnabled ? 'Enabled' : 'Disabled',
    },
    {
      key: 'Organisation',
      value: claim.organization.name,
      linkTo: `/organisations/${claim.organization.id}`,
    },
  ];
  if (mediationEnabled) {
    items.push({
      key: 'Final Amount',
      value: claim.finalAmount ? `£${numeral(claim.finalAmount / 100).format(NumberFormat.THOUSANDS_PENCE)}` : 'N/A',
    });
  }

  if (claim.supplierPaidAt) {
    items.push({
      key: 'Paid At',
      value: moment(claim.supplierPaidAt).format('DD/MM/YYYY'),
    });
  }

  if (claim.legacyId) {
    items.push({
      key: 'Legacy Id',
      value: claim.legacyId,
    });
  }

  return (
    <ClaimDetailsCard flush title="Claim details" tooltip="lorem">
      <Container>
        <Row>
          <Col lg={12} style={{ padding: 0 }}>
            <InlineTable items={items} />
          </Col>
        </Row>
      </Container>
    </ClaimDetailsCard>
  );
};

export default ClaimDetails;

import { AppState } from '../root.reducer';

export const getTenancyOrderHasBeenFetched = (state: AppState) => state.reposit.hasTenancyOrderBeenFetched;
export const getIsTenantFormOpen = (state: AppState) => state.reposit.isTenantFormOpen;
export const getIsPropertyFormOpen = (state: AppState) => state.reposit.isPropertyFormOpen;
export const getSelectedAddressId = (state: AppState) => state.reposit.selectedAddressId;
export const getCurrentAddressForm = (state: AppState) => state.reposit.currentAddressForm;
export const getIsUpdatePropertyModalOpen = (state: AppState) => state.reposit.isUpdatePropertyModalOpen;
export const getCurrentRepositActionModal = (state: AppState) => state.reposit.currentRepositActionModal;
export const getCurrentRepositActionModalData = (state: AppState) => state.reposit.currentRepositActionModalData;
export const getIsTransferModalOpen = (state: AppState) => state.reposit.isTransferRepositModalOpen;
export const getTenantEditFormsOpen = (state: AppState) => state.reposit.tenantEditFormsOpen;

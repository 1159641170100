import React from 'react';
import styled from 'styled-components';
import { REPOSIT_LIST_PAGE_SIZE } from '../../redux/reposit-list/reposit-list.saga';
import { StyledLink } from '../Typography/index';
import LeftArrow from '../../assets/svg/left-purple.svg';
import RightArrow from '../../assets/svg/right-purple.svg';
import { Page } from '../../redux/reposit-list/reposit-list.actions';

interface PaginationProps {
  count: number;
  currentPage: number;
  onNavigate: (page: Page) => void;
}

const PaginationWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 36px 0;
`;

interface PageLinksProps {
  status?: string;
}

const PageLink = styled(StyledLink)<PageLinksProps>`
  background: ${(props) => (props.status === 'active' ? props.theme.colors.bgAccent : '#fff')};
  border: solid 1px ${(props) => props.theme.colors.disabled};
  border-radius: 6px;
  color: ${(props) => (props.status === 'active' ? props.theme.colors.body : props.theme.colors.primaryBrand)};
  font-size: 0.875em;
  padding: 6px 12px;
  margin: 0 6px;
  overflow: hidden;
  text-decoration: none;

  &:hover {
    background-color: ${(props) => props.theme.colors.bgAccent};
  }
`;

interface ButtonProps {
  active?: boolean;
  disabled?: boolean;
}

const Previous = styled(PageLink)<ButtonProps>`
  background: #fff url(${LeftArrow}) no-repeat 12px center;
  background-size: 12px;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'default')};
  padding: 6px 16px;
  text-indent: -9999px;
`;
const Next = styled(PageLink)<ButtonProps>`
  background: #fff url(${RightArrow}) no-repeat 9px center;
  background-size: 12px;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'default')};
  padding: 6px 16px;
  text-indent: -9999px;
`;

const checkWindowIndex = (index: number, currentPage: number) => {
  return Math.abs(currentPage - index) < 5;
};

const Pagination: React.SFC<PaginationProps> = (props) => {
  const { count, currentPage, onNavigate } = props;
  const pages = [];
  const numberOfPages = Math.ceil(count / REPOSIT_LIST_PAGE_SIZE);

  for (let i = 1; i <= numberOfPages; i++) {
    const isActive = i === currentPage;

    if (!checkWindowIndex(i, currentPage)) continue;

    pages.push(
      <PageLink key={i} to="#" status={isActive ? 'active' : undefined} onClick={() => onNavigate(i)}>
        {i}
      </PageLink>
    );
  }

  const prevIsDisabled = currentPage <= 1;
  const nextIsDisabled = currentPage >= count;

  return (
    <PaginationWrapper>
      <Previous to="#" onClick={() => onNavigate('prev')} disabled={prevIsDisabled}>
        Prev
      </Previous>
      {pages}
      <Next to="#" onClick={() => onNavigate('next')} disabled={nextIsDisabled}>
        Next
      </Next>
    </PaginationWrapper>
  );
};

export default Pagination;

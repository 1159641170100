import { OrganizationRecipientDTO, OrganizationsApi } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import SCHEMA from '../schema';
import { createOrganizationsApi, runSagaWithAuth } from '../utils/api.utils';
import {
  fetchOrganizationRecipientFailed,
  fetchOrganizationRecipientRequested,
  fetchOrganizationRecipientSuccess,
} from './organization-recipient.actions';

export function* fetchOrganizationRecipients({ payload }: { payload: { organizationId: string }; type: string }) {
  try {
    const organizationsApi: OrganizationsApi = yield createOrganizationsApi();
    const organizationId = payload.organizationId;
    const apiResponse: AxiosResponse<OrganizationRecipientDTO[]> = yield call(
      runSagaWithAuth(() => organizationsApi.getRecipients(organizationId))
    );
    const ids: string[] = yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.organizationRecipients);
    yield put(fetchOrganizationRecipientSuccess(ids));
  } catch (e) {
    yield put(fetchOrganizationRecipientFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchRecipientSagas() {
  yield takeLatest(fetchOrganizationRecipientRequested.type, fetchOrganizationRecipients);
}

export const DELETE_ORGANIZATION_USER_STORE_KEY = 'ORGANIZATION_USER/DELETE_ORGANIZATION_USER';
export const DELETE_ORGANIZATION_USER_API_REQUESTED = `ORGANIZATION_USER/DELETE_ORGANIZATION_USER_API_REQUESTED`;
export const DELETE_ORGANIZATION_USER_API_SUCCESS = 'ORGANIZATION_USER/DELETE_ORGANIZATION_USER_API_SUCCESS';
export const DELETE_ORGANIZATION_USER_API_FAILED = 'ORGANIZATION_USER/DELETE_ORGANIZATION_USER_API_FAILED';

export function deleteOrganizationUserRequested(payload: string) {
  return {
    type: DELETE_ORGANIZATION_USER_API_REQUESTED,
    payload
  } as const;
}

export function deleteOrganizationUserSuccess(payload: string) {
  return {
    type: DELETE_ORGANIZATION_USER_API_SUCCESS,
    payload
  } as const;
}

export function deleteOrganizationUserFailed(error: string) {
  return {
    type: DELETE_ORGANIZATION_USER_API_FAILED,
    payload: error
  } as const;
}

export type OrgnizationUserActionTypes = ReturnType<
  typeof deleteOrganizationUserRequested | typeof deleteOrganizationUserSuccess | typeof deleteOrganizationUserFailed
>;

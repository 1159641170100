import React, { useEffect } from 'react';
import { RouteProps, RouteComponentProps, Route, Redirect } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { getAccessToken } from '../../redux/auth/auth.selectors';
import ErrorBoundary from '../../utils/ErrorBoundary';
import { setCurrentRoute } from '../../redux/auth/auth.actions';
import { getCurrentUser } from '../../redux/selectors/user.selectors';
import { UserDTOUserTypeIdEnum } from '@reposit/api-client';

export const PrivateRoute = ({ component, ...rest }: RouteProps) => {
  const token = useSelector(getAccessToken);
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);

  useEffect(() => {
    // set current route here so it can be picked up again at login step
    // only set route only one time - so it does not get overwritten after logout
    dispatch(setCurrentRoute(window.location.pathname));
  }, [dispatch]);

  // stops flash on logout
  if (!component || !token || (currentUser && currentUser.userTypeId !== UserDTOUserTypeIdEnum.INTERNAL)) {
    return <Redirect to="/auth/login" />;
  }

  const Component = component; // JSX Elements have to be uppercase.
  const render = (props: RouteComponentProps<any>): React.ReactNode => {
    if (token) {
      return (
        <ErrorBoundary>
          <Component {...props} />
        </ErrorBoundary>
      );
    }
    return null;
  };

  return <Route {...rest} render={render} />;
};

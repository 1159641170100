import React, { Fragment, useState, useCallback } from 'react';
import styled from 'styled-components';
import Button from '../Button/index';
import Modal from '../Modal/index';
import { Header3, P2 } from '../Typography/index';
import TextArea from '../FormFields/TextArea';
import FieldWithLabel from '../FormFields/FieldWithLabel';
import { useSelector } from 'react-redux';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import Checkbox from '../FormFields/Checkbox';

export interface ConfirmationModalProps {
  title: string;
  text?: string;
  enableDetailsField?: boolean;
  onSubmit: (details?: string, notification?: NotificationInfo) => void;
  onDismiss?: () => void;
  loadingActions?: string[];
  enableNotificationsFields?: boolean;
}

export interface NotificationInfo {
  sendTenantNotification: boolean;
  sendSupplierNotification: boolean;
}

const Action = styled.div`
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

const ConfirmationModal = ({
  onSubmit,
  onDismiss,
  title,
  text,
  enableDetailsField,
  enableNotificationsFields,
  loadingActions,
}: ConfirmationModalProps) => {
  const [details, setDetails] = useState<string>('');
  const [sendTenantNotification, setTenantNotification] = useState<boolean>(false);
  const [sendSupplierNotification, setSupplierNotification] = useState<boolean>(false);
  const loading = useSelector(createLoadingSelector(loadingActions || []));
  return (
    <Modal onDismiss={onDismiss}>
      <Fragment>
        <Header3 style={{ marginBottom: 6 }}>{title}</Header3>
        {text && <P2 style={{ marginBottom: 24 }}>{text}</P2>}
        {enableDetailsField && (
          <FieldWithLabel label="Details">
            <TextArea name="message" value={details} onChange={(e) => setDetails(e.target.value)} onBlur={() => {}} />
          </FieldWithLabel>
        )}
        {enableNotificationsFields && (
          <Fragment>
            <P2>Send notification to:</P2>
            <Checkbox
              isChecked={sendTenantNotification}
              label="Tenant"
              name="sendTenantNotification"
              onChange={() => setTenantNotification(!sendTenantNotification)}
            />
            <Checkbox
              isChecked={sendSupplierNotification}
              label="Supplier"
              name="sendSupplierNotification"
              onChange={() => setSupplierNotification(!sendSupplierNotification)}
            />
          </Fragment>
        )}
        <Action>
          <Button buttonType="secondary" noArrow={true} onClick={onDismiss}>
            Cancel
          </Button>
          <Button onClick={() => onSubmit(details, { sendTenantNotification, sendSupplierNotification })} disabled={loading}>
            Confirm
          </Button>
        </Action>
      </Fragment>
    </Modal>
  );
};

export const useConfirmationModal = (confirmationModalProps: ConfirmationModalProps) => {
  const [visible, setVisible] = useState(false);
  const showModal = useCallback((show: boolean) => setVisible(show), [setVisible]);

  const renderConfirmationModal = useCallback(
    () =>
      visible ? (
        <ConfirmationModal
          {...confirmationModalProps}
          onDismiss={() => {
            if (confirmationModalProps.onDismiss) confirmationModalProps.onDismiss();
            showModal(false);
          }}
        />
      ) : null,
    [confirmationModalProps, showModal, visible]
  );

  return { renderConfirmationModal, showModal };
};

export default ConfirmationModal;

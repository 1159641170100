import { LandlordActionTypes } from './landlord.actions';
import { LandlordState } from './landlord.types';

const INITIAL_STATE: LandlordState = {};

export default (state = INITIAL_STATE, action: LandlordActionTypes): LandlordState => {
  switch (action.type) {
    default:
      return state;
  }
};

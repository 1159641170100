import { FlashMessagePayload } from './flash-messages.types';

export const SET_FLASH_MESSAGE = 'FLASH_MESSAGES/SET_FLASH_MESSAGE';
export const DISMISS_FLASH_MESSAGE = 'FLASH_MESSAGES/DISMISS_FLASH_MESSAGE';
export const DISMISS_ALL_FLASH_MESSAGES = 'FLASH_MESSAGES/DISMISS_ALL_FLASH_MESSAGES';

export function setFlashMessage(payload: FlashMessagePayload) {
  return {
    type: SET_FLASH_MESSAGE,
    payload
  } as const;
}

export function dismissFlashMessage(payload: string[]) {
  return {
    type: DISMISS_FLASH_MESSAGE,
    payload
  } as const;
}

export function dismissAllFlashMessages() {
  return {
    type: DISMISS_ALL_FLASH_MESSAGES
  } as const;
}

export type FlashMessagesActionTypes = ReturnType<
  typeof setFlashMessage | typeof dismissFlashMessage | typeof dismissAllFlashMessages
>;

import { TenancyTransferDTO } from '@reposit/api-client';
import moment from 'moment';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { TenancyOrderWithActions } from '../../../redux/selectors';
import { getOrganization } from '../../../redux/selectors/organization.selectors';
import { Table, Tbody, Td, Th, Tr } from '../../Table';
import RepositCard from '../RepositCard';

interface RepositTransferHistoryProps {
  tenancyOrder: TenancyOrderWithActions;
}

interface TransferHistoryRowProps {
  tenancyTransfer: TenancyTransferDTO;
}

const TransferHistoryRow: React.FC<TransferHistoryRowProps> = ({ tenancyTransfer }) => {
  const prevOrg = useSelector(getOrganization(tenancyTransfer.previousOrganizationId));
  const newOrg = useSelector(getOrganization(tenancyTransfer.newOrganizationId));

  const prevOrgName = prevOrg ? prevOrg.name : 'Unknown Organisation';
  const newOrgName = newOrg ? newOrg.name : 'Unknown Organisation';

  return (
    <Tr key={tenancyTransfer.id}>
      <Td>{prevOrgName}</Td>
      <Td>{newOrgName}</Td>
      <Td>
        {tenancyTransfer.completedAt ? moment(tenancyTransfer.completedAt).format('DD/MM/YYYY HH:mm') : 'Not yet transferred'}
      </Td>
    </Tr>
  );
};

const RepositTransferHistory: React.FC<RepositTransferHistoryProps> = ({ tenancyOrder }) => {
  const tenancyTransfers = tenancyOrder.tenancy.tenancyTransfers;

  if (!tenancyTransfers || tenancyTransfers.length === 0) return null;

  return (
    <RepositCard title="Transfer History">
      <Container>
        <Row>
          <Col lg={12} style={{ padding: 0 }}>
            <Table>
              <Tbody>
                <Tr>
                  <Th>Previous Organization</Th>
                  <Th>Organization Transferred To</Th>
                  <Th>Completion Date</Th>
                </Tr>
                {tenancyTransfers.map((item) => (
                  <TransferHistoryRow tenancyTransfer={item} />
                ))}
              </Tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </RepositCard>
  );
};

export default RepositTransferHistory;

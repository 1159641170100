import { OrganizationListFilters } from './organization-list.types';
import { OrganizationSort } from '../../constants/organization';

export const FETCH_ORGANIZATIONS_STORE_KEY = 'ORGANIZATIONS_LIST/FETCH_ORGANIZATIONS';
export const FETCH_ORGANIZATIONS_API_REQUESTED = 'ORGANIZATIONS_LIST/FETCH_ORGANIZATIONS_API_REQUESTED';
export const FETCH_ORGANIZATIONS_API_SUCCESS = 'ORGANIZATIONS_LIST/FETCH_ORGANIZATIONS_API_SUCCESS';
export const FETCH_ORGANIZATIONS_API_FAILED = 'ORGANIZATIONS_LIST/FETCH_ORGANIZATIONS_API_FAILED';

export const ORGANIZATIONS_GOTO_PAGE = 'ORGANIZATIONS_LIST/ORGANIZATIONS_GOTO_PAGE';
export const ORGANIZATIONS_UPDATE_FILTERS = 'ORGANIZATIONS_LIST/ORGANIZATIONS_UPDATE_FILTERS';

export function fetchOrganizationsRequested(pageSize?: number, sort?: OrganizationSort) {
  return {
    type: FETCH_ORGANIZATIONS_API_REQUESTED,
    payload: {
      sort,
      pageSize,
    },
  } as const;
}

export function fetchOrganizationsSuccess(count: number, ids: string[]) {
  return {
    type: FETCH_ORGANIZATIONS_API_SUCCESS,
    payload: {
      count,
      ids,
    },
  } as const;
}

export function fetchOrganizationsFailed(error: string) {
  return {
    type: FETCH_ORGANIZATIONS_API_FAILED,
    payload: error,
  } as const;
}

export type Page = number | 'next' | 'prev';
export function goToPage(page: Page) {
  return {
    type: ORGANIZATIONS_GOTO_PAGE,
    payload: page,
  } as const;
}
export function updateFilters(filters: Partial<OrganizationListFilters>) {
  return {
    type: ORGANIZATIONS_UPDATE_FILTERS,
    payload: filters,
  } as const;
}

export type OrganizationListActionTypes = ReturnType<
  | typeof fetchOrganizationsRequested
  | typeof fetchOrganizationsSuccess
  | typeof fetchOrganizationsFailed
  | typeof goToPage
  | typeof updateFilters
>;

import { sortBy } from 'lodash';
import React, { Fragment, useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import FlashMessage from '../../components/FlashMessage';
import Loading from '../../components/Loading';
import RepositCard from '../../components/Reposit/RepositCard';
import { P2 } from '../../components/Typography';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import {
  fetchOrganizationBankAccountsRequested,
  FETCH_ORG_BANK_ACCOUNTS_STORE_KEY,
} from '../../redux/organization-bank-account/organization-bank-account.actions';
import { getOrganizationBankAccounts } from '../../redux/organization-bank-account/organization-bank-account.selectors';
import { AppState } from '../../redux/root.reducer';
import { FLASH_MESSAGE_TIMEOUT, useFlashMessage } from '../FlashMessage';
import BankAccount from './bank-account';

const BankAccountsContainer = styled.div`
  padding: 10px 35px;
  margin-bottom: 5px;
`;

interface OrganizationBankAccountsProps {
  organizationId: string;
}

const OrganizationBankAccounts: React.FC<OrganizationBankAccountsProps> = ({ organizationId }) => {
  const dispatch = useDispatch();
  let bankAccounts = useSelector((state: AppState) => getOrganizationBankAccounts(state, organizationId));
  bankAccounts = sortBy(bankAccounts, (ba) => ba.accountType);

  const isFetchingLoadingSelector = createLoadingSelector([FETCH_ORG_BANK_ACCOUNTS_STORE_KEY]);
  const isLoading = useSelector(isFetchingLoadingSelector);

  const [flashMessagePayload, dismissFlashMessage] = useFlashMessage([FETCH_ORG_BANK_ACCOUNTS_STORE_KEY]);

  const activeBankAccounts = bankAccounts.filter((ba) => !ba.deletedAt);
  const inactiveBankAccounts = bankAccounts.filter((ba) => !!ba.deletedAt);

  useEffect(() => {
    dispatch(fetchOrganizationBankAccountsRequested({ organizationId }));
  }, [dispatch, organizationId]);

  if (isLoading) return <Loading />;
  return (
    <Container>
      {flashMessagePayload ? (
        <FlashMessage onDismiss={dismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMEOUT} payload={flashMessagePayload} />
      ) : null}
      <Row>
        <Col sm={12}>
          <RepositCard title={activeBankAccounts.length ? 'Active Bank Accounts' : 'No active bank accounts'} tooltip="" flush>
            <BankAccountsContainer>
              {activeBankAccounts.length ? (
                <div>
                  {activeBankAccounts.map((ba) => (
                    <BankAccount key={ba.id} bankAccount={ba} />
                  ))}
                </div>
              ) : (
                <Fragment>
                  <P2>This organization doesn't have any active bank accounts.</P2>
                </Fragment>
              )}
            </BankAccountsContainer>
          </RepositCard>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <RepositCard title={'Inactive Bank Accounts'} tooltip="" flush>
            <BankAccountsContainer>
              {inactiveBankAccounts.length ? (
                <div>
                  {inactiveBankAccounts.map((ba) => (
                    <BankAccount key={ba.id} bankAccount={ba} />
                  ))}
                </div>
              ) : (
                <Fragment>
                  <P2>This organization doesn't have any inactive bank accounts.</P2>
                </Fragment>
              )}
            </BankAccountsContainer>
          </RepositCard>
        </Col>
      </Row>
    </Container>
  );
};

export default OrganizationBankAccounts;

import React from 'react';
import numeral from 'numeral';
import { ClaimWithCheckoutRelationsDTO } from '@reposit/api-client';

import RepositCard from '../Reposit/RepositCard';
import { Table, Thead, Tr, Td, Tbody } from '../Table';
import { A } from '../Typography';
import { truncateFilename } from '../../utils/common.utils';
import { NumberFormat } from '../../constants/number-formats';

interface ClaimArbitrationProps {
  claim: ClaimWithCheckoutRelationsDTO;
}

const ClaimArbitration: React.FC<ClaimArbitrationProps> = ({ claim }) => {
  const { arbitration } = claim;
  const documents = arbitration && arbitration.documents;

  return (
    <RepositCard flush title="Arbitration" tooltip="lorem">
      {arbitration && (
        <>
          <Table>
            <Thead>
              <Tr>
                <Td>Id</Td>
                <Td>Amount</Td>
                <Td>Summary Document</Td>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>{arbitration.id}</Td>
                <Td>£{numeral(arbitration.arbitratedAmount / 100).format(NumberFormat.THOUSANDS_PENCE)}</Td>
                <Td>
                  {documents &&
                    documents.map((arbitrationDocument) => (
                      <>
                        <A target="_blank" href={arbitrationDocument.document.url}>
                          {truncateFilename(arbitrationDocument.document.name)}
                        </A>
                        <br />
                      </>
                    ))}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </>
      )}
    </RepositCard>
  );
};

export default ClaimArbitration;

import { OrganizationBankAccountDTOAccountTypeEnum } from '@reposit/api-client';
import React from 'react';
import { ExpandableContent } from '../../components/ExpandableContent';
import InlineTable, { InlineTableItem } from '../../components/InlineTable';
import { OrganizationBankAccountEntity } from '../../redux/entities/entities.types';
import styled from 'styled-components';
import BankAccountHeader from './bank-account-header';
import moment from 'moment';
import { startCase, toLower } from 'lodash';

const Container = styled.div`
  padding-bottom: 24px;
`;

const SORT_CODE_REGEX = /(\d)(?=(\d{2})+(?!\d))/g;

interface BankAccountProps {
  bankAccount: OrganizationBankAccountEntity;
}

const getBankAccountItems = (bankAccount: OrganizationBankAccountEntity): InlineTableItem[] => {
  const items = [
    {
      key: 'Account Type',
      value: bankAccount.accountType === OrganizationBankAccountDTOAccountTypeEnum.DEFAULT ? 'Primary' : 'Claim',
    },
    { key: 'Bank Name', value: startCase(toLower(bankAccount.bankName)) },
    { key: 'Sort Code', value: bankAccount.sortCode.replace(SORT_CODE_REGEX, '$1-') },
    { key: 'Account Number', value: bankAccount.accountNumber },
    { key: 'Created At', value: moment(bankAccount.createdAt).format('DD/MM/YYYY') },
    { key: 'Deleted At', value: bankAccount.deletedAt ? moment(bankAccount.deletedAt).format('DD/MM/YYYY') : 'na' },
  ];

  return items;
};

const BankAccount: React.FC<BankAccountProps> = ({ bankAccount }) => {
  return (
    <Container>
      <ExpandableContent
        header={<BankAccountHeader bankAccount={bankAccount} />}
        hiddenContent={<InlineTable items={getBankAccountItems(bankAccount)} />}
      />
    </Container>
  );
};

export default BankAccount;

import { filter } from 'lodash';
import { OrganizationInternalOwnerEntity } from '../entities/entities.types';
import { AppState } from '../root.reducer';

export const getOrganizationInternalOwners = (state: AppState, organizationId: string): OrganizationInternalOwnerEntity[] => {
  if (!state.entities.organizationInternalOwner) return [];

  if (!organizationId) return [];

  return filter(state.entities.organizationInternalOwner, (item) => item.organizationId === organizationId);
};

import React from 'react';
import Card from '../Card';
import { Header3, P2 } from '../Typography';
import styled from 'styled-components';
import Button from '../Button';
import { getBreakpoint } from '../../base/style';
import { NavLink } from 'react-router-dom';
import { UserDTO } from '@reposit/api-client';

interface UserCardProps {
  user: UserDTO;
}

const Inner = styled.div`
  min-height: 80px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    display: flex;
    justify-content: flex-end;
  }
`;

const Meta = styled.div`
  margin: 0 0 24px;
  @media screen and (min-width: ${getBreakpoint('lg')}) {
    margin: 0 auto 0 0;
  }
`;

const Name = styled(Header3)`
  margin: 6px 0 0;
  padding: 0;
`;

const Email = styled(P2)`
  margin: 0;
  padding: 0;
`;

const Action = styled.div`
  margin: 24px 0 16px;

  a {
    text-decoration: none;
  }

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    margin: 0;
    padding: 0 0 0 24px;
  }
`;

const UserCard: React.FC<UserCardProps> = ({ user }) => {
  return (
    <Card hover style={{ padding: '5px 5px 10px 40px' }}>
      <Inner>
        <Meta>
          <Name>
            {user.firstName} {user.lastName}
          </Name>
          <Email>{user.email}</Email>
        </Meta>
        <Action>
          <NavLink to={`/users/${user.id}`}>
            <Button buttonType="secondary" mini="true">
              View
            </Button>
          </NavLink>
        </Action>
      </Inner>
    </Card>
  );
};

export default UserCard;

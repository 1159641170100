import { ReconciliationDocumentsApi, SearchKpiDocumentResultsDTO } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { FlashState } from '../../components/FlashMessage';
import { getErrorMessage } from '../../utils/common.utils';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import SCHEMA from '../schema';
import { createReconciliationDocumentsApi, runSagaWithAuth } from '../utils/api.utils';
import {
  fetchReconciliationDocumentsFailed,
  fetchReconciliationDocumentsSuccess,
  FETCH_RECONCILIATION_DOCUMENTS_API_REQUESTED,
  FETCH_RECONCILIATION_DOCUMENTS_STORE_KEY,
  ReconciliationDocumentsActionTypes,
} from './reconciliation-document.actions';
import { getReconciliationDocumentsPagination } from './reconciliation-document.selectors';
import { FetchReconciliationDocumentsPayload } from './reconciliation-document.types';

export const RECONCILIATION_DOCUMENTS_PAGE_SIZE = 10;

// ****************
// WORKERS
// ****************

export function* fetchReconciliationDocument({ payload }: { type: string; payload: FetchReconciliationDocumentsPayload }) {
  try {
    const api: ReconciliationDocumentsApi = yield createReconciliationDocumentsApi();
    const pagination = yield select(getReconciliationDocumentsPagination);
    const apiResponse: AxiosResponse<SearchKpiDocumentResultsDTO> = yield call(
      runSagaWithAuth(() =>
        api.searchReconciliationDocuments(payload.date, payload.date, pagination.page, RECONCILIATION_DOCUMENTS_PAGE_SIZE)
      )
    );

    const ids: string[] = yield syncEntitiesAndGetResults(apiResponse.data.results, SCHEMA.reconciliationDocuments);

    yield put<ReconciliationDocumentsActionTypes>(fetchReconciliationDocumentsSuccess(apiResponse.data.count, ids));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: FETCH_RECONCILIATION_DOCUMENTS_STORE_KEY,
        message: `Success! Fetched Reconciliation Documents for ${payload.date}`,
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<ReconciliationDocumentsActionTypes>(fetchReconciliationDocumentsFailed(get(e, 'response.data.message', e)));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: FETCH_RECONCILIATION_DOCUMENTS_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

// ****************
// WATCHERS
// ****************
export function* watchReconciliationDocumentSagas() {
  yield takeLatest(FETCH_RECONCILIATION_DOCUMENTS_API_REQUESTED, fetchReconciliationDocument);
}

import { ReferenceListFilters } from './reference-list.types';

export const FETCH_REFERENCES_STORE_KEY = 'REFERENCE_LIST/FETCH_REFERENCES';
export const FETCH_REFERENCES_API_REQUESTED = 'REFERENCE_LIST/FETCH_REFERENCES_API_REQUESTED';
export const FETCH_REFERENCES_API_SUCCESS = 'REFERENCE_LIST/FETCH_REFERENCES_API_SUCCESS';
export const FETCH_REFERENCES_API_FAILED = 'REFERENCE_LIST/FETCH_REFERENCES_API_FAILED';

export const REFERENCES_GOTO_PAGE = 'REFERENCE_LIST/REFERENCES_GOTO_PAGE';
export const REFERENCES_UPDATE_FILTERS = 'REFERENCE_LIST/UPDATE_FILTERS';

export function fetchReferencesRequested() {
  return {
    type: FETCH_REFERENCES_API_REQUESTED
  } as const;
}

export function fetchReferencesSuccess(count: number, ids: string[]) {
  return {
    type: FETCH_REFERENCES_API_SUCCESS,
    payload: {
      count,
      ids
    }
  } as const;
}

export function fetchReferencesFailed(error: string) {
  return {
    type: FETCH_REFERENCES_API_FAILED,
    payload: error
  } as const;
}

export type Page = number | 'next' | 'prev';
export function goToPage(page: Page) {
  return {
    type: REFERENCES_GOTO_PAGE,
    payload: page
  } as const;
}
export function updateFilters(filters: Partial<ReferenceListFilters>) {
  return {
    type: REFERENCES_UPDATE_FILTERS,
    payload: filters
  } as const;
}

export type ReferencingListActionTypes = ReturnType<
  | typeof fetchReferencesRequested
  | typeof fetchReferencesSuccess
  | typeof fetchReferencesFailed
  | typeof goToPage
  | typeof updateFilters
>;

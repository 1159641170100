import { useMemo } from 'react';
import { AppState } from '../root.reducer';
import { TenancyInventoryDTO, TenancyInventoryRespondentDTOResponseEnum } from '@reposit/api-client/dist';
import { getFullClaimById } from '../selectors/claim.selectors';
import { get } from 'lodash';

export const getTenancyInventoryByClaimId =
  (claimId: string) =>
  (state: AppState): TenancyInventoryDTO => {
    const claim = useMemo(() => getFullClaimById(state, claimId), [state]);
    const tenancyInventory = get(claim, 'checkout.tenancy.inventory');

    return tenancyInventory;
  };

export const getFirstInventoryRespondentByClaimId = (claimId: string) => (state: AppState) => {
  const tenancyInventory = getTenancyInventoryByClaimId(claimId)(state);
  if (!tenancyInventory || (tenancyInventory && !tenancyInventory.respondents)) {
    return undefined;
  }
  const respondentsThatConfirmed = tenancyInventory.respondents.filter(
    (r) => r.response === TenancyInventoryRespondentDTOResponseEnum.CONFIRMED
  );
  const firstRespondent = respondentsThatConfirmed.sort(
    (a, b) => new Date(a.respondedAt).getTime() - new Date(b.respondedAt).getTime()
  )[0];

  return firstRespondent;
};

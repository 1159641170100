import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { appConfig } from '../../../appConfig';
import Button from '../../../components/Button';
import FlashMessage from '../../../components/FlashMessage';
import DatePicker from '../../../components/FormFields/DatePicker';
import { A, Header2, Header4, P2 } from '../../../components/Typography';
import { DATE_FORMAT } from '../../../constants/dates';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import {
  fetchReconciliationDocumentsRequested,
  FETCH_RECONCILIATION_DOCUMENTS_STORE_KEY,
} from '../../../redux/reconciliation-document/reconciliation-document.actions';
import { getReconciliationDocuments } from '../../../redux/reconciliation-document/reconciliation-document.selectors';
import { FLASH_MESSAGE_TIMEOUT, useFlashMessage } from '../../FlashMessage';

const Wrapper = styled.div`
  margin-top: 1em;
`;

export const ReconciliationDocs = () => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());

  const recDocs = useSelector(getReconciliationDocuments);

  const isSearchingSelector = createLoadingSelector([FETCH_RECONCILIATION_DOCUMENTS_STORE_KEY]);
  const isSearching = useSelector(isSearchingSelector);
  const [globalFlashMessagePayload, onDismissGlobalFlashMessage] = useFlashMessage([FETCH_RECONCILIATION_DOCUMENTS_STORE_KEY]);

  // fetch on initial render
  useEffect(() => {
    dispatch(fetchReconciliationDocumentsRequested(moment().format('YYYY-MM-DD')));
  }, [dispatch]);

  const onClick = () => {
    dispatch(fetchReconciliationDocumentsRequested(moment(date).format('YYYY-MM-DD')));
  };

  return (
    <Container>
      <Row>
        <Col sm={12}>
          {globalFlashMessagePayload ? (
            <>
              <br />
              <FlashMessage
                onDismiss={onDismissGlobalFlashMessage}
                timeRemaining={FLASH_MESSAGE_TIMEOUT}
                payload={globalFlashMessagePayload}
              />
            </>
          ) : undefined}
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Header2>Reconciliation Documents</Header2>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Header4>Please choose a date below to fetch a daily reconciliation summary</Header4>
          <P2>A new file will be generated every day for the close of the previous day</P2>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <DatePicker
            name="recDocumentsDate"
            value={date}
            dateFormat={DATE_FORMAT}
            onBlur={() => {}}
            placeholder={'DD/MM/YYYY'}
            minDate={moment(appConfig.earliestReconciliationDate, 'YYYY-MM-DD').toDate()}
            maxDate={moment().toDate()}
            onChange={(date) => {
              date && setDate(date);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Wrapper>
            <Button onClick={onClick} buttonType="secondary" disabled={isSearching}>
              Get Documents
            </Button>
          </Wrapper>
        </Col>
      </Row>
      {/* This may need pagination in the future */}
      {recDocs &&
        !!recDocs.length &&
        recDocs.map(({ document }) => {
          return (
            <Row key={document && document.id}>
              <Col sm={12}>
                <Wrapper>
                  <A href={document && document.url} download>
                    {document && document.name}
                  </A>
                </Wrapper>
              </Col>
            </Row>
          );
        })}

      {recDocs && !recDocs.length && (
        <Row>
          <Col sm={12}>
            <Wrapper>
              <Header4>No documents found for this date</Header4>
            </Wrapper>
          </Col>
        </Row>
      )}
    </Container>
  );
};

import React, { useState } from 'react';

import { Container, Button, Content } from './styles';

interface CollapsableContentProps {
  buttonTitle?: string;
}

const CollapsableContent: React.FC<CollapsableContentProps> = ({ buttonTitle, children }) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  return (
    <Container>
      <Button onClick={() => setOpen(!isOpen)}>{buttonTitle || 'Show More +'}</Button>
      <Content className={isOpen ? 'active' : ''}>{children}</Content>
    </Container>
  );
};

export default CollapsableContent;

import React, { Fragment, useCallback, useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce/lib';
import Card from '../../components/Card';
import { ContentContainer } from '../../components/Common';
import Error from '../../components/Error';
import Filter from '../../components/Filter';
import FlashMessage from '../../components/FlashMessage';
import Loading from '../../components/Loading';
import OrganisationCard from '../../components/OrganisationCard';
import Pagination from '../../components/Pagination';
import Search from '../../components/Search';
import Sort from '../../components/Sort';
import { Header2, Header3, P1 } from '../../components/Typography';
import { OrganizationFilter } from '../../constants/organization';
import { createErrorMessageSelector } from '../../redux/error/error.selector';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import {
  fetchOrganizationsRequested,
  FETCH_ORGANIZATIONS_STORE_KEY,
  goToPage,
  Page,
  updateFilters as updateFiltersActionCreator,
} from '../../redux/organization-list/organization-list.actions';
import {
  getOrganizationList,
  getOrganizationListFilters,
  getOrganizationListPagination,
} from '../../redux/organization-list/organization-list.selectors';
import { OrganizationListFilters } from '../../redux/organization-list/organization-list.types';
import { CREATE_ORGANIZATION_STORE_KEY } from '../../redux/organization/organization.actions';
import { FLASH_MESSAGE_TIMEOUT, useFlashMessage } from '../FlashMessage';

interface OrganisationsProps {}

export interface OrganizationFilterOption {
  value: OrganizationFilter;
  label: string;
}

const filters: OrganizationFilterOption[] = [
  {
    value: OrganizationFilter.ALL,
    label: 'All',
  },
  {
    value: OrganizationFilter.AGENCY,
    label: 'Agency',
  },
  {
    value: OrganizationFilter.BUILD_TO_RENT,
    label: 'Build to Rent',
  },
  {
    value: OrganizationFilter.PRIVATE,
    label: 'Private',
  },
  {
    value: OrganizationFilter.INTEGRATOR_REFERRAL,
    label: 'Integrator Referral',
  },
];

const Organisations: React.FC<OrganisationsProps> = () => {
  const dispatch = useDispatch();
  const fetchOrganizations = useCallback(() => dispatch(fetchOrganizationsRequested()), [dispatch]);
  const [flashMessage, dismissFlashMessage] = useFlashMessage([CREATE_ORGANIZATION_STORE_KEY]);

  const organizationsLoadingSelector = createLoadingSelector([FETCH_ORGANIZATIONS_STORE_KEY]);
  const getOrganizationsListError = createErrorMessageSelector([FETCH_ORGANIZATIONS_STORE_KEY]);
  const organizationsGotoPage = useCallback((page: Page) => dispatch(goToPage(page)), [dispatch]);
  const isOrganizationsListFetching = useSelector(organizationsLoadingSelector);
  const error = useSelector(getOrganizationsListError);

  const { sort, query, filter, tenancyStatus } = useSelector(getOrganizationListFilters);
  const [debouncedQuery] = useDebounce(query, 500);
  const { page, count } = useSelector(getOrganizationListPagination);
  const updateFilters = useCallback(
    (filters: Partial<OrganizationListFilters>) => dispatch(updateFiltersActionCreator(filters)),
    [dispatch]
  );

  const organizations = useSelector(getOrganizationList);

  useEffect(() => {
    fetchOrganizations();
  }, [fetchOrganizations, debouncedQuery, sort, filter, tenancyStatus, page]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      updateFilters({ query });
    },
    [query, updateFilters]
  );

  const renderOrganizations = () => {
    if (error) return <Error error={error} />;
    if (isOrganizationsListFetching) return <Loading />;
    if (!isOrganizationsListFetching && !organizations.length)
      return (
        <Card>
          <Header3>No organization found.</Header3>
          <P1>Please try another query or reset the filters and try again.</P1>
        </Card>
      );

    return (
      <Fragment>
        {organizations.map((organization: any) => (
          <OrganisationCard key={organization.id} organization={organization} />
        ))}
        <Pagination count={count} currentPage={page} onNavigate={organizationsGotoPage} />
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Container>
        <Row>
          <Col lg={12}>
            <div style={{ marginBottom: 40 }}>
              {flashMessage ? (
                <FlashMessage payload={flashMessage} onDismiss={dismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMEOUT} />
              ) : undefined}
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col sm={12}>
            <Header2>Organisations</Header2>
            <form onSubmit={handleSubmit}>
              <Search onChange={(query) => updateFilters({ query })} value={query} placeholder="Search organizations..." />
            </form>
          </Col>
        </Row>
        <Row>
          <Col sm={8}>
            <Filter
              label="Filter by"
              onSelect={(filter) => {
                updateFilters({ filter });
              }}
              options={filters}
              selected={filter}
            />
          </Col>
          <Col sm={4}>
            <Sort label="Sort by" onSelect={(sort) => updateFilters({ sort })} options={['Created at']} selected={'0'} />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <ContentContainer style={{ marginTop: 48 }}>{renderOrganizations()}</ContentContainer>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Organisations;

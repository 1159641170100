import React from 'react';
import RepositCard from '../Reposit/RepositCard';
import { Table, Thead, Tbody, Td, Tr } from '../Table';
import { CheckEntity } from '../../redux/entities/entities.types';
import moment from 'moment';
import Button from '../Button';
import { useDispatch } from 'react-redux';
import { overrideReferenceCheckRequested, OVERRIDE_REFERENCE_CHECK_STORE_KEY } from '../../redux/referencing/referencing.actions';
import { useFlashMessage, FLASH_MESSAGE_TIMEOUT } from '../../containers/FlashMessage';
import FlashMessage from '../FlashMessage';
import { CheckDTOStatusEnum } from '@reposit/api-client';

interface ChecksProps {
  checks: CheckEntity[];
}

const Checks: React.FC<ChecksProps> = ({ checks }) => {
  const dispatch = useDispatch();
  const [flashMessagePayload, dismissFlashMessage] = useFlashMessage([OVERRIDE_REFERENCE_CHECK_STORE_KEY]);

  return (
    <RepositCard
      flush
      title="Checks"
      flashMessage={
        flashMessagePayload ? (
          <FlashMessage onDismiss={dismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMEOUT} payload={flashMessagePayload} />
        ) : undefined
      }
    >
      <Table>
        <Thead>
          <Tr>
            <Td>Id</Td>
            <Td>Provider</Td>
            <Td>Type</Td>
            <Td>Status</Td>
            <Td>CreatedAt</Td>
            <Td>Action</Td>
          </Tr>
        </Thead>
        <Tbody>
          {checks.map((check) => (
            <Tr key={check.id}>
              <Td>{check.id}</Td>
              <Td>{check.provider}</Td>
              <Td>{check.type}</Td>
              <Td>{check.status}</Td>
              <Td>{moment(check.createdAt).format('DD/MM/YYYY hh:mm')}</Td>
              <Td>
                {check.status === CheckDTOStatusEnum.INREVIEW ? (
                  <Button onClick={() => dispatch(overrideReferenceCheckRequested(check.id))}>Pass Reference</Button>
                ) : (
                  check.status
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </RepositCard>
  );
};

export default Checks;

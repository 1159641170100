import { get, map, flow, compact, first } from 'lodash';

import { AppState } from '../root.reducer';

const getErrors = (actions: string[], state: AppState) => {
  return map(actions, action => get(state, `error.${action}`));
};

export const createErrorMessageSelector = (actions: string[]) => (state: AppState) => {
  // returns the first error messages for actions
  // * We assume when any request fails on a page that
  //   requires multiple API calls, we shows the first error
  const createSelector = flow(
    getErrors,
    compact,
    first
  );
  return createSelector(
    actions,
    state
  );
};

import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  generateCommissionRequested,
  GENERATE_COMMISSION_STORE_KEY,
  clearCsvURL,
} from '../../../redux/integrator/integrator.actions';
import { Container, Row, Col } from 'react-grid-system';
import { Header2, Header4, Header3, A } from '../../../components/Typography';
import Sort from '../../../components/Sort';
import { getIntegratorCsvURL } from '../../../redux/integrator/integrator.selectors';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import { Action } from '../../../components/Reposit/RepositSummary/styles';
import { createErrorMessageSelector } from '../../../redux/error/error.selector';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import { formatIntegratorOptions } from '../../../utils/common.utils';
import { useIntegrators } from '../../../hooks/use-integrators';
import { FullPageLoader } from '../../../components/Loading';

const Wrapper = styled.div`
  margin-top: 1em;
`;

export const IntegratorCommission = () => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<string>('');
  const [isModalShowing, setIsModalShowing] = useState(false);

  const { isLoading: areIntegratorsLoading, integrators } = useIntegrators();
  const integratorOptions = formatIntegratorOptions(integrators);

  const csvLocation = useSelector(getIntegratorCsvURL);

  const errorSelect = createErrorMessageSelector([GENERATE_COMMISSION_STORE_KEY]);
  const error = useSelector(errorSelect);

  const isGeneratingSelector = createLoadingSelector([GENERATE_COMMISSION_STORE_KEY]);
  const isGenerating = useSelector(isGeneratingSelector);

  useEffect(() => {
    return function clearCsvURLCleanup() {
      dispatch(clearCsvURL());
    };
  }, [dispatch]);

  // once the data has loaded
  // set the selected value when unset initially
  useEffect(() => {
    if (integrators.length && !selected) {
      setSelected(integratorOptions[0].value);
    }
  }, [integrators, integratorOptions, selected]);

  const onClick = useCallback(() => {
    setIsModalShowing(true);
  }, []);

  const getSelectedIntegratorName = useCallback(() => {
    const integrator = integrators.find((i) => i.id === selected);
    return integrator ? integrator.name : '';
  }, [selected, integrators]);

  if (areIntegratorsLoading) return <FullPageLoader />;

  return (
    <Container>
      {isModalShowing && (
        <Modal onDismiss={() => setIsModalShowing(false)}>
          <>
            <Header3>Generate the commission report for {getSelectedIntegratorName()}?</Header3>
            <Header4>This will generate a CSV and an invoice in Xero</Header4>
            <Action>
              <Button buttonType="secondary" noArrow={true} onClick={() => setIsModalShowing(false)}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setIsModalShowing(false);
                  dispatch(generateCommissionRequested(selected));
                }}
              >
                Generate
              </Button>
            </Action>
          </>
        </Modal>
      )}
      <Row>
        <Col sm={12}>
          <Header2>Integrator Commission</Header2>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Header4>Please choose the integrator below</Header4>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Sort label="Integrator" onSelect={setSelected} options={integratorOptions} selected={selected} />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Wrapper>
            <Button onClick={onClick} buttonType="secondary" disabled={isGenerating}>
              Generate
            </Button>
          </Wrapper>
        </Col>
      </Row>
      {csvLocation && (
        <Row>
          <Col sm={12}>
            <Wrapper>
              <A href={csvLocation} download>
                Download CSV
              </A>
            </Wrapper>
          </Col>
        </Row>
      )}
      {error && (
        <Row>
          <Col sm={12}>
            <Wrapper>
              <Header4>Oh no something went wrong</Header4>
            </Wrapper>
          </Col>
        </Row>
      )}
    </Container>
  );
};

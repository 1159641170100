import { TenantDTO, OrderCustomerDTO } from '@reposit/api-client';
import React, { useState } from 'react';
import Modal from '../Modal';
import { Header3, P1 } from '../Typography';
import PaymentPlanForm from './PaymentPlanForm';
import { penceToPounds } from '../../utils/common.utils';
import { useFlashMessage, FLASH_MESSAGE_TIMEOUT } from '../../containers/FlashMessage';
import { CREATE_PAYMENT_PLAN_STORE_KEY } from '../../redux/payment-plan/payment-plan.actions';
import FlashMessage from '../FlashMessage';

interface PaymentPlanModalProps {
  onDismiss: () => void;
  totalBalance?: number;
  orderCustomers: OrderCustomerDTO[];
  tenants: TenantDTO[];
  onSubmit: (values: PaymentPlanFormValues) => Promise<any>;
  isSubmitting: boolean;
}

export enum MANUAL_PAYMENT_TAKEN {
  YES = 'YES',
  NO = 'NO',
}

export enum HISTORIC_PAYMENT_PLAN {
  YES = 'YES',
  NO = 'NO',
}

export enum INTEREST_ADDED {
  YES = 'YES',
  NO = 'NO',
}

export interface PaymentPlanFormValues {
  orderCustomerId: string;
  startDate?: Date;
  amount: number;
  instalments: number;
  manualPaymentsTaken: MANUAL_PAYMENT_TAKEN;
  isHistoric: HISTORIC_PAYMENT_PLAN;
  manualPaymentsTotal?: number;
  interestAdded: INTEREST_ADDED;
  interestAmount?: number;
}

const PaymentPlanModal: React.FC<PaymentPlanModalProps> = ({
  onDismiss,
  totalBalance,
  orderCustomers,
  tenants,
  onSubmit,
  isSubmitting,
}) => {
  const [manualPaymentsTotal, setManualPaymentsTotal] = useState(0);
  const [interestAmount, setInterestAmount] = useState(0);
  const [flashMessagePayload, dismissFlashMessage] = useFlashMessage([CREATE_PAYMENT_PLAN_STORE_KEY]);
  const manualPaymentsPenceValue = manualPaymentsTotal * 100;
  const interestPenceValue = interestAmount * 100;
  return (
    <Modal onDismiss={onDismiss} scrollable fixHeight>
      {flashMessagePayload ? (
        <FlashMessage payload={flashMessagePayload} onDismiss={dismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMEOUT} />
      ) : null}
      <Header3 style={{ marginBottom: 15 }}>Open payment plan</Header3>
      {totalBalance ? (
        <>
          <P1>
            The remaining balance is{' '}
            {
              <span style={{ fontWeight: 'bold' }}>
                £{penceToPounds(totalBalance - manualPaymentsPenceValue + interestPenceValue)}
                {interestPenceValue ? ` (£${penceToPounds(interestPenceValue)} interest)` : ''}
              </span>
            }
            .
          </P1>

          <PaymentPlanForm
            tenants={tenants}
            orderCustomers={orderCustomers}
            originalAmountRemaining={totalBalance}
            totalBalance={totalBalance - manualPaymentsPenceValue}
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
            onDismiss={onDismiss}
            setManualPaymentsTotal={setManualPaymentsTotal}
            setInterestAmount={setInterestAmount}
            interestAmount={interestAmount}
          />
        </>
      ) : (
        <P1>This Claim is not in a payable state, therefore no Payment Plan can be created.</P1>
      )}
    </Modal>
  );
};

export default PaymentPlanModal;

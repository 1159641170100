import { merge } from 'lodash';
import { combineReducers, AnyAction } from 'redux';

import { EntityState } from './entities.types';
import { EntityActionTypes, UPDATE_ENTITIES } from './entities.actions';
import { tenancyEntityReducer } from './reducers/tenancy.entity.reducer';
import { tenantEntityReducer } from './reducers/tenant.entity.reducer';
import { claimEntityReducer } from './reducers/claim.entity.reducer';
import { claimItemEntityReducer } from './reducers/claim-item.entity.reducer';
import { tenancyOrderEntityReducer } from './reducers/tenancy-order.entity.reducer';

const initialState: EntityState = {
  address: {},
  document: {},
  landlord: {},
  order: {},
  property: {},
  tenancy: {},
  tenancyOrder: {},
  user: {},
  tenant: {},
  organization: {},
  organizationInvite: {},
  organizationUser: {},
  organizationBankAccount: {},
  orderCustomer: {},
  orderCustomerAction: {},
  claim: {},
  reference: {},
  check: {},
  claimItem: {},
  claimDocument: {},
  claimItemDocument: {},
  claimItemProposal: {},
  claimProposal: {},
  checkout: {},
  integrator: {},
  arbitration: {},
  arbitrationDocument: {},
  kpiDocument: {},
  tenancyAnniversary: {},
  organizationRecipient: {},
  tenancyOrderNote: {},
  paymentPlan: {},
  paymentPlanInstalment: {},
  reconciliationDocument: {},
  internalTeamMember: {},
  organizationInternalOwner: {},
};

export default (state: EntityState = initialState, action: EntityActionTypes) => {
  switch (action.type) {
    case UPDATE_ENTITIES:
      // this does not mutate the orginal state - v important
      return merge({}, state, action.payload);
  }

  return combineReducers<EntityState>({
    address: (state: any = {}, action: AnyAction) => state,
    document: (state: any = {}, action: AnyAction) => state,
    landlord: (state: any = {}, action: AnyAction) => state,
    order: (state: any = {}, action: AnyAction) => state,
    property: (state: any = {}, action: AnyAction) => state,
    tenancy: tenancyEntityReducer,
    tenancyOrder: tenancyOrderEntityReducer,
    user: (state: any = {}, action: AnyAction) => state,
    tenant: tenantEntityReducer,
    organization: (state: any = {}, action: AnyAction) => state,
    organizationInvite: (state: any = {}, action: AnyAction) => state,
    organizationUser: (state: any = {}, action: AnyAction) => state,
    orderCustomer: (state: any = {}, action: AnyAction) => state,
    orderCustomerAction: (state: any = {}, action: AnyAction) => state,
    claim: claimEntityReducer,
    claimItem: claimItemEntityReducer,
    claimItemDocument: (state: any = {}, action: AnyAction) => state,
    claimItemProposal: (state: any = {}, action: AnyAction) => state,
    claimProposal: (state: any = {}, action: AnyAction) => state,
    checkout: (state: any = {}, action: AnyAction) => state,
    claimDocument: (state: any = {}, action: AnyAction) => state,
    reference: (state: any = {}, action: AnyAction) => state,
    check: (state: any = {}, action: AnyAction) => state,
    integrator: (state: any = {}, action: AnyAction) => state,
    arbitration: (state: any = {}, action: AnyAction) => state,
    arbitrationDocument: (state: any = {}, action: AnyAction) => state,
    kpiDocument: (state: any = {}, action: AnyAction) => state,
    tenancyAnniversary: (state: any = {}, action: AnyAction) => state,
    organizationRecipient: (state: any = {}, action: AnyAction) => state,
    tenancyOrderNote: (state: any = {}, action: AnyAction) => state,
    organizationBankAccount: (state: any = {}, action: AnyAction) => state,
    paymentPlan: (state: any = {}, action: AnyAction) => state,
    paymentPlanInstalment: (state: any = {}, action: AnyAction) => state,
    reconciliationDocument: (state: any = {}, action: AnyAction) => state,
    internalTeamMember: (state: any = {}, action: AnyAction) => state,
    organizationInternalOwner: (state: any = {}, action: AnyAction) => state,
  })(state, action);
};

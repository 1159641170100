export enum UsersFilter {
  ALL = '',
  AGENT = 'AGENT',
  ARBITRATOR = 'ARBITRATOR',
  LANDLORD = 'LANDLORD',
  TENANT = 'TENANT',
  ORGANIZATION_ADMIN = 'ORGANIZATION_ADMIN',
  INTERNAL = 'INTERNAL',
}

export enum UsersSort {
  CREATED_AT = 'CREATED_AT',
}

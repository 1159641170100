import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { getBreakpoint } from '../../base/style';
import Button from '../../components/Button';
import { FullPageLoader } from '../../components/Loading';
import Modal from '../../components/Modal';
import RepositCard from '../../components/Reposit/RepositCard';
import Sort from '../../components/Sort';
import { Header3, Header4, P2 } from '../../components/Typography';
import { Option } from '../../interfaces/option.interface';
import { notifyIntegratorRequested } from '../../redux/integrator/integrator.actions';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import { fetchOrganizationUsersRequested, FETCH_ORGANIZATION_USERS_STORE_KEY } from '../../redux/user/user.actions';
import { getOrganizationUsers } from '../../redux/user/user.selectors';

const TeamListWrapper = styled.ul`
  background: #fff;
  margin: 22px 0 36px;
  min-width: 200px;
  overflow: hidden;
  padding: 0;
`;

const TeamMemberItem = styled.div<{ isOpen?: boolean }>`
  background: ${(props) => props.theme.colors.bgAccent};
  margin: 0 0 6px;
  padding: 0 24px 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    padding: ${(props) => (props.isOpen ? `34px` : `5px 34px`)};
  }

  &:hover {
    background: ${(props) => (!props.isOpen ? `#f2f3f4` : props.theme.colors.bgAccent)};
  }
`;

const TeamMemberItemInner = styled.li`
  border-radius: 8px;
  cursor: default;
  box-sizing: border-box;
  padding: 12px 0;
  height: 96px;

  @media all and (min-width: ${getBreakpoint('md')}) {
    height: 112px;
  }

  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 48px;
    padding: 0;
  }
`;

const TeamMember = styled.div`
  margin-bottom: 6px;
  @media all and (min-width: ${getBreakpoint('lg')}) {
    margin-bottom: 0;
  }
`;

const Name = styled(Header4)`
  font-size: 1em;
  margin: 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    margin: 0 10px 0 0;
  }
`;

const Email = styled(P2)`
  margin: 0;
`;

const ViewLink = styled(NavLink)`
  margin: 0 0 0 auto;
`;

const Action = styled.div`
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

const JobTitle = styled.span`
  font-weight: normal;
`;

interface NotifyModalProps {
  onDismiss: () => void;
  integratorOptions: Option[];
  userId: string;
  organizationId: string;
}

const NotifyModal: React.FC<NotifyModalProps> = ({ onDismiss, integratorOptions, userId, organizationId }) => {
  const dispatch = useDispatch();
  const [integratorId, setIntegratorId] = useState('');
  const onSubmit = () => {
    dispatch(notifyIntegratorRequested({ userId, organizationId, integratorId }));
    onDismiss();
  };
  useEffect(() => {
    setIntegratorId(integratorOptions[0].value);
  }, [integratorOptions]);
  return (
    <Modal onDismiss={onDismiss}>
      <Header3 style={{ marginBottom: 6 }}>Notify Integrator</Header3>
      <P2>
        Please select an integrator below. The integrator will be provided with API credentials for this user, and the user will
        be notified that the integration has been enabled.
      </P2>
      <Sort
        label="Integrator"
        onSelect={(val) => setIntegratorId(val)}
        options={integratorOptions}
        selected={integratorId}
        hideLabel
      />
      <Action>
        <Button buttonType="secondary" noArrow={true} onClick={onDismiss}>
          Cancel
        </Button>
        <Button onClick={onSubmit}>Confirm</Button>
      </Action>
    </Modal>
  );
};

interface OrganizationUsersProps {
  organizationId: string;
  integratorOptions: Option[];
}

const OrganizationUsers: React.FC<OrganizationUsersProps> = ({ organizationId, integratorOptions }) => {
  const dispatch = useDispatch();
  const users = useSelector(getOrganizationUsers);
  const isFetchingLoadingSelector = createLoadingSelector([FETCH_ORGANIZATION_USERS_STORE_KEY]);
  const isOrgLoading = useSelector(isFetchingLoadingSelector);
  const [userId, setUserId] = useState('');

  useEffect(() => {
    dispatch(fetchOrganizationUsersRequested(organizationId));
  }, [dispatch, organizationId]);

  if (!users || isOrgLoading) return <FullPageLoader />;

  return (
    <Container>
      {userId ? (
        <NotifyModal
          onDismiss={() => setUserId('')}
          integratorOptions={integratorOptions}
          userId={userId}
          organizationId={organizationId}
        />
      ) : null}
      <Row>
        <Col sm={12}>
          <RepositCard title="Organization Users" tooltip="Some text about Organization Users" flush>
            <TeamListWrapper>
              {users.map(({ id, firstName, lastName, email, attributes }) => (
                <TeamMemberItem key={id}>
                  <TeamMemberItemInner>
                    <TeamMember>
                      <Name>
                        {firstName} {lastName}{' '}
                        <JobTitle>{attributes && attributes.jobTitle ? `(${attributes.jobTitle})` : ''}</JobTitle>
                      </Name>
                      <Email>{email}</Email>
                    </TeamMember>
                    <div>
                      <div style={{ marginRight: 10, display: 'inline-block' }}>
                        <Button buttonType="secondary" mini="true" onClick={() => setUserId(id)}>
                          Notify Integrator
                        </Button>
                      </div>
                      <ViewLink to={`/users/${id}`}>
                        <Button buttonType="secondary" mini="true">
                          View
                        </Button>
                      </ViewLink>
                    </div>
                  </TeamMemberItemInner>
                </TeamMemberItem>
              ))}
            </TeamListWrapper>
          </RepositCard>
        </Col>
      </Row>
    </Container>
  );
};

export default OrganizationUsers;

import { ClaimDTO } from '@reposit/api-client';
import {
  CreateClaimPayload,
  UpdateClaimPayload,
  ImproveClaimPayload,
  FetchClaimByIdPayload,
  PublishClaimPayload,
  RequestClaimEvidencesPayload,
  ResolveClaimApiRequestPayload,
  DeactivateClaimApiRequestPayload,
  SetPaidAtApiRequestPayload,
  UpdateAutoChargeApiRequestPayload,
  CreateArbitrationRequestPayload,
  ReopenClaimPayload,
  SetClaimTotalDownloadTotalPayload,
  SetClaimZipProgressPayload,
  DownloadDocumentsPayload,
} from './claim.types';

export const CREATE_CLAIM_STORE_KEY = `CLAIM/CREATE_CLAIM`;
export const CREATE_CLAIM_API_REQUESTED = `CLAIM/CREATE_CLAIM_API_REQUESTED`;
export const CREATE_CLAIM_API_SUCCESS = `CLAIM/CREATE_CLAIM_API_SUCCESS`;
export const CREATE_CLAIM_API_FAILED = `CLAIM/CREATE_CLAIM_API_FAILED`;

export const FETCH_CLAIM_STORE_KEY = `CLAIM/FETCH_CLAIM`;
export const FETCH_CLAIM_API_REQUESTED = `CLAIM/FETCH_CLAIM_API_REQUESTED`;
export const FETCH_CLAIM_API_SUCCESS = `CLAIM/FETCH_CLAIM_API_SUCCESS`;
export const FETCH_CLAIM_API_FAILED = `CLAIM/FETCH_CLAIM_API_FAILED`;

export const CLEAR_CLAIM_HAS_FETCHED = `CLAIM/CLEAR_CLAIM_HAS_FETCHED`;

export const PUBLISH_CLAIM_STORE_KEY = `CLAIM/PUBLISH_CLAIM`;
export const PUBLISH_CLAIM_API_REQUESTED = `CLAIM/PUBLISH_CLAIM_API_REQUESTED`;
export const PUBLISH_CLAIM_API_SUCCESS = `CLAIM/PUBLISH_CLAIM_API_SUCCESS`;
export const PUBLISH_CLAIM_API_FAILED = `CLAIM/PUBLISH_CLAIM_API_FAILED`;

export const UPDATE_CLAIM_STORE_KEY = `CLAIM/UPDATE_CLAIM`;
export const UPDATE_CLAIM_API_REQUESTED = `CLAIM/UPDATE_CLAIM_API_REQUESTED`;
export const UPDATE_CLAIM_API_SUCCESS = `CLAIM/UPDATE_CLAIM_API_SUCCESS`;
export const UPDATE_CLAIM_API_FAILED = `CLAIM/UPDATE_LAIM_API_FAILED`;

export const IMPROVE_CLAIM_STORE_KEY = `CLAIM/IMPROVE_CLAIM`;
export const IMPROVE_CLAIM_API_REQUESTED = `CLAIM/IMPROVE_CLAIM_API_REQUESTED`;
export const IMPROVE_CLAIM_API_SUCCESS = `CLAIM/IMPROVE_CLAIM_API_SUCCESS`;
export const IMPROVE_CLAIM_API_FAILED = `CLAIM/IMPROVE_CLAIM_API_FAILED`;

export const SET_CURRENT_CLAIM_ACTION_MODAL = `CLAIM/SET_CURRENT_CLAIM_ACTION_MODAL`;

export const APPROVE_CLAIM_STORE_KEY = 'CLAIM/APPROVE_CLAIM';
export const APPROVE_CLAIM_API_REQUESTED = 'CLAIM/APPROVE_CLAIM_API_REQUESTED';
export const APPROVE_CLAIM_API_SUCCESS = 'CLAIM/APPROVE_CLAIM_API_SUCCESS';
export const APPROVE_CLAIM_API_FAILED = 'CLAIM/APPROVE_CLAIM_API_FAILED';

export const DECLINE_CLAIM_STORE_KEY = 'CLAIM/DECLINE_CLAIM';
export const DECLINE_CLAIM_API_REQUESTED = 'CLAIM/DECLINE_CLAIM_API_REQUESTED';
export const DECLINE_CLAIM_API_SUCCESS = 'CLAIM/DECLINE_CLAIM_API_SUCCESS';
export const DECLINE_CLAIM_API_FAILED = 'CLAIM/DECLINE_CLAIM_API_FAILED';

export const REQUEST_CLAIM_EVIDENCES_STORE_KEY = 'CLAIM/REQUEST_CLAIM_EVIDENCES';
export const REQUEST_CLAIM_EVIDENCES_API_REQUEST = 'CLAIM/REQUEST_CLAIM_EVIDENCES_API_REQUEST';
export const REQUEST_CLAIM_EVIDENCES_API_SUCCESS = 'CLAIM/REQUEST_CLAIM_EVIDENCES_API_SUCCESS';
export const REQUEST_CLAIM_EVIDENCES_API_FAILED = 'CLAIM/REQUEST_CLAIM_EVIDENCES_API_FAILED';

export const RESOLVE_CLAIM_STORE_KEY = 'CLAIM/RESOLVE_CLAIM';
export const RESOLVE_CLAIM_API_REQUESTED = 'CLAIM/RESOLVE_CLAIM_API_REQUESTED';
export const RESOLVE_CLAIM_API_SUCCESS = 'CLAIM/RESOLVE_CLAIM_API_SUCCESS';
export const RESOLVE_CLAIM_API_FAILED = 'CLAIM/RESOLVE_CLAIM_API_FAILED';

export const DEACTIVATE_CLAIM_STORE_KEY = 'CLAIM/DEACTIVATE_CLAIM';
export const DEACTIVATE_CLAIM_API_REQUESTED = 'CLAIM/DEACTIVATE_CLAIM_API_REQUESTED';
export const DEACTIVATE_CLAIM_API_SUCCESS = 'CLAIM/DEACTIVATE_CLAIM_API_SUCCESS';
export const DEACTIVATE_CLAIM_API_FAILED = 'CLAIM/DEACTIVATE_CLAIM_API_FAILED';

export const SET_PAID_AT_CLAIM_STORE_KEY = 'CLAIM/SET_PAID_AT_CLAIM';
export const SET_PAID_AT_CLAIM_API_REQUESTED = 'CLAIM/SET_PAID_AT_CLAIM_API_REQUESTED';
export const SET_PAID_AT_CLAIM_API_SUCCESS = 'CLAIM/SET_PAID_AT_CLAIM_API_SUCCESS';
export const SET_PAID_AT_CLAIM_API_FAILED = 'CLAIM/SET_PAID_AT_CLAIM_API_FAILED';

export const UPDATE_AUTO_CHARGE_STORE_KEY = 'CLAIM/UPDATE_AUTO_CHARGE';
export const UPDATE_AUTO_CHARGE_API_REQUESTED = 'CLAIM/UPDATE_AUTO_CHARGE_API_REQUESTED';
export const UPDATE_AUTO_CHARGE_API_SUCCESS = 'CLAIM/UPDATE_AUTO_CHARGE_API_SUCCESS';
export const UPDATE_AUTO_CHARGE_API_FAILED = 'CLAIM/UPDATE_AUTO_CHARGE_API_FAILED';

export const CREATE_ARBITRATION_STORE_KEY = 'CLAIM/CREATE_ARBITRATION';
export const CREATE_ARBITRATION_API_REQUESTED = 'CLAIM/CREATE_ARBITRATION_API_REQUESTED';
export const CREATE_ARBITRATION_API_SUCCESS = 'CLAIM/CREATE_ARBITRATION_API_SUCCESS';
export const CREATE_ARBITRATION_API_FAILED = 'CLAIM/CREATE_ARBITRATION_API_FAILED';

export const REOPEN_CLAIM_STORE_KEY = 'CLAIM/REOPEN_CLAIM';
export const REOPEN_CLAIM_API_REQUESTED = 'CLAIM/REOPEN_CLAIM_API_REQUESTED';
export const REOPEN_CLAIM_API_SUCCESS = 'CLAIM/REOPEN_CLAIM_API_SUCCESS';
export const REOPEN_CLAIM_API_FAILED = 'CLAIM/REOPEN_CLAIM_API_FAILED';

export const DOWNLOAD_DOCUMENTS_STORE_KEY = 'CLAIM/DOWNLOAD_DOCUMENTS';
export const DOWNLOAD_DOCUMENTS_API_REQUESTED = `CLAIM/DOWNLOAD_DOCUMENTS_API_REQUESTED`;
export const DOWNLOAD_DOCUMENTS_API_SUCCESS = 'CLAIM/DOWNLOAD_DOCUMENTS_API_SUCCESS';
export const DOWNLOAD_DOCUMENTS_API_FAILED = 'CLAIM/DOWNLOAD_DOCUMENTS_API_FAILED';

export const SET_CURRENT_CLAIM_ZIP_DOWNLOAD_TOTAL = 'CLAIM/SET_CURRENT_CLAIM_ZIP_DOWNLOAD_TOTAL';
export const SET_CURRENT_CLAIM_ZIP_DOWNLOAD_ZIP_PROGRESS = 'CLAIM/SET_CURRENT_CLAIM_ZIP_DOWNLOAD_ZIP_PROGRESS';
export const INCREMENT_CURRENT_CLAIM_ZIP_DOWNLOAD_CURRENT = 'CLAIM/INCREMENT_CURRENT_CLAIM_ZIP_DOWNLOAD_CURRENT';
export const FINISH_CURRENT_CLAIM_ZIP_DOWNLOAD = 'CLAIM/FINISH_CURRENT_CLAIM_ZIP_DOWNLOAD';
export const START_CURRENT_CLAIM_ZIP_DOWNLOAD = 'CLAIM/START_CURRENT_CLAIM_ZIP_DOWNLOAD';

export const DOWNLOAD_DOCUMENTS_LOCALLY = 'CLAIM/DOWNLOAD_DOCUMENTS_LOCALLY';

export const WAIVE_CLAIM_DISPUTE_FEE_STORE_KEY = 'CLAIM/WAIVE_CLAIM_DISPUTE_FEE';

export const GENERATE_ADR_FORM_STORE_KEY = 'CLAIM/GENERATE_ADR_FORM';

/* -------------------------------------------- CREATE CLAIM -------------------------------------------- */
export function createClaimRequested(payload: CreateClaimPayload) {
  return {
    type: CREATE_CLAIM_API_REQUESTED,
    payload,
  } as const;
}
export function createClaimSuccess() {
  return {
    type: CREATE_CLAIM_API_SUCCESS,
  } as const;
}
export function createClaimFailed(error: string) {
  return {
    type: CREATE_CLAIM_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- FETCH CLAIM -------------------------------------------- */
export function fetchClaimRequested(payload: FetchClaimByIdPayload) {
  return {
    type: FETCH_CLAIM_API_REQUESTED,
    payload,
  } as const;
}
export function fetchClaimSuccess() {
  return {
    type: FETCH_CLAIM_API_SUCCESS,
  } as const;
}
export function fetchClaimFailed(error: string) {
  return {
    type: FETCH_CLAIM_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- CLEAR TENANCY ORDER HAS FETCHED -------------------------------------------- */
export function clearClaimHasFetched() {
  return {
    type: CLEAR_CLAIM_HAS_FETCHED,
  } as const;
}

/* -------------------------------------------- PUBLISH CLAIM -------------------------------------------- */
export function publishClaimRequested(payload: PublishClaimPayload) {
  return {
    type: PUBLISH_CLAIM_API_REQUESTED,
    payload,
  } as const;
}

export function publishClaimSuccess(payload: ClaimDTO) {
  return {
    type: PUBLISH_CLAIM_API_SUCCESS,
    payload,
  } as const;
}

export function publishClaimFailed(error: string) {
  return {
    type: PUBLISH_CLAIM_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- UPDATE CLAIM -------------------------------------------- */
export function updateClaimRequested(payload: UpdateClaimPayload) {
  return {
    type: UPDATE_CLAIM_API_REQUESTED,
    payload,
  } as const;
}

export function updateClaimSuccess(payload: ClaimDTO) {
  return {
    type: UPDATE_CLAIM_API_SUCCESS,
    payload,
  } as const;
}

export function updateClaimFailed(error: string) {
  return {
    type: UPDATE_CLAIM_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- IMPROVE CLAIM -------------------------------------------- */
export function improveClaimRequested(payload: ImproveClaimPayload) {
  return {
    type: IMPROVE_CLAIM_API_REQUESTED,
    payload,
  } as const;
}

export function improveClaimSuccess(payload: ClaimDTO) {
  return {
    type: IMPROVE_CLAIM_API_SUCCESS,
    payload,
  } as const;
}

export function improveClaimFailed(error: string) {
  return {
    type: IMPROVE_CLAIM_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- SET CURRENT CLAIM ACTION MODAL -------------------------------------------- */
export function setCurrentClaimActionModal(payload: string) {
  return {
    type: SET_CURRENT_CLAIM_ACTION_MODAL,
    payload,
  } as const;
}

/* -------------------------------------------- APPROVE CLAIM -------------------------------------------- */
export function approveClaimRequested(claimId: string) {
  return {
    type: APPROVE_CLAIM_API_REQUESTED,
    payload: claimId,
  } as const;
}

export function approveClaimSuccess() {
  return {
    type: APPROVE_CLAIM_API_SUCCESS,
  } as const;
}

export function approveClaimFailed(error: string) {
  return {
    type: APPROVE_CLAIM_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- DECLINE CLAIM -------------------------------------------- */
export function declineClaimRequested(claimId: string) {
  return {
    type: DECLINE_CLAIM_API_REQUESTED,
    payload: claimId,
  } as const;
}

export function declineClaimSuccess() {
  return {
    type: DECLINE_CLAIM_API_SUCCESS,
  } as const;
}

export function declineClaimFailed(error: string) {
  return {
    type: DECLINE_CLAIM_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- REQUEST CLAIM EVIDENCES -------------------------------------------- */
export function requestClaimEvidencesRequested(payload: RequestClaimEvidencesPayload) {
  return {
    type: REQUEST_CLAIM_EVIDENCES_API_REQUEST,
    payload,
  } as const;
}

export function requestClaimEvidencesSuccess() {
  return {
    type: REQUEST_CLAIM_EVIDENCES_API_SUCCESS,
  } as const;
}

export function requestClaimEvidencesFailed(error: string) {
  return {
    type: REQUEST_CLAIM_EVIDENCES_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- RESOLVE CLAIM -------------------------------------------- */
export function resolveClaimApiRequested(payload: ResolveClaimApiRequestPayload) {
  return {
    type: RESOLVE_CLAIM_API_REQUESTED,
    payload,
  } as const;
}

export function resolveClaimApiSuccess(claim: ClaimDTO) {
  return {
    type: RESOLVE_CLAIM_API_SUCCESS,
    payload: claim,
  } as const;
}

export function resolveClaimApiFailed(error: string) {
  return {
    type: RESOLVE_CLAIM_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- DEACTIVATE CLAIM -------------------------------------------- */
export function deactivateClaimApiRequested(payload: DeactivateClaimApiRequestPayload) {
  return {
    type: DEACTIVATE_CLAIM_API_REQUESTED,
    payload,
  } as const;
}

export function deactivateClaimApiSuccess(claim: ClaimDTO) {
  return {
    type: DEACTIVATE_CLAIM_API_SUCCESS,
    payload: claim,
  } as const;
}

export function deactivateClaimApiFailed(error: string) {
  return {
    type: DEACTIVATE_CLAIM_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- SET PAID AT -------------------------------------------- */
export function setPaidAtApiRequested(payload: SetPaidAtApiRequestPayload) {
  return {
    type: SET_PAID_AT_CLAIM_API_REQUESTED,
    payload,
  } as const;
}

export function setPaidAtApiSuccess() {
  return {
    type: SET_PAID_AT_CLAIM_API_SUCCESS,
  } as const;
}

export function setPaidAtApiFailed(error: string) {
  return {
    type: SET_PAID_AT_CLAIM_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- UPDATE AUTO CHARGE CLAIM -------------------------------------------- */
export function updateAutoChargeApiRequested(payload: UpdateAutoChargeApiRequestPayload) {
  return {
    type: UPDATE_AUTO_CHARGE_API_REQUESTED,
    payload,
  } as const;
}

export function updateAutoChargeApiSuccess() {
  return {
    type: UPDATE_AUTO_CHARGE_API_SUCCESS,
  } as const;
}

export function updateAutoChargeApiFailed(error: string) {
  return {
    type: UPDATE_AUTO_CHARGE_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- CREATE ARBITRATION -------------------------------------------- */
export function createArbitrationApiRequested(payload: CreateArbitrationRequestPayload) {
  return {
    type: CREATE_ARBITRATION_API_REQUESTED,
    payload,
  } as const;
}

export function createArbitrationApiSuccess() {
  return {
    type: CREATE_ARBITRATION_API_SUCCESS,
  } as const;
}

export function createArbitrationApiFailed(err: string) {
  return {
    type: CREATE_ARBITRATION_API_FAILED,
    payload: err,
  } as const;
}

/* -------------------------------------------- REOPEN CLAIM -------------------------------------------- */
export function reopenClaimApiRequested(payload: ReopenClaimPayload) {
  return {
    type: REOPEN_CLAIM_API_REQUESTED,
    payload,
  } as const;
}

export function reopenClaimApiSuccess() {
  return {
    type: REOPEN_CLAIM_API_SUCCESS,
  } as const;
}

export function reopenClaimApiFailed(err: string) {
  return {
    type: REOPEN_CLAIM_API_FAILED,
    payload: err,
  } as const;
}

/* -------------------------------------------- DOWNLOAD DOCUMENTS -------------------------------------------- */
export function downloadDocumentsApiRequested(payload: DownloadDocumentsPayload) {
  return {
    type: DOWNLOAD_DOCUMENTS_API_REQUESTED,
    payload,
  } as const;
}

export function downloadDocumentsApiSuccess() {
  return {
    type: DOWNLOAD_DOCUMENTS_API_SUCCESS,
  } as const;
}

export function downloadDocumentsApiFailed(error: string) {
  return {
    type: DOWNLOAD_DOCUMENTS_API_FAILED,
    payload: error,
  } as const;
}

export function downloadDocumentsLocally(payload: string) {
  return {
    type: DOWNLOAD_DOCUMENTS_LOCALLY,
    payload,
  } as const;
}

export function setCurrentClaimZipDownloadTotal(payload: SetClaimTotalDownloadTotalPayload) {
  return {
    type: SET_CURRENT_CLAIM_ZIP_DOWNLOAD_TOTAL,
    payload,
  } as const;
}

export function setCurrentClaimZipDownloadZipProgress(payload: SetClaimZipProgressPayload) {
  return {
    type: SET_CURRENT_CLAIM_ZIP_DOWNLOAD_ZIP_PROGRESS,
    payload,
  } as const;
}

export function incrementCurrentClaimZipDownloadCurrent(claimId: string) {
  return {
    type: INCREMENT_CURRENT_CLAIM_ZIP_DOWNLOAD_CURRENT,
    payload: claimId,
  } as const;
}

export function finishCurrentClaimZipDownload(claimId: string) {
  return {
    type: FINISH_CURRENT_CLAIM_ZIP_DOWNLOAD,
    payload: claimId,
  } as const;
}

export function startCurrentClaimZipDownload(claimId: string) {
  return {
    type: START_CURRENT_CLAIM_ZIP_DOWNLOAD,
    payload: claimId,
  } as const;
}

export type ClaimActionTypes = ReturnType<
  | typeof createClaimRequested
  | typeof createClaimSuccess
  | typeof createClaimFailed
  | typeof fetchClaimRequested
  | typeof fetchClaimSuccess
  | typeof fetchClaimFailed
  | typeof clearClaimHasFetched
  | typeof publishClaimRequested
  | typeof publishClaimSuccess
  | typeof publishClaimFailed
  | typeof improveClaimRequested
  | typeof improveClaimSuccess
  | typeof improveClaimFailed
  | typeof updateClaimRequested
  | typeof updateClaimSuccess
  | typeof updateClaimFailed
  | typeof setCurrentClaimActionModal
  | typeof approveClaimRequested
  | typeof approveClaimSuccess
  | typeof approveClaimFailed
  | typeof declineClaimRequested
  | typeof declineClaimSuccess
  | typeof declineClaimFailed
  | typeof requestClaimEvidencesRequested
  | typeof requestClaimEvidencesSuccess
  | typeof requestClaimEvidencesFailed
  | typeof resolveClaimApiRequested
  | typeof resolveClaimApiSuccess
  | typeof resolveClaimApiFailed
  | typeof deactivateClaimApiRequested
  | typeof deactivateClaimApiSuccess
  | typeof deactivateClaimApiFailed
  | typeof setPaidAtApiRequested
  | typeof setPaidAtApiSuccess
  | typeof setPaidAtApiFailed
  | typeof updateAutoChargeApiRequested
  | typeof updateAutoChargeApiSuccess
  | typeof updateAutoChargeApiFailed
  | typeof createArbitrationApiRequested
  | typeof createArbitrationApiSuccess
  | typeof createArbitrationApiFailed
  | typeof reopenClaimApiRequested
  | typeof reopenClaimApiSuccess
  | typeof reopenClaimApiFailed
  | typeof downloadDocumentsApiRequested
  | typeof downloadDocumentsApiSuccess
  | typeof downloadDocumentsApiFailed
  | typeof setCurrentClaimZipDownloadTotal
  | typeof finishCurrentClaimZipDownload
  | typeof incrementCurrentClaimZipDownloadCurrent
  | typeof setCurrentClaimZipDownloadZipProgress
  | typeof downloadDocumentsLocally
  | typeof startCurrentClaimZipDownload
>;

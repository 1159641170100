import { PolicyDTO } from '@reposit/api-client';
import React from 'react';
import styled from 'styled-components';
import canopiusLogo from '../../../assets/png/canopius.png';
import repositLogo from '../../../assets/png/icon-full-colour.png';
import novusLogo from '../../../assets/png/novus.png';
import { Header5, P2 } from '../../Typography';
import PolicyStatus from '../PolicyStatus';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface PolicyIdProps {
  underwriterId: string;
}

const PolicyIdLabel = styled(Header5)<PolicyIdProps>`
  align-items: center;
  display: flex;
  margin: 0 14px 0 0;
  padding: 0;

  &:before {
    ${(props) =>
      props.underwriterId === 'CANO' &&
      `
      background: transparent url(${canopiusLogo}) no-repeat center center;
      background-size: 24px;
      `}
    ${(props) =>
      props.underwriterId === 'NOVUS' &&
      `
      background: transparent url(${novusLogo}) no-repeat center center;
      background-size: 18px;
      `}
      ${(props) =>
      props.underwriterId === 'REPOSIT' &&
      `
      background: transparent url(${repositLogo}) no-repeat center center;
      background-size: 24px;
      `}
      background-size: 24px;
    content: '';
    display: block;
    height: 20px;
    margin: -3px 10px 0 0;
    width: 20px;
  }
`;

const PolicyDatesText = styled(P2)`
  color: ${(props) => props.theme.colors.body2};
  margin-bottom: 0;
`;

interface PolicyHeaderProps {
  policy: PolicyDTO;
}

const PolicyHeader: React.FC<PolicyHeaderProps> = ({ policy: { id, startDate, endDate, status, type } }) => {
  return (
    <Container>
      <PolicyIdLabel underwriterId={type.underwriter.id}>{id}</PolicyIdLabel>
      <PolicyDatesText>
        {startDate} to {endDate}
      </PolicyDatesText>
      <PolicyStatus status={status || ''} />
    </Container>
  );
};

export default PolicyHeader;

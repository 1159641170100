import { ClaimDTOStatusEnum, ClaimWithCheckoutRelationsDTO } from '@reposit/api-client';
import { denormalize } from 'normalizr';

import { ClaimEntity, DocumentEntity } from '../entities/entities.types';
import { AppState } from '../root.reducer';
import { getClaimDocumentById } from './claim-document.selectors';
import { getDocumentById } from './document.selectors';
import SCHEMA from '../schema';

export const getFullClaimById = (state: AppState, claimId: string): ClaimWithCheckoutRelationsDTO => {
  const claim = denormalize(state.entities.claim[claimId], SCHEMA.claim, state.entities);
  return claim;
};

export const getClaimById = (state: AppState, claimId?: string): ClaimEntity | undefined => {
  if (!state.entities.claim) return undefined;
  if (!claimId) return undefined;

  return state.entities.claim[claimId];
};

export const getDetailsFromClaimById = (state: AppState, claimId?: string): string | undefined => {
  const claim = getClaimById(state, claimId);
  if (!claim) return undefined;
  return claim.details;
};

export const getClaimStatusById = (state: AppState, claimId?: string): ClaimDTOStatusEnum | undefined => {
  const claim = getClaimById(state, claimId);
  if (!claim) return undefined;
  return claim.status;
};

export const getClaims = (state: AppState): { [key: string]: ClaimEntity } => {
  return state.entities.claim;
};

export interface GetClaimByTenancyOrderIdProps {
  tenancyOrderId?: string;
}

export const getClaimByTenancyOrderId = (
  state: AppState,
  { tenancyOrderId }: GetClaimByTenancyOrderIdProps
): ClaimEntity | undefined => {
  if (!state.entities.tenancyOrder) return undefined;
  if (!tenancyOrderId) return undefined;
  const tenanyOrder = state.entities.tenancyOrder[tenancyOrderId];
  const tenancy = tenanyOrder && state.entities.tenancy[tenanyOrder.tenancy];
  const checkout = tenancy && state.entities.checkout[tenancy.checkout];
  const claim = checkout && state.entities.claim[checkout.claim];

  return claim;
};

export const getDocumentsFromClaimById = (state: AppState, claimId: string): DocumentEntity[] => {
  const claim = getClaimById(state, claimId);
  if (!claim) return [];

  const claimDocIds = claim.documents || [];

  const docs: DocumentEntity[] = [];

  for (let i = 0; i < claimDocIds.length; i++) {
    const claimDoc = getClaimDocumentById(state, claimDocIds[i]);
    if (!claimDoc) continue;

    const doc = getDocumentById(state, claimDoc.document);
    doc && docs.push(doc);
  }

  return docs;
};

export const getAdditionalEvidenceDocumentsFromClaimById = (state: AppState, claimId: string): DocumentEntity[] => {
  const allDocs = getDocumentsFromClaimById(state, claimId);
  if (!allDocs) return [];
  return allDocs.filter((doc) => doc && doc.typeId === 'ADDITIONAL_EVIDENCE');
};

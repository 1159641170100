import { ExternalAddressDTO } from '@reposit/api-client';

export const FETCH_ORGANIZATION_ADDRESSES_STORE_KEY = 'ADDRESSES/FETCH_ORGANIZATION_ADDRESSES';
export const FETCH_ORGANIZATION_ADDRESSES_API_REQUESTED = `ADDRESSES/FETCH_ORGANIZATION_ADDRESSES_API_REQUESTED`;
export const FETCH_ORGANIZATION_ADDRESSES_API_SUCCESS = 'ADDRESSES/FETCH_ORGANIZATION_ADDRESSES_API_SUCCESS';
export const FETCH_ORGANIZATION_ADDRESSES_API_FAILED = 'ADDRESSES/FETCH_ORGANIZATION_ADDRESSES_API_FAILED';

export const SEARCH_ADDRESSES_STORE_KEY = 'ADDRESSES/SEARCH_ADDRESSES';
export const SEARCH_ADDRESSES_API_REQUESTED = `ADDRESSES/SEARCH_ADDRESSES_API_REQUESTED`;
export const SEARCH_ADDRESSES_API_SUCCESS = 'ADDRESSES/SEARCH_ADDRESSES_API_SUCCESS';
export const SEARCH_ADDRESSES_API_FAILED = 'ADDRESSES/SEARCH_ADDRESSES_API_FAILED';

export const SET_IS_SHOWING_SEARCH_MODAL = 'ADDRESSES/SET_IS_SHOWING_SEARCH_MODAL';
export const ADD_ADDRESS_TO_ORGANIZATION_ADDRESSES = 'ADDRESSES/ADD_ADDRESS_TO_ORGANIZATION_ADDRESSES';

export function fetchOrganizationAddressesRequested() {
  return {
    type: FETCH_ORGANIZATION_ADDRESSES_API_REQUESTED
  } as const;
}

export function fetchOrganizationAddressesSuccess(payload: string[]) {
  return {
    type: FETCH_ORGANIZATION_ADDRESSES_API_SUCCESS,
    payload
  } as const;
}

export function fetchOrganizationAddressesFailed(error: string) {
  return {
    type: FETCH_ORGANIZATION_ADDRESSES_API_FAILED,
    payload: error
  } as const;
}

export function searchAddressesRequested(payload: string) {
  return {
    type: SEARCH_ADDRESSES_API_REQUESTED,
    payload
  } as const;
}

export function searchAddressesSuccess(payload: ExternalAddressDTO[]) {
  return {
    type: SEARCH_ADDRESSES_API_SUCCESS,
    payload
  } as const;
}

export function searchAddressesFailed(error: string) {
  return {
    type: SEARCH_ADDRESSES_API_FAILED,
    payload: error
  } as const;
}

export function setIsShowingSearchModal(value: boolean) {
  return {
    type: SET_IS_SHOWING_SEARCH_MODAL,
    payload: value
  } as const;
}

export function addAddressToOrganizationAddresses(payload: string) {
  return {
    type: ADD_ADDRESS_TO_ORGANIZATION_ADDRESSES,
    payload
  } as const;
}

export type AddressesActionTypes = ReturnType<
  | typeof fetchOrganizationAddressesRequested
  | typeof fetchOrganizationAddressesSuccess
  | typeof fetchOrganizationAddressesFailed
  | typeof searchAddressesRequested
  | typeof searchAddressesSuccess
  | typeof searchAddressesFailed
  | typeof setIsShowingSearchModal
  | typeof addAddressToOrganizationAddresses
>;

import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Icon404 from '../../assets/svg/404.svg';
import { getBreakpoint } from '../../base/style';
import Button from '../../components/Button';
import { Caption, Header2 } from '../../components/Typography';

interface INotFoundViewProps {}

const NotFoundWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 489px;
  position: relative;

  @media screen and (min-width: ${getBreakpoint('md')}) {
    margin: 24px 0 0;
  }

  &:before {
    content: '';
    position: absolute;
    display: block;
    height: 489px;
    margin: 0 0 0 -82px;
    max-width: 630px;
    width: 100%;
    z-index: 0;

    @media screen and (min-width: ${getBreakpoint('md')}) {
      background: transparent url(${Icon404}) no-repeat 0 0;
      background-size: 100%;
    }
  }
`;

const Title = styled(Header2)`
  margin: 0 0 12px;
  padding: 0;
`;

const Inner = styled.div`
  margin: 60px 0 0;
  text-align: center;
  z-index: 1;

  @media screen and (min-width: ${getBreakpoint('md')}) {
    margin: 220px 0 0;
  }
`;

const Message = styled(Caption)`
  padding: 0 0 16px;
`;

const NotFoundView: React.FC<INotFoundViewProps> = props => {
  return (
    <NotFoundWrapper>
      <Inner>
        <Title>Sorry we can’t find that</Title>
        <Message>Looks like that page doesn’t exist. Check the URL is correct.</Message>
        <NavLink to="/" style={{ textDecoration: 'none' }}>
          <Button>Return Home</Button>
        </NavLink>
      </Inner>
    </NotFoundWrapper>
  );
};

export default NotFoundView;

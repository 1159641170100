import React, { Fragment } from 'react';
import Card from '../../Card';
import { Header3 } from '../../Typography';
import styled from 'styled-components';
import { RepositStatusFilter } from '../../../constants/reposit';

interface NoRepositsListProps {
  currentFilter: string;
}

const RepositCard = styled(Card)`
  padding: 18px 5px 2px;
  a {
    text-decoration: none;
  }
`;

const NoRepositsList: React.FC<NoRepositsListProps> = ({ currentFilter }) => {
  let content = (
    <Fragment>
      <Header3>This organization doesn't have any Reposits.</Header3>
    </Fragment>
  );

  if (!currentFilter)
    return (
      <RepositCard>
        <Fragment>{content}</Fragment>
      </RepositCard>
    );

  switch (currentFilter) {
    case RepositStatusFilter.DRAFT:
      content = (
        <Fragment>
          <Header3>This organization doesn't have any draft Reposits.</Header3>
        </Fragment>
      );
      break;

    case RepositStatusFilter.PENDING:
      content = (
        <Fragment>
          <Header3>This organization doesn't currently have any pending Reposits.</Header3>
        </Fragment>
      );
      break;

    case RepositStatusFilter.ACTIVE:
      content = (
        <Fragment>
          <Header3>This organization doesn't currently have any active Reposits.</Header3>
        </Fragment>
      );
      break;

    case RepositStatusFilter.CHECKED_OUT:
      content = (
        <Fragment>
          <Header3>This organization doesn't currently have any checked out Reposits.</Header3>
        </Fragment>
      );
      break;

    case RepositStatusFilter.DELETED:
      content = (
        <Fragment>
          <Header3>This organization doesn't currently have any deleted Reposits.</Header3>
        </Fragment>
      );
      break;

    default:
  }

  return (
    <RepositCard>
      <Fragment>{content}</Fragment>
    </RepositCard>
  );
};

export default NoRepositsList;

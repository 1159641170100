import { UpdatePropertyPayload } from './property.types';

export const FETCH_ORGANIZATION_PROPERTIES_STORE_KEY = 'PROPERTIES/FETCH_ORGANIZATION_PROPERTIES';
export const FETCH_ORGANIZATION_PROPERTIES_API_REQUESTED = `PROPERTIES/FETCH_ORGANIZATION_PROPERTIES_API_REQUESTED`;
export const FETCH_ORGANIZATION_PROPERTIES_API_SUCCESS = 'PROPERTIES/FETCH_ORGANIZATION_PROPERTIES_API_SUCCESS';
export const FETCH_ORGANIZATION_PROPERTIES_API_FAILED = 'PROPERTIES/FETCH_ORGANIZATION_PROPERTIES_API_FAILED';

export const UPDATE_PROPERTY_STORE_KEY = 'PROPERTIES/UPDATE_PROPERTY';
export const UPDATE_PROPERTY_API_REQUESTED = 'PROPERTIES/UPDATE_PROPERTY_API_REQUESTED';
export const UPDATE_PROPERTY_API_SUCCESS = 'PROPERTIES/UPDATE_PROPERTY_API_SUCCESS';
export const UPDATE_PROPERTY_API_FAILED = 'PROPERTIES/UPDATE_PROPERTY_API_FAILED';

export function fetchOrganizationPropertiesRequested() {
  return {
    type: FETCH_ORGANIZATION_PROPERTIES_API_REQUESTED
  } as const;
}

export function fetchOrganizationPropertiesSuccess(payload: string[]) {
  return {
    type: FETCH_ORGANIZATION_PROPERTIES_API_SUCCESS,
    payload
  } as const;
}

export function fetchOrganizationPropertiesFailed(error: string) {
  return {
    type: FETCH_ORGANIZATION_PROPERTIES_API_FAILED,
    payload: error
  } as const;
}

export function updatePropertyRequested(payload: UpdatePropertyPayload) {
  return {
    type: UPDATE_PROPERTY_API_REQUESTED,
    payload
  } as const;
}

export function updatePropertySuccess() {
  return {
    type: UPDATE_PROPERTY_API_SUCCESS
  } as const;
}

export function updatePropertyFailed(error: string) {
  return {
    type: UPDATE_PROPERTY_API_FAILED,
    payload: error
  } as const;
}

export type PropertiesActionTypes = ReturnType<
  | typeof fetchOrganizationPropertiesRequested
  | typeof fetchOrganizationPropertiesSuccess
  | typeof fetchOrganizationPropertiesFailed
  | typeof updatePropertyRequested
  | typeof updatePropertySuccess
  | typeof updatePropertyFailed
>;

import { AddressState } from './address.types';
import {
  AddressesActionTypes,
  FETCH_ORGANIZATION_ADDRESSES_API_SUCCESS,
  FETCH_ORGANIZATION_ADDRESSES_API_FAILED,
  SEARCH_ADDRESSES_API_SUCCESS,
  SET_IS_SHOWING_SEARCH_MODAL,
  ADD_ADDRESS_TO_ORGANIZATION_ADDRESSES
} from './address.actions';

const initialState: AddressState = {
  currentOrganizationAddresses: [],
  searchedAddresses: [],
  haveCurrentOrganizationAddressesBeenFetched: false,
  isShowingSearchModal: false
};

export default (state = initialState, action: AddressesActionTypes): AddressState => {
  switch (action.type) {
    case FETCH_ORGANIZATION_ADDRESSES_API_SUCCESS:
      return {
        ...state,
        currentOrganizationAddresses: action.payload,
        haveCurrentOrganizationAddressesBeenFetched: true
      };
    case FETCH_ORGANIZATION_ADDRESSES_API_FAILED:
      return {
        ...state,
        haveCurrentOrganizationAddressesBeenFetched: true
      };
    case SEARCH_ADDRESSES_API_SUCCESS:
      return {
        ...state,
        searchedAddresses: action.payload
      };
    case SET_IS_SHOWING_SEARCH_MODAL:
      return {
        ...state,
        isShowingSearchModal: action.payload
      };
    case ADD_ADDRESS_TO_ORGANIZATION_ADDRESSES:
      return {
        ...state,
        currentOrganizationAddresses: [...state.currentOrganizationAddresses, action.payload]
      };
    default:
      return state;
  }
};

import {
  SET_FLASH_MESSAGE,
  FlashMessagesActionTypes,
  DISMISS_FLASH_MESSAGE,
  DISMISS_ALL_FLASH_MESSAGES
} from './flash-messages.actions';
import { FlashMessagePayload } from './flash-messages.types';
import { omit } from 'lodash';

const initialState = {};

export default (state: { [key: string]: FlashMessagePayload } = initialState, action: FlashMessagesActionTypes) => {
  switch (action.type) {
    case SET_FLASH_MESSAGE:
      return {
        ...state,
        [action.payload.key]: action.payload
      };

    case DISMISS_FLASH_MESSAGE:
      return omit(state, action.payload);

    case DISMISS_ALL_FLASH_MESSAGES:
      return {};

    default:
      return state;
  }
};

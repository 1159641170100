import { denormalize } from 'normalizr';
import { sortBy, get } from 'lodash';

import { AppState } from '../root.reducer';
import SCHEMA from '../schema';
import {
  OrderCustomerActionDTO,
  TenancyDTO,
  OrderWithOrderCustomersDTO,
  OrderCustomerDTO,
  TenantDTO,
  CheckoutWithClaimDTO,
  TenancyOrderNoteDTO,
} from '@reposit/api-client';

export interface GetTenancyOrderProps {
  tenancyOrderId: string;
}

export interface TenantWithActions extends TenantDTO {
  actions: OrderCustomerActionDTO[];
}

export interface TenancyWithTenantActions extends Omit<TenancyDTO, 'tenants'> {
  tenants: TenantWithActions[];
  checkout: CheckoutWithClaimDTO;
}

export interface TenancyOrderWithActions {
  id: string;
  tenancy: TenancyWithTenantActions;
  order: OrderWithOrderCustomersDTO;
  tenancyOrderNotes: TenancyOrderNoteDTO[];
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  deactivationReason?: string;
}

export const getTenancyOrder = (state: AppState, props: GetTenancyOrderProps): TenancyOrderWithActions | undefined => {
  if (!state.entities.tenancyOrder) return undefined;

  let tenancyOrder = denormalize(state.entities.tenancyOrder[props.tenancyOrderId], SCHEMA.tenancyOrder, state.entities);

  if (tenancyOrder && tenancyOrder.tenancy && tenancyOrder.tenancy.PPM) {
    tenancyOrder.tenancy.PPM = (tenancyOrder.tenancy.PPM / 100).toFixed(2);
  }

  if (get(tenancyOrder, 'tenancy.tenants', []).length) {
    const orderCustomers = get<OrderCustomerDTO[]>(tenancyOrder, 'order.orderCustomers', []);
    const existingTenants = get(tenancyOrder, 'tenancy.tenants');
    const sortedTenants = sortBy(existingTenants, 'createdAt');
    tenancyOrder.tenancy.tenants = sortedTenants;

    if (orderCustomers.length) {
      tenancyOrder.tenancy.tenants = tenancyOrder.tenancy.tenants.map((tenant: TenantDTO) => {
        const userId = tenant.userId;
        const orderCustomer = orderCustomers.find((oc) => oc.customer.userId === userId);
        return {
          ...tenant,
          actions: orderCustomer ? orderCustomer.actions : [],
        };
      });
    }
  }
  return tenancyOrder;
};

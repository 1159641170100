import { SystemState } from './system.types';
import { TOGGLE_MENU, SystemActionTypes } from './system.actions';

const initialState: SystemState = {
  isMobileMenuOpen: false
};

export default (state = initialState, action: SystemActionTypes): SystemState => {
  switch (action.type) {
    case TOGGLE_MENU:
      return {
        ...state,
        isMobileMenuOpen: state.isMobileMenuOpen ? false : true
      };

    default:
      return state;
  }
};
